import {
  RESET_ACCOUNT_DETAILS,
  SET_ACCOUNT_DETAILS_BALANCE,
} from "../../actions/types";
import { users_accountDetails as initialState } from "../../initialState";
import { BigNumber } from "../../../money";

export default function accountDetailsReducer(state = initialState, action) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_ACCOUNT_DETAILS:
      return initialState;
    case SET_ACCOUNT_DETAILS_BALANCE:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        balance: new BigNumber(action.payload.balance).toString(),
      };
    default:
      return state;
  }
}
