const blankReceiverAddressInfo = {
  name: {
    key: "name",
    databaseKey: "name",
    endpointKey: "receiverName",
    label: "Name",
    value: "Current Resident",
    extractValue: "",
    required: true,
    type: "text",
  },
  businessName: {
    key: "businessName",
    databaseKey: "business_name",
    endpointKey: "receiverBusinessName",
    label: "Business Name",
    value: "",
    extractValue: "",
    required: false,
    type: "text",
  },
  addressLineOne: {
    key: "addressLineOne",
    databaseKey: "address_line_one",
    endpointKey: "receiverAddressLineOne",
    label: "Address Line One",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  addressLineTwo: {
    key: "addressLineTwo",
    databaseKey: "address_line_two",
    endpointKey: "receiverAddressLineTwo",
    label: "Address Line Two",
    value: "",
    extractValue: "",
    required: false,
    type: "text",
  },
  city: {
    key: "city",
    databaseKey: "city",
    endpointKey: "receiverCity",
    label: "City",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  state: {
    key: "state",
    databaseKey: "state",
    endpointKey: "receiverState",
    label: "State",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  zipCode: {
    key: "zipCode",
    databaseKey: "zip_code",
    endpointKey: "receiverZipCode",
    label: "Zip Code",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  country: {
    key: "country",
    databaseKey: "country",
    endpointKey: "receiverCountry",
    label: "Country",
    value: "US",
    extractValue: "",
    required: true,
    type: "text",
  },
  redirectUrl: {
    key: "redirectUrl",
    databaseKey: "redirect_url",
    endpointKey: "redirectUrl",
    label: "QR Code (Redirect) Url",
    value: "",
    extractValue: "",
    required: false,
    type: "text",
  },
};

export default blankReceiverAddressInfo;
