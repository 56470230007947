import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import _, { set } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as helpers from "../../helpers";
import { BigNumber } from "../../money";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Chip as MuiChip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { green, orange } from "@material-ui/core/colors";
import { spacing, display } from "@material-ui/system";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  ExpandMore,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import {
  cancelHandWrittenNoteOrderById,
  getHandWrittenNoteOrderById,
  getAllQrCodeScans,
  getAllUserActivities,
  getSharableFileUrl,
  getSingleFile,
  getQrCodeById,
} from "../../backend";
import { getRequestErrorMessage, getUserName } from "../../helpers";
import OrderStatusChip from "../components/OrderStatusChip";

import { Edit, Trash } from "react-feather";
import { Delete as DeleteIcon } from "@material-ui/icons";
import constants from "../../constants";
import { globalHistory } from "../../history";
import MoneyChip from "../components/MoneyChip";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";
import AdvancedTable from "../tables/AdvancedTable";
import qrCodeScanRowColumns from "../../advancedTableConfigs/qrCodeScanRowColumns";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import PathPreview from "../../components/PathPreview";

const Icon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: 0px;
  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.success || props.pending) && props.theme.palette.common.white};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function QrCodeDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);
  let qrCodeId =
    _.get(props, "location.state.qrCodeId", null) ||
    _.get(props, "match.params.qrCodeId", null);
  if (qrCodeId) {
    qrCodeId = parseInt(qrCodeId);
  }

  const [qrCodeData, setQrCodeData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);

  // fetch the order details
  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        const qrCodeDataResults = await getQrCodeById({ qrCodeId });
        setQrCodeData(qrCodeDataResults.data.qrCode);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });
        setQrCodeData(null);
        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (qrCodeId) {
      loadData();
    } else {
      setErrorMessage("The provided QR code id is not valid");
    }

    loadData();
  }, [qrCodeId, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="QR Code Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            QR Code #{qrCodeId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/qr-code">
              Qr Codes
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !qrCodeData ? null : (
        <>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} lg={10}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Hello{" "}
                          {helpers.getUserNamePlus({
                            replaceForEmailConditional: true,
                          })}
                          ,
                          <br />
                          These are details regarding QR Code #{qrCodeId}. You
                          can view the template, order, and scans here.
                        </Typography>
                      </Grid>

                      {qrCodeData?.qr_code_error_message && (
                        <Grid item xs={12}>
                          <Alert severity="error">
                            {qrCodeData?.qr_code_error_message}
                          </Alert>
                        </Grid>
                      )}

                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">QR Code No.</Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={`#${qrCodeId}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Creation Date
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                qrCodeData.createdAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                qrCodeData.createdAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">
                          Applied To Card
                        </Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={
                            qrCodeData?.was_applied === null
                              ? "Unknown"
                              : qrCodeData?.was_applied
                              ? "Yes"
                              : "No"
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {qrCodeData?.template_text_id && (
                            <Grid item>
                              <Button
                                link
                                component={NavLink}
                                variant="outlined"
                                color="primary"
                                to={{
                                  pathname: `/template/${qrCodeData?.template_text_id}`,
                                  state: {
                                    templateId: qrCodeData?.template_text_id,
                                  },
                                }}
                              >
                                View Template
                              </Button>
                            </Grid>
                          )}
                          {qrCodeData?.hand_written_note_order_id && (
                            <Grid item>
                              <Button
                                link
                                component={NavLink}
                                variant="outlined"
                                color="primary"
                                to={{
                                  pathname: `/order/${qrCodeData?.hand_written_note_order_id}`,
                                  state: {
                                    orderId:
                                      qrCodeData?.hand_written_note_order_id,
                                  },
                                }}
                              >
                                View Order
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
            <Grid item xs={12}>
              <AdvancedTable
                isCollapsed={true}
                title="QR Scans"
                rowColumns={qrCodeScanRowColumns}
                identifier={qrCodeId}
                getRowData={async ({ limit, offset, order }) => {
                  let results = await getAllQrCodeScans({
                    qrCodeId: qrCodeId,
                    limit,
                    offset,
                    order,
                  });

                  let parsedRowData = {
                    rows: results.data.qrCodeScans,
                    totalRowCount: results.data.qrCodeScanCount,
                  };

                  return parsedRowData;
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default QrCodeDetails;
