import React, { useEffect, useState, useRef, useCallback } from "react";
import styled, { withTheme, keyframes } from "styled-components/macro";
import dateFormat from "dateformat";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import {
  GoogleMap,
  LoadScript,
  Marker,
  MarkerClusterer,
  StandaloneSearchBox,
  useGoogleMap,
  OverlayView,
  InfoWindow,
  DrawingManager,
  Polygon,
} from "@react-google-maps/api";
import { nanoid } from "nanoid";
import {
  Popover,
  IconButton,
  List,
  ListItem,
  Backdrop,
  FormControlLabel,
  Switch,
  Checkbox,
  CardActionArea,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Alert as MuiAlert, AlertTitle, TabContext } from "@material-ui/lab";
import {
  green,
  orange,
  grey,
  red,
  yellow,
  blue,
} from "@material-ui/core/colors";
import {
  Delete as DeleteIcon,
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
  ExpandMore,
  ArrowDownward as ArrowDownwardIcon,
  Add,
  DeleteForever,
  Edit,
} from "@material-ui/icons";
import RoomIcon from "@material-ui/icons/Room";
import DoneIcon from "@material-ui/icons/Done";
import * as turf from "@turf/turf";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Snackbar,
  TextField as MuiTextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Portal,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardHeader,
  Menu,
  MenuItem,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
  Navigation,
} from "react-feather";
import { MyLocation as MyLocationIcon } from "@material-ui/icons";
import {
  createNewDataExport,
  createNewMapCollection,
  createNewMapCollectionMarker,
  createNewMapCollectionMarkerComment,
  deleteMapCollectionById,
  deleteMapCollectionMarkerById,
  duplicateMapCollectionById,
  editMapCollectionById,
  editMapCollectionMarkerById,
  getAllHandWrittenNoteOrders,
  getAllMapCollectionMarkerComments,
  getAllMapCollectionMarkers,
  getAllQrCodeScans,
  getAllStatistics,
  getAllUserActivities,
  getBalance,
  getDataExportRequestReturnTypes,
  getDataExportTypes,
  getMapCollectionById,
  getUserAvatarUrl,
  placeHandWrittenNoteOrderMapCollection,
} from "../../backend";
import {
  getHandWrittenNoteOrderCardSize,
  getNamedCoordinateCenters,
  getOrderStatusColor,
  getOrderStatusColor_text,
  getRequestErrorMessage,
} from "../../helpers";
import { BigNumber, getDiscountPercentageProps } from "../../money";
import { googleMapsApiKey } from "../../config";
import { store } from "../../redux/store";
import { globalHistory } from "../../history";
import {
  getActiveExtrasCardSizes,
  getActiveExtrasLastKnownLocation,
  getActiveUser,
  getCurrentUserKey,
} from "../../redux/selectors";
import { setUsersData } from "../../redux/actions/usersActions";
import constants from "../../constants";
import AdvancedTable from "../tables/AdvancedTable";
import qrCodeScanRowColumns from "../../advancedTableConfigs/qrCodeScanRowColumns";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import TemplateTextAutoComplete from "../../components/TemplateTextAutoComplete";
import ConnectedTemplateCreateEdit from "./TemplateCreateEdit";
import MapCollectionForm from "./NewOrder/MapCollectionForm";
import HighlightOnView from "../../components/HighlightOnView";
import AvailableFundAccountAutoComplete from "../../components/AvailableFundAccountAutoComplete";
import QuestionMarkPopover from "../components/QuestionMarkPopover";
import MoneyChip from "../components/MoneyChip";
import PercentageChip from "../components/PercentageChip";
import handWrittenNoteOrderRowColumns from "../../advancedTableConfigs/handWrittenNoteOrderRowColumns";
import ms from "ms";
import useTimeText from "../../hooks/useTimeText";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div
        style={{
          paddingBottom: 75,
        }}
      >
        {value === index && (
          <Box
            style={{
              padding: 8,
            }}
          >
            {children}
          </Box>
        )}
      </div>
    </div>
  );
}

const StyledTabPanel = styled(TabPanel)`
  flex: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function pointInPolygon(point, polygon) {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0];
  const y = point[1];

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const DialogBackground = styled(Paper)`
  ${spacing}
  height: 100vh
  background: ${(props) => props.theme.palette.background.default};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ArrowDownward = styled(ArrowDownwardIcon)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

const StyledSidePanel = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: calc(
    100vh -
      (64px + ${(props) => props.padding}px + ${(props) => props.padding}px)
  );
  border-radius: 0px;
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    height: auto;
    min-height: calc(100vh - 64px);
  }
`;

const StyledMapContainer = styled(Paper)`
  height: calc(
    100vh -
      (64px + ${(props) => props.padding}px + ${(props) => props.padding}px)
  );
  border-radius: 0px;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    height: calc(100vh - 64px - 57px);
  }
`;

const StyledTabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  padding-bottom: 8px;
`;

const StyledTabs = styled(Tabs)``;

const ScrollToButton = (props) => {
  const scroll = (event) => {
    // scroll to the button
    event.preventDefault();
    event.target.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <Grid container my={8} justifyContent="center" alignItems="center">
      <Grid item>
        <Button onClick={scroll} color="primary">
          <ArrowDownward mr={2} />

          {props.title && <React.Fragment>{props.title}</React.Fragment>}
        </Button>
      </Grid>
    </Grid>
  );
};

const markerColors = {
  activeLocation: "#5384ED",
  previousOrder: "#FDBF2D",
};

function CollectionSettings(props) {
  const user = useSelector(getActiveUser);
  let collectionId = props.collectionId;
  let collectionData = props.collectionData;
  let setCollectionData = props.setCollectionData;

  const [name, setName] = useState(collectionData.name || "");

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const getChanges = () => {
    const changes = {
      name,
    };
    return changes;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const changes = getChanges();

    const saveChanges = async () => {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      try {
        await editMapCollectionById({
          collectionId,
          ...changes,
        });
        setCollectionData({
          ...collectionData,
          ...changes,
        });
        setSuccessMessage("Collection updated successfully");
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong saving the settings",
          })
        );
      }
      setIsLoading(false);
    };

    saveChanges();
  };

  return (
    <Card mb={2} variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Settings
        </Typography>

        {errorMessage && (
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert mt={2} mb={1} severity="success">
            {successMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            id="name"
            label="Collection Name"
            disabled={isLoading}
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            my={2}
          />

          <Button
            variant="contained"
            color="primary"
            mt={3}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={25} /> : "Save changes"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function CollectionExports(props) {
  const activeUser = useSelector(getActiveUser).user;
  let collectionId = props.collectionId;
  let collectionData = props.collectionData;
  let collectionMarkers = props.collectionMarkers;

  const [isLoadingResources, setIsLoadingResources] = React.useState(true);
  const [resourceErrorMessage, setResourceErrorMessage] = React.useState("");
  const [exportTypes, setExportTypes] = React.useState([]);
  const [returnTypes, setReturnTypes] = React.useState([]);

  const [selectedExportType, setSelectedExportType] = React.useState("");
  const [selectedReturnType, setSelectedReturnType] = React.useState("EXPORT");

  const [selectedFundAccount, setSelectedFundAccount] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  React.useEffect(() => {
    const loadResources = async () => {
      setIsLoadingResources(true);
      setResourceErrorMessage("");

      try {
        const resources = await Promise.all([
          getDataExportTypes(),
          getDataExportRequestReturnTypes(),
        ]);

        setExportTypes(resources[0].data.dataExportTypes);
        setReturnTypes(resources[1].data.dataExportRequestReturnTypes);
      } catch (err) {
        setResourceErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong loading export options",
          })
        );
      }

      setIsLoadingResources(false);
    };

    loadResources();
  }, []);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    try {
      let results = await createNewDataExport(
        {
          targetId: collectionId,
          exportType: selectedExportType,
          returnType: selectedReturnType,
        },
        {
          overrideUseBalanceFromUserId: selectedFundAccount?.user?.id,
        }
      );

      return globalHistory.push(`/export/${results.data.dataExport.id}`, {
        dataExportId: results.data.dataExport.id,
      });
      setSuccessMessage("Export was created successfully.");
    } catch (error) {
      setErrorMessage(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong exporting the collection",
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <Card mb={2} variant="outlined">
      <CardContent>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6">Export</Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  <QuestionMarkPopover>
                    <Typography variant="body2">
                      Exporting data is a one-time process that let's you
                      generate a spreadsheet from your map data.
                      <br />
                      <br />
                      This feature can be especially useful if you want to use
                      your map to go door knocking, share with another person,
                      or using it with a 3rd party application.
                    </Typography>
                  </QuestionMarkPopover>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {isLoadingResources ? (
                <CircularProgress size={32} />
              ) : resourceErrorMessage ? (
                <Alert severity="warning">{resourceErrorMessage}</Alert>
              ) : (
                <>
                  {errorMessage && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errorMessage}
                    </Alert>
                  )}

                  {successMessage && (
                    <Alert mt={2} mb={1} severity="success">
                      {successMessage}
                    </Alert>
                  )}

                  <Grid container mb={4}>
                    <Typography variant="body2">
                      Select the options below to generate an easy to use
                      spreadsheet from information in this map.
                    </Typography>
                  </Grid>

                  <Grid container mb={4}>
                    <AvailableFundAccountAutoComplete
                      selectedFundAccount={selectedFundAccount}
                      setSelectedFundAccount={setSelectedFundAccount}
                      autoCompleteProps={{
                        size: "small",
                        disabled: false,
                      }}
                    />
                  </Grid>

                  <form onSubmit={handleSubmit}>
                    {/* TODO select type */}

                    <Grid container spacing={4}>
                      {exportTypes
                        .filter((exportType) => {
                          return exportType.target === "map_collection";
                        })
                        .map((exportType) => {
                          const isSelected =
                            selectedExportType === exportType.key;

                          let calculatedPrice = new BigNumber(0);
                          let estimatedMarkers = 0;

                          collectionMarkers.forEach((marker) => {
                            if (marker.isPoint) {
                              calculatedPrice = calculatedPrice.plus(
                                exportType.pricing.perPin.base
                              );
                              estimatedMarkers += 1;
                            } else if (marker.isPolygon) {
                              // calculate square footage
                              const polygonCoordinates = [
                                [
                                  ...marker.coordinates,
                                  marker.coordinates[0],
                                ].map((c) => [c[1], c[0]]),
                              ];
                              const polygonMeters = new BigNumber(
                                turf.area(turf.polygon(polygonCoordinates)) || 0
                              );
                              let polygonSqft = polygonMeters.times("10.764");
                              calculatedPrice = calculatedPrice.plus(
                                polygonSqft.times(
                                  exportType.pricing.perSqft.base
                                )
                              );

                              let estimatedMarkersInside = polygonSqft.dividedBy(
                                "10000"
                              );
                              estimatedMarkers += estimatedMarkersInside.toNumber();
                            }
                          });

                          if (exportType.pricing.perGeocode) {
                            calculatedPrice = calculatedPrice.plus(
                              new BigNumber(estimatedMarkers).times(
                                exportType.pricing.perGeocode.base
                              )
                            );
                          }

                          if (exportType.pricing.perAddressVerification) {
                            calculatedPrice = calculatedPrice.plus(
                              new BigNumber(estimatedMarkers).times(
                                exportType.pricing.perAddressVerification.base
                              )
                            );
                          }

                          let {
                            discountPercentage,
                            discountPercentageDecimal,
                          } = getDiscountPercentageProps(
                            activeUser.discount_percentage
                          );

                          if (!discountPercentage.isZero()) {
                            calculatedPrice = calculatedPrice.times(
                              discountPercentageDecimal
                            );
                          }

                          return (
                            <Grid item xs={12} key={exportType.key}>
                              <Card
                                variant="outlined"
                                style={
                                  isSelected
                                    ? {
                                        borderColor: blue[500],
                                      }
                                    : {}
                                }
                              >
                                <CardActionArea
                                  onClick={() => {
                                    if (isSelected) {
                                      setSelectedExportType(null);
                                    } else {
                                      setSelectedExportType(exportType.key);
                                    }
                                  }}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      mb={1}
                                    >
                                      <Grid item>
                                        <Typography variant="h6">
                                          {exportType.name}
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Checkbox checked={isSelected} />
                                      </Grid>
                                    </Grid>
                                    {/* display estimated price */}
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid item>
                                            <Box
                                              mb={3}
                                              style={{
                                                padding: "0 4px",
                                                backgroundColor: grey[100],
                                                borderRadius: 6,
                                              }}
                                            >
                                              <Grid
                                                container
                                                spacing={2}
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <Typography variant="body2">
                                                    <MoneyChip
                                                      amount={calculatedPrice}
                                                      colorLevel={1}
                                                      propsToPass={{
                                                        margin: 0,
                                                      }}
                                                    />
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <Typography variant="body2">
                                                    Estimated price
                                                  </Typography>
                                                </Grid>
                                                <Grid item />
                                              </Grid>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {!discountPercentage.lte(0) && (
                                        <Grid item xs={12}>
                                          <Grid container>
                                            <Grid item>
                                              <Box
                                                mb={4}
                                                style={{
                                                  padding: "0 4px",
                                                  backgroundColor: grey[100],
                                                  borderRadius: 6,
                                                }}
                                              >
                                                <Grid
                                                  container
                                                  spacing={2}
                                                  alignItems="center"
                                                >
                                                  <Grid item>
                                                    <Typography variant="body2">
                                                      <PercentageChip
                                                        amount={discountPercentage.toNumber()}
                                                        colorLevel={1}
                                                        propsToPass={{
                                                          margin: 0,
                                                        }}
                                                      />
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item>
                                                    <Typography variant="body2">
                                                      Savings included
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item />
                                                </Grid>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Typography variant="body1" gutterBottom>
                                      {exportType.description}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          );
                        })}
                    </Grid>

                    <Grid container spacing={2} alignItems="center" mt={4}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            isLoading ||
                            !selectedExportType ||
                            !collectionMarkers ||
                            collectionMarkers.length < 1
                          }
                        >
                          {isLoading ? (
                            <CircularProgress size={25} />
                          ) : (
                            "Create Map Export"
                          )}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="body2" color="textSecondary">
                              Estimated pricing is not exact and may differ from
                              the charged amount.
                            </Typography>
                          </Grid>
                          <Grid item>
                            <QuestionMarkPopover>
                              <Typography variant="body2">
                                Estimated pricing is based on the currently
                                selected map and may not represent the true
                                price. We do our best to estimate as closely as
                                possible, but the price charged may change (-/+)
                                based on conditions present during the actual
                                fulfillment of the export.
                                <br />
                                <br />
                                Markers with exact coordinates are charged at a
                                piece rate, while polygons are charged by the
                                square footage regardless of how many buildings
                                are found inside.
                                <br />
                                <br />
                                Any steps requiring additional address lookups
                                and validation will be charged an additional
                                piece rate.
                              </Typography>
                            </QuestionMarkPopover>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}

function MarkerComment({ comment }) {
  const timeText = useTimeText({ date: comment.createdAt });

  return (
    <Grid item xs={12}>
      <Paper variant="outlined">
        <Grid container>
          <Grid mb={2} item xs={12}>
            <Box
              style={{
                padding: 12,
                paddingBottom: 0,
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    style={{
                      width: 34,
                      height: 34,
                    }}
                    src={getUserAvatarUrl({
                      userId: comment.user_id,
                      size: 34,
                    })}
                  />
                </Grid>
                <Grid item xs>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {comment.user.firstName && comment.user.lastName
                          ? `${comment.user.firstName} ${comment.user.lastName}`
                          : comment.user.firstName
                          ? comment.user.firstName
                          : comment.user.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">{timeText}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                padding: 12,
              }}
            >
              <Typography variant="body1">
                {comment.comment_body.split("\n").map((item, i) => {
                  return (
                    <span key={i}>
                      {item}
                      <br />
                    </span>
                  );
                })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

function CollectionMarkerComments(props) {
  const activeUser = useSelector(getActiveUser).user;
  let collectionId = props.collectionId;
  let collectionData = props.collectionData;
  let selectedCollectionMarker = props.selectedCollectionMarker;

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [commentText, setCommentText] = useState("");
  const [isCommenting, setIsCommenting] = useState(false);

  const handleFetchComments = React.useCallback(async () => {
    if (!selectedCollectionMarker || !selectedCollectionMarker.id) {
      return;
    }

    setIsLoading(true);
    try {
      let results = await getAllMapCollectionMarkerComments({
        markerId: selectedCollectionMarker?.id,
        markerIdentifier: selectedCollectionMarker?.identifier,
        limit: 50,
      });

      setComments(results.data.comments);
      setCommentCount(results.data.commentCount);
    } catch (error) {
      let errorMessage = getRequestErrorMessage({
        error,
        fallbackMessage: "Something went wrong getting the comments.",
      });
    }
    setIsLoading(false);
  }, [activeUser, collectionId, selectedCollectionMarker]);

  React.useEffect(() => {
    setComments([]);
    setCommentCount(0);
    handleFetchComments();
  }, [handleFetchComments]);

  if (!selectedCollectionMarker) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CardContent>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography
                  variant="h6"
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Comments ({commentCount})
                </Typography>
              </Grid>
              {isLoading && (
                <Grid
                  item
                  style={{
                    display: "flex",
                  }}
                >
                  <CircularProgress size={18} color="inherit" />
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <TextField
                          fullWidth
                          multiline
                          minRows={1}
                          maxRows={6}
                          margin={"dense"}
                          style={{
                            margin: 0,
                          }}
                          variant="outlined"
                          label="Add a comment"
                          value={commentText}
                          onChange={(e) => setCommentText(e.target.value)}
                          disabled={isLoading || isCommenting}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isCommenting || isLoading || !commentText}
                          onClick={async () => {
                            setIsCommenting(true);
                            try {
                              let results = await createNewMapCollectionMarkerComment(
                                {
                                  markerId: selectedCollectionMarker?.id,
                                  markerIdentifier:
                                    selectedCollectionMarker?.identifier,
                                  commentBody: commentText,
                                }
                              );

                              setComments((prev) => {
                                return [results.data.comment, ...prev];
                              });
                              setCommentText("");
                            } catch (error) {
                              let errorMessage = getRequestErrorMessage({
                                error,
                                fallbackMessage:
                                  "Something went wrong posting the comment.",
                              });
                              setErrorMessage(errorMessage);
                            }
                            setIsCommenting(false);
                          }}
                        >
                          {isCommenting ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                {!selectedCollectionMarker ? (
                  <Typography variant="body1" color="textSecondary">
                    No marker selected.
                  </Typography>
                ) : isLoading ? (
                  <Typography variant="body1" color="textSecondary">
                    Loading...
                  </Typography>
                ) : !selectedCollectionMarker.id && comments.length === 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    Can't load comments for new markers, but you can still leave
                    a comment.
                  </Typography>
                ) : comments.length === 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    No comments yet.
                  </Typography>
                ) : (
                  <Grid container spacing={2}>
                    {comments.map((comment) => {
                      return (
                        <MarkerComment comment={comment} key={comment.id} />
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}

// this function will handle the logic of all types of location geometry, including polygons.

function CollectionMarkerItem(props) {
  const [polygonRef, setPolygonRef] = React.useState(null);

  const editItem = useCallback((editProps, updateDatabase = true) => {
    let overlaps;
    if (props.item.isPoint) {
      // if the item is a point, we need to check if it's in the bounds of a polygon
      overlaps = props.markers
        .filter((mk) => mk.isPolygon)
        .some((mk) => {
          let polygonFromMarker = new google.maps.Polygon({
            paths: mk.coordinates.map((coord) => {
              return {
                lat: coord[0],
                lng: coord[1],
              };
            }),
          });

          return google.maps.geometry.poly.containsLocation(
            {
              lat: editProps.coordinates[0][0],
              lng: editProps.coordinates[0][1],
            },
            polygonFromMarker
          );
        });

      if (overlaps) {
        props.handleEditSnackbarErrorMessage(
          "Your marker is inside a polygon and may produce duplicate orders"
        );
      }
    } else if (props.item.isPolygon) {
      let polygon = new google.maps.Polygon({
        paths: editProps.coordinates.map((coord) => {
          return {
            lat: coord[0],
            lng: coord[1],
          };
        }),
      });

      overlaps = props.markers
        .filter((mk) => {
          let isItem = false;

          isItem = mk.id && mk.id === props.item.id;

          isItem = mk.identifier && mk.identifier === props.item.identifier;

          return !isItem;
        })
        .some((mk) => {
          let mkCoordinates = mk.coordinates;

          if (mk.isPoint) {
            return google.maps.geometry.poly.containsLocation(
              new google.maps.LatLng(mkCoordinates[0][0], mkCoordinates[0][1]),
              polygon
            );
          } else if (mk.isPolygon) {
            // create a map polygon from the marker coordinates
            // compare it to the new polygon

            let polygonFromMarker = new google.maps.Polygon({
              paths: mkCoordinates.map((coord) => {
                return {
                  lat: coord[0],
                  lng: coord[1],
                };
              }),
            });

            return (
              mkCoordinates.some((coord) =>
                google.maps.geometry.poly.containsLocation(
                  new google.maps.LatLng(coord[0], coord[1]),
                  polygon
                )
              ) ||
              editProps.coordinates.some((coord) =>
                google.maps.geometry.poly.containsLocation(
                  new google.maps.LatLng(coord[0], coord[1]),
                  polygonFromMarker
                )
              ) ||
              false
            );
          }

          return false;
        });

      if (overlaps) {
        props.handleEditSnackbarErrorMessage(
          "Your polygon is overlapping with another polygon or marker and may produce duplicate orders"
        );
      }
    }

    let prevMarkers = null;

    props.setMarkers((markers) => {
      prevMarkers = _.cloneDeep(markers);
      return markers.map((marker) => {
        if (
          props.item.id
            ? marker.id === props.item.id
            : marker.identifier === props.item.identifier
        ) {
          marker.coordinates = editProps.coordinates;
        }

        return marker;
      });
    });

    if (updateDatabase) {
      editMapCollectionMarkerById({
        markerId: props.item.id || null,
        markerIdentifier: props.item.identifier,
        coordinates: editProps.coordinates,
      })
        .then(() => {})
        .catch((error) => {
          props.handleEditSnackbarErrorMessage(error);
          if (prevMarkers) props.setMarkers(prevMarkers);
        });
    }
  });

  const onDragEnd = useCallback((e) => {
    let coordinates = [];

    if (props.item.isPolygon) {
      let path = polygonRef.getPath();

      for (let i = 0; i < path.length; i++) {
        coordinates.push([path.getAt(i).lat(), path.getAt(i).lng()]);
      }
    } else if (props.item.isPoint) {
      coordinates = [[e.latLng.lat(), e.latLng.lng()]];
    }

    props.collectionData && props.collectionData.is_disabled
      ? props.handleEditSnackbarErrorMessage("Cannot edit this map!")
      : editItem({
          coordinates,
        });
  });

  const onMouseUp_polygon = useCallback(() => {
    let coordinates = [];

    let path = polygonRef.getPath();

    for (let i = 0; i < path.length; i++) {
      coordinates.push([path.getAt(i).lat(), path.getAt(i).lng()]);
    }

    props.collectionData && props.collectionData.is_disabled
      ? props.handleEditSnackbarErrorMessage("Cannot edit this map!")
      : editItem({
          coordinates,
        });
  }, [polygonRef]);

  const parsedCoordinates = props.item.coordinates.map((coordinate) => {
    return {
      lat: coordinate[0],
      lng: coordinate[1],
    };
  });

  const position = getNamedCoordinateCenters(parsedCoordinates);
  const DisplayComponent = props.item.isPolygon ? Polygon : Marker;

  const extraProps = props.item.isPolygon
    ? {
        editable: true,
        paths: parsedCoordinates,
        options: {
          fillColor: "#00ccFF",
          fillOpacity: 0.5,
          strokeWeight: 3,
        },
        onLoad: setPolygonRef,
        onMouseUp: onMouseUp_polygon,
      }
    : props.item.isPoint
    ? {
        position: {
          lat: position.latitude,
          lng: position.longitude,
        },
        onDragEnd: onDragEnd,
      }
    : {};

  return (
    <>
      <DisplayComponent
        onClick={props.setSelfSelected}
        draggable={true}
        {...extraProps}
      />
    </>
  );
}

function MapCollectionDetails(props) {
  props.setContentPadding(0);
  props.setShouldShowFooter(false);

  React.useEffect(() => {
    return () => {
      props.setContentPadding(props.defaultContentPadding);
      props.setShouldShowFooter(true);
    };
  }, []);

  const hasCreatedNewCollection = useRef(false);
  const onload_hasCentered = useRef(false);
  const searchBoxRef = useRef(null);

  const currentUserKey = useSelector(getCurrentUserKey);

  let collectionId =
    _.get(props, "location.state.collectionId", null) ||
    _.get(props, "match.params.collectionId", null);
  if (collectionId) {
    collectionId = parseInt(collectionId);
  }

  const intendToCreate =
    _.get(props, "location.state.intendToCreate", false) ||
    props.location.pathname.includes("/new") ||
    props.intent === "create";

  // values needed for ordering directly from the map page --------------------------------------------------
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedFundAccount, setSelectedFundAccount] = useState(null);

  const [
    createNewTemplateDialogOpen,
    setCreateNewTemplateDialogOpen,
  ] = useState(false);
  const [isLoading_newOrder, setIsLoading_newOrder] = useState(false);
  const [cardSize, setCardSize] = useState(getHandWrittenNoteOrderCardSize());
  const submitButtonPortalContainer = useRef(null);
  const [
    highlightSelectTemplateOnView,
    setHighlightSelectTemplateOnView,
  ] = useState(false);

  // values needed for maps ---------------------------------------------------

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);

  useEffect(() => {
    if (selectedMarker) {
      setSelectedDetailsTab(1);
    }
  }, [selectedMarker]);

  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [collectionData, setCollectionData] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const [mapBounds, setMapBounds] = useState(null);

  const [showAllOrders, setShowAllOrders] = useState(false);
  const [loadingAllOrders, setLoadingAllOrders] = useState(false);
  const [allOrders, setAllOrders] = useState([]);

  useEffect(() => {
    if (map) {
      const bounds_changed = () => {
        setMapBounds(map.getBounds());
      };

      const bounds_changed_listener = google.maps.event.addListener(
        map,
        "bounds_changed",
        bounds_changed
      );

      return () => {
        google.maps.event.removeListener(bounds_changed_listener);
      };
    }
  }, [map]);

  let zoomLevel = null;
  let zoomLevelForFetching = 15;
  if (map) {
    zoomLevel = map.getZoom();
  }

  const fetchAllOrders = React.useRef(
    _.debounce(
      async ({ north, south, east, west, startDate, endDate }) => {
        setLoadingAllOrders(true);
        try {
          const response = await getAllHandWrittenNoteOrders({
            north,
            south,
            east,
            west,

            startDate: startDate ? startDate.getTime() : null,
            endDate: endDate ? endDate.getTime() : null,
          });
          setAllOrders((prev) => {
            const orderMarkers = response.data.orders.map((o) => {
              let coords = _.get(
                o,
                "hand_written_note_order_receiver_address_info.location_geometry.coordinates",
                null
              );
              let lat = _.get(coords, "1", null);
              let lng = _.get(coords, "0", null);
              return {
                id: o.id,
                latitude: lat,
                longitude: lng,
                statusType: o.order_status,
              };
            });

            let next = orderMarkers;

            return next;
          });
        } catch (err) {
          let errorText = getRequestErrorMessage({
            error: err,
            message: "Something went wrong getting all order markers",
          });
          setAllOrders([]);
          setErrorMessage(errorText);
        }
        setLoadingAllOrders(false);
      },
      750,
      { leading: false }
    )
  );

  useEffect(() => {
    if (mapBounds && showAllOrders && zoomLevel >= zoomLevelForFetching) {
      setLoadingAllOrders(true);
      fetchAllOrders.current({
        ...mapBounds.toJSON(),
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      });
    }
  }, [mapBounds, showAllOrders, zoomLevel, selectedStartDate, selectedEndDate]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarKey, setSnackbarKey] = useState(nanoid(21));
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(null);

  const [isDeleteCollectionLoading, setIsDeleteCollectionLoading] = useState(
    false
  );
  const [
    deleteCollectionErrorMessage,
    setDeleteCollectionErrorMessage,
  ] = useState(null);
  const [
    isDeleteCollectionModalOpen,
    setIsDeleteCollectionModalOpen,
  ] = useState(false);

  const [currentLocationIsLoading, setCurrentLocationIsLoading] = useState(
    false
  );

  const lastKnownLocation = useSelector(getActiveExtrasLastKnownLocation);

  const [mapCenter, setMapCenter] = useState({
    lat: lastKnownLocation
      ? lastKnownLocation.latitude || 41.050631
      : 41.050631,
    lng: lastKnownLocation
      ? lastKnownLocation.longitude || -111.959764
      : -111.959764,
  });

  const [activeLocation, setActiveLocation] = useState({
    latitude: mapCenter.lat,
    longitude: mapCenter.lng,
  });

  const handleEditSnackbarErrorMessage = (err) => {
    setSnackbarOpen(false);
    setSnackbarKey(nanoid(21));
    setSnackbarErrorMessage(
      getRequestErrorMessage({
        error: err,
        fallbackMessage: "An unknown error occurred while updating the map",
      })
    );
    setSnackbarOpen(true);
  };

  const setNewMarker = (newProps, updateDatabase = true) => {
    let prevMarkers = _.cloneDeep(markers);
    let prevSelectedMarker = _.cloneDeep(selectedMarker);
    let newMarker = {
      coordinates: newProps.coordinates,
      identifier: nanoid(21),
      isPolygon: newProps.isPolygon,
      isPoint: newProps.isPoint,
    };
    setMarkers([...markers, newMarker]);
    setSelectedMarker(newMarker);
    if (updateDatabase) {
      createNewMapCollectionMarker({
        collectionId,
        coordinates: newProps.coordinates,
        markerIdentifier: newMarker.identifier,
        markerId: null,
      })
        .then((results) => {
          // add the marker id to new marker
          setSelectedMarker({
            ...newMarker,
            id: results.data.mapCollectionMarker.id,
          });
          setMarkers((prev) => {
            return prev.map((m) => {
              if (m.identifier === newMarker.identifier) {
                return {
                  ...m,
                  id: results.data.mapCollectionMarker.id,
                };
              }
              return m;
            });
          });
        })
        .catch((error) => {
          handleEditSnackbarErrorMessage(error);
          setMarkers(prevMarkers);
          setSelectedMarker(prevSelectedMarker);
        });
    }
  };

  const handleDuplicateMapCollection = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const newCollectionResponse = await duplicateMapCollectionById({
        collectionId,
      });
      globalHistory.push({
        pathname: `/map-collection/${newCollectionResponse.data.mapCollection.id}`,
        state: {
          collectionId: newCollectionResponse.data.mapCollection.id,
        },
      });
    } catch (error) {
      setErrorMessage(error);
    }
    setIsLoading(false);
  };

  const moveToCurrentPosition = () => {
    setCurrentLocationIsLoading(true);
    if ("geolocation" in navigator && map) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocationIsLoading(false);
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;
          map.panTo({
            lat,
            lng,
          });
          setActiveLocation({
            latitude: lat,
            longitude: lng,
          });
          store.dispatch(
            setUsersData(getCurrentUserKey(store.getState()), {
              extras: {
                last_known_location: {
                  latitude: lat,
                  longitude: lng,
                },
              },
            })
          );
        },
        (error) => {
          setCurrentLocationIsLoading(false);
          handleEditSnackbarErrorMessage(error);
        },
        {
          enableHighAccuracy: false,
          timeout: 20 * 1000,
          maximumAge: 1 * 1000,
        }
      );
    } else {
      setErrorMessage(
        "Geolocation is not supported by this browser or the map has not loaded yet."
      );
      setCurrentLocationIsLoading(false);
    }
  };

  const moveToFitMarkers = () => {
    if (!map) return;
    if (markers.length === 0) return;
    let flatCoords = markers.map((m) => m.coordinates).flat();

    let markerCenter = getNamedCoordinateCenters(
      flatCoords.map((m) => ({
        latitude: m[0],
        longitude: m[1],
      }))
    );
    let markerBounds = new google.maps.LatLngBounds();
    for (let i = 0; i < flatCoords.length; i++) {
      markerBounds.extend({
        lat: flatCoords[i][0],
        lng: flatCoords[i][1],
      });
    }

    map.fitBounds(markerBounds);
    map.panTo({
      lat: markerCenter.latitude,
      lng: markerCenter.longitude,
    });
    setActiveLocation({
      latitude: markerCenter.latitude,
      longitude: markerCenter.longitude,
    });
  };

  const moveToCoords = (lat, lng) => {
    if (!map) return;
    if (lat && lng) {
      map.panTo({
        lat,
        lng,
      });
      setActiveLocation({
        latitude: lat,
        longitude: lng,
      });
    }
  };

  useEffect(() => {
    if (!onload_hasCentered.current && map) {
      onload_hasCentered.current = true;
      if (markers.length > 0) {
        moveToFitMarkers();
      } else {
        moveToCurrentPosition();
      }
    }
  }, [markers, map]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        if (
          intendToCreate &&
          !collectionId &&
          !hasCreatedNewCollection.current
        ) {
          hasCreatedNewCollection.current = false;
          const newCollectionResponse = await createNewMapCollection({
            name: "Unnamed Map Collection",
          });
          return globalHistory.replace({
            pathname: `/map-collection/${newCollectionResponse.data.mapCollection.id}`,
            state: {
              collectionId: newCollectionResponse.data.mapCollection.id,
            },
          });
        }

        const collectionResponse = await getMapCollectionById({
          collectionId,
        });
        const markerResponse = await getAllMapCollectionMarkers({
          collectionId,
        });
        setMarkers(
          markerResponse.data.mapCollectionMarkers.map((m) => {
            let location_geometry = m.location_geometry;
            let coords = location_geometry.coordinates;
            let isPoint = location_geometry.type === "Point";
            let isPolygon = location_geometry.type === "Polygon";
            let coordinates = isPoint ? [coords] : coords;

            coordinates = isPoint ? [coords] : isPolygon ? coords[0] : coords;

            // postgres/postgis returns coordinates in the opposite order
            // switch them around
            coordinates = coordinates.map((c) => {
              return [c[1], c[0]];
            });

            return {
              id: m.id,
              identifier: m.identifier,
              coordinates,
              isPoint,
              isPolygon,
              locationGeometry: location_geometry,
            };
          })
        );
        setCollectionData(collectionResponse.data.mapCollection);
      } catch (err) {
        let errorText = getRequestErrorMessage({
          error: err,
          message: "Something went wrong loading your dashboard.",
        });
        setMarkers([]);
        setCollectionData(null);
        setErrorMessage(errorText);
      }
      setIsLoading(false);
    }

    fetchData();
  }, [collectionId, currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  let allOrderMarkers = showAllOrders ? allOrders : [];

  // The mapping between latitude, longitude and pixels is defined by the web
  // mercator projection.

  return (
    <React.Fragment>
      <Helmet title="Map Collection" />

      <Snackbar
        key={snackbarKey}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={6000}
        disableWindowBlurListener={true}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarErrorMessage}
      >
        <React.Fragment>
          <Alert
            mt={2}
            mb={1}
            severity="error"
            onClose={() => setSnackbarOpen(false)}
          >
            {snackbarErrorMessage}
          </Alert>
        </React.Fragment>
      </Snackbar>

      {errorMessage ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage ??
              "Something went wrong loading your map collection."}
          </Alert>
        </React.Fragment>
      ) : !collectionId ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            The collectionId is invalid
          </Alert>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} lg={7} xl={8}>
              <StyledMapContainer padding={0}>
                <GoogleMap
                  mapTypeId="satellite"
                  ref={mapRef}
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  center={mapCenter}
                  clickableIcons={false}
                  zoom={zoomLevelForFetching}
                  onLoad={setMap}
                  version="weekly"
                  options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                    streetViewControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    rotateControl: false,
                    mapTypeId: "satellite",
                    zoomControlOptions: {
                      position: google.maps.ControlPosition.RIGHT_TOP,
                    },
                  }}
                >
                  <DrawingManager
                    onMarkerComplete={(marker) => {
                      marker.visible = false;
                      marker.setMap(null);

                      if (collectionData && collectionData.is_disabled) {
                        return handleEditSnackbarErrorMessage(
                          "Cannot edit this map!"
                        );
                      }

                      let overlaps = markers
                        .filter((mk) => mk.isPolygon)
                        .some((mk) => {
                          let polygonFromMarker = new google.maps.Polygon({
                            paths: mk.coordinates.map((coord) => {
                              return {
                                lat: coord[0],
                                lng: coord[1],
                              };
                            }),
                          });

                          return google.maps.geometry.poly.containsLocation(
                            marker.position,
                            polygonFromMarker
                          );
                        });

                      if (overlaps) {
                        handleEditSnackbarErrorMessage(
                          "Your marker is inside a polygon and may produce duplicate orders"
                        );
                      }

                      setNewMarker({
                        coordinates: [
                          [marker.position.lat(), marker.position.lng()],
                        ],
                        isPoint: true,
                      });
                    }}
                    onPolygonComplete={(polygon) => {
                      polygon.visible = false;
                      polygon.setMap(null);

                      if (collectionData && collectionData.is_disabled) {
                        return handleEditSnackbarErrorMessage(
                          "Cannot edit this map!"
                        );
                      }

                      let path = polygon.getPath();
                      let coordinates = [];

                      for (let i = 0; i < path.length; i++) {
                        coordinates.push([
                          path.getAt(i).lat(),
                          path.getAt(i).lng(),
                        ]);
                      }

                      // check if polygon is larger than 250 squares miles
                      // if so, remove the polygon and display an error message

                      const polySquareMeters = google.maps.geometry.spherical.computeArea(
                        path
                      );
                      const milesPerMeter = 0.00000038610215855;
                      const polySquareMiles = polySquareMeters * milesPerMeter;

                      if (polySquareMiles > 250) {
                        handleEditSnackbarErrorMessage(
                          "Polygon too large! Only areas smaller than 250 square miles can be added."
                        );
                        return;
                      }

                      // check if overlaps with other markers
                      // check if overlaps with other polygons
                      // if so, remove the polygon and display an error message

                      let overlaps = markers.some((mk) => {
                        let mkCoordinates = mk.coordinates;

                        if (mk.isPoint) {
                          return google.maps.geometry.poly.containsLocation(
                            new google.maps.LatLng(
                              mkCoordinates[0][0],
                              mkCoordinates[0][1]
                            ),
                            polygon
                          );
                        } else if (mk.isPolygon) {
                          // create a map polygon from the marker coordinates
                          // compare it to the new polygon

                          let polygonFromMarker = new google.maps.Polygon({
                            paths: mkCoordinates.map((coord) => {
                              return {
                                lat: coord[0],
                                lng: coord[1],
                              };
                            }),
                          });

                          if (
                            mkCoordinates.some((coord) =>
                              google.maps.geometry.poly.containsLocation(
                                new google.maps.LatLng(coord[0], coord[1]),
                                polygon
                              )
                            )
                          ) {
                            return true;
                          }

                          if (
                            coordinates.some((coord) =>
                              google.maps.geometry.poly.containsLocation(
                                new google.maps.LatLng(coord[0], coord[1]),
                                polygonFromMarker
                              )
                            )
                          ) {
                            return true;
                          }

                          return false;
                        }

                        return false;
                      });

                      if (overlaps) {
                        handleEditSnackbarErrorMessage(
                          "Your polygon is overlapping with another polygon or marker and may produce duplicate orders"
                        );
                      }

                      setNewMarker({
                        coordinates,
                        isPolygon: true,
                      });
                    }}
                    drawingMode={google.maps.drawing.OverlayType.MARKER}
                    options={{
                      drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [
                          google.maps.drawing.OverlayType.MARKER,
                          google.maps.drawing.OverlayType.POLYGON,
                        ],
                      },
                    }}
                  />
                  {/* active location. Either current or centered on the markers */}
                  <Marker
                    position={{
                      lat: activeLocation.latitude,
                      lng: activeLocation.longitude,
                    }}
                    icon={{
                      path: google.maps.SymbolPath.CIRCLE,
                      scale: 10,
                      fillOpacity: 1,
                      strokeWeight: 2,
                      fillColor: markerColors.activeLocation,
                      strokeColor: "#ffffff",
                    }}
                    draggable={false}
                  />
                  {/* markers from orders */}
                  {zoomLevel >= zoomLevelForFetching && (
                    <MarkerClusterer>
                      {(clusterer) => {
                        return allOrderMarkers.map(
                          (o, index, originalArray) => {
                            return (
                              <Marker
                                key={o.id}
                                position={{
                                  lat: o.latitude,
                                  lng: o.longitude,
                                }}
                                icon={{
                                  path: google.maps.SymbolPath.CIRCLE,
                                  scale: 8,
                                  fillOpacity: 1,
                                  strokeWeight: 2,
                                  fillColor: getOrderStatusColor(o.statusType),
                                  strokeColor: "#ffffff",
                                }}
                                draggable={false}
                                clusterer={clusterer}
                              />
                            );
                          }
                        );
                      }}
                    </MarkerClusterer>
                  )}

                  {/* placed markers and polygons */}
                  {markers.map((mp) => {
                    return (
                      <CollectionMarkerItem
                        key={mp.identifier || mp.id}
                        item={mp}
                        collectionData={collectionData}
                        handleEditSnackbarErrorMessage={
                          handleEditSnackbarErrorMessage
                        }
                        setMarkers={setMarkers}
                        markers={markers}
                        map={map}
                        selectedMarker={selectedMarker}
                        setSelectedMarker={setSelectedMarker}
                        setSelfSelected={() => {
                          setSelectedMarker(mp);
                        }}
                      />
                    );
                  })}
                </GoogleMap>
              </StyledMapContainer>
            </Grid>

            <Grid item xs={12} lg={5} xl={4}>
              <StyledSidePanel padding={0}>
                <StyledTabsContainer>
                  <StyledTabs
                    value={selectedDetailsTab}
                    onChange={(event, newValue) => {
                      setSelectedDetailsTab(newValue);
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      label={
                        <Typography
                          variant="button"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                          component="span"
                        >
                          Details
                        </Typography>
                      }
                      value={0}
                    />
                    <Tab
                      label={
                        <Typography
                          variant="button"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                          component="span"
                        >
                          Markers & Polygons
                        </Typography>
                      }
                      value={1}
                    />
                    <Tab
                      label={
                        <Typography
                          variant="button"
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                          component="span"
                        >
                          Orders
                        </Typography>
                      }
                      value={2}
                    />
                  </StyledTabs>
                </StyledTabsContainer>
                <StyledTabPanel value={selectedDetailsTab} index={0}>
                  <Card mb={2} variant="outlined">
                    <CardContent>
                      <Grid container spacing={2}>
                        {collectionData && collectionData.is_disabled && (
                          <>
                            <Grid item xs={12}>
                              <Alert severity="info">
                                <AlertTitle>Disabled Map</AlertTitle>
                                This map has already been used and markers
                                cannot be edited. Orders can still be placed
                                using it.
                              </Alert>
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} />
                          </>
                        )}
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Collection #{collectionId}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography variant="body1">
                            "{collectionData.name}" has a total of (
                            {markers.length}){" "}
                            {markers.length > 1 ? "markers" : "marker"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} />
                        <Grid item xs={12} sm={6}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              moveToFitMarkers();
                            }}
                            fullWidth
                            disabled={
                              currentLocationIsLoading || markers.length < 1
                            }
                          >
                            <RoomIcon />
                            <Typography ml={2} variant="button">
                              Show Markers
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              moveToCurrentPosition();
                            }}
                            fullWidth
                            disabled={currentLocationIsLoading}
                          >
                            {currentLocationIsLoading ? (
                              <CircularProgress size={25} color="inherit" />
                            ) : (
                              <>
                                <MyLocationIcon />{" "}
                                <Typography ml={2} variant="button">
                                  Current Location
                                </Typography>
                              </>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  <Card mb={2} variant="outlined">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Search Places</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <StandaloneSearchBox
                            onLoad={(ref) => {
                              searchBoxRef.current = ref;
                            }}
                            onPlacesChanged={() => {
                              const places = searchBoxRef.current.getPlaces();
                              let lat = null;
                              let lng = null;

                              let location = _.get(
                                places,
                                "0.geometry.location"
                              );

                              if (location) {
                                lat = location.lat ? location.lat() : null;
                                lng = location.lng ? location.lng() : null;
                              }

                              moveToCoords(lat, lng);
                            }}
                          >
                            <TextField
                              variant="outlined"
                              label={"Search"}
                              fullWidth
                              my={4}
                            />
                          </StandaloneSearchBox>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <CollectionSettings
                    collectionId={collectionId}
                    collectionData={collectionData}
                    setCollectionData={setCollectionData}
                  />

                  <CollectionExports
                    collectionId={collectionId}
                    collectionData={collectionData}
                    collectionMarkers={markers}
                    setCollectionData={setCollectionData}
                  />

                  <Card mb={2} variant="outlined">
                    <CardContent>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Grid container direction="row" alignItems="center">
                            <Typography
                              display="inline"
                              style={{
                                marginRight: 4,
                                fontWeight: "bold",
                              }}
                              variant="h6"
                            >
                              Overlays
                            </Typography>
                            <QuestionMarkPopover>
                              <Typography variant="body1">
                                Display markers (previous orders, other maps,
                                etc) in addition to the current map
                              </Typography>
                            </QuestionMarkPopover>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={6}>
                            <Grid item xs={12}>
                              Only applies when viewing a map at a zoom level of{" "}
                              {zoomLevelForFetching} or greater. Current zoom
                              level: {zoomLevel}
                            </Grid>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid item xs={12} container spacing={4}>
                                <Grid item xs={12} sm>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="outlined"
                                    format="MM/dd/yyyy"
                                    id="start-date-picker"
                                    label="Start Date"
                                    value={selectedStartDate}
                                    onChange={setSelectedStartDate}
                                    KeyboardButtonProps={{
                                      "aria-label": "change start date",
                                    }}
                                    disabled={
                                      zoomLevel < zoomLevelForFetching ||
                                      loadingAllOrders
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="outlined"
                                    format="MM/dd/yyyy"
                                    id="end-date-picker"
                                    label="End Date"
                                    value={selectedEndDate}
                                    onChange={setSelectedEndDate}
                                    KeyboardButtonProps={{
                                      "aria-label": "change end date",
                                    }}
                                    disabled={
                                      zoomLevel < zoomLevelForFetching ||
                                      loadingAllOrders
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm>
                                  <Grid container alignItems="center">
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={showAllOrders}
                                          onChange={(event) => {
                                            setShowAllOrders(
                                              event.target.checked
                                            );
                                          }}
                                          disabled={
                                            zoomLevel < zoomLevelForFetching ||
                                            loadingAllOrders
                                          }
                                        />
                                      }
                                      label="All Orders"
                                    />
                                    <QuestionMarkPopover>
                                      <Typography variant="body1">
                                        Shows all the orders from your account,
                                        this includes items with the status of
                                        "canceled" & "errored"
                                      </Typography>
                                    </QuestionMarkPopover>
                                    {loadingAllOrders && (
                                      <CircularProgress
                                        size={16}
                                        style={{
                                          marginLeft: 8,
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </CardContent>
                  </Card>

                  <AdvancedTable
                    title={
                      <div>
                        <Typography variant="h6">User Activity</Typography>
                        <Typography color="textSecondary" variant="caption">
                          (marker activity excluded)
                        </Typography>
                      </div>
                    }
                    paperProps={{
                      variant: "outlined",
                    }}
                    containerSpacing={4}
                    isCollapsed={true}
                    rowColumns={userActivityRowColumns}
                    identifier={collectionId}
                    getRowData={async ({ limit, offset, order }) => {
                      let results = await getAllUserActivities({
                        limit,
                        offset,
                        order,
                        relations: [["map_collection_id", collectionId]],
                        includeResponsibility: true,
                      });

                      let parsedRowData = {
                        rows: results.data.userActivities,
                        totalRowCount: results.data.userActivityCount,
                      };

                      return parsedRowData;
                    }}
                  />

                  <AdvancedTable
                    title={
                      <Grid container>
                        <Typography
                          variant="h6"
                          style={{
                            marginRight: 4,
                          }}
                        >
                          QR Scans
                        </Typography>
                        <QuestionMarkPopover>
                          <Typography variant="body1" gutterBottom>
                            This tables shows all the QR codes that are
                            associated with this map.
                          </Typography>
                          <Typography variant="body1">
                            For example: Maps have multiple markers that then
                            create orders, and those orders will have QR codes
                            attached to them for customers to scan. This shows
                            all of the scans related through that process. (Map{" "}
                            {"->"} Marker {"->"} Order {"->"} QR Code {"->"}{" "}
                            Scan)
                          </Typography>
                        </QuestionMarkPopover>
                      </Grid>
                    }
                    paperProps={{
                      variant: "outlined",
                    }}
                    containerSpacing={4}
                    isCollapsed={true}
                    rowColumns={qrCodeScanRowColumns}
                    identifier={collectionId}
                    getRowData={async ({ limit, offset, order }) => {
                      let results = await getAllQrCodeScans({
                        mapCollectionId: collectionId,
                        limit,
                        offset,
                        order,
                      });

                      let parsedRowData = {
                        rows: results.data.qrCodeScans,
                        totalRowCount: results.data.qrCodeScanCount,
                      };

                      return parsedRowData;
                    }}
                  />
                  <AdvancedTable
                    title={"Collection Orders"}
                    paperProps={{
                      variant: "outlined",
                    }}
                    containerSpacing={4}
                    isCollapsed={true}
                    rowColumns={handWrittenNoteOrderRowColumns}
                    identifier={collectionId}
                    getRowData={async ({ limit, offset, order }) => {
                      let results = await getAllHandWrittenNoteOrders({
                        mapCollectionId: collectionId,
                        limit,
                        offset,
                        order,
                      });

                      let parsedRowData = {
                        rows: results.data.orders,
                        totalRowCount: results.data.order_count,
                      };

                      return parsedRowData;
                    }}
                  />

                  <Card mt={2} mb={2} variant="outlined">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Actions</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              setSelectedDetailsTab(2);
                              setHighlightSelectTemplateOnView(true);
                            }}
                          >
                            Create Order
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={handleDuplicateMapCollection}
                          >
                            Duplicate Collection
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            style={{ borderColor: red[500], color: red[500] }}
                            fullWidth
                            onClick={() => {
                              setIsDeleteCollectionModalOpen(true);
                            }}
                          >
                            Delete Forever
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </StyledTabPanel>
                <StyledTabPanel value={selectedDetailsTab} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">
                                Markers & Polygons ({markers.length})
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1">
                                Using the grabber tool, click a marker or
                                polygon on the map to view details about.
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardHeader
                          title={
                            selectedMarker
                              ? `${
                                  selectedMarker.isPolygon
                                    ? "Polygon"
                                    : "Marker"
                                } #${
                                  selectedMarker.id || selectedMarker.identifier
                                }`
                              : "Selection Details"
                          }
                          subheader={
                            selectedMarker
                              ? "View details about this marker below"
                              : "Select a marker on the map to continue"
                          }
                          titleTypographyProps={{ align: "center" }}
                          subheaderTypographyProps={{ align: "center" }}
                          pb={0}
                          pt={2}
                        />
                      </Card>
                    </Grid>

                    <>
                      <Grid item xs={12} />
                      <Grid item xs={12} />
                    </>

                    {selectedMarker && selectedMarker.id && (
                      <Grid item xs={12}>
                        <AdvancedTable
                          title={"Marker Orders"}
                          paperProps={{
                            variant: "outlined",
                          }}
                          containerSpacing={4}
                          isCollapsed={true}
                          rowColumns={handWrittenNoteOrderRowColumns}
                          identifier={`${collectionId}-${selectedMarker.id}`}
                          getRowData={async ({ limit, offset, order }) => {
                            let results = await getAllHandWrittenNoteOrders({
                              mapCollectionMarkerId: selectedMarker.id,
                              limit,
                              offset,
                              order,
                            });

                            let parsedRowData = {
                              rows: results.data.orders,
                              totalRowCount: results.data.order_count,
                            };

                            return parsedRowData;
                          }}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <CollectionMarkerComments
                        collectionId={collectionId}
                        collectionData={collectionData}
                        selectedCollectionMarker={selectedMarker}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Card mt={2} mb={2} variant="outlined">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">Actions</Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                style={
                                  selectedMarker && !collectionData.is_disabled
                                    ? {
                                        borderColor: red[500],
                                        color: red[500],
                                      }
                                    : {}
                                }
                                fullWidth
                                disabled={
                                  !selectedMarker || collectionData.is_disabled
                                }
                                onClick={() => {
                                  if (
                                    collectionData &&
                                    !collectionData.is_disabled
                                  ) {
                                    let prevMarkers = null;
                                    setSelectedMarker(null);
                                    setMarkers((markers) => {
                                      prevMarkers = _.cloneDeep(markers);
                                      return markers.filter((marker) =>
                                        selectedMarker.id
                                          ? marker.id !== selectedMarker.id
                                          : marker.identifier !==
                                            selectedMarker.identifier
                                      );
                                    });

                                    deleteMapCollectionMarkerById({
                                      markerId: selectedMarker.id || null,
                                      markerIdentifier:
                                        selectedMarker.identifier,
                                    }).catch((error) => {
                                      handleEditSnackbarErrorMessage(error);
                                      if (prevMarkers) setMarkers(prevMarkers);
                                    });
                                  } else {
                                    handleEditSnackbarErrorMessage(
                                      "This collection is disabled"
                                    );
                                  }
                                }}
                              >
                                Delete Forever
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </StyledTabPanel>
                <StyledTabPanel value={selectedDetailsTab} index={2}>
                  <HighlightOnView
                    shouldHighlight={highlightSelectTemplateOnView}
                    setShouldHighlight={setHighlightSelectTemplateOnView}
                  >
                    <Card variant="outlined" mb={2}>
                      <CardHeader
                        title={
                          <Typography variant="h6">
                            Select a template to start an order
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Grid container spacing={4}>
                          <Grid item xs>
                            <TemplateTextAutoComplete
                              selectedTemplate={selectedTemplate}
                              setSelectedTemplate={setSelectedTemplate}
                              autoCompleteProps={{
                                size: "small",
                                disabled: isLoading_newOrder,
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setCreateNewTemplateDialogOpen(true);
                              }}
                            >
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Grid container alignItems="center">
                                    {selectedTemplate ? <Edit /> : <Add />}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Typography variant="button">
                                    {selectedTemplate ? "Edit" : "Create"}{" "}
                                    Template
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <AvailableFundAccountAutoComplete
                              selectedFundAccount={selectedFundAccount}
                              setSelectedFundAccount={setSelectedFundAccount}
                              autoCompleteProps={{
                                size: "small",
                                disabled: false,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card variant="outlined">
                      {!selectedTemplate ? (
                        <React.Fragment>
                          <CardHeader
                            title="Order Form"
                            subheader="Select a template above to continue"
                            titleTypographyProps={{ align: "center" }}
                            subheaderTypographyProps={{ align: "center" }}
                            pb={0}
                            pt={2}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <CardHeader
                            title={`Create A New Order From '${collectionData.name}'`}
                            subheader="Fill in the details below to complete your order"
                            titleTypographyProps={{ align: "center" }}
                            subheaderTypographyProps={{ align: "center" }}
                            pb={0}
                            pt={2}
                          />
                          <MapCollectionForm
                            selectedTemplate={selectedTemplate}
                            selectedFundAccount={selectedFundAccount}
                            cardSize={cardSize}
                            redirectMapCollectionData={collectionData}
                            redirectMapCollectionMarkers={markers}
                            hide_selectMapCollection={true}
                          />
                        </React.Fragment>
                      )}
                    </Card>
                  </HighlightOnView>
                </StyledTabPanel>
              </StyledSidePanel>
            </Grid>
          </Grid>

          <Dialog
            open={isDeleteCollectionModalOpen}
            onClose={() => {
              setIsDeleteCollectionModalOpen(false);
            }}
            aria-labelledby="alert-delete-title"
            aria-describedby="alert-delete-description"
          >
            <DialogTitle id="alert-delete-title">
              Delete Map Collection
            </DialogTitle>
            <DialogContent>
              {deleteCollectionErrorMessage && (
                <React.Fragment>
                  <Alert mt={2} mb={1} severity="warning">
                    {deleteCollectionErrorMessage}
                  </Alert>
                  <br />
                </React.Fragment>
              )}
              <DialogContentText id="alert-delete-description">
                This action is permanent and your map collection will be
                completely deleted. Are you sure you want to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {isDeleteCollectionLoading ? (
                <React.Fragment>
                  <Grid container spacing={0} justifyContent="center">
                    <Grid item>
                      <CircularProgress size={25} color="secondary" />
                    </Grid>
                  </Grid>
                  <br />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    disabled={isDeleteCollectionLoading}
                    onClick={() => {
                      setIsDeleteCollectionLoading(true);
                      setDeleteCollectionErrorMessage(null);

                      deleteMapCollectionById({
                        collectionId,
                      })
                        .then(() => {
                          globalHistory.replace({
                            pathname: "/map-collection",
                          });
                        })
                        .catch((err) => {
                          let errorText = getRequestErrorMessage({
                            error: err,
                            fallbackMessage:
                              "Something went wrong deleting the map collection",
                          });

                          setDeleteCollectionErrorMessage(errorText);
                          setIsDeleteCollectionLoading(false);
                        });
                    }}
                    color="primary"
                  >
                    Delete
                  </Button>
                  <Button
                    disabled={isDeleteCollectionLoading}
                    variant="contained"
                    onClick={() => {
                      setIsDeleteCollectionModalOpen(false);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Close
                  </Button>
                </React.Fragment>
              )}
            </DialogActions>
          </Dialog>

          <Dialog
            open={createNewTemplateDialogOpen}
            fullWidth={true}
            maxWidth={"xl"}
            PaperComponent={DialogBackground}
          >
            <DialogTitle>
              {selectedTemplate ? "Edit Template" : "Create New Template"}
            </DialogTitle>
            <DialogContent>
              <ConnectedTemplateCreateEdit
                templateData={selectedTemplate}
                intent={Boolean(selectedTemplate) ? "edit" : "create"}
                onCompletion={(data) => {
                  setSelectedTemplate(data.data.template);
                  setCardSize(
                    getHandWrittenNoteOrderCardSize(
                      data.data.template.card_size
                    )
                  );
                  setCreateNewTemplateDialogOpen(false);
                }}
                customSubmitButtonContainer={({ children }) => {
                  return (
                    <Portal container={submitButtonPortalContainer.current}>
                      {children}
                    </Portal>
                  );
                }}
              />
            </DialogContent>
            <DialogActions ref={submitButtonPortalContainer}>
              <Button
                onClick={() => {
                  setCreateNewTemplateDialogOpen(false);
                }}
                variant="outlined"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default MapCollectionDetails;
