import React from "react";
import dateFormat from "dateformat";
import ms from "ms";

export default function useTimeText(props) {
  const { date } = props;

  const getTimeText = React.useCallback(() => {
    let createdAtDate = new Date(date);
    let timeDifference = new Date() - createdAtDate;
    if (timeDifference < 1000) {
      timeDifference = 1000;
    }
    if (timeDifference < ms("1hr")) {
      return `${ms(timeDifference, { long: true })} ago`;
    } else if (
      dateFormat(createdAtDate, "dddd") === dateFormat(new Date(), "dddd")
    ) {
      return `Today, ${dateFormat(createdAtDate, "h:MM TT")}`;
    } else if (
      dateFormat(
        new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate() + 1
        ),
        "dddd"
      ) === dateFormat(new Date(), "dddd")
    ) {
      // return yesterday and time
      return `Yesterday, ${dateFormat(createdAtDate, "h:MM TT")}`;
    } else {
      // return date and time
      return `${dateFormat(createdAtDate, "dddd, yyyy h:MM TT")}`;
    }
  }, [date]);

  const [timeText, setTimeText] = React.useState(getTimeText());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeText(getTimeText());
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return timeText;
}
