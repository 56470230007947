import React from "react";
import projectPackage from "../../package.json";
import _ from "lodash";
import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  MapPin,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  ShoppingCart,
  FileText,
  PieChart,
  Sliders,
  Users,
  User,
  Settings as SettingsIcon,
  MessageCircle,
  Server as ServerIcon,
  DownloadCloud,
} from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Pages components
import Blank from "../pages/pages/Blank";
import OrderDetails from "../pages/pages/OrderDetails";
import PurchaseOrderList from "../pages/pages/PurchaseOrderList";
import Pricing from "../pages/pages/Pricing";
import Settings from "../pages/pages/Settings";
import Chat from "../pages/pages/Chat";

// Documentation
import Changelog from "../pages/docs/Changelog";
import PurchaseOrderDetails from "../pages/pages/PurchaseOrderDetails";
import TemplateList from "../pages/pages/TemplateList";
import TemplateDetails from "../pages/pages/TemplateDetails";
import AllOrderList from "../pages/pages/AllOrderList";
import TemplateSettings from "../pages/pages/TemplateSettings";
import Redeem from "../pages/pages/Redeem";
import Dashboard from "../pages/pages/Dashboard";
import AllTransactionGroupList from "../pages/pages/AllTransactionGroupList";
import TransactionGroupDetails from "../pages/pages/TransactionGroupDetails";
import AllTransactionList from "../pages/pages/AllTransactionList";
import TransactionDetails from "../pages/pages/TransactionDetails";
import Plans from "../pages/pages/Plans";
import AccountSettings from "../pages/pages/AccountSettings";
import NewOrder from "../pages/pages/NewOrder";
import LoginRedirect from "../components/LoginRedirect";
import OneTimePayment from "../pages/pages/OneTimePayment";
import MapCollectionDetails from "../pages/pages/MapCollectionDetails";
import MapCollectionList from "../pages/pages/MapCollectionList";
import constants from "../constants";
import UserShareList from "../pages/pages/UserShareList";
import UserShareDetails from "../pages/pages/UserShareDetails";
import NewUserShare from "../pages/pages/NewUserShare";
import ChildrenDashboard from "../pages/pages/ChildrenDashboard";
import ChildrenGraph from "../pages/pages/ChildrenGraph";
import { Typography } from "@material-ui/core";
import Welcome from "../pages/docs/Welcome";
import AddCredits from "../pages/pages/AddCredits";
import AllServerRequestList from "../pages/pages/AllServerRequestList";
import PremiumTemplates from "../pages/pages/PremiumTemplates";
import { CropFree } from "@material-ui/icons";
import QrCodeScanner from "../pages/pages/QrCodeScanner";
import InternalQrCodeDetails from "../pages/pages/InternalQrCodeDetails";
import Templates from "../pages/docs/Templates";
import Maps from "../pages/docs/Maps";
import Orders from "../pages/docs/Orders";
import UserShares from "../pages/docs/UserShares";
import AllDataExportList from "../pages/pages/AllDataExportList";
import DataExportDetails from "../pages/pages/DataExportDetails";
import AllQrCodeList from "../pages/pages/AllQrCodeList";
import QrCodeDetails from "../pages/pages/QrCodeDetails";
import AllQrCodeScanList from "../pages/pages/AllQrCodeScanList";
import QrCodeScanDetails from "../pages/pages/QrCodeScanDetails";
import AllContactList from "../pages/pages/AllContactList";
import AllContactGroupList from "../pages/pages/AllContactGroupList";
import NewContact from "../pages/pages/NewContact";
import NewContactGroup from "../pages/pages/NewContactGroup";
import ContactDetails from "../pages/pages/ContactDetails";
import ContactGroupDetails from "../pages/pages/ContactGroupDetails";
import ComingSoon from "../pages/pages/ComingSoon";

const internal_dashboardRoutes = {
  id: "Dashboard",
  path: "/internal/dashboard",
  name: "Dashboard",
  icon: <User />,
  header: "Internal",
  component: ChildrenDashboard,
  guard: AuthGuard,
  useWithRole: constants.presetRoles.repsUp,
};
const internal_managedUsersRoutes = {
  id: "Managed Users",
  path: "/internal/managed-users",
  name: "Managed Users",
  icon: <Users />,
  component: ChildrenGraph,
  guard: AuthGuard,
  useWithRole: constants.presetRoles.repsUp,
};
const internal_qrCodeRoutes = {
  id: "Qr Codes",
  path: "/internal/qr-code",
  name: "Qr Codes",
  icon: <CropFree />,
  guard: AuthGuard,
  useWithRole: constants.presetRoles.repsUp,
  children: [
    {
      path: "/internal/qr-code/scanner",
      name: "Scanner",
      component: QrCodeScanner,
      guard: AuthGuard,
      useWithRole: constants.presetRoles.repsUp,
    },
    {
      path: "/internal/qr-code/:uuid",
      name: "Qr Code",
      component: InternalQrCodeDetails,
      guard: AuthGuard,
      includeInSidebar: false,
      useWithRole: constants.presetRoles.repsUp,
    },
  ],
};
const internal_serverRequestRoutes = {
  id: "Server Requests",
  path: "/internal/server-request",
  name: "Server Requests",
  icon: <ServerIcon />,
  children: [
    {
      path: "/internal/server-request",
      name: "All Requests",
      component: AllServerRequestList,
      guard: AuthGuard,
      useWithRole: constants.presetRoles.ceoUp,
    },
    // {
    //   path: "/internal/server-request/:requestId",
    //   name: "Server Request",
    //   component: React.Fragment,
    //   guard: AuthGuard,
    //   includeInSidebar: false,
    //   useWithRole: constants.presetRoles.ceoUp,
    // },
  ],
  guard: AuthGuard,
  useWithRole: constants.presetRoles.ceoUp,
};

const dashboardRoutes = {
  id: "Dashboard",
  path: "/",
  icon: <User />,
  header: "Account",
  children: null,
  component: Dashboard,
  guard: AuthGuard,
};

const chatRoutes = {
  id: "Chat",
  path: "/chat",
  icon: <MessageCircle />,
  component: Chat,
  guard: AuthGuard,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  icon: <CreditCard />,
  children: [
    {
      path: "/account/settings",
      name: "Settings",
      component: AccountSettings,
      guard: AuthGuard,
    },

    {
      path: "/account/share",
      name: "User Shares",
      component: UserShareList,
      guard: AuthGuard,
    },
    {
      path: "/account/share/new",
      name: "Create New",
      component: NewUserShare,
      guard: AuthGuard,
      includeInSidebar: false,
    },
    {
      path: "/account/share/:userShareId",
      name: "User Share",
      component: UserShareDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
    {
      path: "/account/plans",
      name: "Plans",
      component: Plans,
      guard: AuthGuard,
    },
    {
      path: "/account/redeem",
      name: "Redeem Code",
      component: Redeem,
      guard: AuthGuard,
    },
    {
      path: "/account/add-credits",
      name: "Add Credits (Internal)",
      component: AddCredits,
      guard: AuthGuard,
      useOnlyWhenActing: true,
      useWithPrimaryUserRole: constants.presetRoles.repsUp,
      alwaysShowForPrimaryUserRole: constants.presetRoles.ceoUp,
    },
    {
      path: "/account/one-time-payment",
      name: "One Time Payment",
      component: OneTimePayment,
      guard: AuthGuard,
    },
    {
      path: "/account/transaction-group",
      name: "Transaction Groups",
      component: AllTransactionGroupList,
      guard: AuthGuard,
    },
    {
      path: "/account/transaction-group/:transactionGroupId",
      name: "Transaction Group",
      component: TransactionGroupDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
    {
      path: "/account/transaction",
      name: "Transactions",
      component: AllTransactionList,
      guard: AuthGuard,
    },
    {
      path: "/account/transaction/:transactionId",
      name: "Transaction",
      component: TransactionDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const contactRoutes = {
  id: "Contacts",
  path: "/contact",
  icon: <Users />,
  badge: "Coming Soon",
  component: ComingSoon,
  guard: AuthGuard,
  // children: [
  //   {
  //     path: "/contact",
  //     name: "All Contacts",
  //     component: AllContactList,
  //     guard: AuthGuard,
  //   },
  //   {
  //     path: "/contact/new",
  //     name: "New Contact",
  //     component: NewContact,
  //     guard: AuthGuard,
  //   },
  //   {
  //     path: "/contact/group",
  //     name: "Contact Groups",
  //     component: AllContactGroupList,
  //     guard: AuthGuard,
  //   },
  //   {
  //     path: "/contact/group/new",
  //     name: "New Contact Group",
  //     component: NewContactGroup,
  //     guard: AuthGuard,
  //   },
  //   {
  //     path: "/contact/:contactId",
  //     name: "Contact",
  //     component: ContactDetails,
  //     guard: AuthGuard,
  //     includeInSidebar: false,
  //   },
  //   {
  //     path: "/contact/group/:contactGroupId",
  //     name: "Contact Group",
  //     component: ContactGroupDetails,
  //     guard: AuthGuard,
  //     includeInSidebar: false,
  //   },
  // ],
};

const mapRoutes = {
  id: "Maps",
  path: "/map-collection",
  icon: <MapPin />,
  children: [
    {
      path: "/map-collection/new",
      name: "Create New",
      component: MapCollectionDetails,
      guard: AuthGuard,
    },
    {
      path: "/map-collection",
      name: "View All",
      component: MapCollectionList,
      guard: AuthGuard,
    },
    {
      path: "/map-collection/:collectionId",
      name: "Map Collection",
      component: MapCollectionDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
  ],
};

const exportRoutes = {
  id: "Exports",
  path: "/export",
  icon: <DownloadCloud />,
  children: [
    {
      path: "/export",
      name: "View All",
      component: AllDataExportList,
      guard: AuthGuard,
    },
    {
      path: "/export/:dataExportId",
      name: "Export",
      component: DataExportDetails, // ExportDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
  ],
};

const qrCodeRoutes = {
  id: "QR Codes",
  path: "/qr-code",
  icon: <CropFree />,
  children: [
    {
      path: "/qr-code",
      name: "View All",
      component: AllQrCodeList,
      guard: AuthGuard,
    },
    {
      path: "/qr-code/scan",
      name: "All Scans",
      component: AllQrCodeScanList,
      guard: AuthGuard,
    },
    {
      path: "/qr-code/:qrCodeId",
      name: "Export",
      component: QrCodeDetails, // ExportDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
    {
      path: "/qr-code/scan/:qrCodeScanId",
      name: "Export",
      component: QrCodeScanDetails, // ExportDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
  ],
};

const templateRoutes = {
  id: "Templates",
  path: "/template",
  icon: <FileText />,
  children: [
    {
      path: "/template/new",
      name: "Create New",
      component: TemplateSettings,
      guard: AuthGuard,
    },
    {
      path: "/template/premium",
      name: "Premium Templates",
      component: PremiumTemplates,
      guard: AuthGuard,
    },
    {
      path: "/template",
      name: "View All",
      component: TemplateList,
      guard: AuthGuard,
    },
    {
      path: "/template/edit",
      name: "Edit Template",
      component: TemplateSettings,
      guard: AuthGuard,
      includeInSidebar: false,
      includeInSidebarWhenActive: true,
    },
    {
      path: "/template/:templateId",
      name: "Template Details",
      component: TemplateDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const orderRoutes = {
  id: "Orders",
  path: "/order",
  icon: <ShoppingCart />,
  children: [
    {
      path: "/order/new",
      name: "Create New",
      component: NewOrder,
      guard: AuthGuard,
      includeInSidebar: true,
    },
    {
      path: "/purchase-order",
      name: "View Purchase Orders",
      component: PurchaseOrderList,
      guard: AuthGuard,
    },
    {
      path: "/order",
      name: "View All",
      component: AllOrderList,
      guard: AuthGuard,
    },
    {
      path: "/purchase-order/:purchaseOrder",
      name: "Purchase Order",
      component: PurchaseOrderDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
    {
      path: "/order/:orderId",
      name: "Order",
      component: OrderDetails,
      guard: AuthGuard,
      includeInSidebar: false,
    },
  ],
  component: null,
  guard: AuthGuard,
};

const docRoutes = {
  id: "Documentation",
  path: "/documentation",
  icon: <BookOpen />,
  header: "Radial Link",
  children: [
    {
      path: "/documentation/getting-started",
      name: "Getting Started",
      component: Welcome,
      guard: AuthGuard,
    },
    {
      path: "/documentation/templates",
      name: "Templates",
      component: Templates,
      guard: AuthGuard,
    },
    {
      path: "/documentation/maps",
      name: "Maps",
      component: Maps,
      guard: AuthGuard,
    },
    {
      path: "/documentation/orders",
      name: "Orders",
      component: Orders,
      guard: AuthGuard,
    },
    {
      path: "/documentation/share",
      name: "User Shares",
      component: UserShares,
      guard: AuthGuard,
    },
  ],
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
      guard: LoginRedirect,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
      guard: LoginRedirect,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// const changelogRoutes = {
//   id: "Changelog",
//   path: "/changelog",
//   badge: `v${projectPackage.version}`,
//   icon: <List />,
//   component: Changelog,
//   children: null,
//   guard: AuthGuard,
// };

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  internal_dashboardRoutes,
  internal_managedUsersRoutes,
  internal_qrCodeRoutes,
  internal_serverRequestRoutes,
  dashboardRoutes,
  chatRoutes,
  accountRoutes,
  contactRoutes,
  templateRoutes,
  mapRoutes,
  exportRoutes,
  qrCodeRoutes,
  orderRoutes,
  docRoutes,
  // changelogRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  internal_dashboardRoutes,
  internal_managedUsersRoutes,
  internal_qrCodeRoutes,
  internal_serverRequestRoutes,
  dashboardRoutes,
  chatRoutes,
  accountRoutes,
  contactRoutes,
  templateRoutes,
  mapRoutes,
  exportRoutes,
  qrCodeRoutes,
  orderRoutes,
  docRoutes,
  // changelogRoutes,
];
