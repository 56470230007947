import { useState, useEffect, useRef } from "react";
import { Chip, Icon as MuiIcon, IconButton } from "@material-ui/core";
import { ExternalLink } from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { globalHistory } from "../history";
import { RemoveRedEye } from "@material-ui/icons";
import ClickableTableCell from "../components/ClickableTableCell";

const Icon = styled(MuiIcon)(spacing);

const handleOrderTableCellClick = (row) => {
  globalHistory.push({
    pathname: `/qr-code/scan/${row.id}`,
    state: {
      qrCodeScanId: row.id,
    },
  });
};

const CopyRowItem = ({ rowItem, rowIndex, row }) => {
  const [copiedText, setCopiedText] = useState("Copy");
  const [shouldShowFullText, setShouldShowFullText] = useState(false);
  const showFullTextTimeoutRef = useRef(null);
  const maxLength = 32;

  useEffect(() => {
    // reset copied text after timeout
    const timeout = setTimeout(() => {
      setCopiedText("");
    }, 5000);
  }, [copiedText]);

  return (
    <CopyToClipboard text={rowItem} onCopy={() => setCopiedText(rowItem)}>
      <Tooltip
        title={
          copiedText === rowItem
            ? "Copied"
            : shouldShowFullText
            ? rowItem
            : "Copy To Clipboard"
        }
        placement="top"
        onOpen={() => {
          clearTimeout(showFullTextTimeoutRef.current);
          showFullTextTimeoutRef.current = setTimeout(() => {
            setShouldShowFullText(true);
          }, 1000);
        }}
        onClose={() => {
          clearTimeout(showFullTextTimeoutRef.current);
          setShouldShowFullText(false);
        }}
      >
        <Chip
          size="small"
          mr={1}
          mb={1}
          label={
            `${rowItem}`.length > maxLength
              ? `${rowItem.slice(0, maxLength)}...`
              : `${rowItem}`
          }
        />
      </Tooltip>
    </CopyToClipboard>
  );
};

const qrCodeScanRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Scan #",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "user_agent",
    alignment: "left",
    label: "User Agent",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => {
      return <CopyRowItem rowItem={rowItem} rowIndex={index} row={row} />;
    },
  },
  {
    id: "ip_address",
    alignment: "left",
    label: "IP Address",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => {
      return <CopyRowItem rowItem={rowItem} rowIndex={index} row={row} />;
    },
  },
  {
    id: "redirected_to_url",
    alignment: "left",
    label: "Redirected To URL",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) =>
      rowItem ? (
        <>
          <a
            href={rowItem}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            {rowItem}{" "}
            <Icon component={ExternalLink} ml={2} fontSize="inherit" />
          </a>
        </>
      ) : (
        "N/A"
      ),
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        aria-label="details"
        onClick={(event) => handleOrderTableCellClick(row)}
      >
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default qrCodeScanRowColumns;
