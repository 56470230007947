const blankSenderAddressInfo = {
  name: {
    key: "name",
    databaseKey: "name",
    endpointKey: "senderName",
    label: "Name",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  businessName: {
    key: "businessName",
    databaseKey: "business_name",
    endpointKey: "senderBusinessName",
    label: "Business Name",
    value: "",
    extractValue: "",
    required: false,
    type: "text",
  },
  addressLineOne: {
    key: "addressLineOne",
    databaseKey: "address_line_one",
    endpointKey: "senderAddressLineOne",
    label: "Address Line One",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  addressLineTwo: {
    key: "addressLineTwo",
    databaseKey: "address_line_two",
    endpointKey: "senderAddressLineTwo",
    label: "Address Line Two",
    value: "",
    extractValue: "",
    required: false,
    type: "text",
  },
  city: {
    key: "city",
    databaseKey: "city",
    endpointKey: "senderCity",
    label: "City",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  state: {
    key: "state",
    databaseKey: "state",
    endpointKey: "senderState",
    label: "State",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  zipCode: {
    key: "zipCode",
    databaseKey: "zip_code",
    endpointKey: "senderZipCode",
    label: "Zip Code",
    value: "",
    extractValue: "",
    required: true,
    type: "text",
  },
  country: {
    key: "country",
    databaseKey: "country",
    endpointKey: "senderCountry",
    label: "Country",
    value: "US",
    extractValue: "",
    required: true,
    type: "text",
  },
};

export default blankSenderAddressInfo;
