import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import queryString from "query-string";
import { globalHistory } from "../../history";
import { NavLink } from "react-router-dom";
import {
  requestLoginToken,
  loginWithToken,
  getAllExtras,
  signUp,
} from "../../backend";

import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { OnWaitlist } from "../../errors";
import { getRequestErrorMessage } from "../../helpers";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Spacer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const Wrapper = styled(Card)`
  margin: ${(props) => props.theme.spacing(3)}px;
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export default function SignUp(props) {
  let { url_loginToken, url_loginOTP } = queryString.parse(
    globalHistory.location.search
  );

  if (url_loginToken && url_loginOTP) {
    globalHistory.replace({
      pathname: "/auth/sign-in",
      search: `?login_token=${url_loginToken}&login_otp=${url_loginOTP}`,
    });

    return null;
  }

  const [isOnWaitlist, setIsOnWaitlist] = React.useState(false);
  const [successfullySignedUp, setSuccessfullySignedUp] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(false);

  const [primaryAddress, setPrimaryAddress] = React.useState("");
  const [businessPhoneNumber, setBusinessPhoneNumber] = React.useState("");
  const [primaryCRM, setPrimaryCRM] = React.useState("");
  const [averageAnnualRevenue, setAverageAnnualRevenue] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const requestSignup = async () => {
      setIsFetching(true);
      setErrorMessage(null);
      try {
        let response = await signUp({
          firstName,
          lastName,
          businessName,
          email,

          primaryAddress,
          businessPhoneNumber,
          primaryCRM,
          averageAnnualRevenue,
        });

        setIsOnWaitlist(response.data.is_on_waitlist ? true : false);
        setSuccessfullySignedUp(true);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "An error occurred while signing up.",
        });

        setErrorMessage(message);
      }

      setIsFetching(false);
    };

    requestSignup();
  };

  return (
    <>
      <Helmet title="Sign In" />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Wrapper>
            <CardHeader
              title={
                <React.Fragment>
                  <Typography variant="h2" gutterBottom>
                    Let's get started
                    <br />
                    <Typography variant="subtitle1">
                      Create your account below
                    </Typography>
                  </Typography>
                </React.Fragment>
              }
              subheader={
                <Typography variant="body2" color="textSecondary">
                  Already have an account?{" "}
                  <Link component={NavLink} to="/auth/sign-in">
                    Sign in
                  </Link>
                </Typography>
              }
            />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {isOnWaitlist ? (
                      <React.Fragment>
                        <Alert severity="info">
                          <strong>You're on the waitlist</strong> - now that you
                          are in the system, we will reach out and let you know
                          when your spot is at the top of the list!
                        </Alert>
                        <Spacer />
                      </React.Fragment>
                    ) : successfullySignedUp ? (
                      <React.Fragment>
                        <Alert severity="success">
                          <strong>Success!</strong> - you're now signed up -
                          head to the{" "}
                          <Link component={NavLink} to="/auth/sign-in">
                            sign in
                          </Link>{" "}
                          page and try signing in!
                        </Alert>
                        <Spacer />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {errorMessage && (
                          <React.Fragment>
                            <Alert mt={2} mb={1} severity="warning">
                              {errorMessage}
                            </Alert>
                            <Spacer />
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required={true}
                          type="firstName"
                          name="firstName"
                          label="first Name"
                          value={firstName}
                          fullWidth
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required={true}
                          type="lastName"
                          name="lastName"
                          label="last Name"
                          value={lastName}
                          fullWidth
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required={true}
                      type="email"
                      name="email"
                      label="Email Address"
                      value={email}
                      fullWidth
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 12,
                    }}
                  />
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required={true}
                          type="businessName"
                          name="businessName"
                          label="Business Name"
                          value={businessName}
                          fullWidth
                          onChange={(e) => {
                            setBusinessName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required={true}
                          type="phoneNumber"
                          name="phoneNumber"
                          label="Business Phone"
                          value={businessPhoneNumber}
                          fullWidth
                          onChange={(e) => {
                            setBusinessPhoneNumber(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required={true}
                      type="address"
                      name="address"
                      label="Business Address"
                      value={primaryAddress}
                      fullWidth
                      onChange={(e) => {
                        setPrimaryAddress(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required={true}
                          type="primaryCRM"
                          name="primaryCRM"
                          label="CRM"
                          value={primaryCRM}
                          fullWidth
                          onChange={(e) => {
                            setPrimaryCRM(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required={true}
                          type="averageAnnualRevenue"
                          name="averageAnnualRevenue"
                          label="Annual Revenue"
                          value={averageAnnualRevenue}
                          fullWidth
                          onChange={(e) => {
                            setAverageAnnualRevenue(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 12,
                    }}
                  />
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isFetching}
                    >
                      {isFetching ? (
                        <React.Fragment>
                          <CircularProgress size={20} />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>Create Account</React.Fragment>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Wrapper>
        </Grid>
      </Grid>
    </>
  );
}
