import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Introduction() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Getting Started
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        Hello, we hope you find this documentation useful. Radial Link has been
        crafted to help automate and facilitate physical marketing to your local
        customers. We have simple to use features as well as advanced ones for
        those that need them and we provide up-to-date documentation to help you
        get up and running with all our current and new features.
      </Typography>
    </Box>
  );
}

function TableOfContents() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Table of Contents
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        <ul>
          <li>
            <Link component={NavLink} exact to="/documentation/getting-started">
              Getting Started
            </Link>
          </li>
          <li>
            <Link component={NavLink} exact to="/documentation/templates">
              Templates
            </Link>
          </li>
          <li>
            <Link component={NavLink} exact to="/documentation/maps">
              Maps
            </Link>
          </li>
          <li>
            <Link component={NavLink} exact to="/documentation/orders">
              Orders
            </Link>
          </li>
          <li>
            <Link component={NavLink} exact to="/documentation/share">
              User Shares
            </Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
function SomethingMissing() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Something missing?
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        If something is missing in the documentation or if you found some part
        confusing, please send us an email (
        <Link href="mailto:support@radial-link.net">
          support@radial-link.net
        </Link>
        ) with your suggestions for improvement. We love hearing from you!
      </Typography>
    </Box>
  );
}

function Welcome() {
  return (
    <React.Fragment>
      <Helmet title="Getting Started" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Documentation
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Documentation</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Introduction />
          <TableOfContents />
          <SomethingMissing />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Welcome;
