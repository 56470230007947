import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import _, { set } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as helpers from "../../helpers";
import { BigNumber } from "../../money";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Chip as MuiChip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { green, orange } from "@material-ui/core/colors";
import { spacing, display } from "@material-ui/system";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  ExpandMore,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import {
  cancelHandWrittenNoteOrderById,
  getHandWrittenNoteOrderById,
  getAllQrCodeScans,
  getAllUserActivities,
} from "../../backend";
import { getRequestErrorMessage, getUserName } from "../../helpers";
import OrderStatusChip from "../components/OrderStatusChip";

import { Edit, Trash } from "react-feather";
import { Delete as DeleteIcon } from "@material-ui/icons";
import constants from "../../constants";
import { globalHistory } from "../../history";
import MoneyChip from "../components/MoneyChip";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";
import AdvancedTable from "../tables/AdvancedTable";
import qrCodeScanRowColumns from "../../advancedTableConfigs/qrCodeScanRowColumns";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import PathPreview from "../../components/PathPreview";

const Icon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: 0px;
  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.success || props.pending) && props.theme.palette.common.white};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function OrderDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let orderId =
    _.get(props, "location.state.orderId", null) ??
    _.get(props, "match.params.orderId", null);
  if (orderId) {
    orderId = parseInt(orderId);
  }
  const [orderData, setOrderData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCancelOrderLoading, setIsCancelOrderLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = React.useState(false);
  const [cancelErrorMessage, setCancelErrorMessage] = React.useState(null);
  const [orderQrScanData, setOrderQrScanData] = React.useState(null);
  const user = useSelector(getActiveUser).user;
  const orderQrScanRowsPerPage = [5, 10, 25, 50, 100];
  let senderAddressInfo;
  let receiverAddressInfo;
  let templateParameters;
  let generatedPaths;
  let templateText;
  let userAuthToken;

  if (orderData) {
    senderAddressInfo = orderData.hand_written_note_order_sender_address_info;
    receiverAddressInfo =
      orderData.hand_written_note_order_receiver_address_info;
    templateParameters = orderData.template_parameters;
    generatedPaths = orderData.generated_paths;
    templateText = orderData.template_text;
    userAuthToken = orderData.user_auth_token;
  }

  // fetch the order details
  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const orderDataResults = await getHandWrittenNoteOrderById(orderId);
        const qrCodeScanDataResults = await getAllQrCodeScans({
          orderId,
          limit: 0,
        });
        setOrderQrScanData(qrCodeScanDataResults.data);
        setOrderData(orderDataResults.data.order);
        setErrorMessage(null);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });
        setOrderData(null);

        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (orderId) {
      loadData();
    } else {
      setErrorMessage("The provided order id is not valid");
    }

    loadData();
  }, [orderId, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="Order Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Order #{orderId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/purchase-order">
              Purchase Orders
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              onClick={() => {
                setIsCancelModalOpen(true);
              }}
            >
              <DeleteIcon />
            </Button>
            <Dialog
              open={isCancelModalOpen}
              onClose={() => {
                setIsCancelModalOpen(false);
              }}
              aria-labelledby="alert-cancel-title"
              aria-describedby="alert-cancel-description"
            >
              <DialogTitle id="alert-cancel-title">Cancel Order</DialogTitle>
              <DialogContent>
                {(!orderData ||
                  orderData.order_status !==
                    constants.hand_written_note_order_status_types.pending) && (
                  <React.Fragment>
                    <Alert mt={2} mb={1} severity="warning">
                      This order can't be cancelled because it has already moved
                      out of pending status.
                    </Alert>
                    <br />
                  </React.Fragment>
                )}

                {cancelErrorMessage && (
                  <React.Fragment>
                    <Alert mt={2} mb={1} severity="warning">
                      {cancelErrorMessage}
                    </Alert>
                    <br />
                  </React.Fragment>
                )}
                <DialogContentText id="alert-delete-description">
                  This action is permanent and your order will be cancelled
                  completely. Are you sure you want to continue?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {isCancelOrderLoading ? (
                  <React.Fragment>
                    <Grid container spacing={0} justifyContent="center">
                      <Grid item>
                        <CircularProgress size={25} color="secondary" />
                      </Grid>
                    </Grid>
                    <br />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      disabled={
                        !orderData ||
                        orderData.order_status !==
                          constants.hand_written_note_order_status_types
                            .pending ||
                        isCancelOrderLoading
                      }
                      onClick={() => {
                        setIsCancelOrderLoading(true);
                        setCancelErrorMessage(null);

                        cancelHandWrittenNoteOrderById(orderData.id)
                          .then(() => {
                            globalHistory.replace({
                              pathname: "/order",
                            });
                          })
                          .catch((err) => {
                            let errorText = helpers.getRequestErrorMessage({
                              error: err,
                              fallbackMessage:
                                "Something went wrong canceling the order",
                            });

                            setCancelErrorMessage(errorText);
                            setIsCancelOrderLoading(false);
                          });
                      }}
                      color="primary"
                    >
                      Cancel Order
                    </Button>
                    <Button
                      disabled={isCancelOrderLoading}
                      variant="contained"
                      onClick={() => {
                        setIsCancelModalOpen(false);
                      }}
                      color="primary"
                      autoFocus
                    >
                      Close
                    </Button>
                  </React.Fragment>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !orderData ? null : (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={10}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Hello{" "}
                          {helpers.getUserNamePlus({
                            replaceForEmailConditional: true,
                          })}
                          ,
                          <br />
                          This is your tracking information and invoice status
                          for order #{orderId}.
                        </Typography>
                      </Grid>

                      {/* alerts */}

                      {(orderData.order_error_message ||
                        orderData.order_cancel_message) && (
                        <Grid item xs={12}>
                          {orderData.order_error_message && (
                            <Alert severity="error" mb={2}>
                              {orderData.order_error_message}
                            </Alert>
                          )}
                          {orderData.order_cancel_message && (
                            <Alert severity="warning" mb={2}>
                              {orderData.order_cancel_message}
                            </Alert>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          link
                          component={NavLink}
                          variant="outlined"
                          color="primary"
                          to={{
                            pathname: `/template/${orderData.template_text_id}`,
                            state: {
                              templateId: orderData.template_text_id,
                            },
                          }}
                        >
                          View Template
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">Order No.</Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={`#${orderId}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Creation Date
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                orderData.createdAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                orderData.createdAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">Order Status.</Typography>
                        <br />
                        <OrderStatusChip
                          order_status={orderData.order_status}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Last Updated
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                orderData.updatedAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                orderData.updatedAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">
                          Sender Address
                        </Typography>
                        {senderAddressInfo && (
                          <Typography variant="body2">
                            {senderAddressInfo.name}
                            <br />
                            {senderAddressInfo.bussiness_name && (
                              <>
                                {senderAddressInfo.bussiness_name}
                                <br />
                              </>
                            )}
                            {senderAddressInfo.address_line_one}
                            <br />
                            {senderAddressInfo.address_line_two && (
                              <>
                                {senderAddressInfo.address_line_two}
                                <br />
                              </>
                            )}
                            {senderAddressInfo.city}, {senderAddressInfo.state}{" "}
                            {senderAddressInfo.zip_code}
                            <br />
                            {senderAddressInfo.country}
                            <br />
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Receiver Address
                        </Typography>
                        {receiverAddressInfo && (
                          <Typography variant="body2" align="right">
                            {receiverAddressInfo.name}
                            <br />
                            {receiverAddressInfo.bussiness_name && (
                              <>
                                {receiverAddressInfo.bussiness_name}
                                <br />
                              </>
                            )}
                            {receiverAddressInfo.address_line_one}
                            <br />
                            {receiverAddressInfo.address_line_two && (
                              <>
                                {receiverAddressInfo.address_line_two}
                                <br />
                              </>
                            )}
                            {receiverAddressInfo.city},{" "}
                            {receiverAddressInfo.state}{" "}
                            {receiverAddressInfo.zip_code}
                            <br />
                            {receiverAddressInfo.country}
                            <br />
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="title">QR Scans</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={orderQrScanData.qrCodeScanCount}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography variant="title" gutterBottom>
                              Generated Paths
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                {orderData.generated_paths ? (
                                  <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Entire Card
                                      </Typography>
                                      <Paper
                                        variant="outlined"
                                        style={{
                                          padding: "16px",
                                        }}
                                      >
                                        <PathPreview
                                          paths={orderData.generated_paths}
                                        />
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <React.Fragment>
                                    <Typography
                                      variant="subtitle2"
                                      color="textSecondary"
                                      align="center"
                                    >
                                      No preview available.
                                    </Typography>
                                  </React.Fragment>
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card px={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderData.transactions.map((transaction, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{transaction.name}</TableCell>
                            <TableCell align="right">
                              <MoneyChip
                                amount={transaction.amount}
                                transactionType={transaction.transaction_type}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="details"
                                component={NavLink}
                                to={{
                                  pathname: `/account/transaction/${transaction.id}`,
                                  state: {
                                    transactionId: transaction.id,
                                  },
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell>
                          <strong>Total</strong>
                        </TableCell>
                        <TableCell align="right">
                          {(() => {
                            let depositSum = new BigNumber(0);
                            let withdrawalSum = new BigNumber(0);

                            orderData.transactions.map((transaction, index) => {
                              let ttype = transaction.transaction_type;

                              if (
                                ttype ===
                                constants.transaction_types.deposit.typeName
                              ) {
                                depositSum = depositSum.plus(
                                  new BigNumber(transaction.amount)
                                );
                              }
                              if (
                                ttype ===
                                constants.transaction_types.withdrawal.typeName
                              ) {
                                withdrawalSum = withdrawalSum.plus(
                                  new BigNumber(transaction.amount)
                                );
                              }
                            });

                            let total = depositSum.minus(withdrawalSum);

                            return <MoneyChip amount={total} />;
                          })()}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
                <Card pb={6} px={6}>
                  <CardContent style={{ textAlign: "center" }}>
                    <div>
                      <Typography variant="caption" gutterBottom align="center">
                        Extra note: Total amount is non refundable after moving
                        out of pending status. The company may refund depending
                        on errors occuring during the creation of the order, in
                        which case your account balance will automatically be
                        credited.
                      </Typography>
                    </div>
                    {/* <Button variant="contained" color="primary" mt={2}>
                      Print this receipt
                    </Button> */}
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
            {/* <Grid item xs={12} lg={10}>
              <Shadow>
                <Card pb={6} px={6} mt={2}>
                  <CardContent>
                    <Typography variant="h6" mt={4} gutterBottom>
                      Fulfillment
                    </Typography>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid> */}
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Divider my={6} />
              <AdvancedTable
                isCollapsed={true}
                title="QR Scans"
                rowColumns={qrCodeScanRowColumns}
                identifier={orderId}
                getRowData={async ({ limit, offset, order }) => {
                  let results = await getAllQrCodeScans({
                    orderId,
                    limit,
                    offset,
                    order,
                  });

                  let parsedRowData = {
                    rows: results.data.qrCodeScans,
                    totalRowCount: results.data.qrCodeScanCount,
                  };

                  return parsedRowData;
                }}
              />
              <AdvancedTable
                isCollapsed={true}
                title="User Activity"
                rowColumns={userActivityRowColumns}
                identifier={orderId}
                getRowData={async ({ limit, offset, order }) => {
                  let results = await getAllUserActivities({
                    limit,
                    offset,
                    order,
                    relations: [["hand_written_note_order_id", orderId]],
                    includeResponsibility: true,
                  });

                  let parsedRowData = {
                    rows: results.data.userActivities,
                    totalRowCount: results.data.userActivityCount,
                  };

                  return parsedRowData;
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default OrderDetails;
