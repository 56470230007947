import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";
import _, { has } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

import {
  CardContent,
  Button as MuiButton,
  Card as MuiCard,
  Typography as MuiTypography,
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing, display } from "@material-ui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

import { Alert as MuiAlert } from "@material-ui/lab";
import {
  getAllHandWrittenNoteOrders,
  getHandWrittenNoteOrdersByPurchaseOrder,
} from "../../backend";
import {
  getRequestErrorMessage,
  getUserName,
  getUserNamePlus,
} from "../../helpers";
import { green, orange } from "@material-ui/core/colors";
import OrderStatusChip from "../components/OrderStatusChip";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";
import ClickableTableCell from "../../components/ClickableTableCell";
import { globalHistory } from "../../history";
import handWrittenNoteOrderRowColumns from "../../advancedTableConfigs/handWrittenNoteOrderRowColumns";
import AdvancedTable from "../tables/AdvancedTable";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function PurchaseOrderDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);
  const purchaseOrder =
    _.get(props, "location.state.purchaseOrder", null) ??
    _.get(props, "match.params.purchaseOrder", null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [orderCount, setOrderCount] = React.useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await getHandWrittenNoteOrdersByPurchaseOrder({
          purchaseOrder,
          limit: 1,
        });

        setOrderCount(result.data.order_count);
      } catch (error) {
        setErrorMessage(getRequestErrorMessage(error));
      }
      setIsLoading(false);
    };

    fetchData();
  }, [purchaseOrder, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="Purchase Order Details" />
      <Typography variant="h3" gutterBottom display="inline">
        Purchase Order: {purchaseOrder}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Link component={NavLink} exact to="/purchase-order">
          Purchase Orders
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Shadow>
              <Card px={6} pt={6}>
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Typography variant="body2" gutterBottom>
                        Hello{" "}
                        {getUserNamePlus({
                          replaceForEmailConditional: true,
                        })}
                        ,
                        <br />
                        This is a collection of all the orders made for purchase
                        order '{purchaseOrder}'.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="caption">Purchase Order</Typography>
                      <Typography variant="body2">'{purchaseOrder}'</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="caption"
                        align="right"
                        display="block"
                      >
                        Total Orders
                      </Typography>
                      <Typography variant="body2" align="right">
                        {orderCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Shadow>
          </Grid>
        </Grid>
      )}
      <br />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AdvancedTable
            title={"Orders"}
            rowColumns={handWrittenNoteOrderRowColumns}
            identifier={purchaseOrder}
            getRowData={async ({ limit, offset, order }) => {
              let results = await getAllHandWrittenNoteOrders({
                purchaseOrder,
                limit,
                offset,
                order,
              });

              let parsedRowData = {
                rows: results.data.orders,
                totalRowCount: results.data.order_count,
              };

              return parsedRowData;
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PurchaseOrderDetails;
