import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  IconButton,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Box,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Switch,
  FormControlLabel,
  InputAdornment,
} from "@material-ui/core";

import { CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  CloudUpload as MuiCloudUpload,
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import { getRequestErrorMessage } from "../../helpers";
import {
  createNewUserShare,
  generateApiToken,
  getAllApiTokens,
  getUserFallbackReturnAddress,
  getUserShareEmptyPermissionObject,
  userUpdateFallbackReturnAddress,
  userUpdateGeneral,
} from "../../backend";
import {
  getActiveUser,
  getCurrentUserKey,
  getPrimaryUser,
} from "../../redux/selectors";
import { currentUserKey } from "../../redux/initialState";
import { globalHistory } from "../../history";
import RecursivePermissionList from "../components/RecursivePermissionList";
import {
  resetPermissionsObject,
  setAllPermissionsObject,
} from "../../permissions";
import constants from "../../constants";

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function NewShareForm() {
  const user = useSelector(getActiveUser).user;
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;

  const currentUserKey = useSelector(getCurrentUserKey);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [share_credits, set_share_credits] = React.useState(false);
  const [
    share_credits_rolling_amount,
    set_share_credits_rolling_amount,
  ] = React.useState(0);
  const [
    share_credits_rolling_window_days,
    set_share_credits_rolling_window_days,
  ] = React.useState(7);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [
    userSharePermissionObject,
    setUserSharePermissionObject,
  ] = React.useState(null);
  const [
    originalUserSharePermissionObject,
    setOriginalUserSharePermissionObject,
  ] = React.useState(null);

  const handleSetPermissionPathValue = (path, value) => {
    if (!Array.isArray(path)) {
      path = [path];
    }
    let currentPermissions = _.cloneDeep(userSharePermissionObject);
    path.forEach((p, i) => {
      let v = value;
      if (Array.isArray(v)) {
        v = v[i];
      }
      _.set(currentPermissions, p, v);
    });
    setUserSharePermissionObject(currentPermissions);
  };

  const handleResetAllPermissions = () => {
    let oldPerms = _.cloneDeep(originalUserSharePermissionObject);
    setUserSharePermissionObject(oldPerms);
  };

  const handleClearAllPermissions = () => {
    let newPerms = resetPermissionsObject(userSharePermissionObject);
    setUserSharePermissionObject(newPerms);
  };

  const handleCheckAllPermissions = () => {
    let newPerms = setAllPermissionsObject(userSharePermissionObject, true);
    setUserSharePermissionObject(newPerms);
  };

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
  }, [currentUserKey]);

  const getDetails = () => {
    const details = {
      email: email,
      permissionObject: userSharePermissionObject,
      defaultFirstName: firstName,
      defaultLastName: lastName,
      share_credits,
      share_credits_rolling_amount,
      share_credits_rolling_window_days,
    };
    return details;
  };

  useEffect(() => {
    // load in a blank permission object
    const getData = async () => {
      setIsLoading(true);
      setErrorMessage("");
      try {
        let results = await getUserShareEmptyPermissionObject();
        setUserSharePermissionObject(results.data.permissionObject);
        setOriginalUserSharePermissionObject(results.data.permissionObject);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage:
              "There was an error loading the user share permissions.",
          })
        );
      }
      setIsLoading(false);
    };
    getData();
  }, [currentUserKey]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newShareDetails = getDetails();

    const saveData = async () => {
      setIsLoading(true);
      setErrorMessage("");
      try {
        let results = await createNewUserShare(newShareDetails);
        return globalHistory.push({
          pathname: `/account/share/${results.data.userShare.id}`,
          state: {
            userShareId: results.data.userShare.id,
          },
        });
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong saving your user share",
          })
        );
      }
      setIsLoading(false);
    };

    saveData();
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Share With User
        </Typography>
        <Typography variant="body1" gutterBottom>
          If this user doesn't exist in our system, we'll automatically create
          them an account with the email address you provide.
        </Typography>

        {errorMessage && (
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="first_name"
                label="First name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                my={2}
                disabled={isLoading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="last-name"
                label="Last name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                my={2}
                disabled={isLoading}
                required={true}
              />
            </Grid>
          </Grid>

          <TextField
            id="email"
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            my={2}
            disabled={isLoading}
            required={true}
          />

          <Divider my={4} />
          <Grid container spacing={6}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Credit Sharing <small>(optional)</small>
                </Typography>
              </Grid>
              <Grid item xs />
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={share_credits}
                      onChange={(e) => {
                        set_share_credits(e.target.checked);
                      }}
                      disabled={isLoading}
                      name="share_credits"
                    />
                  }
                  fullWidth
                  label="Enable for this user"
                />
              </Grid>
              <Grid item xs={12}>
                {/* explanation text */}
                <Typography variant="body2" gutterBottom>
                  Credit sharing allows you to set an allowance from your own
                  balance for other people to use directly in their own account.
                  Let your finance department setup billing and then share with
                  your best sales reps.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="share_credits_rolling_amount"
                label="Allowance Amount"
                variant="outlined"
                value={share_credits_rolling_amount}
                onChange={(e) =>
                  set_share_credits_rolling_amount(e.target.value)
                }
                fullWidth
                my={2}
                disabled={isLoading || !share_credits}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="share_credits_rolling_window_days"
                label="Replenish After"
                variant="outlined"
                value={share_credits_rolling_window_days}
                onChange={(e) =>
                  set_share_credits_rolling_window_days(e.target.value)
                }
                fullWidth
                my={2}
                disabled={isLoading || !share_credits}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">Days</InputAdornment>
                  ),
                }}
                helperText={`This user will be able to use $${share_credits_rolling_amount} every ${share_credits_rolling_window_days} day(s).`}
              />
            </Grid>
          </Grid>

          <Divider my={4} />
          <Grid container spacing={6}>
            <Grid item xs={12} container alignItems="center">
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  Workspace Permissions <small>(optional)</small>
                </Typography>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Button
                  onClick={handleClearAllPermissions}
                  disabled={isLoading}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleCheckAllPermissions}
                  disabled={isLoading}
                >
                  Set All
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleResetAllPermissions}
                  disabled={isLoading}
                >
                  Reset Original
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {/* explanation text */}
              <Typography variant="body2" gutterBottom>
                Workspace permissions are used to control who can access your
                account. You can grant very granular access for only the people
                that need them.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RecursivePermissionList
                node={userSharePermissionObject}
                updatePathValue={handleSetPermissionPathValue}
                compareUser={primaryUser}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            mt={3}
            type="submit"
            disabled={isLoading}
            // fullWidth
          >
            {isLoading ? <CircularProgress size={25} /> : "Submit"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function NewUserShare() {
  return (
    <React.Fragment>
      <Helmet title="New User Share" />

      <Typography variant="h3" gutterBottom display="inline">
        New User Share
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>New User Share</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <NewShareForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NewUserShare;
