import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import _ from "lodash";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Introduction() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        User Shares
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        User shares provide a way for you to share parts of your account to
        other individuals, whether that be to a specific part of your account
        (think finance department, field techs, creating maps, etc), or just
        sharing your credits to your entire sales force. We have two primary
        ways of doing this:
        <ul>
          <li>
            <Link component={HashLink} to="#credit_sharing">
              Credit Sharing
            </Link>
          </li>
          <li>
            <Link component={HashLink} to="#workspace_permissions">
              Workspace Permissions
            </Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}

function BasicSection(props) {
  return (
    <Box mb={10}>
      <Typography
        variant="h4"
        id={_.snakeCase(_.toLower(props.title))}
        gutterBottom
      >
        {props.title}
      </Typography>
      {props.children}
    </Box>
  );
}

function TableOfContents() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Table of Contents
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        <ul>
          <li>
            <Link component={NavLink} exact to="/documentation/getting-started">
              Getting Started
            </Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
function SomethingMissing() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Something missing?
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        If something is missing in the documentation or if you found some part
        confusing, please send us an email (
        <Link href="mailto:support@radial-link.net">
          support@radial-link.net
        </Link>
        ) with your suggestions for improvement. We love hearing from you!
      </Typography>
    </Box>
  );
}

function UserShares() {
  return (
    <React.Fragment>
      <Helmet title="User Shares" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Documentation
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Documentation</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Introduction />
          <BasicSection title="Credit Sharing">
            <Typography variant="body1" gutterBottom my={4}>
              Credit sharing allows you to allot a certain amount of your
              credits to be used by other individuals within their own account.
              This happens on a rolling basis and doesn't transfer your credits
              anywhere, for example: if you have $100 credits and you share $50
              credits with your best sales representative, they will have the
              option to select and use <strong>up to</strong> that amount
              directly from your account within a given time frame (usually two
              weeks).
            </Typography>
          </BasicSection>

          <BasicSection title="Workspace Permissions">
            <Typography variant="body1" gutterBottom my={4}>
              Workspace permissions allow sharing your workspace with other
              individuals. You can select very granular control of what exactly
              is shared too, from only being able to access financial
              information, creating maps, or just the ability to read and not
              create anything.
            </Typography>
            <Typography variant="body1" gutterBottom my={4}>
              These workspaces can be accessed by clicking on your name at the
              very bottom of the sidebar, then searching for the desired
              workspace (query for by: email, first name, last name).
            </Typography>
          </BasicSection>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserShares;
