import useBreakpoint from "use-breakpoint";
import constants from "../constants";

const getExtraProps = (breakpointProps) => {
  const isExtraSmall = breakpointProps.breakpoint === "xs";
  const isSmall = breakpointProps.breakpoint === "sm";
  const isMedium = breakpointProps.breakpoint === "md";
  const isLarge = breakpointProps.breakpoint === "lg";
  const isExtraLarge = breakpointProps.breakpoint === "xl";

  const isMobile = isExtraSmall || isSmall;
  const isTablet = isMedium || isLarge;
  const isDesktop = isLarge || isExtraLarge;

  return {
    ...breakpointProps,
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isMobile,
    isTablet,
    isDesktop,
  };
};

export const useDeviceBreakpoint = (...args) => {
  const breakpointProps = useBreakpoint(constants.breakpoints, ...args);
  return getExtraProps(breakpointProps);
};
