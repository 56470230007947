import { useState, useEffect } from "react";
import { Chip, Icon as MuiIcon, IconButton } from "@material-ui/core";
import { ExternalLink } from "react-feather";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import ClickableTableCell from "../components/ClickableTableCell";
import { RemoveRedEye } from "@material-ui/icons";
import { globalHistory } from "../history";
import { green, red } from "@material-ui/core/colors";

const Icon = styled(MuiIcon)(spacing);

const handleOrderTableCellClick = (row) => {
  globalHistory.push({
    pathname: `/qr-code/${row.id}`,
    state: {
      qrCodeId: row.id,
    },
  });
};

const CopyRowItem = ({ rowItem, rowIndex, row }) => {
  const [copiedText, setCopiedText] = useState("Copy");
  const maxLength = 32;

  useEffect(() => {
    // reset copied text after timeout
    const timeout = setTimeout(() => {
      setCopiedText("");
    }, 5000);
  }, [copiedText]);

  return (
    <CopyToClipboard text={rowItem} onCopy={() => setCopiedText(rowItem)}>
      <Tooltip
        title={copiedText === rowItem ? "Copied" : "Copy To Clipboard"}
        placement="top"
      >
        <Chip
          size="small"
          mr={1}
          mb={1}
          label={
            `${rowItem}`.length > maxLength
              ? `${rowItem.slice(0, maxLength)}...`
              : `${rowItem}`
          }
        />
      </Tooltip>
    </CopyToClipboard>
  );
};

const qrCodeRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "QR Code #",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "template_text",
    alignment: "left",
    label: "Template Name",
    allowSort: false,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `${rowItem.name}`,
  },
  {
    id: "hand_written_note_order_id",
    alignment: "left",
    label: "Order #",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "qr_code_scan_count",
    alignment: "left",
    label: "Scan Count",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip size="small" mr={1} mb={1} label={rowItem} />
    ),
  },
  {
    id: "was_applied",
    alignment: "left",
    label: "Applied To Card",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        mr={1}
        mb={1}
        label={rowItem === null ? "Unknown" : rowItem ? "Yes" : "No"}
      />
    ),
  },
  {
    id: "qr_code_error_message",
    alignment: "left",
    label: "Errored",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <div
        style={{
          height: 25,
          width: 25,
          borderRadius: 1000,
          backgroundColor: rowItem ? red[200] : green[200],
        }}
      />
    ),
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        aria-label="details"
        onClick={(event) => handleOrderTableCellClick(row)}
      >
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default qrCodeRowColumns;
