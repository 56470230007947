import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import {
  parseKeyValuePair,
  // getDelimiterRegex,
  // scaleToFit,
} from "../../helpers";
import {
  createNewTemplate,
  getAllExtras,
  editTemplate,
  verifyTemplate,
  deleteTemplateById,
  getAllIndustries,
  getAllCategories,
  // deleteTemplateById,
  // getTemplateById,
  // placeHandWrittenNoteOrder,
  // getAllTemplates,
  // cancelHandWrittenNoteOrdersByTemplateId,
} from "../../backend";
import * as helpers from "../../helpers";

import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
} from "@material-ui/core";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";

import { Delete as DeleteIcon } from "@material-ui/icons";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { spacing } from "@material-ui/system";
import { globalHistory } from "../../history";
import PathPreview from "../../components/PathPreview";
import { BigNumber } from "../../money";
import constants from "../../constants";
import { getActiveExtras, getActiveUser } from "../../redux/selectors";
import { compareRoles } from "../../permissions";
import ConnectedTemplateCreateEdit from "./TemplateCreateEdit";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const insertTextAtCursor = (e) => {
  let textToInsert = " this is the inserted text ";
  let cursorPosition = e.target.selectionStart;
  let textBeforeCursorPosition = e.target.value.substring(0, cursorPosition);
  let textAfterCursorPosition = e.target.value.substring(
    cursorPosition,
    e.target.value.length
  );
  e.target.value =
    textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
};

const premadeTemplateParamaters = [
  {
    label: "Sales Rep Name",
    getValue: (props) =>
      `${props.startDelimeter} sales_rep_name ${props.endDelimeter}`,
  },
];

class TemplateSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteModalOpen: false,
      loading: false,
      deleteErrorMessage: "",
    };

    this.intendToEdit =
      props.location.pathname.includes("/edit") || props.intent === "edit";

    this.editTemplateData = _.get(
      globalHistory,
      "location.state.templateData",
      null
    );
  }

  render() {
    if (this.intendToEdit && !this.editTemplateData) {
      globalHistory.replace({
        pathname: "/template/new",
        state: {},
      });

      return null;
    }

    let hasChildrenTemplates = false;
    let hasOrders = false;

    if (this.intendToEdit) {
      hasChildrenTemplates =
        this.editTemplateData.children_template_texts &&
        this.editTemplateData.children_template_texts.length > 0;

      hasOrders =
        this.editTemplateData.hand_written_note_orders &&
        this.editTemplateData.hand_written_note_orders.length > 0;
    }

    return (
      <React.Fragment>
        <Helmet title="Settings" />

        <Grid justifyContent="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              {this.intendToEdit
                ? `'${this.editTemplateData.name}'`
                : `'${this.state.name || "Unnamed"}'`}{" "}
              Template Settings
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} exact to="/">
                Dashboard
              </Link>
              <Link component={NavLink} exact to="/template">
                Templates
              </Link>
              <Typography>Template Settings</Typography>
            </Breadcrumbs>
          </Grid>

          {this.intendToEdit && (
            <Grid item>
              <div>
                <Button
                  onClick={() => {
                    this.setState({
                      isDeleteModalOpen: true,
                    });
                  }}
                >
                  <DeleteIcon />
                </Button>
                <Dialog
                  open={this.state.isDeleteModalOpen}
                  onClose={() => {
                    this.setState({
                      isDeleteModalOpen: false,
                    });
                  }}
                  aria-labelledby="alert-delete-title"
                  aria-describedby="alert-delete-description"
                >
                  <DialogTitle id="alert-delete-title">
                    Delete Template
                  </DialogTitle>
                  <DialogContent>
                    {(hasChildrenTemplates || hasOrders) && (
                      <React.Fragment>
                        <Alert mt={2} mb={1} severity="warning">
                          {hasChildrenTemplates
                            ? "This template can't be deleted because it has child templates"
                            : "This template can't be deleted because it has orders"}
                        </Alert>
                        <br />
                      </React.Fragment>
                    )}

                    {this.state.deleteErrorMessage && (
                      <React.Fragment>
                        <Alert mt={2} mb={1} severity="warning">
                          {this.state.deleteErrorMessage}
                        </Alert>
                        <br />
                      </React.Fragment>
                    )}
                    <DialogContentText id="alert-delete-description">
                      This action is permanent and your template will be deleted
                      forever. Are you sure you want to continue?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    {this.state.loading ? (
                      <React.Fragment>
                        <Grid container spacing={0} justifyContent="center">
                          <Grid item>
                            <CircularProgress size={25} color="secondary" />
                          </Grid>
                        </Grid>
                        <br />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Button
                          disabled={
                            hasChildrenTemplates ||
                            hasOrders ||
                            this.state.loading
                          }
                          onClick={() => {
                            this.setState({
                              loading: true,
                              deleteErrorMessage: null,
                            });
                            deleteTemplateById(this.editTemplateData.id)
                              .then(() => {
                                globalHistory.replace({
                                  pathname: "/template",
                                });
                              })
                              .catch((err) => {
                                let errorText = helpers.getRequestErrorMessage({
                                  error: err,
                                  fallbackMessage:
                                    "Something went wrong deleting the template",
                                });

                                this.setState({
                                  deleteErrorMessage: errorText,
                                  loading: false,
                                });
                              });
                          }}
                          color="primary"
                        >
                          Delete
                        </Button>
                        <Button
                          disabled={this.state.loading}
                          variant="contained"
                          onClick={() => {
                            this.setState({
                              isDeleteModalOpen: false,
                            });
                          }}
                          color="primary"
                          autoFocus
                        >
                          Close
                        </Button>
                      </React.Fragment>
                    )}
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          )}
        </Grid>

        <Divider my={6} />

        <ConnectedTemplateCreateEdit
          intent={this.intendToEdit ? "edit" : undefined}
          templateData={this.editTemplateData}
        />
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    extras: getActiveExtras(state),
    user: getActiveUser(state).user,
  };
};
const ConnectedTemplateSettings = connect(mapState)(TemplateSettings);

export default ConnectedTemplateSettings;
