import {
  Chip,
  Avatar as MuiAvatar,
  Box,
  Grid,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components";
import OrderStatusChip from "../pages/components/OrderStatusChip";
import { globalHistory } from "../history";
import ClickableTableCell from "../components/ClickableTableCell";
import { RemoveRedEye } from "@material-ui/icons";

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const handleTableCellClick = (row) => {
  globalHistory.push({
    pathname: `/purchase-order/${row.purchase_order}`,
    state: {
      purchaseOrder: row.purchase_order,
    },
  });
};

const purchaseOrderGroupRowColumns = [
  {
    id: "purchase_order",
    alignment: "left",
    label: "Purchase Order",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return (
        <Customer>
          <Avatar>{`${rowItem}`.slice(0, 1).toUpperCase()}</Avatar>
          <Box ml={3}>{rowItem}</Box>
        </Customer>
      );
    },
  },
  {
    id: "largest_order_status",
    alignment: "left",
    label: "Largest Status",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <OrderStatusChip order_status={rowItem.order_status} />
          </Grid>
          <Grid item>
            <Chip size="small" mr={1} mb={1} label={rowItem.count} />
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: "latest_order",
    alignment: "left",
    label: "Newest Status",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return <OrderStatusChip order_status={rowItem.order_status} />;
    },
  },
  {
    id: "count",
    alignment: "left",
    label: "Order Count",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip size="small" mr={1} mb={1} label={rowItem} />
    ),
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(row.latest_order.createdAt).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(row.latest_order.updatedAt).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        aria-label="details"
        onClick={(event) => handleTableCellClick(row)}
      >
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default purchaseOrderGroupRowColumns;
