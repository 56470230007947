import React from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getAllContacts,
  getAllDataExports,
  getAllHandWrittenNoteOrders,
  getAllPurchaseOrders,
  getSingleContact,
  updateContact,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";

import { Alert as MuiAlert } from "@material-ui/lab";
import OrderStatusChip from "../components/OrderStatusChip";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import { globalHistory } from "../../history";
import ClickableTableCell from "../../components/ClickableTableCell";
import AdvancedTable from "../tables/AdvancedTable";
import handWrittenNoteOrderRowColumns from "../../advancedTableConfigs/handWrittenNoteOrderRowColumns";
import dataExportRowColumns from "../../advancedTableConfigs/dataExportRowColumns";
import contactRowColumns from "../../advancedTableConfigs/contactRowColumns";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const blankAddressInfo = {
  name: {
    key: "name",
    endpointKey: "name",
    value: "",
    label: "Name",
    required: true,
  },
  businessName: {
    key: "businessName",
    endpointKey: "business_name",
    value: "",
    label: "Business Name",
    required: false,
  },
  addressLineOne: {
    key: "addressLineOne",
    endpointKey: "address_line_one",
    value: "",
    label: "Address Line One",
    required: true,
  },
  addressLineTwo: {
    key: "addressLineTwo",
    endpointKey: "address_line_two",
    value: "",
    label: "Address Line Two",
    required: false,
  },
  city: {
    key: "city",
    endpointKey: "city",
    value: "",
    label: "City",
    required: true,
  },
  state: {
    key: "state",
    endpointKey: "state",
    value: "",
    label: "State",
    required: true,
  },
  zipCode: {
    key: "zipCode",
    endpointKey: "zip_code",
    value: "",
    label: "Zip Code",
    required: true,
  },
  country: {
    key: "country",
    endpointKey: "country",
    value: "US",
    label: "Country",
    required: false,
  },
};

function ContactDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let contactId =
    _.get(props, "location.state.contactId", null) ||
    _.get(props, "match.params.contactId", null);
  if (contactId) {
    contactId = parseInt(contactId);
  }

  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [contactData, setContactData] = React.useState(null);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [addressInfo, setAddressInfo] = React.useState({
    ...blankAddressInfo,
  });

  const handleSetAddressInfo = (n) => {
    let prev = _.cloneDeep(addressInfo);
    let merged = _.merge(prev, n);
    setAddressInfo(merged);
  };

  const [isLoading_save, setIsLoading_save] = React.useState(false);
  const [errorMessage_save, setErrorMessage_save] = React.useState(null);
  const [successMessage_save, setSuccessMessage_save] = React.useState(null);

  const handleSave = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading_save(true);
    setErrorMessage_save(null);
    setSuccessMessage_save(null);

    const details = {
      contactId,
      firstName,
      lastName,
      mobileNumber,
      email,
      address: Object.values(addressInfo).reduce((prev, curr) => {
        if (curr.value) {
          prev[curr.key] = `${curr.value}`;
        }

        return prev;
      }, {}),
    };

    try {
      const response = await updateContact(details);

      setSuccessMessage_save("Contact updated successfully");
    } catch (error) {
      setErrorMessage_save(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Failed to update contact",
        })
      );
    }

    setIsLoading_save(false);
  };

  const loadData = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    setSuccessMessage_save(null);
    setErrorMessage_save(null);

    try {
      const response = await getSingleContact({
        contactId,
      });

      setContactData(response.data.contact);
      setFirstName(response.data.contact.first_name);
      setLastName(response.data.contact.last_name);
      setMobileNumber(response.data.contact.mobile_number);
      setEmail(response.data.contact.email);
      let newAddressInfo = Object.values(blankAddressInfo).reduce(
        (prev, curr) => {
          prev[curr.key] = {
            ...curr,
            value: response.data.contact.contact_address[curr.endpointKey],
          };

          return prev;
        },
        {}
      );
      setAddressInfo(newAddressInfo);
    } catch (error) {
      setErrorMessage(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Failed to load contact details",
        })
      );
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [contactId, currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Contact Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Contact #{contactId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>Contact Details</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              Contact Details
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      {errorMessage ? (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      ) : !contactId ? (
        <Alert mt={2} mb={1} severity="warning">
          The contactId is invalid
        </Alert>
      ) : (
        <React.Fragment>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Contact Details"
                  subheader="View and edit details about your contact below"
                />

                <form onSubmit={handleSave}>
                  <CardContent>
                    <Grid container spacing={6}>
                      {successMessage_save && (
                        <Grid item xs={12}>
                          <Alert severity="success">
                            {successMessage_save}
                          </Alert>
                        </Grid>
                      )}

                      {errorMessage_save && (
                        <Grid item xs={12}>
                          <Alert severity="error">{errorMessage_save}</Alert>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Basics</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={"First Name"}
                                  disabled={isLoading}
                                  required={true}
                                  value={firstName}
                                  onChange={(event) => {
                                    setFirstName(event.target.value);

                                    if (addressInfo.name.value === firstName) {
                                      handleSetAddressInfo({
                                        name: {
                                          value: event.target.value,
                                        },
                                      });
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={"Last Name"}
                                  disabled={isLoading}
                                  required={true}
                                  value={lastName}
                                  onChange={(event) => {
                                    setLastName(event.target.value);

                                    if (
                                      addressInfo.name.value &&
                                      addressInfo.name.value.startsWith(
                                        firstName
                                      )
                                    ) {
                                      // firstName + " " + event.target.value

                                      handleSetAddressInfo({
                                        name: {
                                          value:
                                            firstName +
                                            (event.target.value
                                              ? " " + event.target.value
                                              : ""),
                                        },
                                      });
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={"Mobile Number"}
                                  disabled={isLoading}
                                  value={mobileNumber}
                                  type="tel"
                                  onChange={(event) => {
                                    setMobileNumber(event.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={"Email"}
                                  disabled={isLoading}
                                  value={email}
                                  type="email"
                                  onChange={(event) => {
                                    setEmail(event.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Address</Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={addressInfo.name.label}
                                  disabled={isLoading}
                                  required={addressInfo.name.required}
                                  value={addressInfo.name.value}
                                  onChange={(event) => {
                                    handleSetAddressInfo({
                                      [addressInfo.name.key]: {
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={addressInfo.businessName.label}
                                  disabled={isLoading}
                                  required={addressInfo.businessName.required}
                                  value={addressInfo.businessName.value}
                                  onChange={(event) => {
                                    handleSetAddressInfo({
                                      [addressInfo.businessName.key]: {
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label={addressInfo.addressLineOne.label}
                              disabled={isLoading}
                              required={addressInfo.addressLineOne.required}
                              value={addressInfo.addressLineOne.value}
                              onChange={(event) => {
                                handleSetAddressInfo({
                                  [addressInfo.addressLineOne.key]: {
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label={addressInfo.addressLineTwo.label}
                              disabled={isLoading}
                              required={addressInfo.addressLineTwo.required}
                              value={addressInfo.addressLineTwo.value}
                              onChange={(event) => {
                                handleSetAddressInfo({
                                  [addressInfo.addressLineTwo.key]: {
                                    value: event.target.value,
                                  },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={addressInfo.city.label}
                                  disabled={isLoading}
                                  required={addressInfo.city.required}
                                  value={addressInfo.city.value}
                                  onChange={(event) => {
                                    handleSetAddressInfo({
                                      [addressInfo.city.key]: {
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={addressInfo.state.label}
                                  disabled={isLoading}
                                  required={addressInfo.state.required}
                                  value={addressInfo.state.value}
                                  onChange={(event) => {
                                    handleSetAddressInfo({
                                      [addressInfo.state.key]: {
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={addressInfo.zipCode.label}
                                  disabled={isLoading}
                                  required={addressInfo.zipCode.required}
                                  value={addressInfo.zipCode.value}
                                  onChange={(event) => {
                                    handleSetAddressInfo({
                                      [addressInfo.zipCode.key]: {
                                        value: event.target.value,
                                      },
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ContactDetails;
