import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Checkbox,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Chip as MuiChip,
  Avatar,
  Box,
  CircularProgress,
  Collapse,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { getRequestErrorMessage } from "../../helpers";
import { Alert as MuiAlert } from "@material-ui/lab";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import Refresh from "@material-ui/icons/Refresh";
import { ExpandMore } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";

const Alert = styled(MuiAlert)(spacing);

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    totalRows,
    onRequestSort,
    rowColumns,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < totalRows}
            checked={totalRows > 0 && numSelected === totalRows}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {rowColumns.map((headCell, index) => (
          <TableCell
            key={`${headCell.id}-${index}`}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.allowSort ? (
              <>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </>
            ) : (
              <>
                <TableSortLabel active={orderBy === headCell.id} hideSortIcon>
                  {headCell.label}
                </TableSortLabel>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    title,
    errorMessage,
    isCollapsed,
    setIsCollapsed,
    refreshCount,
    setRefreshCount,
    totalRows,
    isLoading,
  } = props;

  return (
    <Toolbar>
      <Grid container spacing={2} direction="row">
        {errorMessage && (
          <Grid item xs={12}>
            <Alert mt={4} severity="warning">
              {errorMessage}
            </Alert>
          </Grid>
        )}
        <Grid item xs>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1">
                  {numSelected} selected
                </Typography>
              ) : (
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                  <Grid item>
                    {/* refresh the table */}
                    <IconButton
                      size={"small"}
                      onClick={() => setRefreshCount(refreshCount + 1)}
                      disabled={isCollapsed || isLoading}
                      style={
                        isLoading
                          ? {
                              animation: `spin ${0.5}s linear infinite`,
                            }
                          : {}
                      }
                    >
                      <Refresh />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Typography variant="subtitle1">
                          ({totalRows})
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" id="tableTitle">
                          {title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <ExpandMore
              style={{
                color: grey[600],
                transform: isCollapsed ? "rotate(0deg)" : "rotate(180deg)",
                transition: "transform 100ms",
              }}
            />
          </IconButton>
          {/* <Grid
            container
            spacing={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={!isCollapsed}
                    onChange={(event, checked) => {
                      setIsCollapsed(!checked);
                    }}
                  />
                }
                label="Open"
              />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

function EnhancedTable(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let [isCollapsed, setIsCollapsed] = useState(
    props.isCollapsed !== undefined ? props.isCollapsed : false
  );

  let [refreshCount, setRefreshCount] = useState(0);

  if (
    props.isCollapsed &&
    props.setIsCollapsed &&
    typeof props.setIsCollapsed === "function"
  ) {
    isCollapsed = props.isCollapsed;
    setIsCollapsed = props.setIsCollapsed;
  }

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updated_at");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [isLoading, setIsLoading] = React.useState(true);

  let errorMessage = props.errorMessage;
  let setErrorMessage = props.setErrorMessage;

  if (!setErrorMessage) {
    [errorMessage, setErrorMessage] = useState(null);
  }

  let rows = props.rowData;
  let setRows = props.setRowData;

  if (!setRows) {
    [rows, setRows] = useState([]);
  }

  const [totalRows, setTotalRows] = React.useState(0);

  React.useEffect(() => {
    setRows([]);
    setTotalRows(0);
    setPage(0);
    setRowsPerPage(10);
  }, [orderBy, order, props.identifier]);

  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const response = await props.getRowData({
          order: [orderBy, order],
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        });
        if (
          !response ||
          !Array.isArray(response.rows) ||
          typeof response.totalRowCount !== "number"
        ) {
          throw new Error("Invalid response received");
        }
        setRows(response.rows);
        setTotalRows(response.totalRowCount);
        setErrorMessage(null);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong loading the table data!",
        });

        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    loadData();
  }, [
    refreshCount,
    order,
    orderBy,
    page,
    rowsPerPage,
    currentUserKey,
    props.identifier,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected((current) => _.uniq([...current, ...newSelecteds]));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = Math.abs(rowsPerPage - rows.length);

  return (
    <Paper elevation={3} {...props.paperProps}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        title={props.title}
        errorMessage={errorMessage}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        refreshCount={refreshCount}
        setRefreshCount={setRefreshCount}
        totalRows={totalRows}
        isLoading={isLoading}
      />
      <Collapse in={!isCollapsed}>
        <TableContainer
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              totalRows={totalRows}
              rowColumns={props.rowColumns}
            />

            {isLoading ? (
              <>
                <TableBody>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={false}
                    tabIndex={-1}
                    selected={false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={false} />
                    </TableCell>
                    {props.rowColumns.map((headCell, index) => (
                      <TableCell key={`loading-${headCell.id}-${index}`}>
                        <CircularProgress size={25} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </>
            ) : totalRows === 0 ? (
              <>
                <TableBody>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={false}
                    tabIndex={-1}
                    selected={false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox checked={false} disabled />
                    </TableCell>
                    <TableCell colSpan={props.rowColumns.length}>
                      <Typography variant="inherit">
                        Hmmm... Looks like there is no data to show, please try
                        changing the filters.
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <TableBody>
                {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`row-${row.id}-${index}-${labelId}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>
                      {props.rowColumns.map((el, elIndex) => {
                        const customRowRenderProps = props.getCustomRowRenderProps
                          ? props.getCustomRowRenderProps({
                              row,
                              index,
                              rowItem: row[el.id],
                              rowConfigItem: el,
                            })
                          : {};

                        const propsToPassToConfig = {
                          row,
                          index,
                          rowItem: row[el.id],
                          customRowRenderProps: customRowRenderProps,
                        };

                        const CellComponent = el.cellComponent || TableCell;

                        let cellProps = {};
                        if (el.getCellProps) {
                          cellProps = {
                            ...el.getCellProps(propsToPassToConfig),
                          };
                        }

                        return (
                          <CellComponent
                            component="th"
                            alignment={el.alignment}
                            scope="row"
                            {...cellProps}
                            key={`cell-${row.id}-${el.id}-${index}-${elIndex}`}
                          >
                            {el.renderRowItem
                              ? el.renderRowItem(propsToPassToConfig)
                              : row[el.id]}
                          </CellComponent>
                        );
                      })}
                      {/* <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.calories}</TableCell>
                      <TableCell align="right">{row.fat}</TableCell>
                      <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Collapse>
    </Paper>
  );
}

function AdvancedTable(props) {
  const {
    title,
    rowColumns,
    rowData,
    setRowData,
    errorMessage,
    setErrorMessage,
    identifier,
    getRowData,
    getCustomRowRenderProps,
    isCollapsed,
    setIsCollapsed,
    containerSpacing,
    paperProps = {},
  } = props;

  return (
    <React.Fragment>
      <Grid container spacing={containerSpacing ?? 6}>
        <Grid item xs={12}>
          <EnhancedTable
            title={title}
            rowColumns={rowColumns}
            rowData={rowData}
            setRowData={setRowData}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            identifier={identifier}
            getRowData={getRowData}
            getCustomRowRenderProps={getCustomRowRenderProps}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            paperProps={paperProps}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdvancedTable;
