import React, { useEffect } from "react";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import blankSenderAddressInfo from "./blankSenderAddressInfo";
import {
  getAllMapCollectionMarkers,
  getAllMapCollections,
  placeHandWrittenNoteOrder,
  placeHandWrittenNoteOrderMapCollection,
} from "../../../backend";
import { globalHistory } from "../../../history";
import { getRequestErrorMessage } from "../../../helpers";
import _ from "lodash";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { CircularProgress } from "@material-ui/core";
import { BigNumber, getDiscountPercentageProps } from "../../../money";
import Autocomplete from "@material-ui/lab/Autocomplete";
import fillBlankAddressValues from "./fillBlankAddressValues";
import { useSelector } from "react-redux";
import {
  getPrimaryUserFallbackReturnAddressInfo,
  getActiveUserFallbackReturnAddressInfo,
  getActiveUser,
} from "../../../redux/selectors";
import HttpsTextField from "../../../components/HttpsTextField";

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CardHeader = styled(MuiCardHeader)(spacing);

const getDefaultOrderParametersObject = (parameters) => {
  return parameters
    .map((key) => {
      return {
        value: "",
        key: key,
      };
    })
    .reduce((result, item, index, array) => {
      result[item.key] = item;
      return result;
    }, {});
};

const blankResults = {
  mapCollections: [],
  mapCollectionCount: 0,
};

const SelectMapCollection = (props) => {
  const {
    selectedCollection,
    setSelectedCollection,
    selectedCollectionMarkers,
    setSelectedCollectionMarkers,
    isParentLoading,
  } = props;

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(blankResults);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, or, q }) => {
        setIsLoading(true);

        try {
          const response = await getAllMapCollections({
            limit: l,
            offset: o,
            order: or,
            query: q,
          });

          setSearchResults({
            mapCollections: response.data.mapCollections,
            mapCollectionsCount: response.data.mapCollectionsCount,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Unable to search.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      setSearchResults(blankResults);
      setErrorMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
      });
    } else {
      setSearchResults(blankResults);
    }
  }, [searchQuery, limit, offset, order, orderBy]);

  React.useEffect(() => {
    if (selectedCollection) {
      const getMarkers = async () => {
        let response = await getAllMapCollectionMarkers({
          collectionId: selectedCollection.id,
        });

        setSelectedCollectionMarkers(
          response.data.mapCollectionMarkers.map((m) => {
            let coords = m.location_geometry.coordinates;
            let lat = coords[1];
            let lng = coords[0];
            return {
              id: m.id,
              identifier: m.identifier,
              latitude: lat,
              longitude: lng,
            };
          })
        );
      };
      getMarkers();
    }
  }, [selectedCollection]);

  const parsedOptions = searchResults.mapCollections.map(
    (mapCollection) => mapCollection
  );

  if (
    selectedCollection &&
    !parsedOptions.some((o) => o.id === selectedCollection.id)
  ) {
    parsedOptions.push(selectedCollection);
  }

  return (
    <Grid container spacing={6}>
      <Typography variant="h6">Selected Collection</Typography>
      <Grid container spacing={6} item>
        <Grid item xs={12} sm>
          <Autocomplete
            id="map-collection-search"
            disableClearable={true}
            loading={isLoading}
            loadingText="Loading..."
            noOptionsText={errorMessage || "No results."}
            fullWidth
            options={parsedOptions}
            getOptionSelected={(option, value) =>
              option && selectedCollection
                ? option.id === selectedCollection.id
                : false
            }
            getOptionLabel={(option) => `#${option.id} - ${option.name}`}
            onChange={(e, newValue) => {
              if (newValue) {
                setSelectedCollection(newValue);
              }
            }}
            value={selectedCollection}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  label={"Collection Name"}
                  required
                  error={errorMessage ? true : false}
                  variant="outlined"
                  value={searchQuery}
                  disabled={isParentLoading}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function MapCollectionForm(props) {
  const {
    selectedTemplate,
    selectedFundAccount,
    cardSize,
    redirectMapCollectionData,
    redirectMapCollectionMarkers,
  } = props;

  const activeUser = useSelector(getActiveUser).user;

  let userFallbackReturnAddress = useSelector(
    getPrimaryUserFallbackReturnAddressInfo
  );

  if (!userFallbackReturnAddress)
    userFallbackReturnAddress = useSelector(
      getActiveUserFallbackReturnAddressInfo
    );

  const templateId = selectedTemplate.id;
  const includeEnvelopeParams =
    !cardSize || cardSize.envelopeInchDimensions !== null;
  const includeSenderAddressInfo = !selectedTemplate.exclude_sender_address_info;

  const [selectedCollection, setSelectedCollection] = React.useState(
    redirectMapCollectionData || null
  );
  useEffect(() => {
    if (redirectMapCollectionData) {
      setSelectedCollection(redirectMapCollectionData);
    }
  }, [redirectMapCollectionData]);

  const [
    selectedCollectionMarkers,
    setSelectedCollectionMarkers,
  ] = React.useState(redirectMapCollectionMarkers || []);
  useEffect(() => {
    if (redirectMapCollectionMarkers) {
      setSelectedCollectionMarkers(redirectMapCollectionMarkers);
    }
  }, [redirectMapCollectionMarkers]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [purchaseOrder, setPurchaseOrder] = React.useState(
    redirectMapCollectionData?.name || ""
  );

  const [redirectUrl, setRedirectUrl] = React.useState(null);

  React.useEffect(() => {
    if (redirectMapCollectionData?.name) {
      setPurchaseOrder(redirectMapCollectionData.name);
    }
  }, [redirectMapCollectionData?.name]);

  const [receiverName, setReceiverName] = React.useState("Current Resident");

  const [orderParameters, setOrderParameters] = React.useState(null);
  const [senderAddressInfo, setSenderAddressInfo] = React.useState(null);

  const handleSetSenderAddressInfo = (n) => {
    let prev = _.cloneDeep(senderAddressInfo);
    let merged = _.merge(prev, n);
    setSenderAddressInfo(merged);
  };

  const handleSetOrderParameters = (n) => {
    let prev = _.cloneDeep(orderParameters);
    let merged = _.merge(prev, n);
    setOrderParameters(merged);
  };

  const getOrderDetails = () => {
    let orderDetails = {
      templateId: templateId,
      collectionId: selectedCollection.id,
      purchaseOrder: purchaseOrder,
      redirectUrl: redirectUrl
        ? redirectUrl.includes("://")
          ? redirectUrl
          : `https://${redirectUrl}`
        : null,
      receiverName: receiverName,
    };

    if (orderParameters) {
      orderDetails.parameters = {};
      Object.values(orderParameters).forEach((param) => {
        orderDetails.parameters[param.key] = param.value;
      });
    }

    if (senderAddressInfo) {
      Object.values(senderAddressInfo).forEach((param) => {
        orderDetails[param.endpointKey] = param.value;
      });
    }

    return orderDetails;
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);
    setErrorMessage(null);
    try {
      const orderDetails = getOrderDetails();
      let results = await placeHandWrittenNoteOrderMapCollection(orderDetails, {
        overrideUseBalanceFromUserId: selectedFundAccount?.user?.id,
      });
      return globalHistory.push("/order");
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage:
            "An unknown error occurred while submitting your order",
        })
      );
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (
      selectedTemplate.required_parameters &&
      selectedTemplate.required_parameters.length > 0
    ) {
      setOrderParameters(
        getDefaultOrderParametersObject(selectedTemplate.required_parameters)
      );
    }

    if (includeEnvelopeParams && includeSenderAddressInfo) {
      if (userFallbackReturnAddress) {
        setSenderAddressInfo(
          fillBlankAddressValues(
            blankSenderAddressInfo,
            userFallbackReturnAddress
          )
        );
      } else {
        setSenderAddressInfo(blankSenderAddressInfo);
      }
    }
  }, [templateId, includeEnvelopeParams, includeSenderAddressInfo]);

  const {
    discountPercentageDecimal,
    discountPercentage,
  } = getDiscountPercentageProps(activeUser.discount_percentage);

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <CardContent>
              {errorMessage && (
                <React.Fragment>
                  <Alert mt={2} mb={1} severity="warning">
                    {errorMessage ??
                      "An unknown error occurred while submitting your order"}
                  </Alert>
                  <br />
                  <br />
                </React.Fragment>
              )}
              {selectedCollectionMarkers.length > 0 && (
                <React.Fragment>
                  <Alert mt={2} mb={1} severity="info">
                    Estimated Price: $
                    {new BigNumber(
                      selectedCollectionMarkers.length >=
                      cardSize.priceByOrderType.single.threshold
                        ? cardSize.priceByOrderType.single.overThresholdPrice
                        : cardSize.priceByOrderType.single.price
                    )
                      .multipliedBy(selectedCollectionMarkers.length)
                      .times(discountPercentageDecimal)
                      .toFormat(2)}{" "}
                    ($
                    {new BigNumber(
                      selectedCollectionMarkers.length >=
                      cardSize.priceByOrderType.single.threshold
                        ? cardSize.priceByOrderType.single.overThresholdPrice
                        : cardSize.priceByOrderType.single.price
                    )
                      .times(discountPercentageDecimal)
                      .toFormat(2)}
                    /order)
                    {!discountPercentage.lte(0) &&
                      ` - ${discountPercentage}% savings applied`}
                    <br />
                    <Typography variant="caption">
                      POLYGONS NOT INCLUDED
                    </Typography>
                  </Alert>
                  <br />
                  <br />
                </React.Fragment>
              )}
              {!props.hide_selectMapCollection && (
                <React.Fragment>
                  <SelectMapCollection
                    selectedCollection={selectedCollection}
                    setSelectedCollection={setSelectedCollection}
                    selectedCollectionMarkers={selectedCollectionMarkers}
                    setSelectedCollectionMarkers={setSelectedCollectionMarkers}
                    isParentLoading={isLoading}
                  />
                  <br />
                  <br />
                </React.Fragment>
              )}
              <Grid container spacing={6}>
                <Typography variant="h6">Options</Typography>
                <Grid container spacing={6} item>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      type="text"
                      id={"purchaseOrder"}
                      label={"Purchase Order"}
                      variant="outlined"
                      value={purchaseOrder}
                      onChange={(e) => {
                        setPurchaseOrder(e.target.value);
                      }}
                      fullWidth
                      my={2}
                      disabled={isLoading || !selectedCollection}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      type="text"
                      id={"receiverName"}
                      label={"Receiver Name"}
                      variant="outlined"
                      value={receiverName}
                      onChange={(e) => {
                        setReceiverName(e.target.value);
                      }}
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <HttpsTextField
                      value={redirectUrl}
                      label="QR Code (Redirect) URL"
                      helperText="A url to provide tracking for and redirect all clients to through a printed QR code included with each card (i.e. scheduling form, landing page, etc)"
                      onChange={(e) => {
                        setRedirectUrl(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <br />
              {orderParameters && (
                <React.Fragment>
                  <Grid container spacing={6}>
                    <Typography variant="h6">Template Parameters</Typography>
                    <Grid container spacing={6} item>
                      {Object.values(orderParameters).map((param) => {
                        return (
                          <Grid item xs={12} sm key={param.key}>
                            <TextField
                              type="text"
                              id={param.key}
                              label={_.words(param.key)
                                .map(_.capitalize)
                                .join(" ")}
                              helperText={param.key}
                              variant="outlined"
                              value={param.value}
                              onChange={(e) => {
                                handleSetOrderParameters({
                                  [param.key]: {
                                    value: e.target.value,
                                  },
                                });
                              }}
                              fullWidth
                              my={2}
                              disabled={isLoading || !selectedCollection}
                              required={true}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>

                  <br />
                  <br />
                </React.Fragment>
              )}
              {senderAddressInfo && (
                <React.Fragment>
                  <Grid container spacing={6}>
                    <Typography variant="h6">Sender Address</Typography>
                    <Grid container spacing={6} item>
                      <Grid item xs={12} md={6}>
                        <TextField
                          type="text"
                          id={senderAddressInfo.name.endpointKey}
                          label={senderAddressInfo.name.label}
                          variant="outlined"
                          value={senderAddressInfo.name.value}
                          onChange={(e) =>
                            handleSetSenderAddressInfo({
                              [senderAddressInfo.name.key]: {
                                value: e.target.value,
                              },
                            })
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !selectedCollection}
                          required={senderAddressInfo.name.required}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          type="text"
                          id={senderAddressInfo.businessName.endpointKey}
                          label={senderAddressInfo.businessName.label}
                          variant="outlined"
                          value={senderAddressInfo.businessName.value}
                          onChange={(e) =>
                            handleSetSenderAddressInfo({
                              [senderAddressInfo.businessName.key]: {
                                value: e.target.value,
                              },
                            })
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !selectedCollection}
                          required={senderAddressInfo.businessName.required}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        id={senderAddressInfo.addressLineOne.endpointKey}
                        label={senderAddressInfo.addressLineOne.label}
                        variant="outlined"
                        value={senderAddressInfo.addressLineOne.value}
                        onChange={(e) =>
                          handleSetSenderAddressInfo({
                            [senderAddressInfo.addressLineOne.key]: {
                              value: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        my={2}
                        disabled={isLoading || !selectedCollection}
                        required={senderAddressInfo.addressLineOne.required}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        id={senderAddressInfo.addressLineTwo.endpointKey}
                        label={senderAddressInfo.addressLineTwo.label}
                        variant="outlined"
                        value={senderAddressInfo.addressLineTwo.value}
                        onChange={(e) =>
                          handleSetSenderAddressInfo({
                            [senderAddressInfo.addressLineTwo.key]: {
                              value: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        my={2}
                        disabled={isLoading || !selectedCollection}
                        required={senderAddressInfo.addressLineTwo.required}
                      />
                    </Grid>

                    <Grid container spacing={6} item>
                      <Grid item xs={12} md={4}>
                        <TextField
                          type="text"
                          id={senderAddressInfo.city.endpointKey}
                          label={senderAddressInfo.city.label}
                          variant="outlined"
                          value={senderAddressInfo.city.value}
                          onChange={(e) =>
                            handleSetSenderAddressInfo({
                              [senderAddressInfo.city.key]: {
                                value: e.target.value,
                              },
                            })
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !selectedCollection}
                          required={senderAddressInfo.city.required}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          type="text"
                          id={senderAddressInfo.state.endpointKey}
                          label={senderAddressInfo.state.label}
                          variant="outlined"
                          value={senderAddressInfo.state.value}
                          onChange={(e) =>
                            handleSetSenderAddressInfo({
                              [senderAddressInfo.state.key]: {
                                value: e.target.value,
                              },
                            })
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !selectedCollection}
                          required={senderAddressInfo.state.required}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          type="text"
                          id={senderAddressInfo.zipCode.endpointKey}
                          label={senderAddressInfo.zipCode.label}
                          variant="outlined"
                          value={senderAddressInfo.zipCode.value}
                          onChange={(e) =>
                            handleSetSenderAddressInfo({
                              [senderAddressInfo.zipCode.key]: {
                                value: e.target.value,
                              },
                            })
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !selectedCollection}
                          required={senderAddressInfo.zipCode.required}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <br />
                  <br />
                </React.Fragment>
              )}
            </CardContent>
            <CardActions>
              <Button
                // fullWidth
                variant="contained"
                color="primary"
                type="submit"
                disabled={isLoading || !selectedCollection}
              >
                {isLoading ? (
                  <React.Fragment>
                    <CircularProgress size={24} />
                  </React.Fragment>
                ) : (
                  "Submit"
                )}
              </Button>
            </CardActions>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
