import { createStore, applyMiddleware, compose as reduxCompose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import { initialState } from "./initialState";
import logger from "redux-logger";
import { isDevelopment, isStaging } from "../config";

const middleware = [
  // logger
];

const persistConfig = {
  key: "root_v2",
  storage,
  blacklist: ["networkStatus"],
};

if (isDevelopment || isStaging) middleware.push(logger);

let compose = reduxCompose;
if (
  (isDevelopment || isStaging) &&
  window &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
)
  compose = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

let devtools;
let comp = [applyMiddleware(...middleware)];

if (typeof window !== "undefined") {
  devtools =
    (isDevelopment || isStaging) && window.devToolsExtension
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f;

  comp.push(devtools);
}

export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  initialState,
  compose(...comp)
);

export const persistor = persistStore(store);
