import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import _, { set } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as helpers from "../../helpers";
import { BigNumber } from "../../money";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Chip as MuiChip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { green, orange } from "@material-ui/core/colors";
import { spacing, display } from "@material-ui/system";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import {
  cancelHandWrittenNoteOrderById,
  getHandWrittenNoteOrderById,
  getTransactionGroupById,
  getTransactionById,
} from "../../backend";
import { getRequestErrorMessage, getUserName } from "../../helpers";
import OrderStatusChip from "../components/OrderStatusChip";

import { Edit, Trash } from "react-feather";
import { Delete as DeleteIcon } from "@material-ui/icons";
import constants from "../../constants";
import { globalHistory } from "../../history";
import MoneyChip from "../components/MoneyChip";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";

const Icon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: 0px;
  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.success || props.pending) && props.theme.palette.common.white};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function TransactionDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let transactionId =
    _.get(props, "location.state.transactionId", null) ??
    _.get(props, "match.params.transactionId", null);
  if (transactionId) {
    transactionId = parseInt(transactionId);
  }
  const [transactionData, setTransactionData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const user = useSelector(getActiveUser).user;

  // fetch the transaction details
  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const response = await getTransactionById(transactionId);
        setTransactionData(response.data.transaction);
        setErrorMessage(null);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });
        setTransactionData(null);

        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (transactionId) {
      loadData();
    } else {
      setErrorMessage("The provided transaction id is not valid");
    }

    loadData();
  }, [transactionId, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="Transaction Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Transaction #{transactionId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/transaction">
              Transaction
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !transactionData ? null : (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={10}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Hello{" "}
                          {helpers.getUserNamePlus({
                            replaceForEmailConditional: true,
                          })}
                          ,
                          <br />
                          This is your transaction history for #{transactionId}.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">
                          Transaction No.
                        </Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={`#${transactionId}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Creation Date
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                transactionData.createdAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                transactionData.createdAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">
                          Transaction Total.
                        </Typography>
                        <br />
                        <MoneyChip
                          amount={transactionData.amount}
                          transactionType={transactionData.transaction_type}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Last Updated
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                transactionData.updatedAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                transactionData.updatedAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">
                          Sender Address
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Receiver Address
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card px={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Item #</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[transactionData.hand_written_note_order]
                        .filter((o) => o)
                        .map((order, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>#{order.id}</TableCell>
                              <TableCell>Handwritten Note Order</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="details"
                                  component={NavLink}
                                  to={{
                                    pathname: `/order/${order.id}`,
                                    state: {
                                      orderId: order.id,
                                    },
                                  }}
                                >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {[transactionData.promotional_code]
                        .filter((o) => o)
                        .map((promo, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>#{promo.id}</TableCell>
                              <TableCell>Promotional Code</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-label="details"
                                  disabled={true}
                                >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Card>
                <Card pb={6} px={6}>
                  <CardContent style={{ textAlign: "center" }}>
                    <div>
                      <Typography variant="caption" gutterBottom align="center">
                        Extra note: Total amount is non refundable after order
                        has move out of pending status. The company may refund
                        depending on errors occuring during the creation of the
                        order, in which case your account balance will
                        automatically be credited.
                      </Typography>
                    </div>
                    {/* <Button variant="contained" color="primary" mt={2}>
                      Print this receipt
                    </Button> */}
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default TransactionDetails;
