import _ from "lodash";
import constants from "../constants";

export function setAllPermissionsObject(permissionObject, value) {
  let currentPermissions = _.cloneDeep(permissionObject);
  let newPermissions = _.cloneDeep(permissionObject);

  let resetPermissions = (permissions, path) => {
    _.forEach(permissions, (n, key) => {
      if (!n.disabled) {
        let newPath = path ? `${path}.${key}` : key;
        _.set(newPermissions, `${newPath}.read`, Boolean(value));
        _.set(newPermissions, `${newPath}.write`, Boolean(value));
        if (n.children) {
          resetPermissions(n.children, `${newPath}.children`);
        }
      }
    });
  };

  resetPermissions(currentPermissions);
  return newPermissions;
}

export function resetPermissionsObject(permissionObject) {
  let newPermissions = setAllPermissionsObject(permissionObject, false);
  return newPermissions;
}

export function getRoleNumber(role) {
  switch (role) {
    case constants.roles.user.typeName:
      return 1;
    case constants.roles.rep.typeName:
      return 2;
    case constants.roles.repManager.typeName:
      return 3;
    case constants.roles.superAdmin.typeName:
      return 4;
    case constants.roles.ceo.typeName:
      return 5;
    default:
      return 0;
  }
}

// WARNING: uses eval for custom comparitor. Should only ever be used internal and should scrub any user inputs.
export function compareRoles(role1, comparitor, role2) {
  let role1Number = getRoleNumber(role1);
  let role2Number = getRoleNumber(role2);
  return Boolean(eval(`${role1Number} ${comparitor} ${role2Number}`));
}
