import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { globalHistory } from "../history";
import {
  getActiveAccessToken,
  getActiveAccessTokenDetails,
  getPrimaryRefreshTokenDetails,
} from "../redux/selectors";

// For routes that can only be accessed by authenticated users
function LoginRedirect({ children }) {
  const refreshTokenDetails = useSelector(getPrimaryRefreshTokenDetails);
  const accessTokenDetails = useSelector(getActiveAccessTokenDetails);
  const accessToken = useSelector(getActiveAccessToken);

  if (
    accessToken &&
    accessToken.value &&
    refreshTokenDetails &&
    refreshTokenDetails.expirationDate &&
    refreshTokenDetails.expirationDate > Date.now()
  ) {
    globalHistory.push("/");
    return null;
  } else {
    return children;
  }
}

export default LoginRedirect;
