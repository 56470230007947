import { Chip, Avatar as MuiAvatar, Box, IconButton } from "@material-ui/core";
import { globalHistory } from "../history";
import ClickableTableCell from "../components/ClickableTableCell";
import { RemoveRedEye } from "@material-ui/icons";
import styled from "styled-components";
import DisplayUserActivitiesResponsibleUsers from "./DisplayUserActivitiesResponsibleUsers";
import { getUserAvatarUrl } from "../backend";

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const handleTableCellClick = (row) => {
  globalHistory.push({
    pathname: `/account/share/${row.id}`,
    state: {
      userShareId: row.id,
    },
  });
};

// requires extractedRowUser from getcustomRowRenderProps

const userShareRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Share #",
    allowSort: false,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "id",
    alignment: "left",
    label: "User #",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem, customRowRenderProps }) => {
      const extractedRowUser = _.get(
        customRowRenderProps,
        "extractedRowUser",
        {}
      );

      return `#${extractedRowUser.id}`;
    },
  },
  {
    id: "user_activities",
    alignment: "left",
    label: "User Activity",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <DisplayUserActivitiesResponsibleUsers userActivities={rowItem} />
    ),
  },
  {
    id: "firstName",
    alignment: "left",
    label: "First Name",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem, customRowRenderProps }) => {
      const extractedRowUser = _.get(
        customRowRenderProps,
        "extractedRowUser",
        {}
      );

      return (
        <Customer>
          <Avatar src={getUserAvatarUrl({ userId: extractedRowUser.id })} />
          <Box ml={3}>
            {_.capitalize(extractedRowUser.firstName || "Unnamed")}
          </Box>
        </Customer>
      );
    },
  },
  {
    id: "lastName",
    alignment: "left",
    label: "Last Name",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem, customRowRenderProps }) => {
      const extractedRowUser = _.get(
        customRowRenderProps,
        "extractedRowUser",
        {}
      );

      return _.capitalize(extractedRowUser.lastName || "User");
    },
  },
  {
    id: "businessName",
    alignment: "left",
    label: "Business Name",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem, customRowRenderProps }) => {
      const extractedRowUser = _.get(
        customRowRenderProps,
        "extractedRowUser",
        {}
      );

      return _.capitalize(extractedRowUser.businessName || "");
    },
  },
  {
    id: "email",
    alignment: "left",
    label: "Email",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem, customRowRenderProps }) => {
      const extractedRowUser = _.get(
        customRowRenderProps,
        "extractedRowUser",
        {}
      );

      return _.capitalize(extractedRowUser.email);
    },
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        aria-label="details"
        onClick={(event) => handleTableCellClick(row)}
      >
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default userShareRowColumns;
