import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import _, { set } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as helpers from "../../helpers";
import { BigNumber } from "../../money";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Chip as MuiChip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar as MuiAvatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
  ButtonBase,
  FormControlLabel,
  Tooltip,
  Switch,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { green, orange } from "@material-ui/core/colors";
import { spacing, display } from "@material-ui/system";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import {
  deleteUserShareById,
  editUserShareById,
  getActorAccessToken,
  getAllUserActivities,
  getUserAvatarUrl,
  getUserShareById,
} from "../../backend";
import { getRequestErrorMessage, getUserName } from "../../helpers";
import OrderStatusChip from "../components/OrderStatusChip";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Edit, Trash } from "react-feather";
import { Delete as DeleteIcon } from "@material-ui/icons";
import constants from "../../constants";
import { globalHistory } from "../../history";
import MoneyChip from "../components/MoneyChip";
import {
  getActiveUser,
  getAllUserKeys,
  getCurrentUserKey,
  getPrimaryUser,
} from "../../redux/selectors";
import { store } from "../../redux/store";
import { setCurrentUserKey } from "../../redux/actions/currentUserKeyActions";
import { resetUsersData } from "../../redux/actions/usersActions";
import RecursivePermissionList from "../components/RecursivePermissionList";
import {
  resetPermissionsObject,
  setAllPermissionsObject,
} from "../../permissions";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import AdvancedTable from "../tables/AdvancedTable";
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const Icon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: 0px;
  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.success || props.pending) && props.theme.palette.common.white};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function UserProfile({
  user,
  label,
  type,
  userShare,
  handleSwitchWorkspace,
  isLoading,
}) {
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;

  // user_share_user
  // user_share_actor
  // user_share_manager

  let canSwitchToUser = false;
  if (
    type === "user_share_user" &&
    activeUser.id === userShare.user_share_actor.id
  ) {
    canSwitchToUser = true;
  }

  let isActiveUser = activeUser.id === user.id;

  let isSwitchToUserDisabled = userShare.user_share_actor.id !== primaryUser.id;

  return (
    <React.Fragment>
      <Card px={6} pt={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                {label}
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item>
                <Avatar
                  src={getUserAvatarUrl({
                    userId: user.id,
                  })}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    {helpers.getUserNamePlus({
                      overrideUser: user,
                      compareUser: activeUser,
                      includeIsYou: true,
                    })}
                  </Grid>
                  <Grid item>{user.email}</Grid>
                </Grid>
              </Grid>
              <Grid item xs></Grid>
              <Grid item>
                {canSwitchToUser && (
                  <Tooltip
                    title={
                      isSwitchToUserDisabled
                        ? "Access is not available to you in this workspace"
                        : "Switch to this user"
                    }
                  >
                    <div>
                      <Button
                        onClick={() => {
                          handleSwitchWorkspace(user.id);
                        }}
                        variant="contained"
                        color="primary"
                        disabled={isLoading || isSwitchToUserDisabled}
                      >
                        {isLoading ? <CircularProgress size={24} /> : "Switch"}
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

function UserShareDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;

  let userShareId =
    _.get(props, "location.state.userShareId", null) ??
    _.get(props, "match.params.userShareId", null);
  if (userShareId) {
    userShareId = parseInt(userShareId);
  }
  const [userShareData, setUserShareData] = React.useState(null);

  const [share_credits, set_share_credits] = React.useState(false);
  const [
    share_credits_rolling_amount,
    set_share_credits_rolling_amount,
  ] = React.useState(0);
  const [
    share_credits_rolling_window_days,
    set_share_credits_rolling_window_days,
  ] = React.useState(0);

  const [
    userSharePermissionObject,
    setUserSharePermissionObject,
  ] = React.useState(null);
  const [
    originalUserSharePermissionObject,
    setOriginalUserSharePermissionObject,
  ] = React.useState(null);

  const handleSetPermissionPathValue = (path, value) => {
    if (!Array.isArray(path)) {
      path = [path];
    }
    let currentPermissions = _.cloneDeep(userSharePermissionObject);
    path.forEach((p, i) => {
      let v = value;
      if (Array.isArray(v)) {
        v = v[i];
      }
      _.set(currentPermissions, p, v);
    });
    setUserSharePermissionObject(currentPermissions);
  };

  const handleResetAllPermissions = () => {
    let oldPerms = _.cloneDeep(originalUserSharePermissionObject);
    setUserSharePermissionObject(oldPerms);
  };

  const handleClearAllPermissions = () => {
    let newPerms = resetPermissionsObject(userSharePermissionObject);
    setUserSharePermissionObject(newPerms);
  };

  const handleCheckAllPermissions = () => {
    let newPerms = setAllPermissionsObject(userSharePermissionObject, true);
    setUserSharePermissionObject(newPerms);
  };

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const user = useSelector(getActiveUser).user;
  const userKeyList = useSelector(getAllUserKeys);
  const userKeyList_excludePrimary = userKeyList.filter(
    (key) => key !== "primary"
  );

  // isDeleteModalOpen
  // deleteErrorMessage
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = React.useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);

  const getDetails = () => {
    const details = {
      userShareId: userShareId,
      permissionObject: userSharePermissionObject,
      share_credits,
      share_credits_rolling_amount,
      share_credits_rolling_window_days,
    };
    return details;
  };

  const handleSaveChanges = async () => {
    const saveChanges = async () => {
      setIsSaving(true);
      setSuccessMessage("");

      try {
        let details = getDetails();
        let response = await editUserShareById(details);
        setUserShareData(response.data.userShare);
        setUserSharePermissionObject(response.data.permissionObject);
        setOriginalUserSharePermissionObject(response.data.permissionObject);
        setSuccessMessage("Changes saved successfully");
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error: error,
            fallbackMessage: "Something went wrong saving the changes",
          })
        );
      }

      setIsSaving(false);
    };

    saveChanges();
  };

  const handleSwitchWorkspace = async (switchToUserId) => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const response = await getActorAccessToken(
        {
          userId: switchToUserId,
        },
        {
          forcePrimaryUser: true,
        }
      );
      store.dispatch(setCurrentUserKey(response.data.user.id));
      userKeyList_excludePrimary
        .filter((userKey) => userKey !== `${response.data.user.id}`)
        .forEach((userKey) => {
          store.dispatch(resetUsersData(userKey));
        });
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage: "Unable to switch workspace.",
        })
      );
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        const response = await getUserShareById({ userShareId });
        setUserShareData(response.data.userShare);
        setUserSharePermissionObject(response.data.permissionObject);
        setOriginalUserSharePermissionObject(response.data.permissionObject);
        set_share_credits(response.data.userShare.share_credits);
        set_share_credits_rolling_amount(
          parseFloat(
            response.data.userShare.share_credits_rolling_amount !== null
              ? response.data.userShare.share_credits_rolling_amount
              : 0
          )
        );
        set_share_credits_rolling_window_days(
          response.data.userShare.share_credits_rolling_window_days !== null
            ? response.data.userShare.share_credits_rolling_window_days
            : 0
        );
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });
        setUserShareData(null);
        setUserSharePermissionObject(null);

        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (userShareId) {
      loadData();
    } else {
      setErrorMessage("The provided user share id is not valid");
    }

    loadData();
  }, [userShareId, currentUserKey]);

  const shareUser = _.get(userShareData, "user_share_user", null);
  const shareActor = _.get(userShareData, "user_share_actor", null);
  const shareManager = _.get(userShareData, "user_share_manager", null);

  const isShareManager = shareManager && shareManager.id === activeUser.id;

  const canEditPermissions = isShareManager;

  return (
    <React.Fragment>
      <Helmet title="User Share Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            User Share #{userShareId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/account/share">
              User Share
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
        {isShareManager && (
          <Grid item>
            <div>
              <Button
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                <DeleteIcon />
              </Button>
              <Dialog
                open={isDeleteModalOpen}
                onClose={() => {
                  setIsDeleteModalOpen(false);
                }}
                aria-labelledby="alert-delete-title"
                aria-describedby="alert-delete-description"
              >
                <DialogTitle id="alert-delete-title">
                  Delete User Share
                </DialogTitle>
                <DialogContent>
                  {deleteErrorMessage && (
                    <React.Fragment>
                      <Alert mt={2} mb={1} severity="warning">
                        {deleteErrorMessage}
                      </Alert>
                      <br />
                    </React.Fragment>
                  )}
                  <DialogContentText id="alert-delete-description">
                    This action is permanent and your template will be deleted
                    forever. Are you sure you want to continue?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {isLoading || isDeleteLoading ? (
                    <React.Fragment>
                      <Grid container spacing={0} justifyContent="center">
                        <Grid item>
                          <CircularProgress size={25} color="secondary" />
                        </Grid>
                      </Grid>
                      <br />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        onClick={() => {
                          setIsDeleteLoading(true);
                          setDeleteErrorMessage(null);

                          deleteUserShareById({ userShareId: userShareData.id })
                            .then(() => {
                              globalHistory.replace({
                                pathname: "/account/share",
                              });
                            })
                            .catch((err) => {
                              let errorText = getRequestErrorMessage({
                                error: err,
                                fallbackMessage:
                                  "Something went wrong deleting the user share",
                              });
                              setDeleteErrorMessage(errorText);
                              setIsDeleteLoading(false);
                            });
                        }}
                        color="primary"
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setIsDeleteModalOpen(false);
                        }}
                        color="primary"
                        autoFocus
                      >
                        Close
                      </Button>
                    </React.Fragment>
                  )}
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !userShareData ? null : (
        <>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item xs={12}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      {successMessage && (
                        <Grid item xs={12}>
                          <Alert mt={2} mb={1} severity="success">
                            {successMessage}
                          </Alert>
                        </Grid>
                      )}
                      <Grid item xs={12} container alignItems="center">
                        <Grid item>
                          <Typography variant="h6">Credit Sharing</Typography>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={share_credits}
                                onChange={(e) => {
                                  set_share_credits(e.target.checked);
                                }}
                                disabled={isLoading}
                                name="share_credits"
                              />
                            }
                            fullWidth
                            label="Enable for this user"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {/* explanation text */}
                          <Typography variant="body2" gutterBottom>
                            Credit sharing allows you to set an allowance from
                            your own balance for other people to use directly in
                            their own account. Let your finance department
                            handle billing and then share credits with your best
                            sales reps.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          type="text"
                          id="share_credits_rolling_amount"
                          label="Allowance Amount"
                          variant="outlined"
                          value={share_credits_rolling_amount}
                          onChange={(e) =>
                            set_share_credits_rolling_amount(e.target.value)
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !share_credits}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          type="text"
                          id="share_credits_rolling_window_days"
                          label="Replenish After"
                          variant="outlined"
                          value={share_credits_rolling_window_days}
                          onChange={(e) =>
                            set_share_credits_rolling_window_days(
                              e.target.value
                            )
                          }
                          fullWidth
                          my={2}
                          disabled={isLoading || !share_credits}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                Days
                              </InputAdornment>
                            ),
                          }}
                          helperText={`This user will be able to use $${share_credits_rolling_amount} every ${share_credits_rolling_window_days} day(s).`}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item xs={12} container alignItems="center">
                        <Grid item>
                          <Typography variant="h6" gutterBottom>
                            Workspace Permissions
                          </Typography>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                          <Button
                            onClick={handleClearAllPermissions}
                            disabled={!canEditPermissions || isSaving}
                          >
                            Clear All
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={handleCheckAllPermissions}
                            disabled={!canEditPermissions || isSaving}
                          >
                            Set All
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            onClick={handleResetAllPermissions}
                            disabled={!canEditPermissions || isSaving}
                          >
                            Reset Original
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {/* explanation text */}
                        <Typography variant="body2" gutterBottom>
                          Workspace permissions are used to control who can
                          access your account. You can grant very granular
                          access for only the people that need them.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <RecursivePermissionList
                          node={userSharePermissionObject}
                          updatePathValue={handleSetPermissionPathValue}
                          disabled={!canEditPermissions || isSaving}
                          compareUser={primaryUser}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleSaveChanges();
                          }}
                          color="primary"
                          disabled={!canEditPermissions || isSaving}
                        >
                          {isSaving ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
            <Grid item xs={12}>
              <UserProfile
                user={shareActor}
                label={"Shared To User"}
                type={"user_share_actor"}
                userShare={userShareData}
                handleSwitchWorkspace={handleSwitchWorkspace}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <UserProfile
                user={shareUser}
                label={"Access To User"}
                type={"user_share_user"}
                userShare={userShareData}
                handleSwitchWorkspace={handleSwitchWorkspace}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <UserProfile
                user={shareManager}
                label={"Managed By User"}
                type={"user_share_manager"}
                userShare={userShareData}
                handleSwitchWorkspace={handleSwitchWorkspace}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <AdvancedTable
                title="User Activity"
                rowColumns={userActivityRowColumns}
                identifier={userShareId}
                isCollapsed={true}
                getRowData={async ({ limit, offset, order }) => {
                  let results = await getAllUserActivities({
                    limit,
                    offset,
                    order,
                    relations: [["user_share_id", userShareId]],
                    includeResponsibility: true,
                  });

                  let parsedRowData = {
                    rows: results.data.userActivities,
                    totalRowCount: results.data.userActivityCount,
                  };

                  return parsedRowData;
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default UserShareDetails;
