import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Chip = styled(MuiChip)`
  height: 20px;
  margin-top: -3px;
  font-weight: bold;
  font-size: 75%;
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Changelog() {
  return (
    <React.Fragment>
      <Helmet title="Changelog" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Changelog
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Changelog</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v3.0.0" /> – Sept 14th, 2021
              <ul>
                <li>Web App 3.0</li>
                <li>Add entirely new permission system</li>
                <li>Add user sharing</li>
                <li>Fix various bugs</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip color="secondary" label="v2.12.6" /> – Sept 2nd, 2021
              <ul>
                <li>Update to PWA (Progressive Web App)</li>
                <li>Allow offline caching of certain data and app resources</li>
                <li>Detect changes to network status</li>
                <li>
                  Display alerts when a new update is available or the network
                  status changes
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.11.6" /> – Aug 31th, 2021
              <ul>
                <li>Add map collection orders</li>
                <li>Allow map collection orders to be placed</li>
                <li>Fix small bugs</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.10.6" /> – Aug 30th, 2021
              <ul>
                <li>Add map collections</li>
                <li>Add search to app bar for map collections</li>
                <li>
                  Create pages and views for displaying map collections and
                  handling markers
                </li>
                <li>Allow creating and deleting maps and markers</li>
                <li>Add "coming soon" for map orders on the new orders page</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.9.6" /> – Aug 26th, 2021
              <ul>
                <li>
                  Add a redirect from login page to dashboard if user is already
                  logged in
                </li>
                <li>Add one time payments to the dashboard</li>
                <li>Rework refund system</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.9.4" /> – Aug 25th, 2021
              <ul>
                <li>Fix multiple bugs</li>
                <li>Add slight styling changes</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.9.3" /> – Aug 24th, 2021
              <ul>
                <li>Add bulk order type support (spreadsheets)</li>
                <li>Add more pricing variations & bulk pricing support</li>
                <li>
                  Display more accurate pricing in templates, plans, and order
                  pages
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.9.2" /> – Aug 20th, 2021
              <ul>
                <li>Add ability to place orders directly from the web app</li>
                <li>Add "Create Order" button on template detail pages</li>
                <li>Allow selecting from multiple order types</li>
                <li>Small changes and bug fixes</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.8.2" /> – Aug 19th, 2021
              <ul>
                <li>
                  Implement search functionality for templates, orders, purchase
                  orders, and transactions
                </li>
                <li>Add better filtering and reactivity to all data tables</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.7.2" /> – Aug 19th, 2021
              <ul>
                <li>Change selectable options for card sizes and font types</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.7.1" /> – Aug 18th, 2021
              <ul>
                <li>Add template previews while editing</li>
                <li>Improve the speed of address validation</li>
                <li>
                  Deprecation: web app v2.7.1 must use a backend running v3.10.7
                  or higher. All others are now unsupported
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.6.1" /> – Aug 18th, 2021
              <ul>
                <li>Start adding API tokens</li>
                <li>
                  Add API token copy button without displaying the entire token
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.5.1" /> – Aug 17th, 2021
              <ul>
                <li>Add return address fallback settings</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.4.1" /> – Aug 17th, 2021
              <ul>
                <li>Add settings page</li>
                <li>Allow updating first name, last name, and business name</li>
                <li>Fix bugs</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.3.1" /> – Aug 16th, 2021
              <ul>
                <li>Add order count to template view</li>
                <li>Allow sorting by order count</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.2.1" /> – Aug 16th, 2021
              <ul>
                <li>Add ability to cancel subscriptions</li>
                <li>Add tax collection</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.1.1" /> – Aug 11th, 2021
              <ul>
                <li>Add more billing plans</li>
                <li>Integrate with stripe checkout for payments</li>
                <li>Update various formats and styles</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v2.0.1" /> – Aug 6th, 2021
              <ul>
                <li>Update account billing</li>
                <li>Require positive account balance to place orders</li>
                <li>Add extra billing details on order pages</li>
                <li>
                  Add pages for transactions, transaction groups, and
                  promotional codes
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.3.4" /> – July 31th, 2021
              <ul>
                <li>Add more accurate profile statistics</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.2.4" /> – July 30th, 2021
              <ul>
                <li>
                  Fix cancel order alert dialog not disabling buttons correctly
                </li>
                <li>
                  Fix delete template alert dialog not disabling buttons
                  correctly
                </li>
                <li>Update template settings styling</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.2.3" /> – July 30th, 2021
              <ul>
                <li>Temporarily remove order buttons</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.2.2" /> – July 30th, 2021
              <ul>
                <li>Allow canceling orders when in pending status.</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.2.1" /> – July 30th, 2021
              <ul>
                <li>
                  Add login redirect from any url path when credentials are
                  present
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.2.0" /> – July 30th, 2021
              <ul>
                <li>Add template delete button</li>
                <li>
                  Fix template edit button showing up before the template is
                  loaded
                </li>
                <li>
                  Add better template settings header so we know which template
                  we are working on
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.1.0" /> – July 30th, 2021
              <ul>
                <li>Update template creation</li>
                <li>Update template editing</li>
                <li>
                  Organize advanced template settings into expandable section
                </li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>

          <Box mt={3}>
            <Typography variant="subtitle1">
              <Chip label="v1.0.0" /> – July 29th, 2021
              <ul>
                <li>Update to brand new web app!</li>
              </ul>
            </Typography>
            <Divider my={6} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Changelog;
