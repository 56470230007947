import { RESET_NETWORK_STATUS, SET_NETWORK_STATUS } from "./types";

export const resetNetworkStatus = () => {
  return {
    type: RESET_NETWORK_STATUS,
  };
};

export const setNetworkStatus = (networkStatus) => {
  return {
    type: SET_NETWORK_STATUS,
    payload: {
      networkStatus,
    },
  };
};
