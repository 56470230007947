import "@stripe/stripe-js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

import { PersistGate } from "redux-persist/integration/react";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./mocks";
import {
  Snackbar,
  Button,
  Paper,
  Grid,
  SnackbarContent,
} from "@material-ui/core";

import reportWebVitals from "./reportWebVitals";
import { Alert as MuiAlert } from "@material-ui/lab";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "./redux/store";
import { spacing } from "@material-ui/system";

import styled, { withTheme } from "styled-components/macro";
import { nanoid } from "nanoid";

const Alert = styled(MuiAlert)(spacing);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  if (window && window.ga) {
    ga("send", "event", {
      eventCategory: "Web Vitals",
      eventAction: name,
      eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate
    });
  }
}

reportWebVitals(sendToAnalytics);
