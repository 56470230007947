import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ComingSoon() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />

      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 32,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1" align="center">
          🎉 Coming Soon!
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          style={{
            maxWidth: 600,
          }}
          align="center"
        >
          This feature is not yet available, but we are glad you're interested
          in it. We'll let you know as soon as it's ready.
        </Typography>
      </div>
    </React.Fragment>
  );
}

export default ComingSoon;
