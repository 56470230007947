import GitInfo from "react-git-info/macro";
const gitInfo = GitInfo();

let siteUrl = "https://app.radial-link.net";
let backendUrl = "https://backend.radial-link.net";
let isLocal = false;
let isDevelopment = false;
let isStaging = false;
let isProduction = true;
let googleMapsApiKey =
  true || isProduction ? "AIzaSyDJEoUgJLY3xhSC6AP6lR049KiUWC8xrdY" : "";

if (process.env.NODE_ENV === "development") {
  siteUrl = "http://localhost:8000";
  backendUrl = "https://staging-backend.radial-link.net";
  isLocal = false;
  isDevelopment = true;
  isStaging = false;
  isProduction = false;
}

if (
  process.env.NODE_ENV === "local" ||
  process.env.REACT_APP_HOST_ENV === "local"
) {
  // used for locally hosted backend
  siteUrl = "http://localhost:8000";
  backendUrl = "http://localhost:4000";
  isLocal = true;
  isDevelopment = true;
  isStaging = false;
  isProduction = false;
}

if (
  process.env.NODE_ENV === "staging" ||
  process.env.REACT_APP_HOST_ENV === "staging"
) {
  siteUrl = "https://staging-app.radial-link.net";
  backendUrl = "https://staging-backend.radial-link.net";
  isLocal = false;
  isDevelopment = false;
  isStaging = true;
  isProduction = false;
}

const REVISION_HASH = gitInfo.commit.hash;
const REVISION_HASH_SHORT = gitInfo.commit.shortHash;

if (window) {
  window.REVISION_HASH = `${REVISION_HASH}`;
  window.REVISION_HASH_SHORT = `${REVISION_HASH_SHORT}`;
}

module.exports = {
  siteUrl,
  backendUrl,
  isLocal,
  isDevelopment,
  isStaging,
  isProduction,
  googleMapsApiKey: googleMapsApiKey,
  REVISION_HASH,
  REVISION_HASH_SHORT,
};
