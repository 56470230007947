import {
  RESET_ACCESS_TOKEN_DATA,
  SET_ACCESS_TOKEN_DATA,
} from "../../actions/types";
import { users_accessTokenDetails as initialState } from "../../initialState";

export default function accessTokenReducer(state = initialState, action) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_ACCESS_TOKEN_DATA:
      return initialState;
    case SET_ACCESS_TOKEN_DATA:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
