import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import _, { set } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as helpers from "../../helpers";
import { BigNumber } from "../../money";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Chip as MuiChip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { green, orange } from "@material-ui/core/colors";
import { spacing, display } from "@material-ui/system";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  ExpandMore,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import {
  cancelHandWrittenNoteOrderById,
  getHandWrittenNoteOrderById,
  getAllQrCodeScans,
  getAllUserActivities,
  getSingleDataExport,
  getSharableFileUrl,
  getSingleFile,
} from "../../backend";
import { getRequestErrorMessage, getUserName } from "../../helpers";
import OrderStatusChip from "../components/OrderStatusChip";

import { Edit, Trash } from "react-feather";
import { Delete as DeleteIcon } from "@material-ui/icons";
import constants from "../../constants";
import { globalHistory } from "../../history";
import MoneyChip from "../components/MoneyChip";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";
import AdvancedTable from "../tables/AdvancedTable";
import qrCodeScanRowColumns from "../../advancedTableConfigs/qrCodeScanRowColumns";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import PathPreview from "../../components/PathPreview";

const Icon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: 0px;
  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.success || props.pending) && props.theme.palette.common.white};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function DataExportDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);
  let dataExportId =
    _.get(props, "location.state.dataExportId", null) ||
    _.get(props, "match.params.dataExportId", null);
  if (dataExportId) {
    dataExportId = parseInt(dataExportId);
  }

  const [dataExport, setDataExportData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const file = _.get(dataExport, "file", null);
  const mapCollection = _.get(dataExport, "map_collection", null);

  const [isLoadingDownloadLink, setIsLoadingDownloadLink] = React.useState(
    false
  );

  // fetch the order details
  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        const dataExportResults = await getSingleDataExport({ dataExportId });
        setDataExportData(dataExportResults.data.dataExport);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });
        setDataExportData(null);
        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (dataExportId) {
      loadData();
    } else {
      setErrorMessage("The provided export id is not valid");
    }

    loadData();
  }, [dataExportId, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="Export Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Export #{dataExportId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/purchase-order">
              Exports
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !dataExport ? null : (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={10}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Hello{" "}
                          {helpers.getUserNamePlus({
                            replaceForEmailConditional: true,
                          })}
                          ,
                          <br />
                          These are your prepared files for export #
                          {dataExportId}.
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">Export No.</Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={`#${dataExportId}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Creation Date
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                dataExport.createdAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                dataExport.createdAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      {!file ? (
                        <Grid item xs={12} justifyContent="center">
                          <Typography>
                            No downloadable file currently available. Please
                            check back later.
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isLoadingDownloadLink}
                            onClick={async () => {
                              // get temporary download url
                              // then open a new tab with the url

                              setIsLoadingDownloadLink(true);
                              setErrorMessage(null);

                              try {
                                const downloadUrl = await getSingleFile({
                                  fileId: file.id,
                                  includeSignedUrl: true,
                                });
                                window.open(
                                  downloadUrl.data.signedUrl,
                                  "_blank"
                                );
                              } catch (error) {
                                let message = getRequestErrorMessage({
                                  error,
                                  fallbackMessage: "Something went wrong!",
                                });
                                setErrorMessage(message);
                              }

                              setIsLoadingDownloadLink(false);
                            }}
                          >
                            {isLoadingDownloadLink ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              "Download Export"
                            )}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
                <Card px={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataExport.transactions.map((transaction, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{transaction.name}</TableCell>
                            <TableCell align="right">
                              <MoneyChip
                                amount={transaction.amount}
                                transactionType={transaction.transaction_type}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="details"
                                component={NavLink}
                                to={{
                                  pathname: `/account/transaction/${transaction.id}`,
                                  state: {
                                    transactionId: transaction.id,
                                  },
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        <TableCell>
                          <strong>Total</strong>
                        </TableCell>
                        <TableCell align="right">
                          {(() => {
                            let depositSum = new BigNumber(0);
                            let withdrawalSum = new BigNumber(0);

                            dataExport.transactions.map(
                              (transaction, index) => {
                                let ttype = transaction.transaction_type;

                                if (
                                  ttype ===
                                  constants.transaction_types.deposit.typeName
                                ) {
                                  depositSum = depositSum.plus(
                                    new BigNumber(transaction.amount)
                                  );
                                }
                                if (
                                  ttype ===
                                  constants.transaction_types.withdrawal
                                    .typeName
                                ) {
                                  withdrawalSum = withdrawalSum.plus(
                                    new BigNumber(transaction.amount)
                                  );
                                }
                              }
                            );

                            let total = depositSum.minus(withdrawalSum);

                            return <MoneyChip amount={total} />;
                          })()}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
                <Card pb={6} px={6}>
                  <CardContent style={{ textAlign: "center" }}>
                    <div>
                      <Typography variant="caption" gutterBottom align="center">
                        Extra note: Total amount is non refundable. The company
                        may refund depending on errors occurring during the
                        creation of the export, in which case your account
                        balance will automatically be credited.
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default DataExportDetails;
