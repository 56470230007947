import React, { useRef, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";
import * as backend from "./backend";
import styled from "styled-components/macro";
import {
  CircularProgress,
  Snackbar,
  Button,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { THEMES } from "./constants";
import {
  getActiveUserId,
  getIsLoggedIn,
  getNetworkStatusIsOnline,
} from "./redux/selectors";
import { Detector } from "react-detect-offline";
import { setNetworkStatus } from "./redux/actions/networkStatusActions";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { nanoid } from "nanoid";
import { isDevelopment } from "./config";

// We can expose the backend functions to the window when testing
if (isDevelopment) {
  window.backend = backend;
}

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import {
  connectSocket,
  getAccessToken,
  getActorAccessToken,
  getAllExtras,
  socket,
} from "./backend";
import { load } from "./stripe";
import { LoadScript } from "@react-google-maps/api";
import { googleMapsApiKey } from "./config";
import {
  getActiveUser,
  getCurrentUserKey,
  getIsPrimaryUser,
} from "./redux/selectors";
import { store } from "./redux/store";
import { resetCurrentUserKey } from "./redux/actions/currentUserKeyActions";
import { configs } from "eslint-plugin-prettier";
import ChatPopup from "./components/ChatPopup";

const googleLibs = ["places", "drawing"];

const WebSocketHandler = () => {
  const activeUser = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);
  const activeUserId = useSelector(getActiveUserId);
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    socket.off("refresh-token-revoked");
    socket.once("refresh-token-revoked", async () => {
      // this clients refresh token has been revoked and we need to log them out
      console.log("Refresh token revoked. Continuing to logout.");

      await backend.logout();
    });
  }, [currentUserKey]);

  useEffect(() => {
    // ensures we dont connect to socket if we are not logged in
    // will also handle connecting on user change
    // logout function on backend will also appropriately disconnect depending on user

    if (isLoggedIn && currentUserKey) {
      connectSocket(true);
    }
  }, [isLoggedIn, currentUserKey]);

  return null;
};

const NetworkStatus = () => {
  return (
    <React.Fragment>
      <Detector
        render={() => null}
        onChange={(online) => {
          store.dispatch(
            setNetworkStatus({
              isOnline: Boolean(online),
            })
          );
        }}
      />
    </React.Fragment>
  );
};

const HandleServiceWorker = () => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);
  const [registration, setRegistration] = React.useState(null);

  const onSWUpdate = (registration) => {
    console.log(
      "New content is available and will be used when all " +
        "tabs for this page are closed. See https://cra.link/PWA."
    );
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onSWSuccess = (registration) => {
    console.log("Content is cached for offline use.");
  };

  const onSWRegister = (registration) => {
    console.log("Service Worker registered.");
    setRegistration(registration);
  };

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
      setShowReload(false);
      window.location.reload(true);
    } else {
      setShowReload(false);
    }
  };

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
      onSuccess: onSWSuccess,
      onRegister: onSWRegister,
    });
  }, [null]);

  return (
    <React.Fragment>
      <Snackbar
        open={showReload}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // onClose={() => setShowReload(false)}
      >
        <Paper
          variant="outlined"
          component={Alert}
          severity={"info"}
          onClose={() => setShowReload(false)}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <AlertTitle>New Version Available</AlertTitle>
            </Grid>
            <Grid item>
              <Typography>
                Update to get all the new features and bug fixes!
              </Typography>
            </Grid>
            <Grid item style={{ minHeight: 8 }} />
            <Grid item>
              <Button variant="contained" color="primary" onClick={reloadPage}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Snackbar>
    </React.Fragment>
  );
};

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      backendOffline: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    getAllExtras()
      .then((data) => {
        // all loaded
        this.setState({
          loading: false,
        });

        backend
          .getUserFallbackReturnAddress()
          .then((userFallbackReturnAddress) => {
            console.log("Loaded user fallback address");
          })
          .catch((error) => {
            console.log("Error loading user fallback address:", error);
          });
      })
      .catch((err) => {
        // let fallbackMessage =
        //   "Something went wrong loading the needed resources. We may be down for maintenance, please refresh or try again later";
        // if (err && err.message && err.message === "Failed to fetch") {
        //   this.setState({
        //     loading: false,
        //     errorMessage: fallbackMessage,
        //   });
        // } else {
        //   this.setState({
        //     loading: false,
        //     errorMessage: fallbackMessage,
        //   });
        // }
        this.setState({
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentUserKey !== prevProps.currentUserKey) {
      getAllExtras()
        .then((data) => {})
        .catch((err) => {});
    }
  }

  render() {
    const themeMode = this.props.isPrimaryUser ? THEMES.DEFAULT : THEMES.ACTING;
    if (
      this.props.isPrimaryUser === false &&
      (this.props.activeUser === null || this.props.activeUser === undefined)
    ) {
      store.dispatch(resetCurrentUserKey());
    }

    return (
      <React.Fragment>
        <HelmetProvider>
          <Helmet titleTemplate="%s | Radial Link" defaultTitle="Radial Link" />
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MuiThemeProvider theme={createTheme(themeMode)}>
                <ThemeProvider theme={createTheme(themeMode)}>
                  <WebSocketHandler />
                  <HandleServiceWorker />
                  <NetworkStatus />
                  <LoadScript
                    googleMapsApiKey={googleMapsApiKey}
                    libraries={googleLibs}
                    loadingElement={
                      <>
                        <Loading>
                          <CircularProgress size={50} />
                        </Loading>
                      </>
                    }
                  >
                    {this.state.loading ? (
                      <>
                        <Loading>
                          <CircularProgress size={50} />
                        </Loading>
                      </>
                    ) : this.state.errorMessage ? (
                      <>
                        <Loading>
                          <Alert mt={2} mb={1} severity="warning">
                            {this.state.errorMessage}
                          </Alert>
                        </Loading>
                      </>
                    ) : (
                      <>
                        <Routes />
                      </>
                    )}
                  </LoadScript>
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </HelmetProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isPrimaryUser: getIsPrimaryUser(state),
    activeUser: getActiveUser(state).user,
    currentUserKey: getCurrentUserKey(state),
    currentState: state,
  };
};

export default connect(mapStateToProps)(App);
