import React, { useEffect } from "react";
import _ from "lodash";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
  ButtonBase,
  FormControlLabel,
  Grid,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors";
import constants from "../../../constants";
import { Help, HelpOutline } from "@material-ui/icons";
import styled from "styled-components";
import { withStyles } from "@material-ui/styles";

const Tooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(MuiTooltip);

export default function QuestionMarkPopover(props) {
  let { children, color = "#acacac" } = props;

  return (
    <Tooltip title={children}>
      <Help
        style={{
          fontSize: 18,
          color: color,
        }}
      />
    </Tooltip>
  );
}
