import constants from "../constants";

export const initialState = {
  networkStatus: {
    isOnline: true,
  },
  currentUserKey: "primary",
  users: {
    primary: {
      user: {
        id: null,
        firstName: null,
        lastName: null,
        role: constants.roles.user.typeName,
        businessName: null,
        stripe_customer_identifier: null,
        email: null,
        is_admin: false,
        is_demo: false,
        createdAt: null,
        updatedAt: null,
        parent_user_id: null,
        is_on_waitlist: null,
      },
      userFallbackReturnAddressInfo: {
        name: null,
        business_name: null,
        address_line_one: null,
        address_line_two: null,
        city: null,
        state: null,
        zip_code: null,
        country: null,
        id: null,
        user_id: null,
        location_geometry: null,
        createdAt: null,
        updatedAt: null,
      },
      accountDetails: {
        balance: "0.00",
      },
      accessToken: {
        value: null,
      },
      accessTokenDetails: {
        expirationMinutes: null,
        expirationDate: null,
      },
      refreshTokenDetails: {
        expirationMinutes: null,
        expirationDate: null,
      },
      extras: {
        last_known_location: {
          latitude: null,
          longitude: null,
        },
        templates: [],
        template_count: 0,
        card_sizes: [],
        card_size_count: 0,
        font_types: [],
        font_type_count: 0,
        latest_purchase_order_group_count: 0,
        latest_purchase_order_groups: [],
        orders_without_purchase_order: 0,
      },
    },
  },
};

export const networkStatus = initialState.networkStatus;
export const currentUserKey = initialState.currentUserKey;

export const users = initialState.users;
export const users_user = initialState.users.primary.user;
export const users_userFallbackReturnAddressInfo =
  initialState.users.primary.userFallbackReturnAddressInfo;
export const users_accountDetails = initialState.users.primary.accountDetails;
export const users_accessToken = initialState.users.primary.accessToken;
export const users_accessTokenDetails =
  initialState.users.primary.accessTokenDetails;
export const users_refreshTokenDetails =
  initialState.users.primary.refreshTokenDetails;
export const users_extras = initialState.users.primary.extras;
