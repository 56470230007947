import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  TextField,
  Portal,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PercentageChip from "../../../pages/components/PercentageChip";

import { Add, Edit, StarBorder as StarIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { getAllTemplates } from "../../../backend";
import {
  getHandWrittenNoteOrderCardSize,
  getRequestErrorMessage,
} from "../../../helpers";
import { useSelector } from "react-redux";
import BulkForm from "./BulkForm";
import RadialForm from "./RadialForm";
import MapCollectionForm from "./MapCollectionForm";
import { globalHistory } from "../../../history";
import { BigNumber, getDiscountPercentageProps } from "../../../money";
import {
  getActiveExtrasCardSizes,
  getActiveUser,
  getCurrentUserKey,
} from "../../../redux/selectors";
import TemplateTextAutoComplete from "../../../components/TemplateTextAutoComplete";
import AvailableFundAccountAutoComplete from "../../../components/AvailableFundAccountAutoComplete";
import ConnectedTemplateCreateEdit from "../TemplateCreateEdit";
import QuestionMarkPopover from "../../components/QuestionMarkPopover";
import { grey } from "@material-ui/core/colors";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const DialogBackground = styled(Paper)`
  ${spacing}
  height: 100vh;
  background: ${(props) => props.theme.palette.background.default};
`;

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

const orderForms = {
  bulk: BulkForm,
  radial: RadialForm,
  mapCollection: MapCollectionForm,
};

const OrderForm = (props) => {
  const {
    selectedOrderType,
    selectedTemplate,
    selectedFundAccount,
    redirectMapCollectionData,
    redirectMapCollectionMarkers,
    cardSize,
  } = props;

  let Form = React.Fragment;

  if (selectedOrderType && selectedTemplate) {
    Form = orderForms[selectedOrderType.type] || React.Fragment;
  }

  return (
    <Grid item xs={12}>
      <Card p={5}>
        {!selectedTemplate || !selectedOrderType ? (
          <React.Fragment>
            <CardHeader
              title="Order Form"
              subheader="Select a template and an order type to continue"
              titleTypographyProps={{ align: "center" }}
              subheaderTypographyProps={{ align: "center" }}
              pb={0}
              pt={2}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CardHeader
              title={`Create A ${_.words(selectedOrderType.type)
                .map(_.capitalize)
                .join(" ")} Order`}
              subheader="Fill in the details below to complete your order"
              titleTypographyProps={{ align: "center" }}
              subheaderTypographyProps={{ align: "center" }}
              pb={0}
              pt={2}
            />
            <Form
              selectedTemplate={selectedTemplate}
              selectedFundAccount={selectedFundAccount}
              cardSize={cardSize}
              redirectMapCollectionData={redirectMapCollectionData}
              redirectMapCollectionMarkers={redirectMapCollectionMarkers}
            />
          </React.Fragment>
        )}
      </Card>
    </Grid>
  );
};

const NewOrderItem = (props) => {
  const { title, subheader, priceObject, description, action } = props;

  const activeUser = useSelector(getActiveUser).user;

  const {
    discountPercentageDecimal,
    discountPercentage,
  } = getDiscountPercentageProps(activeUser.discount_percentage);

  // set default prices and threshold
  const p = priceObject ? priceObject.price || 0 : 0;
  const t = priceObject ? priceObject.threshold || 0 : 0;
  const otp = priceObject ? priceObject.overThresholdPrice || 0 : 0;

  const price = new BigNumber(p).times(discountPercentageDecimal);
  const threshold = new BigNumber(t);
  const overThresholdPrice = new BigNumber(otp).times(
    discountPercentageDecimal
  );

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card p={5}>
        <CardHeader
          title={title}
          // subheader={subheader}
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{ align: "center" }}
          pb={0}
          pt={2}
        />

        <CardContent>
          {!discountPercentage.lte(0) && (
            <Grid container justifyContent="center">
              <Grid item>
                <Box
                  mb={4}
                  style={{
                    padding: "0 4px",
                    backgroundColor: grey[100],
                    borderRadius: 6,
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body2">
                        <PercentageChip
                          amount={discountPercentage.toNumber()}
                          colorLevel={1}
                          propsToPass={{
                            margin: 0,
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Savings included</Typography>
                    </Grid>
                    <Grid item />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
          <Price>
            <Typography
              component="h2"
              variant="h1"
              color="textPrimary"
              align="center"
              display="inline"
            >
              ${price.toFormat(2)}
            </Typography>

            <Typography
              variant="h6"
              color="textSecondary"
              align="center"
              display="inline"
            >
              / Each Order
            </Typography>
          </Price>
          <Price>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              align="center"
              display="inline"
            >
              ${overThresholdPrice.toFormat(2)}
            </Typography>

            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
              display="inline"
            >
              / Each / {threshold.toFixed(0)}+
            </Typography>
          </Price>
          <Typography variant="subtitle1" align="left">
            {description}
          </Typography>
        </CardContent>
        <CardActions>{action}</CardActions>
      </Card>
    </Grid>
  );
};

function NewOrder(props) {
  const cardSizes = useSelector(getActiveExtrasCardSizes);

  let redirectTemplateData = _.get(
    globalHistory,
    "location.state.templateData",
    null
  );

  let redirectMapCollectionData = _.get(
    globalHistory,
    "location.state.mapCollectionData",
    null
  );
  let redirectMapCollectionMarkers = _.get(
    globalHistory,
    "location.state.mapCollectionMarkers",
    []
  );

  // template selection and creation
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);

  const [
    createNewTemplateDialogOpen,
    setCreateNewTemplateDialogOpen,
  ] = React.useState(false);
  const submitButtonPortalContainer = React.useRef(null);

  // fund Account selection
  const [selectedFundAccount, setSelectedFundAccount] = React.useState(null);

  const [selectedOrderType, setSelectedOrderType] = React.useState(null);
  const [cardSize, setCardSize] = React.useState(
    getHandWrittenNoteOrderCardSize()
  );

  React.useEffect(() => {
    if (redirectTemplateData) {
      setSelectedTemplate(redirectTemplateData);
    }
  }, [redirectTemplateData]);

  React.useEffect(() => {
    if (selectedTemplate) {
      setCardSize(getHandWrittenNoteOrderCardSize(selectedTemplate.card_size));
    }
  }, [selectedTemplate]);

  React.useEffect(() => {
    setSelectedOrderType(null);
  }, [selectedTemplate]);

  return (
    <React.Fragment>
      <Helmet title="New Order" />
      <Typography variant="h3" gutterBottom display="inline">
        New Order
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>New Order</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Header>
        <Typography variant="h3" gutterBottom align="center">
          What kind of order do you want to create?
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          We have several options to choose from.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card p={5}>
                <CardHeader
                  title="Select Template & Fund Account"
                  subheader="Select a template and the account to pull funds from in order to start building your order"
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <Dialog
                  open={createNewTemplateDialogOpen}
                  fullWidth={true}
                  maxWidth={"xl"}
                  PaperComponent={DialogBackground}
                >
                  <DialogTitle>
                    {selectedTemplate ? "Edit Template" : "Create New Template"}
                  </DialogTitle>
                  <DialogContent>
                    <ConnectedTemplateCreateEdit
                      templateData={selectedTemplate}
                      intent={Boolean(selectedTemplate) ? "edit" : "create"}
                      onCompletion={(data) => {
                        setSelectedTemplate(data.data.template);
                        setCreateNewTemplateDialogOpen(false);
                      }}
                      customSubmitButtonContainer={({ children }) => {
                        return (
                          <Portal
                            container={submitButtonPortalContainer.current}
                          >
                            {children}
                          </Portal>
                        );
                      }}
                    />
                  </DialogContent>
                  <DialogActions ref={submitButtonPortalContainer}>
                    <Button
                      onClick={() => {
                        setCreateNewTemplateDialogOpen(false);
                      }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs>
                      <TemplateTextAutoComplete
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        autoCompleteProps={{
                          size: "small",
                          disabled: false,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setCreateNewTemplateDialogOpen(true);
                        }}
                        style={{
                          height: "100%",
                        }}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Grid container alignItems="center">
                              {selectedTemplate ? <Edit /> : <Add />}
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography variant="button">
                              {selectedTemplate ? "Edit" : "Create"} Template
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <AvailableFundAccountAutoComplete
                        selectedFundAccount={selectedFundAccount}
                        setSelectedFundAccount={setSelectedFundAccount}
                        autoCompleteProps={{
                          size: "small",
                          disabled: false,
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <NewOrderItem
              title="Bulk & Manual"
              subheader="Select an order type to use"
              priceObject={cardSize ? cardSize.priceByOrderType.bulk : null}
              description={
                <React.Fragment>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    direction="column"
                  >
                    <Grid item>
                      <Button
                        component={"a"}
                        href="/static/file/bulk_order_template_blank_example.xlsx"
                        download
                        color="primary"
                        variant="outlined"
                      >
                        <Typography
                          variant="body1"
                          style={{
                            marginRight: 4,
                          }}
                        >
                          Download spreadsheet
                        </Typography>
                        <QuestionMarkPopover color="inherit">
                          <Typography variant="body1">
                            Download a starter spreadsheet to help build your
                            bulk order.
                            <br />
                            <br />
                            Includes the basic column headers that are needed to
                            automatically complete the form.
                          </Typography>
                        </QuestionMarkPopover>
                      </Button>
                    </Grid>
                    <Grid item>
                      <ul>
                        <li>
                          Create multiple orders from a single spreadsheet
                        </li>
                        <li>Or, directly to one specific client</li>
                      </ul>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              action={
                <React.Fragment>
                  <Button
                    fullWidth
                    variant={
                      selectedOrderType && selectedOrderType.type === "bulk"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    onClick={() => {
                      setSelectedOrderType({
                        type: "bulk",
                      });
                    }}
                    disabled={!selectedTemplate || !selectedFundAccount}
                  >
                    {selectedOrderType && selectedOrderType.type === "bulk" ? (
                      <React.Fragment>Selected</React.Fragment>
                    ) : (
                      <React.Fragment>Select</React.Fragment>
                    )}
                  </Button>
                </React.Fragment>
              }
            />

            <NewOrderItem
              title="Nearby Neighbors"
              subheader={"Select an order type to use"}
              priceObject={cardSize ? cardSize.priceByOrderType.radial : null}
              description={
                <React.Fragment>
                  <ul>
                    <li>Send directly to your clients neighbors</li>
                    <li>Only requires a single input address</li>
                  </ul>
                </React.Fragment>
              }
              action={
                <React.Fragment>
                  <Button
                    fullWidth
                    variant={
                      selectedOrderType && selectedOrderType.type === "radial"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    onClick={() => {
                      setSelectedOrderType({
                        type: "radial",
                      });
                    }}
                    disabled={
                      !selectedTemplate ||
                      !selectedFundAccount ||
                      (cardSize && !cardSize.envelopeInchDimensions)
                    }
                  >
                    {cardSize && !cardSize.envelopeInchDimensions ? (
                      <React.Fragment>Unsupported Template</React.Fragment>
                    ) : selectedOrderType &&
                      selectedOrderType.type === "radial" ? (
                      <React.Fragment>Selected</React.Fragment>
                    ) : (
                      <React.Fragment>Select</React.Fragment>
                    )}
                  </Button>
                </React.Fragment>
              }
            />

            <NewOrderItem
              title="Map Markers"
              subheader={"Select an order type to use"}
              priceObject={
                cardSize ? cardSize.priceByOrderType.mapCollection : null
              }
              description={
                <React.Fragment>
                  <ul>
                    <li>Create multiple orders from a map markers</li>
                    <li>Requires one map collection</li>
                  </ul>
                </React.Fragment>
              }
              action={
                <React.Fragment>
                  <Button
                    fullWidth
                    variant={
                      selectedOrderType &&
                      selectedOrderType.type === "mapCollection"
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    onClick={() => {
                      setSelectedOrderType({
                        type: "mapCollection",
                      });
                    }}
                    disabled={
                      !selectedTemplate ||
                      !selectedFundAccount ||
                      (cardSize && !cardSize.envelopeInchDimensions)
                    }
                  >
                    {cardSize && !cardSize.envelopeInchDimensions ? (
                      <React.Fragment>Unsupported Template</React.Fragment>
                    ) : selectedOrderType &&
                      selectedOrderType.type === "mapCollection" ? (
                      <React.Fragment>Selected</React.Fragment>
                    ) : (
                      <React.Fragment>Select</React.Fragment>
                    )}
                  </Button>
                </React.Fragment>
              }
            />

            <OrderForm
              selectedOrderType={selectedOrderType}
              selectedTemplate={selectedTemplate}
              selectedFundAccount={selectedFundAccount}
              redirectMapCollectionData={redirectMapCollectionData}
              redirectMapCollectionMarkers={redirectMapCollectionMarkers}
              cardSize={cardSize}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NewOrder;
