import React from "react";
import _ from "lodash";
import { findBounds, getWindowDimensions, scaleToFit } from "../../helpers";

export default class PathPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceWidth: getWindowDimensions().width,
    };

    this.canvasRef = React.createRef();
    this.containerRef = React.createRef();
    this.updateCanvas = this.updateCanvas.bind(this);
    this.updateDeviceWidth = this.updateDeviceWidth.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleWindowResize_debounce = _.debounce(
      this.handleWindowResize,
      200,
      {
        leading: false,
      }
    );
  }

  updateCanvas() {
    let canvas = this.canvasRef.current;

    if (canvas) {
      const containerWidth = this.containerRef.current
        ? this.containerRef.current.getBoundingClientRect().width || this.width
        : this.width;

      let lineWidth =
        ((this.width > this.height ? this.width : this.height) /
          containerWidth) *
        2;

      if (lineWidth > 6) lineWidth = 6;
      // let dpi = window.devicePixelRatio
      // canvas.setAttribute("width", width * dpi);
      // canvas.setAttribute("height", height * dpi);
      let ctx = canvas.getContext("2d");

      const width = canvas.width;
      const height = canvas.height;
      ctx.clearRect(0, 0, width, height);
      ctx.strokeStyle = "#16264c";

      if (!(this.props.paths.length > 0)) return;

      let paths = this.props.paths;

      let sizedPaths = scaleToFit(paths, {
        maxX: width,
        maxY: height,
      });

      sizedPaths.forEach((path) => {
        path.forEach((coord, index, ar) => {
          let x = coord[0];
          let y = coord[1];

          if (index === 0) {
            ctx.moveTo(x, y);
            ctx.beginPath();
            ctx.lineWidth = lineWidth;
            ctx.lineJoin = "round";
            ctx.lineCap = "round";
          }

          ctx.lineTo(x, y);

          if (index === ar.length - 1) {
            ctx.stroke();
          }
        });
      });
    }
  }

  updateDeviceWidth() {
    this.setState({
      deviceWidth: getWindowDimensions().width,
    });
  }

  handleWindowResize() {
    this.updateDeviceWidth();
    this.updateCanvas();
  }

  componentDidMount() {
    if (this.props.paths && this.props.paths.length > 0) {
      this.updateCanvas();
    }

    this.updateDeviceWidth();
    window.addEventListener("resize", this.handleWindowResize_debounce);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize_debounce);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (_.isEqual(prevProps.paths, this.props.paths)) {
      return;
    }
    if (this.props.paths && this.props.paths.length > 0) {
      this.updateCanvas();
    }
  }

  render() {
    let bounds = findBounds(this.props.paths || []);

    if (bounds.length > 0) {
      let [[minX, minY], [maxX, maxY]] = bounds;
      this.width = maxX - minX;
      this.height = maxY - minY;

      let scale = 1;

      this.width > this.height
        ? (scale = 1000 / this.width)
        : (scale = 1000 / this.height);

      this.width = this.width * scale;
      this.height = this.height * scale;
    } else {
      this.width = 1000;
      this.height = 1000;
    }

    if (!this.props.paths || this.props.paths.length < 1) {
      return null;
    }

    return (
      <>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          ref={this.containerRef}
        >
          <canvas
            width={`${this.width}px`}
            height={`${this.height}px`}
            style={{
              width: "calc(100% )",
            }}
            ref={this.canvasRef}
          />
        </section>
      </>
    );
  }
}
