import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import _, { set } from "lodash";
import { BigNumber } from "../../money";

import { red, green, blue } from "@material-ui/core/colors";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader as MuiCardHeader,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  TextField as MuiTextField,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
} from "react-feather";
import { getAllStatistics, redeemPromotionalCode } from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { getActiveUser } from "../../redux/selectors";

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

function Redeem() {
  const user = useSelector(getActiveUser).user;

  const code_section_one_ref = React.useRef();
  const code_section_two_ref = React.useRef();
  const code_section_three_ref = React.useRef();
  const code_section_four_ref = React.useRef();
  const submit_code_ref = React.useRef();

  const [codeSectionOne, setCodeSectionOne] = useState("");
  const [codeSectionTwo, setCodeSectionTwo] = useState("");
  const [codeSectionThree, setCodeSectionThree] = useState("");
  const [codeSectionFour, setCodeSectionFour] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [promotionalCodeData, setPromotionalCodeData] = useState(null);
  const [balance, setBalance] = useState(null);

  const getCode = () => {
    let r = {
      errorMessage: null,
      code: null,
    };

    if (
      !codeSectionOne &&
      !codeSectionTwo &&
      !codeSectionThree &&
      !codeSectionFour
    ) {
      r.errorMessage = "No code was found";
    } else if (
      codeSectionOne.length !== 4 ||
      codeSectionTwo.length !== 4 ||
      codeSectionThree.length !== 4 ||
      codeSectionFour.length !== 4
    ) {
      r.errorMessage = "Each input must be 4 digits";
    }

    r.code = `${codeSectionOne}-${codeSectionTwo}-${codeSectionThree}-${codeSectionFour}`;

    return r;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let codeResults = getCode();

    if (codeResults.errorMessage) {
      setErrorMessage(codeResults.errorMessage);
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    // redeem the code from the server
    try {
      let promotionalCodeResults = await redeemPromotionalCode({
        code: codeResults.code,
      });
      setPromotionalCodeData(promotionalCodeResults.data.promotionalCode);
      setBalance(promotionalCodeResults.data.balance);
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage: "Something went wrong claiming the promotional code",
        })
      );
    }

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Helmet title="Redeem Code" />

      <Typography variant="h3" gutterBottom display="inline">
        Redeem Code
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Redeem</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Header>
        <Typography variant="h3" gutterBottom align="center">
          Redeem a promotional code
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          Enter your 16 digit code below and we will immediately apply it to
          your account balance.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card p={5}>
            {isLoading ? (
              <React.Fragment>
                <Loading>
                  <CircularProgress />
                </Loading>
              </React.Fragment>
            ) : promotionalCodeData ? (
              <React.Fragment>
                <CardHeader
                  title="Success"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom align="center">
                    An amount of $
                    {new BigNumber(promotionalCodeData.amount).toFixed(2)} has
                    been applied to your account and your total balance is now $
                    {new BigNumber(balance).toFixed(2)}
                  </Typography>
                  <br />
                  {/* {promotionalCodeData.message && (
                    <Typography variant="subtitle1" gutterBottom align="center">
                      {promotionalCodeData.message}
                    </Typography>
                  )} */}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="contained"
                    id="submit_code"
                    ref={submit_code_ref}
                    onClick={() => {
                      // reset all values
                      setCodeSectionOne("");
                      setCodeSectionTwo("");
                      setCodeSectionThree("");
                      setCodeSectionFour("");

                      setIsLoading(false);
                      setErrorMessage(null);
                      setPromotionalCodeData(null);
                      setBalance(null);
                    }}
                  >
                    Submit Another Code
                  </Button>
                </CardActions>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title="16 Digit Code"
                    titleTypographyProps={{ align: "center" }}
                    pb={0}
                    pt={2}
                  />
                  <CardContent>
                    {errorMessage && (
                      <React.Fragment>
                        <Alert mt={2} mb={1} severity="warning">
                          {errorMessage}
                        </Alert>
                        <br />
                      </React.Fragment>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          id="code_section_one"
                          inputRef={code_section_one_ref}
                          label="11AA"
                          variant="outlined"
                          // value={this.state.name}
                          required
                          fullWidth
                          my={2}
                          type="text"
                          value={codeSectionOne}
                          onChange={(e) => {
                            setCodeSectionOne(e.target.value);
                          }}
                          onInput={(e) => {
                            let goNext = false;
                            if (e.target.value.length === 4) {
                              goNext = true;
                            }

                            let fillAll = e.target.value.length > 16;
                            if (fillAll) {
                              let sections = e.target.value.split("-");
                              if (sections.length < 4) {
                                sections = [
                                  e.target.value.slice(0, 4),
                                  e.target.value.slice(5, 8),
                                  e.target.value.slice(9, 12),
                                  e.target.value.slice(13, 16),
                                ];
                              }

                              sections = sections.map((o) =>
                                `${o}`
                                  .split(" ")
                                  .join("")
                                  .slice(0, 4)
                                  .toUpperCase()
                              );

                              e.target.value = sections[0];
                              setCodeSectionOne(sections[0]);
                              setCodeSectionTwo(sections[1]);
                              setCodeSectionThree(sections[2]);
                              setCodeSectionFour(sections[3]);
                            }

                            e.target.value = `${e.target.value}`
                              .split(" ")
                              .join("")
                              .slice(0, 4)
                              .toUpperCase();

                            if (goNext) {
                              code_section_two_ref.current.focus();
                            }
                          }}
                          // onChange={(e) => {
                          //   this.setStateAndVerify({
                          //     name: e.target.value,
                          //   });
                          // }}
                          // disabled={this.state.loading}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          id="code_section_two"
                          inputRef={code_section_two_ref}
                          label="22BB"
                          variant="outlined"
                          // value={this.state.name}
                          required
                          fullWidth
                          my={2}
                          type="text"
                          value={codeSectionTwo}
                          onChange={(e) => {
                            setCodeSectionTwo(e.target.value);
                          }}
                          onInput={(e) => {
                            let goNext = false;
                            if (e.target.value.length === 4) {
                              goNext = true;
                            }

                            e.target.value = `${e.target.value}`
                              .split(" ")
                              .join("")
                              .slice(0, 4)
                              .toUpperCase();

                            if (goNext) {
                              code_section_three_ref.current.focus();
                            } else if (e.target.value.length < 1) {
                              code_section_one_ref.current.focus();
                            }
                          }}
                          // onChange={(e) => {
                          //   this.setStateAndVerify({
                          //     name: e.target.value,
                          //   });
                          // }}
                          // disabled={this.state.loading}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          id="code_section_three"
                          inputRef={code_section_three_ref}
                          label="33CC"
                          variant="outlined"
                          // value={this.state.name}
                          required
                          fullWidth
                          my={2}
                          type="text"
                          value={codeSectionThree}
                          onChange={(e) => {
                            setCodeSectionThree(e.target.value);
                          }}
                          onInput={(e) => {
                            let goNext = false;
                            if (e.target.value.length === 4) {
                              goNext = true;
                            }

                            e.target.value = `${e.target.value}`
                              .split(" ")
                              .join("")
                              .slice(0, 4)
                              .toUpperCase();

                            if (goNext) {
                              code_section_four_ref.current.focus();
                            } else if (e.target.value.length < 1) {
                              code_section_two_ref.current.focus();
                            }
                          }}
                          // onChange={(e) => {
                          //   this.setStateAndVerify({
                          //     name: e.target.value,
                          //   });
                          // }}
                          // disabled={this.state.loading}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          id="code_section_four"
                          inputRef={code_section_four_ref}
                          label="44DD"
                          variant="outlined"
                          // value={this.state.name}
                          required
                          fullWidth
                          my={2}
                          type="text"
                          value={codeSectionFour}
                          onChange={(e) => {
                            setCodeSectionFour(e.target.value);
                          }}
                          onInput={(e) => {
                            let goNext = false;
                            if (e.target.value.length === 4) {
                              goNext = true;
                            }

                            e.target.value = `${e.target.value}`
                              .split(" ")
                              .join("")
                              .slice(0, 4)
                              .toUpperCase();

                            if (goNext) {
                              submit_code_ref.current.focus();
                            } else if (e.target.value.length < 1) {
                              code_section_three_ref.current.focus();
                            }
                          }}
                          // onChange={(e) => {
                          //   this.setStateAndVerify({
                          //     name: e.target.value,
                          //   });
                          // }}
                          // disabled={this.state.loading}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      id="submit_code"
                      ref={submit_code_ref}
                      type="submit"
                    >
                      Redeem
                    </Button>
                  </CardActions>
                </form>
              </React.Fragment>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Redeem;
