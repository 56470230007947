import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  IconButton,
} from "@material-ui/core";
import { useDeviceBreakpoint } from "../../hooks/useDeviceBreakpoint";
import { spacing } from "@material-ui/system";
import ms from "ms";
import SendIcon from "@material-ui/icons/Send";
import { ArrowBack } from "@material-ui/icons";
import { getRequestErrorMessage, getUserNamePlus } from "../../helpers";
import { useSelector } from "react-redux";
import {
  getActiveUser,
  getCurrentUserKey,
  getPrimaryUser,
} from "../../redux/selectors";
import {
  createNewMessage,
  findOrCreatePrivateMessageGroup,
  getAllMessageGroupParticipants,
  getAllMessageGroups,
  getAllMessages,
} from "../../backend";
import { Alert as MuiAlert, AvatarGroup } from "@material-ui/lab";
import { nanoid } from "nanoid";
import ChatWindow from "./ChatViews";

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: calc(100% - 1px);
`;

const ChatSidebar = styled(Grid)`
  border-right: ${(props) =>
    props.displayDivider ? `1px solid ${props.theme.palette.divider}` : "none"};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled(Grid)`
  overflow-y: scroll;
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-flex;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  ${(props) => props.theme.shadows[1]};
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)}px;
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function Chat(props) {
  props.setContentPadding(0);
  props.setShouldShowFooter(false);

  React.useEffect(() => {
    return () => {
      props.setContentPadding(props.defaultContentPadding);
      props.setShouldShowFooter(true);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Chat" />
      <Divider />
      <ChatWindow />
    </React.Fragment>
  );
}

export default Chat;
