import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import _ from "lodash";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Introduction() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Map Collections
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        Map collections are the easiest way for your field techs to keep track
        of and send mail to potential customers. Each map can have multiple
        kinds of features that convert directly into an address.
        <ul>
          <li>
            <Link component={HashLink} exact to="#markers">
              Markers
            </Link>
          </li>
          <li>
            <Link component={HashLink} exact to="#polygons">
              Polygons
            </Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}

function BasicSection(props) {
  return (
    <Box mb={10}>
      <Typography
        variant="h4"
        id={_.snakeCase(_.toLower(props.title))}
        gutterBottom
      >
        {props.title}
      </Typography>
      {props.children}
    </Box>
  );
}

function TableOfContents() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Table of Contents
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        <ul>
          <li>
            <Link component={NavLink} exact to="/documentation/getting-started">
              Getting Started
            </Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
function SomethingMissing() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Something missing?
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        If something is missing in the documentation or if you found some part
        confusing, please send us an email (
        <Link href="mailto:support@radial-link.net">
          support@radial-link.net
        </Link>
        ) with your suggestions for improvement. We love hearing from you!
      </Typography>
    </Box>
  );
}

function Maps() {
  return (
    <React.Fragment>
      <Helmet title="Maps" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Documentation
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Documentation</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Introduction />
          <BasicSection title="Markers">
            <Typography variant="body1" gutterBottom my={4}>
              Markers are the most basic feature of a map collection. They are
              used to create a single point of interest on a map.
            </Typography>

            <Typography variant="body1" gutterBottom my={4}>
              All map markers will be converted directly into an address when
              placing an order. Driving around a neighborhood that has a damaged
              roof? Land scaping needs? The neighbors all have solar? Place a
              marker on the map for those houses and we'll get your message to
              them.
            </Typography>
          </BasicSection>
          <BasicSection title="Polygons">
            <Typography variant="body1" gutterBottom my={4}>
              Polygons are used to create multiple points of interest when a few
              markers are not enough. You can draw a shape on the map around a
              street or neighborhood and we'll get your message to all the
              houses in that area.
            </Typography>

            <Typography variant="body1" gutterBottom my={4}>
              Similar to markers, polygons will be converted directly into
              addresses, but this is accomplished a bit differently. Our system
              takes any polygon you draw on the map and finds all the houses
              within that area, then sends mail directly to each house found. We
              even support multiple polygons per map.
            </Typography>
          </BasicSection>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Maps;
