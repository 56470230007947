import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme, keyframes } from "styled-components/macro";
import { flash } from "react-animations";
import useOnScreen from "../hooks/useOnScreen";

const highlightAnimation = keyframes`${flash}`;

const highlightAnimationDuration = 1 * 1000;
const HighlightDiv = styled.div`
  animation: ${highlightAnimationDuration}ms
    ${(props) => {
      return props.highlight ? highlightAnimation : "";
    }};
`;

export default function HighlightOnView(props) {
  let [shouldHighlight, setShouldHighlight] = useState(
    props.shouldHighlight !== undefined ? props.shouldHighlight : true
  );

  if (props.shouldHighlight && props.setShouldHighlight) {
    shouldHighlight = props.shouldHighlight;
    setShouldHighlight = props.setShouldHighlight;
  }

  const [highlight, setHighlight] = useState(false);
  const containerRef = useRef(null);
  const isVisible = useOnScreen(containerRef);

  useEffect(() => {
    if (isVisible && shouldHighlight) {
      setShouldHighlight(false);
      setHighlight(true);
      setTimeout(() => {
        setHighlight(false);
      }, highlightAnimationDuration);
    }
  }, [shouldHighlight, isVisible]);

  return (
    <HighlightDiv highlight={highlight} ref={containerRef}>
      {props.children}
    </HighlightDiv>
  );
}
