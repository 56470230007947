import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import _, { set } from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as helpers from "../../helpers";
import { BigNumber } from "../../money";

import {
  CardContent,
  Grid,
  IconButton,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Chip as MuiChip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { green, grey, orange } from "@material-ui/core/colors";
import { spacing, display } from "@material-ui/system";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  ExpandMore,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import {
  cancelHandWrittenNoteOrderById,
  getHandWrittenNoteOrderById,
  getAllQrCodeScans,
  getAllUserActivities,
  getSharableFileUrl,
  getSingleFile,
  getQrCodeById,
  getQrCodeScanById,
} from "../../backend";
import { getRequestErrorMessage, getUserName } from "../../helpers";
import OrderStatusChip from "../components/OrderStatusChip";

import { Edit, ExternalLink, Trash } from "react-feather";
import { Delete as DeleteIcon } from "@material-ui/icons";
import constants from "../../constants";
import { globalHistory } from "../../history";
import MoneyChip from "../components/MoneyChip";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";
import AdvancedTable from "../tables/AdvancedTable";
import qrCodeScanRowColumns from "../../advancedTableConfigs/qrCodeScanRowColumns";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import PathPreview from "../../components/PathPreview";
import CopyToClipboard from "react-copy-to-clipboard";

const Icon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  margin-right: 0px;
  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.success || props.pending) && props.theme.palette.common.white};
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function QrCodeScanDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);
  let qrCodeScanId =
    _.get(props, "location.state.qrCodeScanId", null) ||
    _.get(props, "match.params.qrCodeScanId", null);
  if (qrCodeScanId) {
    qrCodeScanId = parseInt(qrCodeScanId);
  }

  const [qrCodeScanData, setQrCodeScanData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);

  // fetch the order details
  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        const qrCodeScanDataResults = await getQrCodeScanById({
          scanId: qrCodeScanId,
        });
        setQrCodeScanData(qrCodeScanDataResults.data.qrCodeScan);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });
        setQrCodeScanData(null);
        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (qrCodeScanId) {
      loadData();
    } else {
      setErrorMessage("The provided QR code scan id is not valid");
    }

    loadData();
  }, [qrCodeScanId, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="QR Code Scan Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            QR Code Scan #{qrCodeScanId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/qr-code">
              Qr Code Scans
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !qrCodeScanData ? null : (
        <>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} lg={10}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                          Hello{" "}
                          {helpers.getUserNamePlus({
                            replaceForEmailConditional: true,
                          })}
                          ,
                          <br />
                          These are details regarding QR Code Scan #
                          {qrCodeScanId}. You can view the template, order, and
                          scan information here.
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">
                          QR Code Scan No.
                        </Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={`#${qrCodeScanId}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Scanned At
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={new Date(
                                qrCodeScanData.createdAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                qrCodeScanData.createdAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" align="right">
                          Redirected To Url
                        </Typography>

                        <Paper
                          elevation={0}
                          style={{
                            backgroundColor: grey[200],
                            cursor: "pointer",
                          }}
                        >
                          <CardContent>
                            <a
                              href={qrCodeScanData.redirected_to_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  wordWrap: "break-word",
                                }}
                              >
                                {qrCodeScanData.redirected_to_url}{" "}
                                <Icon
                                  component={ExternalLink}
                                  ml={2}
                                  fontSize="inherit"
                                />
                              </Typography>
                            </a>
                          </CardContent>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">IP Address</Typography>
                        <CopyToClipboard text={qrCodeScanData?.ip_address}>
                          <Tooltip title={"Copy to clipboard"} placement="top">
                            <Paper
                              elevation={0}
                              style={{
                                backgroundColor: grey[200],
                                cursor: "pointer",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="body1"
                                  style={{
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {qrCodeScanData?.ip_address}
                                </Typography>
                              </CardContent>
                            </Paper>
                          </Tooltip>
                        </CopyToClipboard>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">User Agent</Typography>
                        <CopyToClipboard text={qrCodeScanData?.user_agent}>
                          <Tooltip title={"Copy to clipboard"} placement="top">
                            <Paper
                              elevation={0}
                              style={{
                                backgroundColor: grey[200],
                                cursor: "pointer",
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="body1"
                                  style={{
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {qrCodeScanData?.user_agent}
                                </Typography>
                              </CardContent>
                            </Paper>
                          </Tooltip>
                        </CopyToClipboard>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {qrCodeScanData?.qr_code?.template_text_id && (
                            <Grid item>
                              <Button
                                link
                                component={NavLink}
                                variant="outlined"
                                color="primary"
                                to={{
                                  pathname: `/template/${qrCodeScanData?.qr_code?.template_text_id}`,
                                  state: {
                                    templateId:
                                      qrCodeScanData?.qr_code?.template_text_id,
                                  },
                                }}
                              >
                                View Template
                              </Button>
                            </Grid>
                          )}
                          {qrCodeScanData?.qr_code
                            ?.hand_written_note_order_id && (
                            <Grid item>
                              <Button
                                link
                                component={NavLink}
                                variant="outlined"
                                color="primary"
                                to={{
                                  pathname: `/order/${qrCodeScanData?.qr_code?.hand_written_note_order_id}`,
                                  state: {
                                    orderId:
                                      qrCodeScanData?.qr_code
                                        ?.hand_written_note_order_id,
                                  },
                                }}
                              >
                                View Order
                              </Button>
                            </Grid>
                          )}
                          {qrCodeScanData?.qr_code_id && (
                            <Grid item>
                              <Button
                                link
                                component={NavLink}
                                variant="outlined"
                                color="primary"
                                to={{
                                  pathname: `/qr-code/${qrCodeScanData?.qr_code_id}`,
                                  state: {
                                    qrCodeId: qrCodeScanData?.qr_code_id,
                                  },
                                }}
                              >
                                View QR Code
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default QrCodeScanDetails;
