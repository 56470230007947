import React from "react";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import {
  parseKeyValuePair,
  getDelimiterRegex,
  getVariablesFromDelimitedText,
  // scaleToFit,
} from "../../helpers";
import {
  createNewTemplate,
  getAllExtras,
  editTemplate,
  verifyTemplate,
  deleteTemplateById,
  getAllIndustries,
  getAllCategories,
  // deleteTemplateById,
  // getTemplateById,
  // placeHandWrittenNoteOrder,
  // getAllTemplates,
  // cancelHandWrittenNoteOrdersByTemplateId,
} from "../../backend";
import * as helpers from "../../helpers";
import { isDevelopment } from "../../config";

import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid as GridMui,
  Link,
  TextField as MuiTextField,
  Typography,
  CircularProgress as CircularProgressMui,
  FormControlLabel,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Portal,
  Chip,
  ButtonGroup,
  InputAdornment,
} from "@material-ui/core";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";

import { Delete as DeleteIcon, ExpandMore } from "@material-ui/icons";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { spacing } from "@material-ui/system";
import { globalHistory } from "../../history";
import PathPreview from "../../components/PathPreview";
import { BigNumber } from "../../money";
import constants from "../../constants";
import { getActiveExtras, getActiveUser } from "../../redux/selectors";
import { compareRoles } from "../../permissions";
import QuestionMarkPopover from "../components/QuestionMarkPopover";
import { store } from "../../redux/store";
import HttpsTextField from "../../components/HttpsTextField";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const CondensedAccordionSummary = styled(AccordionSummary)`
  min-height: 0 !important;
`;

const Grid = styled(GridMui)(spacing);

const Alert = styled(MuiAlert)(spacing);
const Card = styled(MuiCard)(spacing);
const CircularProgress = styled(CircularProgressMui)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const insertTextAtCursor = (e) => {
  let textToInsert = " this is the inserted text ";
  let cursorPosition = e.target.selectionStart;
  let textBeforeCursorPosition = e.target.value.substring(0, cursorPosition);
  let textAfterCursorPosition = e.target.value.substring(
    cursorPosition,
    e.target.value.length
  );
  e.target.value =
    textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
};

const preMadeTemplateParameters = [
  {
    label: "Sales Rep Name",
    getValue: (props) =>
      `${props.startDelimiter} sales_rep_name ${props.endDelimiter}`,
    testParameterString: "sales_rep_name: John Doe",
    defaultParameter: {
      sales_rep_name: {
        key: "sales_rep_name",
        value: "John Doe",
      },
    },
  },
  {
    label: "Sales Rep Email",
    getValue: (props) =>
      `${props.startDelimiter} sales_rep_email ${props.endDelimiter}`,
    testParameterString: "sales_rep_email: johndoe@example.com",
    defaultParameter: {
      sales_rep_email: {
        key: "sales_rep_email",
        value: "johndoe@example.com",
      },
    },
  },
  {
    label: "Sales Rep Phone",
    getValue: (props) =>
      `${props.startDelimiter} sales_rep_phone ${props.endDelimiter}`,
    testParameterString: "sales_rep_phone: 123-456-7890",
    defaultParameter: {
      sales_rep_phone: {
        key: "sales_rep_phone",
        value: "123-456-7890",
      },
    },
  },
  {
    label: "Client Name",
    getValue: (props) =>
      `${props.startDelimiter} client_name ${props.endDelimiter}`,
    testParameterString: "client_name: Cindy Smith",
    defaultParameter: {
      client_name: {
        key: "client_name",
        value: "Cindy Smith",
      },
    },
  },
  {
    label: "Client Email",
    getValue: (props) =>
      `${props.startDelimiter} client_email ${props.endDelimiter}`,
    testParameterString: "client_email: cindysmith@example.com",
    defaultParameter: {
      client_email: {
        key: "client_email",
        value: "cindysmith@example.com",
      },
    },
  },
  {
    label: "Client Phone",
    getValue: (props) =>
      `${props.startDelimiter} client_phone ${props.endDelimiter}`,
    testParameterString: "client_phone: 098-765-4321",
    defaultParameter: {
      client_phone: {
        key: "client_phone",
        value: "098-765-4321",
      },
    },
  },
  {
    label: "Discount Amount",
    getValue: (props) =>
      `${props.startDelimiter} discount_amount ${props.endDelimiter}`,
    testParameterString: "discount_amount: $50.00",
    defaultParameter: {
      discount_amount: {
        key: "discount_amount",
        value: "$50.00",
      },
    },
  },
  {
    id: "custom_value",
    label: "Custom Value",
    getValue: (props) =>
      `${props.startDelimiter} custom_value ${props.endDelimiter}`,
    testParameterString: "custom_value: Blank",
    defaultParameter: {
      custom_value: {
        key: "custom_value",
        value: "Blank",
      },
    },
  },
];

let defaultState = {
  isDeleteModalOpen: false,
  deleteErrorMessage: null,
  loading: false,

  // available settings intensity
  settingsIntensity: 2,

  // loading variables. This includes categories and industries
  isLoadingVariables: true,
  availableIndustries: [],
  availableIndustryCount: 0,
  availableCategories: [],
  availableCategoryCount: 0,

  name: "Unnamed Template",
  redirectUrl: "",
  startDelimiter: "</",
  endDelimiter: "/>",
  cardSize: "default",
  fontType: "default",
  templateUpper: "",
  template:
    "Hi there </ client_name />,\n\nStart typing your template and we'll generate a preview for you when you pause for a few seconds!",
  teaserText: "",
  requiredParameters: {
    client_name: {
      key: "client_name",
      value: "John Doe",
    },
  },
  errorMessage: null,
  isAdvancedAccordionOpen: false,
  pathPreview: {
    paths: [],
    errorMessage: "",
  },
  pathPreviewUpper: {
    paths: [],
    errorMessage: "",
  },
  filledTemplate: "",
  verifyingTemplate: false,
  isTemplateVerified: false,
  orderTimeoutDays: 7 * 3,
  preventDuplicateOrderTimeoutDays: 60,
  preventDuplicateReceiverNameOrderTimeoutDays: 0,
  preventDuplicateOrders: true,
  preventDuplicateReceiverNameOrders: false,
  excludeSenderAddressInfo: false,
  uppercaseAllAddressCharacters: true,
  parentTemplateTextId: null,
  requireParentPreviousOrders: false,
  industryId: "",
  categoryId: "",
  isPublic: false,
};

let getInitialState = (template, usersFirstName) => {
  let templateData = {};

  if (!template) {
    return {
      ...defaultState,
      requiredParameters: {
        client_name: {
          key: "client_name",
          value: usersFirstName ? usersFirstName : "John Doe",
        },
      },
    };
  } else {
    templateData = {
      templateData,
      ...template,
    };
  }

  let state = {
    ...defaultState,
    name: templateData.name,
    redirectUrl: templateData.redirect_url || defaultState.redirectUrl,
    startDelimiter: templateData.start_delimiter,
    endDelimiter: templateData.end_delimiter,
    cardSize: templateData.card_size || defaultState.cardSize,
    fontType: templateData.font_type || defaultState.fontType,
    template: templateData.text || defaultState.template,
    templateUpper: templateData.text_upper || defaultState.templateUpper,
    teaserText: templateData.teaser_text || defaultState.teaserText,
    orderTimeoutDays:
      templateData.order_timeout_days || defaultState.orderTimeoutDays,
    preventDuplicateOrderTimeoutDays:
      templateData.prevent_duplicate_order_timeout_days ||
      defaultState.preventDuplicateOrderTimeoutDays,
    preventDuplicateReceiverNameOrderTimeoutDays:
      templateData.prevent_duplicate_receiver_name_order_timeout_days ||
      defaultState.preventDuplicateReceiverNameOrderTimeoutDays,
    preventDuplicateOrders:
      typeof templateData.prevent_duplicate_orders === "boolean"
        ? templateData.prevent_duplicate_orders
        : defaultState.preventDuplicateOrders,
    preventDuplicateReceiverNameOrders:
      typeof templateData.prevent_duplicate_receiver_name_orders === "boolean"
        ? templateData.prevent_duplicate_receiver_name_orders
        : defaultState.preventDuplicateReceiverNameOrders,
    excludeSenderAddressInfo:
      typeof templateData.exclude_sender_address_info === "boolean"
        ? templateData.exclude_sender_address_info
        : defaultState.excludeSenderAddressInfo,
    uppercaseAllAddressCharacters:
      typeof templateData.exclude_sender_address_info === "boolean"
        ? templateData.uppercase_all_address_characters
        : defaultState.uppercaseAllAddressCharacters,
    parentTemplateTextId:
      templateData.parent_template_text_id || defaultState.parentTemplateTextId,
    requireParentPreviousOrders:
      typeof templateData.require_parent_previous_orders === "boolean"
        ? templateData.require_parent_previous_orders
        : defaultState.requireParentPreviousOrders,
    industryId: templateData.industry_id || defaultState.industryId,
    categoryId: templateData.category_id || defaultState.categoryId,
    isPublic:
      typeof templateData.is_public === "boolean"
        ? templateData.is_public
        : defaultState.isPublic,
    requiredParameters: templateData.required_parameters
      ? templateData.required_parameters.reduce((prev, cur) => {
          prev[cur] = {
            key: cur,
            value: "Blank",
          };
          return prev;
        }, {})
      : {},
  };

  return state;
};

class TemplateCreateEdit extends React.Component {
  constructor(props) {
    super(props);

    this.intendToEdit = props.intent === "edit";
    this.editTemplateData = props.templateData;

    let activeUser = getActiveUser(store.getState()).user;
    let usersFirstName = "John Doe";

    if (activeUser.firstName) {
      usersFirstName = activeUser.firstName;
    }

    if (activeUser.firstName && activeUser.lastName) {
      usersFirstName = `${activeUser.firstName} ${activeUser.lastName}`;
    }

    this.state = getInitialState(this.editTemplateData, usersFirstName);
    this.handleSaveTemplate = this.handleSaveTemplate.bind(this);
    this.verifyTemplateWithBackend = this.verifyTemplateWithBackend.bind(this);
    this.verifyTemplateWithBackend_debounce = _.debounce(
      this.verifyTemplateWithBackend,
      1500,
      { leading: false }
    );

    this.templateUpper_ref = React.createRef();
    this.template_ref = React.createRef();
    this.activeTemplate = null;
  }

  getTemplateDetails() {
    let parameters = Object.values(this.state.requiredParameters).reduce(
      (prev, cur) => {
        prev[cur.key] = cur.value;

        return prev;
      },
      {}
    );

    const foundCardSize = helpers.getHandWrittenNoteOrderCardSize(
      this.state.cardSize
    );
    const upperTextIsDisabled =
      !_.get(foundCardSize, "envelopeInchDimensions", null) ||
      !foundCardSize.supportsUpperLower ||
      !foundCardSize.cardInchDimensions_upper;

    let templateDetails = {
      name: this.state.name,
      startDelimiter: this.state.startDelimiter,
      endDelimiter: this.state.endDelimiter,
      cardSize: this.state.cardSize,
      fontType: this.state.fontType,
      templateUpper: upperTextIsDisabled ? null : this.state.templateUpper,
      template: this.state.template,
      teaserText: this.state.teaserText,
      parameters: parameters,
      orderTimeoutDays: this.state.orderTimeoutDays,
      preventDuplicateOrderTimeoutDays: this.state
        .preventDuplicateOrderTimeoutDays,
      preventDuplicateReceiverNameOrderTimeoutDays: this.state
        .preventDuplicateReceiverNameOrderTimeoutDays,
      preventDuplicateOrders: this.state.preventDuplicateOrders,
      preventDuplicateReceiverNameOrders: this.state
        .preventDuplicateReceiverNameOrders,
      excludeSenderAddressInfo: this.state.excludeSenderAddressInfo,
      uppercaseAllAddressCharacters: this.state.uppercaseAllAddressCharacters,
      parentTemplateTextId:
        typeof this.state.parentTemplateTextId === "number"
          ? this.state.parentTemplateTextId
          : null,
      requireParentPreviousOrders:
        typeof this.state.parentTemplateTextId === "number"
          ? this.state.requireParentPreviousOrders
          : false,
      redirectUrl: this.state.redirectUrl,
      industryId: this.state.industryId,
      categoryId: this.state.categoryId,
      isPublic: this.state.isPublic,
    };
    if (this.intendToEdit) {
      templateDetails.templateId = this.editTemplateData.id;
    }
    return templateDetails;
  }

  handleSaveTemplate(e) {
    if (e) {
      e.preventDefault();
    }

    let templateDetails = this.getTemplateDetails();

    this.setState({ loading: true });

    if (this.intendToEdit) {
      editTemplate(templateDetails)
        .then((data) => {
          let templateId = data.data.template.id;
          getAllExtras()
            .then((data) => {})
            .catch((error) => {});
          if (this.props.onCompletion) {
            this.props.onCompletion(data);
          } else {
            globalHistory.push({
              pathname: `/template/${templateId}`,
            });
          }
        })
        .catch((err) => {
          let errorText = helpers.getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong verifying the template",
          });

          this.setState({ errorMessage: errorText, loading: false });
        });
    } else {
      createNewTemplate(templateDetails)
        .then((data) => {
          let templateId = data.data.template.id;
          getAllExtras()
            .then((data) => {})
            .catch((error) => {});
          if (this.props.onCompletion) {
            this.props.onCompletion(data);
          } else {
            globalHistory.push({
              pathname: `/template/${templateId}`,
            });
          }
        })
        .catch((err) => {
          let errorText = helpers.getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong verifying the template",
          });

          this.setState({ errorMessage: errorText, loading: false });
        });
    }
  }

  verifyTemplateWithBackend(e) {
    if (e) {
      e.preventDefault();
    }

    const verify = () => {
      let templateDetails = this.getTemplateDetails();

      verifyTemplate(templateDetails)
        .then((data) => {
          this.setState({
            error: null,
            pathPreview: data.data.pathPreview,
            pathPreviewUpper: data.data.pathPreviewUpper,
            filledTemplate: data.data.filledTemplate,
            requiredParameters: {
              ...data.data.requiredParameters.reduce((prev, cur) => {
                prev[cur] = {
                  key: cur,
                  value: "Blank",
                };
                return prev;
              }, {}),
              ...this.state.requiredParameters,
            },
            verifyingTemplate: false,
            isTemplateVerified: true,
          });
        })
        .catch((err) => {
          let errorText = helpers.getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong verifying the template",
          });

          this.setState({
            errorMessage: errorText,
            filledTemplate: "",
            requiredParameters: {},
            verifyingTemplate: false,
            isTemplateVerified: false,
          });
        });
    };

    this.setState(
      {
        errorMessage: null,
        pathPreview: defaultState.pathPreview,
        pathPreviewUpper: defaultState.pathPreviewUpper,
        verifyingTemplate: true,
        isTemplateVerified: false,
      },
      verify
    );
  }

  setStateAndVerify(updateState, cb) {
    this.setState(
      (state) => {
        let additionalState = updateState;

        if (typeof additionalState === "function") {
          additionalState = additionalState(state);
        }

        return {
          ...additionalState,
          errorMessage: null,
          pathPreview: defaultState.pathPreview,
          pathPreviewUpper: defaultState.pathPreviewUpper,
          verifyingTemplate: true,
          isTemplateVerified: false,
        };
      },
      () => {
        if (cb && typeof cb === "function") cb();
        this.verifyTemplateWithBackend_debounce();
      }
    );
  }

  componentDidMount() {
    if (
      this.state.startDelimiter &&
      this.state.endDelimiter &&
      this.state.cardSize &&
      this.state.fontType &&
      this.state.template
    ) {
      this.verifyTemplateWithBackend();
    }

    const fetchVariables = async () => {
      this.setState({
        isLoadingVariables: true,
      });

      try {
        let variables = await Promise.all([
          getAllIndustries({}),
          getAllCategories({}),
          // TODO: allow loading the template to edit (if applicable), the card details, and fonts.
        ]);

        this.setState({
          availableIndustries: variables[0].data.industries,
          availableIndustryCount: variables[0].data.industryCount,
          availableCategories: variables[1].data.categories,
          availableCategoryCount: variables[1].data.categoryCount,
        });
      } catch (e) {
        let errorText = helpers.getRequestErrorMessage({
          error: e,
          fallbackMessage: "Something went wrong loading the needed assets.",
        });

        this.setState({
          errorMessage: errorText,
        });
      }

      this.setState({
        isLoadingVariables: false,
      });
    };

    fetchVariables();
  }

  render() {
    if (this.intendToEdit && !this.editTemplateData) {
      return (
        <Typography variant="subtitle2" color="textSecondary" align="center">
          The selected template is unavailable to edit. Please try again later.
          (Error: No template data was provided)
        </Typography>
      );
    }

    const parentTemplateOptions = this.props.extras.templates.filter((o) => {
      if (this.intendToEdit) {
        return !(o.id === this.editTemplateData.id);
      } else {
        return true;
      }
    });

    let hasChildrenTemplates = false;
    let hasOrders = false;

    if (this.intendToEdit) {
      hasChildrenTemplates =
        this.editTemplateData.children_template_texts &&
        this.editTemplateData.children_template_texts.length > 0;

      hasOrders =
        this.editTemplateData.hand_written_note_orders &&
        this.editTemplateData.hand_written_note_orders.length > 0;
    }

    const availableFontTypes = this.props.extras.font_types.filter((o) => {
      if (this.state.fontType === o.key) {
        return true;
      }

      if (o.hideFromClient) {
        return constants.presetRoles.repsUp.includes(this.props.user.role);
      }

      return true;
    });

    const availableCardSizes = this.props.extras.card_sizes.filter((o) => {
      if (this.state.cardSize === o.key) {
        return true;
      }

      if (o.hideFromClient) {
        return constants.presetRoles.repsUp.includes(this.props.user.role);
      }

      return true;
    });

    const foundCardSize = helpers.getHandWrittenNoteOrderCardSize(
      this.state.cardSize
    );

    const upperTextIsDisabled =
      !_.get(foundCardSize, "envelopeInchDimensions", null) ||
      !foundCardSize.supportsUpperLower ||
      !foundCardSize.cardInchDimensions_upper;

    const isRepresentativeOrGreater = compareRoles(
      this.props.user.role,
      ">=",
      constants.roles.rep.typeName
    );

    let CustomSubmitButtonContainer =
      this.props.customSubmitButtonContainer || React.Fragment;

    return (
      <React.Fragment>
        {this.state.isLoadingVariables ? (
          <React.Fragment>
            <Grid container spacing={0} justifyContent="center">
              <Grid item>
                <CircularProgress size={50} />
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container spacing={2} direction="row" alignItems="stretch">
              <Grid item xs={12} xl={this.state.settingsIntensity > 3 ? 12 : 6}>
                <Card>
                  <CardContent>
                    <form>
                      {/* header and settings intensity */}
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          spacing={4}
                        >
                          <Grid item>
                            <Typography variant="h6">Settings</Typography>
                          </Grid>
                          <Grid item xs />
                          <Grid item>
                            <Button
                              onClick={() => {
                                this.setState({
                                  settingsIntensity: 1,
                                });
                              }}
                              color={
                                this.state.settingsIntensity === 1
                                  ? "primary"
                                  : "default"
                              }
                              variant={
                                this.state.settingsIntensity === 1
                                  ? "contained"
                                  : "outlined"
                              }
                            >
                              Minimal
                            </Button>
                          </Grid>
                          <Grid item>
                            <ButtonGroup>
                              {["Basic", "Intermediate", "Advanced"].map(
                                (name, index) => {
                                  const level = index + 2;
                                  const isSelected =
                                    this.state.settingsIntensity === level;

                                  return (
                                    <Button
                                      key={name}
                                      onClick={() => {
                                        this.setState({
                                          settingsIntensity: level,
                                        });
                                      }}
                                      color={isSelected ? "primary" : "default"}
                                      variant={
                                        isSelected ? "contained" : "outlined"
                                      }
                                    >
                                      {name}
                                    </Button>
                                  );
                                }
                              )}
                            </ButtonGroup>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* template name */}
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            id="name"
                            label="Template Name"
                            variant="outlined"
                            value={this.state.name}
                            required
                            fullWidth
                            my={2}
                            type="text"
                            onChange={(e) => {
                              this.setStateAndVerify({
                                name: e.target.value,
                              });
                            }}
                            disabled={this.state.loading}
                          />
                        </Grid>
                      </Grid>

                      {/* qr code url */}
                      {this.state.settingsIntensity > 1 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <HttpsTextField
                              label="QR Code (Redirect) URL"
                              helperText="A url to provide tracking for and redirect all clients to through a printed QR code included with each card (i.e. scheduling form, landing page, etc)"
                              value={this.state.redirectUrl}
                              setValue={(value) => {
                                this.setStateAndVerify({
                                  redirectUrl: value,
                                });
                              }}
                              disabled={this.state.loading}
                              id="redirectUrl"
                              my={2}
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* Font type & Card Size selection */}
                      {this.state.settingsIntensity > 1 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" my={2} fullWidth>
                              <InputLabel id="font_type_label">
                                Font Type
                              </InputLabel>
                              <Select
                                labelId="font_type_label"
                                id="font_type"
                                required
                                value={this.state.fontType}
                                onChange={(e) => {
                                  this.setStateAndVerify({
                                    fontType: e.target.value,
                                  });
                                }}
                                label="Font Type"
                                disabled={this.state.loading}
                              >
                                {availableFontTypes.map((size, index) => {
                                  return (
                                    <MenuItem value={size.key} key={size.key}>
                                      {size.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" my={2} fullWidth>
                              <InputLabel id="card_size_label">
                                Card Size
                              </InputLabel>
                              <Select
                                labelId="card_size_label"
                                id="card_size"
                                required
                                fullWidth
                                value={this.state.cardSize}
                                onChange={(e) => {
                                  this.setStateAndVerify({
                                    cardSize: e.target.value,
                                  });
                                }}
                                label="Card Size"
                                disabled={this.state.loading}
                              >
                                {availableCardSizes.map((size, index) => {
                                  return (
                                    <MenuItem value={size.key} key={size.key}>
                                      {size.name} ($
                                      {new BigNumber(size.lowestPrice).toFormat(
                                        2
                                      )}{" "}
                                      - $
                                      {new BigNumber(
                                        size.highestPrice
                                      ).toFormat(2)}
                                      )
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Industry, Category, & isPublic selection */}
                      {isRepresentativeOrGreater &&
                        this.state.settingsIntensity > 2 && (
                          <React.Fragment>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item xs={12} sm={4}>
                                <FormControl
                                  variant="outlined"
                                  my={2}
                                  fullWidth
                                >
                                  <InputLabel id="industry_label">
                                    Industry
                                  </InputLabel>
                                  <Select
                                    labelId="industry_label"
                                    id="industry"
                                    value={this.state.industryId}
                                    onChange={(e) => {
                                      this.setStateAndVerify({
                                        industryId: e.target.value,
                                      });
                                    }}
                                    label="Industry"
                                    disabled={this.state.loading}
                                  >
                                    <MenuItem value={""}>None</MenuItem>
                                    {this.state.availableIndustries.map(
                                      (industry, index) => {
                                        return (
                                          <MenuItem
                                            value={industry.id}
                                            key={industry.id}
                                          >
                                            {industry.name}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <FormControl
                                  variant="outlined"
                                  my={2}
                                  fullWidth
                                >
                                  <InputLabel id="category_label">
                                    Category
                                  </InputLabel>
                                  <Select
                                    labelId="category_label"
                                    id="category"
                                    value={this.state.categoryId}
                                    onChange={(e) => {
                                      this.setStateAndVerify({
                                        categoryId: e.target.value,
                                      });
                                    }}
                                    label="Category"
                                    disabled={this.state.loading}
                                  >
                                    <MenuItem value={""}>None</MenuItem>
                                    {this.state.availableCategories.map(
                                      (category, index) => {
                                        return (
                                          <MenuItem
                                            value={category.id}
                                            key={category.id}
                                          >
                                            {category.name}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={this.state.isPublic}
                                      onChange={(e) => {
                                        this.setStateAndVerify({
                                          isPublic: e.target.checked,
                                        });
                                      }}
                                      disabled={this.state.loading}
                                      name="is_public"
                                    />
                                  }
                                  fullWidth
                                  label="Public Template"
                                />
                                <QuestionMarkPopover>
                                  <Typography variant="body1">
                                    Mark this template as public so that it can
                                    be shared with everyone on the platform.
                                    <br />
                                    <br />
                                    Also will become available under "Premium
                                    Templates" for anyone to duplicate.
                                  </Typography>
                                </QuestionMarkPopover>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        )}

                      {/* start delimiter & End delimiter selection */}
                      {this.state.settingsIntensity > 3 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="start_delimiter"
                              label="Start Delimiter"
                              variant="outlined"
                              required
                              fullWidth
                              my={2}
                              type="text"
                              value={this.state.startDelimiter}
                              onChange={(e) => {
                                this.setStateAndVerify({
                                  startDelimiter: e.target.value,
                                });
                              }}
                              disabled={this.state.loading}
                              helperText={
                                "A special character that signifies the start of a variable name. For example, if you want to use the variable '</ name />' in your template, you would enter '</' here."
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="end_delimiter"
                              label="End Delimiter"
                              variant="outlined"
                              required
                              fullWidth
                              my={2}
                              type="text"
                              value={this.state.endDelimiter}
                              onChange={(e) => {
                                this.setStateAndVerify({
                                  endDelimiter: e.target.value,
                                });
                              }}
                              disabled={this.state.loading}
                              helperText={
                                "A special character that signifies the end of a variable name. For example, if you want to use the variable '</ name />' in your template, you would enter '/>' here."
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* Exclude Sender Address & Uppercase All Address Characters */}
                      {this.state.settingsIntensity > 3 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.excludeSenderAddressInfo}
                                  onChange={(e) => {
                                    this.setStateAndVerify({
                                      excludeSenderAddressInfo:
                                        e.target.checked,
                                    });
                                  }}
                                  disabled={this.state.loading}
                                  name="exclude_sender_address_info"
                                />
                              }
                              label="Exclude Sender Address"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    this.state.uppercaseAllAddressCharacters
                                  }
                                  onChange={(e) => {
                                    this.setStateAndVerify({
                                      uppercaseAllAddressCharacters:
                                        e.target.checked,
                                    });
                                  }}
                                  disabled={this.state.loading}
                                  name="uppercase_all_address_characters"
                                />
                              }
                              label="Uppercase All Address Characters"
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* Order expiration / timeout days */}
                      {this.state.settingsIntensity > 2 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              id="order_timeout_days"
                              label="Order Timeout Days"
                              variant="outlined"
                              fullWidth
                              my={2}
                              type="number"
                              value={this.state.orderTimeoutDays}
                              onChange={(e) => {
                                this.setState({
                                  orderTimeoutDays: e.target.value,
                                });
                              }}
                              disabled={this.state.loading}
                              helperText="Number of days before an order expires. Order will automatically be canceled if not completed within this period."
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* Prevent Duplicate Orders & timeout days | Prevent Duplicate Receiver Name Orders & timeout */}
                      {this.state.settingsIntensity > 3 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.preventDuplicateOrders}
                                  onChange={(e) => {
                                    this.setStateAndVerify({
                                      preventDuplicateOrders: e.target.checked,
                                    });
                                  }}
                                  name="prevent_duplicate_orders"
                                  disabled={this.state.loading}
                                />
                              }
                              label="Prevent Duplicate Orders"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="prevent_duplicate_order_timeout_days"
                              label="Prevent Duplicate Order Timeout Days"
                              variant="outlined"
                              fullWidth
                              my={2}
                              type="number"
                              value={
                                this.state.preventDuplicateOrderTimeoutDays
                              }
                              onChange={(e) => {
                                this.setStateAndVerify({
                                  preventDuplicateOrderTimeoutDays:
                                    e.target.value,
                                });
                              }}
                              disabled={
                                !this.state.preventDuplicateOrders ||
                                this.state.loading
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    this.state
                                      .preventDuplicateReceiverNameOrders
                                  }
                                  onChange={(e) => {
                                    this.setStateAndVerify({
                                      preventDuplicateReceiverNameOrders:
                                        e.target.checked,
                                    });
                                  }}
                                  name="prevent_duplicate_receiver_name_orders"
                                  disabled={this.state.loading}
                                />
                              }
                              label="Prevent Duplicate Receiver Name Orders"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <TextField
                              id="prevent_duplicate_receiver_name_order_timeout_days"
                              label="Prevent Duplicate Receiver Name Orders Timeout Days"
                              variant="outlined"
                              fullWidth
                              my={2}
                              type="number"
                              value={
                                this.state
                                  .preventDuplicateReceiverNameOrderTimeoutDays
                              }
                              onChange={(e) => {
                                this.setStateAndVerify({
                                  preventDuplicateReceiverNameOrderTimeoutDays:
                                    e.target.value,
                                });
                              }}
                              disabled={
                                !this.state
                                  .preventDuplicateReceiverNameOrders ||
                                this.state.loading
                              }
                            />
                          </Grid>
                        </Grid>
                      )}

                      {/* Parent template selection & require parent previous orders */}
                      {this.state.settingsIntensity > 3 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              id="parent_template_text_id"
                              options={parentTemplateOptions}
                              getOptionLabel={(option) =>
                                option.name
                                  ? `${option.name} #${option.id}`
                                  : ""
                              }
                              getOptionSelected={(option) =>
                                option.id === this.state.parentTemplateTextId
                              }
                              fullWidth
                              value={
                                parentTemplateOptions.find(
                                  (o) =>
                                    o.id === this.state.parentTemplateTextId
                                ) ?? null
                              }
                              onChange={(e, newValue) => {
                                this.setStateAndVerify({
                                  parentTemplateTextId: newValue
                                    ? newValue.id
                                    : null,
                                });
                              }}
                              disabled={this.state.loading}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parent Template"
                                  variant="outlined"
                                  helperText={
                                    "A parent template can provide special constraints for other templates, and multiple templates can be chained together."
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            container
                            alignItems="center"
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    this.state.requireParentPreviousOrders
                                  }
                                  onChange={(e) => {
                                    this.setStateAndVerify({
                                      requireParentPreviousOrders:
                                        e.target.checked,
                                    });
                                  }}
                                  name="required_parent_previous_orders"
                                  disabled={
                                    !this.state.parentTemplateTextId ||
                                    this.state.loading
                                  }
                                />
                              }
                              label="Require Parent Previous Orders"
                            />
                            <QuestionMarkPopover>
                              <Typography variant="body1">
                                Ensures that orders from this template will only
                                be placed if the parent template has already
                                sent an order to the same address.
                              </Typography>
                            </QuestionMarkPopover>
                          </Grid>
                        </Grid>
                      )}

                      {/* teaser text */}
                      {this.state.settingsIntensity > 2 && (
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              id="teaserText"
                              label="Teaser Text"
                              variant="outlined"
                              fullWidth
                              // my={2}
                              type="text"
                              value={this.state.teaserText}
                              helperText={
                                "A short phrase written near the bottom left of the envelope. Example: 'Special Discount Inside!'."
                              }
                              onChange={(e) => {
                                this.setStateAndVerify({
                                  teaserText: e.target.value,
                                });
                              }}
                              disabled={this.state.loading}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid container spacing={0} mb={2}>
                        {/* Test Parameters */}
                        {this.state.settingsIntensity > 1 && (
                          <Accordion
                            component={Grid}
                            item
                            xs={12}
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                            }}
                          >
                            <CondensedAccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Grid
                                container
                                xs={12}
                                direction="row"
                                alignItems="center"
                              >
                                <Typography
                                  display="inline"
                                  style={{
                                    marginRight: 4,
                                  }}
                                >
                                  Required Parameters
                                </Typography>
                                <QuestionMarkPopover>
                                  <Typography variant="body1">
                                    A list of all the variables found in the
                                    template.
                                    <br />
                                    <br />
                                    Adds the ability to test the template with
                                    different variables and have them rendered
                                    into the preview.
                                  </Typography>
                                </QuestionMarkPopover>
                              </Grid>
                            </CondensedAccordionSummary>
                            <AccordionDetails
                              style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                              }}
                            >
                              <Grid container spacing={3}>
                                {Object.values(this.state.requiredParameters)
                                  .length < 1 ? (
                                  <Grid
                                    item
                                    xs
                                    container
                                    justifyContent="center"
                                  >
                                    <Typography color="textSecondary">
                                      No Required Parameters
                                    </Typography>
                                  </Grid>
                                ) : (
                                  _.sortBy(
                                    Object.values(
                                      this.state.requiredParameters
                                    ),
                                    "key"
                                  ).map((param) => {
                                    return (
                                      <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        lg={3}
                                        key={`input_variable_${param.key}`}
                                      >
                                        <Grid
                                          container
                                          spacing={2}
                                          alignItems="center"
                                        >
                                          <Grid item xs>
                                            <TextField
                                              label={param.key}
                                              variant="outlined"
                                              fullWidth
                                              my={2}
                                              type="text"
                                              margin="dense"
                                              disabled={this.state.loading}
                                              value={param.value}
                                              onChange={(e) => {
                                                let value = e.target.value;

                                                this.setStateAndVerify({
                                                  requiredParameters: {
                                                    ...this.state
                                                      .requiredParameters,
                                                    [param.key]: {
                                                      key: param.key,
                                                      value,
                                                    },
                                                  },
                                                });
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  })
                                )}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {/* insert options */}
                        <Accordion
                          component={Grid}
                          item
                          xs={12}
                          defaultExpanded={true}
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          <CondensedAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              direction="row"
                              alignItems="center"
                            >
                              <Typography
                                display="inline"
                                style={{
                                  marginRight: 4,
                                }}
                              >
                                Insert
                              </Typography>
                              <QuestionMarkPopover>
                                <Typography variant="body1">
                                  Quickly add a new variable into the template
                                  wherever your cursor is.
                                  <br />
                                  <br />
                                  You can also use any custom value and
                                  overwrite the variable name (e.g. "
                                  {this.state.startDelimiter} business_name{" "}
                                  {this.state.endDelimiter}")
                                </Typography>
                              </QuestionMarkPopover>
                            </Grid>
                          </CondensedAccordionSummary>
                          <AccordionDetails
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Grid item xs={12} container spacing={2}>
                              {preMadeTemplateParameters.map((param, index) => {
                                const isCustomParameter =
                                  param.id === "custom_value";
                                return (
                                  <Grid item>
                                    <Chip
                                      key={`pre-made-template-parameter-${param.label}-${index}`}
                                      label={param.label}
                                      disabled={this.state.loading}
                                      variant={"outlined"}
                                      onClick={() => {
                                        if (
                                          !(
                                            this.templateUpper_ref.current ||
                                            this.template_ref.current
                                          ) ||
                                          !this.activeTemplate
                                        ) {
                                          return null;
                                        }

                                        // insert the preMade template parameter value into the active textField
                                        let currentTextFieldKey = null;
                                        let currentTextField = null;
                                        let currentTextFieldValue = "";

                                        if (
                                          this.templateUpper_ref ===
                                          this.activeTemplate
                                        ) {
                                          currentTextFieldKey = "templateUpper";
                                          currentTextField = this
                                            .templateUpper_ref;
                                          currentTextFieldValue = this.state
                                            .templateUpper;
                                        } else if (
                                          this.template_ref ===
                                          this.activeTemplate
                                        ) {
                                          currentTextFieldKey = "template";
                                          currentTextField = this.template_ref;
                                          currentTextFieldValue = this.state
                                            .template;
                                        }

                                        if (!currentTextField) {
                                          // display a message and return
                                          return null;
                                        }

                                        let selectionStart =
                                          currentTextField.current
                                            .selectionStart;

                                        let parameterValue = param.getValue({
                                          startDelimiter: this.state
                                            .startDelimiter,
                                          endDelimiter: this.state.endDelimiter,
                                        });

                                        const newTextFieldValue =
                                          currentTextFieldValue.substring(
                                            0,
                                            selectionStart
                                          ) +
                                          parameterValue +
                                          currentTextFieldValue.substring(
                                            currentTextField.current
                                              .selectionEnd
                                          );

                                        currentTextField.current.focus();

                                        this.setStateAndVerify(
                                          (state) => {
                                            return {
                                              [currentTextFieldKey]: newTextFieldValue,
                                              requiredParameters: {
                                                ...param.defaultParameter,
                                                ...state.requiredParameters,
                                              },
                                            };
                                          },
                                          () => {
                                            if (isCustomParameter)
                                              currentTextField.current.setSelectionRange(
                                                selectionStart +
                                                  this.state.startDelimiter
                                                    .length +
                                                  1,
                                                selectionStart +
                                                  parameterValue.length -
                                                  (this.state.endDelimiter
                                                    .length +
                                                    1)
                                              );
                                          }
                                        );
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>

                        {/* upper text */}
                        {!upperTextIsDisabled &&
                          this.state.settingsIntensity > 1 && (
                            <Accordion
                              component={Grid}
                              item
                              xs={12}
                              style={{
                                marginTop: 0,
                              }}
                              defaultExpanded={Boolean(
                                this.state.templateUpper
                              )}
                            >
                              <CondensedAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <Grid container alignItems="center">
                                  <Typography
                                    style={{
                                      marginRight: 4,
                                    }}
                                  >
                                    Card Top Half
                                  </Typography>
                                  <QuestionMarkPopover>
                                    <Typography variant="body1">
                                      Allows creating additional text above the
                                      fold line
                                    </Typography>
                                  </QuestionMarkPopover>
                                </Grid>
                              </CondensedAccordionSummary>
                              <AccordionDetails
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              >
                                <TextField
                                  id="template_upper"
                                  label="Card Top Half"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  rows={15}
                                  type="text"
                                  onFocus={() => {
                                    this.activeTemplate = this.templateUpper_ref;
                                  }}
                                  error={upperTextIsDisabled}
                                  value={this.state.templateUpper}
                                  inputRef={this.templateUpper_ref}
                                  onChange={(e) => {
                                    const value = e.target.value;

                                    const paramsFromTemplate = getVariablesFromDelimitedText(
                                      {
                                        text: `${value} ${this.state.template}`,
                                        startDelimiter: this.state
                                          .startDelimiter,
                                        endDelimiter: this.state.endDelimiter,
                                      }
                                    );

                                    let nextParameters = {};

                                    paramsFromTemplate.forEach((key) => {
                                      let target = this.state
                                        .requiredParameters[key];

                                      if (target) {
                                        nextParameters[key] = target;
                                      } else {
                                        nextParameters[key] = {
                                          key,
                                          value: "Blank",
                                        };
                                      }
                                    });

                                    this.setStateAndVerify({
                                      templateUpper: value,
                                      requiredParameters: nextParameters,
                                    });
                                  }}
                                  disabled={this.state.loading}
                                />
                              </AccordionDetails>
                            </Accordion>
                          )}

                        <Grid item xs={12} mt={4}>
                          <TextField
                            id="template"
                            label="Card Bottom Half"
                            variant="outlined"
                            required
                            fullWidth
                            multiline
                            rows={15}
                            type="text"
                            onFocus={() => {
                              this.activeTemplate = this.template_ref;
                            }}
                            helperText={`A medium length hand written message to be filled with extra parameters. Example: Hello, ${this.state.startDelimiter} customer_full_name ${this.state.endDelimiter}! --> Hello, John Doe!`}
                            value={this.state.template}
                            inputRef={this.template_ref}
                            onChange={(e) => {
                              const value = e.target.value;

                              const paramsFromTemplate = getVariablesFromDelimitedText(
                                {
                                  text: `${value} ${this.state.templateUpper}`,
                                  startDelimiter: this.state.startDelimiter,
                                  endDelimiter: this.state.endDelimiter,
                                }
                              );

                              let nextParameters = {};

                              paramsFromTemplate.forEach((key) => {
                                let target = this.state.requiredParameters[key];

                                if (target) {
                                  nextParameters[key] = target;
                                } else {
                                  nextParameters[key] = {
                                    key,
                                    value: "Blank",
                                  };
                                }
                              });

                              this.setStateAndVerify({
                                template: value,
                                requiredParameters: nextParameters,
                              });
                            }}
                            disabled={this.state.loading}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <CustomSubmitButtonContainer>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              onClick={this.handleSaveTemplate}
                              disabled={
                                this.state.loading ||
                                !this.state.isTemplateVerified ||
                                !!this.state.pathPreview.errorMessage ||
                                !!this.state.pathPreviewUpper.errorMessage
                              }
                            >
                              <Typography variant="button">
                                {(() => {
                                  if (this.state.loading) {
                                    return "Saving...";
                                  }

                                  if (this.state.pathPreview.errorMessage) {
                                    return "Invalid Bottom Half";
                                  }

                                  if (
                                    this.state.pathPreviewUpper.errorMessage
                                  ) {
                                    return "Invalid Top Half";
                                  }

                                  if (this.state.verifyingTemplate) {
                                    return "Verifying Template";
                                  }

                                  if (this.state.isTemplateVerified) {
                                    return "Save Template";
                                  }

                                  return "Invalid Template";
                                })()}
                              </Typography>
                              {(this.state.loading ||
                                this.state.verifyingTemplate) && (
                                <CircularProgress
                                  size={20}
                                  color="secondary"
                                  ml={2}
                                />
                              )}
                            </Button>
                          </CustomSubmitButtonContainer>
                        </Grid>
                      </Grid>
                    </form>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} xl={this.state.settingsIntensity > 3 ? 12 : 6}>
                <Card>
                  <CardContent>
                    <Accordion defaultExpanded={true} elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6" gutterBottom>
                          Validation & Preview
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12}>
                            {this.state.errorMessage && (
                              <React.Fragment>
                                <Alert mt={2} mb={1} severity="warning">
                                  {this.state.errorMessage}
                                </Alert>
                                <br />
                              </React.Fragment>
                            )}
                            {this.state.pathPreviewUpper.errorMessage && (
                              <React.Fragment>
                                <Alert mt={2} mb={1} severity="warning">
                                  Upper Path Error:{" "}
                                  {this.state.pathPreviewUpper.errorMessage}
                                </Alert>
                                <br />
                              </React.Fragment>
                            )}
                            {this.state.pathPreview.errorMessage && (
                              <React.Fragment>
                                <Alert mt={2} mb={1} severity="warning">
                                  Lower Path Error:{" "}
                                  {this.state.pathPreview.errorMessage}
                                </Alert>
                                <br />
                              </React.Fragment>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            spacing={3}
                            justifyContent="center"
                          >
                            <Grid item xs={12}>
                              {this.state.loading ||
                              this.state.verifyingTemplate ? (
                                <React.Fragment>
                                  <CircularProgress
                                    size={25}
                                    color="secondary"
                                  />
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Card Top Half
                                      </Typography>
                                      <Paper
                                        variant="outlined"
                                        style={{
                                          padding: "16px",
                                        }}
                                      >
                                        <PathPreview
                                          paths={
                                            this.state.pathPreviewUpper.paths
                                          }
                                        />
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Card Bottom Half
                                      </Typography>
                                      <Paper
                                        variant="outlined"
                                        style={{
                                          padding: "16px",
                                        }}
                                      >
                                        <PathPreview
                                          paths={this.state.pathPreview.paths}
                                        />
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    extras: getActiveExtras(state),
    user: getActiveUser(state).user,
  };
};
const ConnectedTemplateCreateEdit = connect(mapState)(TemplateCreateEdit);

export default ConnectedTemplateCreateEdit;
