const errorTypes = require("./types");

export class NoProvidedToken extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NoProvidedToken);
    }

    this.name = "NoProvidedToken";
    this.description = args[0] || "";
    this.type = errorTypes.NO_TOKEN_PROVIDED;
  }
}

export class UserNotAdmin extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserNotAdmin);
    }

    this.name = "UserNotAdmin";
    this.description = args[0] || "";
    this.type = errorTypes.USER_NOT_ADMIN;
  }
}

export class InvalidActorAccessToken extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidActorAccessToken);
    }

    this.name = "InvalidActorAccessToken";
    this.description = args[0] || "";
    this.type = errorTypes.ACTOR_ACCESS_TOKEN_INVALID;
  }
}

export class InvalidUserAccessToken extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidUserAccessToken);
    }

    this.name = "InvalidUserAccessToken";
    this.description = args[0] || "";
    this.type = errorTypes.USER_ACCESS_TOKEN_INVALID;
  }
}

export class InvalidUserRefreshToken extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidUserRefreshToken);
    }

    this.name = "InvalidUserRefreshToken";
    this.description = args[0] || "";
    this.type = errorTypes.USER_REFRESH_TOKEN_INVALID;
  }
}

export class InvalidUserApiToken extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidUserApiToken);
    }

    this.name = "InvalidUserApiToken";
    this.description = args[0] || "";
    this.type = errorTypes.USER_API_TOKEN_INVALID;
  }
}

export class InvalidUserLoginToken extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidUserLoginToken);
    }

    this.name = "InvalidUserLoginToken";
    this.description = args[0] || "";
    this.type = errorTypes.USER_LOGIN_TOKEN_INVALID;
  }
}

export class NonExistentRoute extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NonExistentRoute);
    }

    this.name = "NonExistentRoute";
    this.description = args[0] || "";
    this.type = errorTypes.NON_EXISTENT_ROUTE;
  }
}

export class TemplateVerificationError extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TemplateVerificationError);
    }

    this.name = "TemplateVerificationError";
    this.description = args[0] || "";
    this.type = errorTypes.TEMPLATE_VERIFICATION_ERROR;
  }
}

export class InvalidRequestParameters extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidRequestParameters);
    }

    this.name = "InvalidRequestParameters";
    this.description = args[0] || "";
    this.type = errorTypes.INVALID_REQUEST_PARAMETERS;
  }
}
export class NonExistentOrUnauthorized extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NonExistentOrUnauthorized);
    }

    this.name = "NonExistentOrUnauthorized";
    this.description = args[0] || "";
    this.type = errorTypes.NON_EXISTENT_OR_UNAUTHORIZED;
  }
}
export class AssociationConstraint extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AssociationConstraint);
    }

    this.name = "AssociationConstraint";
    this.description = args[0] || "";
    this.type = errorTypes.ASSOCIATION_CONSTRAINT;
  }
}
export class DatabaseError extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DatabaseError);
    }

    this.name = "DatabaseError";
    this.description = args[0] || "";
    this.type = errorTypes.DATABASE_ERROR;
  }
}
export class StatusConstraint extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StatusConstraint);
    }

    this.name = "StatusConstraint";
    this.description = args[0] || "";
    this.type = errorTypes.STATUS_CONSTRAINT;
  }
}
export class InsufficientFunds extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InsufficientFunds);
    }

    this.name = "InsufficientFunds";
    this.description = args[0] || "";
    this.type = errorTypes.INSUFFICENT_FUNDS;
  }
}
export class InsufficientPermissions extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InsufficientPermissions);
    }

    this.name = "InsufficientPermissions";
    this.description = args[0] || "";
    this.type = errorTypes.INSUFFICENT_PERMISSIONS;
  }
}
export class InsufficientRole extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InsufficientRole);
    }

    this.name = "InsufficientRole";
    this.description = args[0] || "";
    this.type = errorTypes.INSUFFICENT_ROLE;
  }
}
export class NotStripe extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotStripe);
    }

    this.name = "NotStripe";
    this.description = args[0] || "";
    this.type = errorTypes.NOT_STRIPE;
  }
}

export class OnWaitlist extends Error {
  constructor(...args) {
    super(...args);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, OnWaitlist);
    }

    this.name = "OnWaitlist";
    this.description = args[0] || "";
    this.type = errorTypes.ON_WAITLIST;
  }
}
