import React, { useState } from "react";
import Webcam from "react-webcam";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../vendor/roundedBarCharts";
import _ from "lodash";
import jsQR from "jsqr";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader as MuiCardHeader,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  TextField as MuiTextField,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
} from "react-feather";
import {
  getAllStatistics,
  getCheckoutSessionOneTimePayment,
  getQrCodeById_internal,
  redeemPromotionalCode,
  submitHandWrittenNoteOrderAction_internal,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { stripe, load } from "../../stripe";
import { globalHistory } from "../../history";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function InternalQrCodeDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let uuid =
    _.get(props, "location.state.uuid", null) ??
    _.get(props, "match.params.uuid", null);

  const [qrCodeData, setQrCodeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const [actionIsLoading, setActionIsLoading] = useState(false);
  const [actionErrorMessage, setActionErrorMessage] = useState(null);
  const [actionSuccessMessage, setActionSuccessMessage] = useState(null);

  const [actionMessage, setActionMessage] = useState("");

  const replaceOrder = () => {
    const load = async () => {
      // replace order by the qr code uuid

      setActionIsLoading(true);
      setActionErrorMessage(null);
      setActionSuccessMessage(null);

      try {
        await submitHandWrittenNoteOrderAction_internal({
          orderId: qrCodeData.hand_written_note_order_id,
          actionType: "replace",
          actionMessage: actionMessage || null,
        });
        return globalHistory.push("/internal/qr-code/scanner");
      } catch (error) {
        setActionErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "An error occurred while replacing the order.",
          })
        );
      }

      setActionIsLoading(false);
    };

    load();
  };

  const cancelRefundOrder = () => {
    const load = async () => {
      // cancel the refund

      setActionIsLoading(true);
      setActionErrorMessage(null);
      setActionSuccessMessage(null);

      try {
        await submitHandWrittenNoteOrderAction_internal({
          orderId: qrCodeData.hand_written_note_order_id,
          actionType: "cancel",
          actionMessage: actionMessage || null,
        });
        return globalHistory.push("/internal/qr-code/scanner");
      } catch (error) {
        setActionErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage:
              "An error occurred while cancelling the refunding the order.",
          })
        );
      }

      setActionIsLoading(false);
    };

    load();
  };

  React.useEffect(() => {
    async function load() {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        // get the qr code data
        let qrCodeResults = await getQrCodeById_internal({
          qrCodeUUID: uuid,
        });

        setQrCodeData(qrCodeResults.data.qrCode);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage:
              "An error occurred while loading the QR code data.",
          })
        );

        setQrCodeData(null);
      }

      setIsLoading(false);
    }

    load();
  }, [uuid, currentUserKey]);

  return (
    <React.Fragment>
      <Helmet title="Internal Qr Code Details" />

      <Typography
        variant="h3"
        gutterBottom
        display="inline"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginRight: 4,
          }}
        >
          Qr Code{" "}
        </span>

        <Typography display="inline" color="textSecondary">
          ({uuid})
        </Typography>
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Qr Code Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {isLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : errorMessage ? (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            {errorMessage && (
              <Alert mt={2} mb={1} severity="warning">
                {errorMessage}
              </Alert>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={8}>
              {actionErrorMessage && (
                <Alert mt={2} mb={1} severity="warning">
                  {actionErrorMessage}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              {actionSuccessMessage && (
                <Alert mt={2} mb={1} severity="success">
                  {actionSuccessMessage}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={8}>
              <Card>
                <CardHeader
                  title={`Order #${qrCodeData.hand_written_note_order_id} Actions`}
                  subheader="Select from available actions"
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography gutterBottom>
                        These actions will take affect immediately and cannot be
                        reversed. Only use this page if you know what you're
                        doing.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        minRows={4}
                        maxRows={8}
                        helperText="Enter a message that will be visible to the customer (i.e. 'due to creation error')"
                        fullWidth
                        label="Action Message"
                        variant="outlined"
                        value={actionMessage}
                        disabled={actionIsLoading}
                        onChange={(e) => setActionMessage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          replaceOrder();
                        }}
                        disabled={actionIsLoading}
                      >
                        {actionIsLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Replace Order"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          cancelRefundOrder();
                        }}
                        disabled={actionIsLoading}
                      >
                        {actionIsLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Cancel & Refund"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default InternalQrCodeDetails;
