import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  presentationLayoutRoutes,
  protectedRoutes,
} from "./index";
import { globalHistory } from "../history";
import queryString from "query-string";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../pages/auth/Page404";
import { useSelector } from "react-redux";
import {
  getActiveUser,
  getIsPrimaryUser,
  getPrimaryUser,
} from "../redux/selectors";
import ChatPopup from "../components/ChatPopup";

const childRoutes = (Layout, routes) => {
  const user = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;
  const isPrimaryUser = useSelector(getIsPrimaryUser);
  return routes.map(
    (
      {
        component: Component,
        guard,
        children,
        path,
        useWithRole,
        useWithPrimaryUserRole,
        useOnlyWhenActing,
        alwaysShowForPrimaryUserRole,
      },
      index
    ) => {
      const Guard = guard || React.Fragment;

      let shouldAlwaysShow = false;

      if (alwaysShowForPrimaryUserRole !== undefined) {
        if (
          primaryUser &&
          alwaysShowForPrimaryUserRole &&
          (Array.isArray(alwaysShowForPrimaryUserRole)
            ? alwaysShowForPrimaryUserRole.includes(primaryUser.role)
            : primaryUser.role === alwaysShowForPrimaryUserRole)
        ) {
          shouldAlwaysShow = true;
        }
      }

      if (!shouldAlwaysShow) {
        if (useOnlyWhenActing && isPrimaryUser) {
          return null;
        }

        if (
          user &&
          useWithRole &&
          (Array.isArray(useWithRole)
            ? !useWithRole.includes(user.role)
            : user.role !== useWithRole)
        ) {
          return null;
        }

        if (
          primaryUser &&
          useWithPrimaryUserRole &&
          (Array.isArray(useWithPrimaryUserRole)
            ? !useWithPrimaryUserRole.includes(primaryUser.role)
            : primaryUser.role !== useWithPrimaryUserRole)
        ) {
          return null;
        }
      }

      return children ? (
        children.map((element, index) => {
          const Guard = element.guard || React.Fragment;
          const ElementComponent = element.component || React.Fragment;

          let shouldAlwaysShow = false;

          if (element.alwaysShowForPrimaryUserRole !== undefined) {
            if (
              primaryUser &&
              element.alwaysShowForPrimaryUserRole &&
              (Array.isArray(element.alwaysShowForPrimaryUserRole)
                ? element.alwaysShowForPrimaryUserRole.includes(
                    primaryUser.role
                  )
                : primaryUser.role === element.alwaysShowForPrimaryUserRole)
            ) {
              shouldAlwaysShow = true;
            }
          }

          if (!shouldAlwaysShow) {
            if (element.useOnlyWhenActing && isPrimaryUser) {
              return null;
            }

            if (
              user &&
              element.useWithRole &&
              (Array.isArray(element.useWithRole)
                ? !element.useWithRole.includes(user.role)
                : user.role !== element.useWithRole)
            ) {
              return null;
            }

            if (
              primaryUser &&
              element.useWithPrimaryUserRole &&
              (Array.isArray(element.useWithPrimaryUserRole)
                ? !element.useWithPrimaryUserRole.includes(primaryUser.role)
                : primaryUser.role !== element.useWithPrimaryUserRole)
            ) {
              return null;
            }
          }

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props) => (
                <Layout>
                  <Guard>
                    <ElementComponent {...props} />
                  </Guard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );
};
const Routes = () => {
  let { login_token, login_otp } = queryString.parse(
    globalHistory.location.search
  );

  if (login_token && login_otp) {
    globalHistory.replace({
      pathname: "/auth/sign-in",
      search: globalHistory.location.search,
    });
  }

  return (
    <Router history={globalHistory}>
      <Switch>
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
      <ChatPopup />
    </Router>
  );
};

export default Routes;
