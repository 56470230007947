import { EventTarget, Event } from "event-target-shim";
import React, { useState, useEffect, useCallback } from "react";

let customEvents = new EventTarget();

const memoryState = {};

// A quick and dirty way to store state in memory
// without having to persist to localStorage or
// mess with redux boilerplate.

// Mostly used for peristing state between unmounts (i.e. notificationDropdown being unmounted and remounted for every new route/page)
export default function useMemoryState(key, initialState, options) {
  if (!options) {
    options = {};
  }

  const { syncPage = true } = options;

  const [state, setState] = useState(() => {
    const hasMemoryValue = Object.prototype.hasOwnProperty.call(
      memoryState,
      key
    );
    if (hasMemoryValue) {
      return memoryState[key];
    } else {
      return typeof initialState === "function" ? initialState() : initialState;
    }
  });

  useEffect(() => {
    const hasMemoryValue = Object.prototype.hasOwnProperty.call(
      memoryState,
      key
    );

    if (hasMemoryValue) {
      setState(memoryState[key]);
    } else {
      setState(
        typeof initialState === "function" ? initialState() : initialState
      );
    }
  }, [key]);

  useEffect(() => {
    if (syncPage) {
      let localEvent = function (e) {
        const memoryValue = memoryState[key];
        setState(memoryValue);
      };

      customEvents.addEventListener(key, localEvent);

      return () => {
        customEvents.removeEventListener(key, localEvent);
      };
    }
  }, [key, syncPage]);

  const onChange = useCallback(
    (nextState) => {
      let next =
        typeof nextState === "function"
          ? nextState(memoryState[key])
          : nextState;
      memoryState[key] = next;
      setState(next);
      syncPage && customEvents.dispatchEvent(new Event(key));
    },
    [key, syncPage]
  );

  return [state, onChange];
}
