import React from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getAllHandWrittenNoteOrders,
  getAllTransactionGroups,
  getAllPurchaseOrders,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";

import { Alert as MuiAlert } from "@material-ui/lab";
import OrderStatusChip from "../components/OrderStatusChip";
import { BigNumber } from "../../money";
import MoneyChip from "../components/MoneyChip";
import constants from "../../constants";
import { getCurrentUserKey } from "../../redux/selectors";
import { useSelector } from "react-redux";
import ClickableTableCell from "../../components/ClickableTableCell";
import { globalHistory } from "../../history";
import AdvancedTable from "../tables/AdvancedTable";
import transactionGroupRowColumns from "../../advancedTableConfigs/transactionGroupRowColumns";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function AllTransactionGroupList() {
  const [errorMessage, setErrorMessage] = React.useState(null);

  return (
    <React.Fragment>
      <Helmet title="All Transaction Groups" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            All Transaction Groups
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>All Transaction Groups</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Order
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AdvancedTable
            title="Transaction Groups"
            rowColumns={transactionGroupRowColumns}
            identifier={null}
            getRowData={async ({ order, limit, offset }) => {
              let results = await getAllTransactionGroups({
                order,
                limit,
                offset,
              });

              let parsedRowData = {
                rows: results.data.transaction_groups,
                totalRowCount: results.data.transaction_group_count,
              };

              return parsedRowData;
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AllTransactionGroupList;
