import React, { useEffect, useState } from "react";
import {
  Chip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography as MuiTypography,
  CircularProgress,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Cancel, DeleteForever } from "@material-ui/icons";
import { getRequestErrorMessage } from "../helpers";
import { revokeApiToken } from "../backend";
import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { red } from "@material-ui/core/colors";

const Alert = styled(MuiAlert)(spacing);
const Typography = styled(MuiTypography)(spacing);

const RevokeToken = ({ tokenId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
    setIsLoading(false);
  }, [isDialogOpen]);

  let handleRevoke = (event) => {
    event && event.preventDefault();

    let revoke = async () => {
      setIsLoading(true);
      setErrorMessage(null);

      try {
        let result = await revokeApiToken(tokenId);
        setSuccessMessage(
          "The token was successfully revoked. Please refresh the results to see the change"
        );
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "There was an error revoking the token.",
        });

        setErrorMessage(message);
      }

      setIsLoading(false);
    };

    revoke();
  };

  const hasSuccessMessage = Boolean(successMessage);

  return (
    <React.Fragment>
      <IconButton
        aria-label="delete"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        <DeleteForever />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isDialogOpen}
        onClose={
          isLoading
            ? undefined
            : () => {
                setIsDialogOpen(false);
              }
        }
      >
        <DialogTitle>Revoke Token</DialogTitle>
        {errorMessage && (
          <React.Fragment>
            <DialogContent>
              <Alert severity="warning">{errorMessage}</Alert>
            </DialogContent>
          </React.Fragment>
        )}
        {hasSuccessMessage && (
          <React.Fragment>
            <DialogContent>
              <Alert severity="success">{successMessage}</Alert>
            </DialogContent>
          </React.Fragment>
        )}
        <DialogContent>
          <Typography mb={2}>
            Are you sure you want to revoke this token? This action is permanent
            and cannot be undone.
          </Typography>

          <Typography>
            Services using this token will no longer be able to access Radial
            Link and will stop working.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDialogOpen(false);
            }}
            disabled={isLoading}
            variant={hasSuccessMessage ? "contained" : undefined}
            color={hasSuccessMessage ? "primary" : undefined}
          >
            Close
          </Button>
          <Button
            onClick={handleRevoke}
            variant={hasSuccessMessage ? undefined : "contained"}
            style={{
              ...(hasSuccessMessage
                ? {}
                : {
                    backgroundColor: red[500],
                    color: "white",
                  }),
            }}
            disabled={isLoading || hasSuccessMessage}
          >
            {isLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <Typography>Revoke</Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const CopyRowItem = ({ rowItem, rowIndex, row }) => {
  const [copiedText, setCopiedText] = useState("Copy");
  const maxLength = 32;

  useEffect(() => {
    // reset copied text after timeout
    const timeout = setTimeout(() => {
      setCopiedText("");
    }, 5000);
  }, [copiedText]);

  return (
    <CopyToClipboard text={rowItem} onCopy={() => setCopiedText(rowItem)}>
      <Tooltip
        title={copiedText === rowItem ? "Copied" : "Copy To Clipboard"}
        placement="top"
      >
        <Chip
          size="small"
          mr={1}
          mb={1}
          label={
            `${rowItem}`.length > maxLength
              ? `${rowItem.slice(0, maxLength)}...`
              : `${rowItem}`
          }
        />
      </Tooltip>
    </CopyToClipboard>
  );
};

const apiTokenRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Token #",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "description",
    alignment: "left",
    label: "Description",
    allowSort: true,
  },
  // {
  //   id: "token",
  //   alignment: "left",
  //   label: "Token",
  //   allowSort: true,
  //   renderRowItem: ({ row, index, rowItem }) => {
  //     return <CopyRowItem rowItem={rowItem} rowIndex={index} row={row} />;
  //   },
  // },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => {
      return <RevokeToken tokenId={row.id} />;
    },
  },
];

export default apiTokenRowColumns;
