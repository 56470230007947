import { RESET_NETWORK_STATUS, SET_NETWORK_STATUS } from "../actions/types";
import { networkStatus as initialState } from "../initialState";

export default function networkStatusReducer(state = initialState, action) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_NETWORK_STATUS:
      return initialState;
    case SET_NETWORK_STATUS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload.networkStatus,
      };
    default:
      return state;
  }
}
