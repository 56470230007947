import config from "../config";
import constants from "../constants";
import { store } from "../redux/store";
import _ from "lodash";
import { isInstanceOfSome } from "../helpers";
import {
  getActiveAccessToken,
  getActiveUser,
  getAllUserKeys,
  getCurrentUserKey,
  getIsPrimaryUser,
  getPrimaryAccessToken,
} from "../redux/selectors";
import { resetUsersData, setUsersData } from "../redux/actions/usersActions";
import { resetCurrentUserKey } from "../redux/actions/currentUserKeyActions";
import { io } from "socket.io-client";
const errors = require("../errors");

const apiVersion = "v2";
const host = config.backendUrl;
const options = {};

options.headers = {
  Authorization: "Bearer ",
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-ACTOR-TOKEN": "",
};
options.credentials = "include";
options.redirect = "follow";

const socketAuth = {
  accessToken: null,
  apiToken: null,
  actorToken: null,
};

const getSocketAuth = (accessToken, actorToken, props) => {
  if (!props) {
    props = {};
  }
  let { forcePrimaryUser } = props;

  let currentState = store.getState();
  let socketAuth_cloned = _.cloneDeep(socketAuth);
  let accessToken_cloned = _.cloneDeep(accessToken);
  let reduxAccessToken_cloned = _.cloneDeep(
    getPrimaryAccessToken(currentState).value
  );

  let actor_accessToken_cloned = _.cloneDeep(actorToken);
  let actor_reduxAccessToken_cloned = _.cloneDeep(
    getActiveAccessToken(currentState).value
  );

  let isPrimaryUser = getIsPrimaryUser(currentState);

  if (!actor_accessToken_cloned) {
    actor_accessToken_cloned = actor_reduxAccessToken_cloned;
  }

  if (!isPrimaryUser && actor_accessToken_cloned) {
    socketAuth_cloned.actorToken = actor_accessToken_cloned;
  } else {
    socketAuth_cloned.actorToken = null;
  }

  if (!accessToken_cloned) {
    accessToken_cloned = reduxAccessToken_cloned;
  }
  if (!accessToken_cloned) {
    socketAuth_cloned.accessToken = null;
  }

  if (forcePrimaryUser) {
    socketAuth_cloned.actorToken = null;
  }

  socketAuth_cloned.accessToken = accessToken_cloned;
  return socketAuth_cloned;
};

const socket_host_endpoint = config.backendUrl;
const socket_namespace_path = "/user";
const socket_endpoint = socket_host_endpoint + socket_namespace_path;
const socket_default_props = {
  autoConnect: false,
  reconnection: true,
  auth: getSocketAuth(),
  transports: ["websocket"],
};

export const socket = io(socket_endpoint, socket_default_props);
export const connectSocket = (force = false) => {
  if (!socket.connected || force) {
    console.log("Attemping to connect to socket.io");
    socket.disconnect();
    socket.auth = getSocketAuth();
    socket.connect();
  }
};

socket.on("connect", () => {
  console.log("Established connection to socket.io.");
});

socket.on("disconnect", () => {
  console.log("Disconnected from socket.io.");
});

socket.on("connect_error", (error) => {
  console.log(`A socket.io connection error occurred: ${error.message}`);
});

socket.on("connect_error", (error) => {
  let responseError = _.get(error, "data.responseError", null);
  let invalidUserAccessToken = _.get(
    error,
    "data.invalidUserAccessToken",
    false
  );
  let invalidActorAccessToken = _.get(
    error,
    "data.invalidActorAccessToken",
    false
  );

  let currentState = store.getState();
  let isPrimaryUser = getIsPrimaryUser(currentState);
  let activeUser = getActiveUser(currentState).user;

  if (invalidUserAccessToken) {
    getAccessToken()
      .then((data) => {
        connectSocket();
      })
      .catch((err) => {
        // ignore
      });
  } else if (invalidActorAccessToken && !isPrimaryUser) {
    getActorAccessToken_plain(
      { userId: activeUser.id },
      { originalError: responseError }
    )
      .then((data) => {
        connectSocket();
      })
      .catch((error) => {
        if (
          error.errorIsServerErrorInstance &&
          error.data.error instanceof errors.InvalidUserAccessToken
        ) {
          getAccessToken()
            .then((data) => {
              connectSocket();
            })
            .catch((err) => {
              // ignore
            });
        }
        // something went wrong getting the access token
      });
  }
});

const getOptions = (accessToken, actorToken, props) => {
  if (!props) {
    props = {};
  }
  let {
    forcePrimaryUser,
    overrideUseBalanceFromUserId,
    includeContentType = true,
  } = props;

  let currentState = store.getState();
  let options_cloned = _.cloneDeep(options);
  let accessToken_cloned = _.cloneDeep(accessToken);
  let reduxAccessToken_cloned = _.cloneDeep(
    getPrimaryAccessToken(currentState).value
  );

  let actor_accessToken_cloned = _.cloneDeep(actorToken);
  let actor_reduxAccessToken_cloned = _.cloneDeep(
    getActiveAccessToken(currentState).value
  );

  let isPrimaryUser = getIsPrimaryUser(currentState);

  if (!actor_accessToken_cloned) {
    actor_accessToken_cloned = actor_reduxAccessToken_cloned;
  }

  if (!isPrimaryUser && actor_accessToken_cloned) {
    options_cloned.headers["X-ACTOR-TOKEN"] = actor_accessToken_cloned;
  } else {
    delete options_cloned.headers["X-ACTOR-TOKEN"];
  }

  if (!accessToken_cloned) {
    accessToken_cloned = reduxAccessToken_cloned;
  }

  if (!accessToken_cloned) {
    delete options_cloned.headers.Authorization;
  }

  if (forcePrimaryUser) {
    delete options_cloned.headers["X-ACTOR-TOKEN"];
  }

  if (typeof overrideUseBalanceFromUserId === "number") {
    options_cloned.headers[
      "X-USE-BALANCE-FROM-USER-ID"
    ] = overrideUseBalanceFromUserId;
  }

  if (!includeContentType) {
    delete options_cloned.headers["Content-Type"];
    delete options_cloned.headers["content-type"];
  }

  options_cloned.headers.Authorization = `Bearer ${accessToken_cloned}`;
  return options_cloned;
};

const catchHandler = (error) => {
  return new Promise((resolve, reject) => {
    let r = () => {
      return reject(error);
    };

    if (!error) {
      return r();
    }

    // if (error.isServerResponse) {
    //   if (error.errorIsServerErrorInstance) {
    //     if (error.data.error instanceof errors.InvalidUserRefreshToken) {
    //       let currentState = store.getState();
    //       let userKeyList = getAllUserKeys(currentState);
    //       store.dispatch(resetCurrentUserKey());
    //       userKeyList.forEach((userKey) => {
    //         store.dispatch(resetUsersData(userKey));
    //       });

    //       return fetch(`${host}/api/${apiVersion}/user/auth/logout`, {
    //         method: "get",
    //         ...opts,
    //       })
    //         .then(responseHandler)
    //         .then((data) => {
    //           return Promise.resolve(data);
    //         })
    //         .catch(catchHandler);
    //     } else {
    //       return r();
    //     }
    //   } else {
    //     return r();
    //   }
    // }

    if (!error.message) {
      return r();
    }

    if (error.message === "" || error.message === " ") {
      return r();
    }

    if (error.message === "Failed to fetch") {
      return r();
    }

    return r();
  });
};

const responseHandler = (res) => {
  const contentType = res.headers.get("content-type");
  let isJson = false;
  if (contentType && contentType.indexOf("application/json") !== -1) {
    isJson = true;
  }

  if (!res.ok || res.status !== 200) {
    if (!isJson) {
      return res.text().then((data) => {
        let rejectData = {
          isServerResponse: false,
          errorIsServerErrorInstance: false,
          data,
        };

        return Promise.reject(rejectData);
      });
    }

    return res.json().then((data) => {
      let rejectData = {
        isServerResponse: false,
        errorIsServerErrorInstance: false,
        data,
      };
      if (
        data[constants.serverResponseIdentifier[0]] ===
        constants.serverResponseIdentifier[1]
      ) {
        rejectData.isServerResponse = true;
      }

      if (
        rejectData.isServerResponse &&
        _.isObject(rejectData.data.error) &&
        rejectData.data.error.name
      ) {
        let ErrorConstructor = errors[rejectData.data.error.name];
        let errorInstance = null;
        if (ErrorConstructor) {
          errorInstance = new ErrorConstructor(
            rejectData.data.error.description
          );
        }
        if (
          errorInstance &&
          isInstanceOfSome(errorInstance, Object.values(errors))
        ) {
          rejectData.errorIsServerErrorInstance = true;
          rejectData.data.error = errorInstance;

          if (rejectData.data.error instanceof errors.InvalidUserRefreshToken) {
            let currentState = store.getState();
            let userKeyList = getAllUserKeys(currentState);
            store.dispatch(resetCurrentUserKey());
            userKeyList.forEach((userKey) => {
              store.dispatch(resetUsersData(userKey));
            });
          }
        }
      }

      return Promise.reject(rejectData);
    });
  } else {
    return res.json().then((data) => {
      return Promise.resolve(data);
    });
  }
};

const fetch_retryForAccessToken = (url, options = {}, props) => {
  return new Promise((resolve, reject) => {
    if (!props) {
      props = {};
    }

    if (props.originalError) {
      return reject(props.originalError);
    }

    let opts = getOptions(null, null, props);
    let baseOpts = opts;
    let combinedOptions = _.merge({ method: "get" }, opts, options);
    return fetch(url, combinedOptions)
      .then(responseHandler)
      .then((data) => {
        return resolve(data);
      })
      .catch(catchHandler)
      .catch((originalError) => {
        let currentState = store.getState();
        let isPrimaryUser = getIsPrimaryUser(currentState);
        let activeUser = getActiveUser(currentState).user;

        const retryForActorToken = () => {
          return getActorAccessToken_plain(
            { userId: activeUser.id },
            { originalError }
          )
            .then((data) => {
              opts = getOptions(null, data.data.accessToken.value, props);
              combinedOptions = _.merge({ method: "get" }, opts, options);

              return fetch(url, combinedOptions)
                .then(responseHandler)
                .then((data) => {
                  return resolve(data);
                })
                .catch((retryError) => {
                  reject(retryError);
                });
            })
            .catch((accessTokenError) => {
              return reject(accessTokenError);
            });
        };

        if (
          originalError.errorIsServerErrorInstance &&
          originalError.data.error instanceof errors.InvalidUserAccessToken
        ) {
          return getAccessToken({ originalError })
            .then((data) => {
              opts = getOptions(data.data.accessToken.value, null, props);
              combinedOptions = _.merge({ method: "get" }, opts, options);

              return fetch(url, combinedOptions)
                .then(responseHandler)
                .then((data) => {
                  return resolve(data);
                })
                .catch((retryError) => {
                  if (
                    retryError.errorIsServerErrorInstance &&
                    retryError.data.error instanceof
                      errors.InvalidActorAccessToken
                  ) {
                    return retryForActorToken();
                  } else {
                    reject(retryError);
                  }
                });
            })
            .catch((accessTokenError) => {
              return reject(accessTokenError);
            });
        } else if (
          originalError.errorIsServerErrorInstance &&
          originalError.data.error instanceof errors.InvalidActorAccessToken
        ) {
          if (isPrimaryUser) {
            return reject(originalError);
          }

          return retryForActorToken();
        } else {
          return reject(originalError);
        }
      });
  });
};

export async function cancelHandWrittenNoteOrderById(orderId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/cancel`,
    {
      method: "Post",
      body: JSON.stringify({
        orderId: parseInt(orderId),
      }),
    },
    props
  ).then((data) => {
    let balance = data.data.balanceAfterRefund;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));
    return Promise.resolve(data);
  });
}

export async function cancelHandWrittenNoteOrdersByTemplateId(
  templateId,
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/cancel-template-orders`,
    {
      method: "Post",
      body: JSON.stringify({
        templateId: parseInt(templateId),
      }),
    },
    props
  ).then((data) => {
    let balance = data.data.balanceAfterRefund;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));

    return Promise.resolve(data);
  });
}

export async function getHandWrittenNoteOrderById(orderId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order`,
    {
      method: "Post",
      body: JSON.stringify({
        orderId: parseInt(orderId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllHandWrittenNoteOrders(
  {
    query,
    north,
    east,
    south,
    west,
    limit,
    offset,
    order,
    notIn,
    statusType,
    orderType,
    startDate,
    endDate,
    templateId,
    purchaseOrder,
    mapCollectionMarkerId,
    mapCollectionId,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        statusType: statusType ? `${statusType}` : null,
        orderType: orderType ? `${orderType}` : null,
        purchaseOrder: purchaseOrder ? `${purchaseOrder}` : null,
        north: north ? parseFloat(north) : null,
        east: east ? parseFloat(east) : null,
        south: south ? parseFloat(south) : null,
        west: west ? parseFloat(west) : null,
        notIn: notIn ? (notIn.length > 0 ? notIn : null) : null,
        dateRange: startDate && endDate ? [startDate, endDate] : null,
        templateId: templateId ? parseInt(templateId) : null,
        mapCollectionMarkerId: mapCollectionMarkerId
          ? parseInt(mapCollectionMarkerId)
          : null,
        mapCollectionId: mapCollectionId ? parseInt(mapCollectionId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSubscriptions(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/stripe/get-subscriptions`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function cancelSubscription({ stripe_price_id }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/stripe/cancel-subscription`,
    {
      method: "Post",
      body: JSON.stringify({
        stripe_price_id,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getCheckoutSessionOneTimePayment({ amount }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/stripe/get-checkout-session-one-time-payment`,
    {
      method: "Post",
      body: JSON.stringify({
        amount: `${amount}`,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getCheckoutSession({ stripe_price_id }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/stripe/get-checkout-session`,
    {
      method: "Post",
      body: JSON.stringify({
        stripe_price_id,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getPlans({ limit, offset, order }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/stripe/get-plans`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getUserFallbackReturnAddress(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/fallback-return-address`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    let fallbackAddress = data.data.fallbackReturnAddress;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    if (fallbackAddress) {
      store.dispatch(
        setUsersData(userKey, {
          userFallbackReturnAddressInfo: fallbackAddress,
        })
      );
    }
    return Promise.resolve(data);
  });
}

export async function userUpdateFallbackReturnAddress(
  {
    senderName,
    senderBusinessName,
    senderAddressLineOne,
    senderAddressLineTwo,
    senderCity,
    senderState,
    senderZipCode,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/update-fallback-return-address`,
    {
      method: "Post",
      body: JSON.stringify({
        senderName,
        senderBusinessName,
        senderAddressLineOne,
        senderAddressLineTwo,
        senderCity,
        senderState,
        senderZipCode,
      }),
    },
    props
  ).then((data) => {
    let fallbackAddress = data.data.fallbackReturnAddress;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    if (fallbackAddress) {
      store.dispatch(
        setUsersData(userKey, {
          userFallbackReturnAddressInfo: fallbackAddress,
        })
      );
    }
    return Promise.resolve(data);
  });
}

export async function userUpdateGeneral(
  { firstName, lastName, businessName, profilePhotoFileId },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/update-general`,
    {
      method: "Post",
      body: JSON.stringify({
        firstName: firstName ? `${firstName}` : null,
        lastName: lastName ? `${lastName}` : null,
        businessName: businessName ? `${businessName}` : null,
        profilePhotoFileId: profilePhotoFileId
          ? parseInt(profilePhotoFileId)
          : null,
      }),
    },
    props
  ).then((data) => {
    let { firstName, lastName, businessName } = data.data.user;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(
      setUsersData(userKey, {
        user: { firstName, lastName, businessName },
      })
    );
    return Promise.resolve(data);
  });
}

export async function getTransactionGroupById(transactionGroupId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/transaction-groups`,
    {
      method: "Post",
      body: JSON.stringify({
        transactionGroupId: parseInt(transactionGroupId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

// Accepts an id of a user_auth_token (which will be associated with different items in the database). Maps, Templates, etc. will have a user_auth_token_id.
// It will return the effective "creator" of the item.
// Example response:
/*
  {
    isActorResponsible: Boolean
    isApiKeyResponsible: Boolean
    isUserResponsible: Boolean
    responsibleUser: {...} || null
    responsibleUserId: Integer || null
    userAuthToken: {...}
  }
*/
export async function getResponsibleUserByTokenId({ tokenId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/activity/token-responsibility`,
    {
      method: "Post",
      body: JSON.stringify({
        tokenId: parseInt(tokenId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getUserActivityById(
  { activityId, includeResponsibility },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/activity`,
    {
      method: "Post",
      body: JSON.stringify({
        activityId: parseInt(activityId),
        includeResponsibility: includeResponsibility ? true : false,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllUserActivities(
  { query, limit, offset, order, type, relations, includeResponsibility },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/activity/all`,
    {
      method: "Post",
      body: JSON.stringify({
        query: query ? `${query}` : null,
        limit: parseInt(limit),
        offset: parseInt(offset),
        order,
        type: type ? `${type}` : null,
        relations:
          relations && Array.isArray(relations) && relations.length > 0
            ? relations
            : null,
        includeResponsibility: includeResponsibility ? true : false,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function userSearchAll({ limit, offset, query }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/search-all`,
    {
      method: "Post",
      body: JSON.stringify({
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllTransactionGroups({ limit, offset, order }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/transaction-groups/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getTransactionById(transactionId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/transactions`,
    {
      method: "Post",
      body: JSON.stringify({
        transactionId: parseInt(transactionId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllTransactions({ limit, offset, order }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/transactions/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getHandWrittenNoteOrdersByTemplateId(
  { templateId, limit, offset, order },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/template-orders/`,
    {
      method: "Post",
      body: JSON.stringify({
        templateId: parseInt(templateId),
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function getHandWrittenNoteOrdersByPurchaseOrder(
  { purchaseOrder, limit, offset, order },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/purchase-order/`,
    {
      method: "Post",
      body: JSON.stringify({
        purchaseOrder,
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllPurchaseOrders({ limit, offset, order }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/purchase-order/all`,
    {
      method: "Post",
      body: JSON.stringify({
        limit: parseInt(limit),
        offset: parseInt(offset),
        order,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllHandWrittenNoteOrders_map(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/all-map`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function placeHandWrittenNoteOrder(handWrittenNoteDetails, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/new`,
    {
      method: "Post",
      body: JSON.stringify(handWrittenNoteDetails),
    },
    props
  ).then((data) => {
    let balance = data.data.balanceAfterOrder;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));

    return Promise.resolve(data);
  });
}

export async function placeHandWrittenNoteOrderRadial(
  handWrittenNoteDetails,
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/new-radial`,
    {
      method: "Post",
      body: JSON.stringify(handWrittenNoteDetails),
    },
    props
  ).then((data) => {
    let balance = data.data.balanceAfterOrder;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));

    return Promise.resolve(data);
  });
}

export async function placeHandWrittenNoteOrderMapCollection(
  handWrittenNoteDetails,
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/new-map-collection`,
    {
      method: "Post",
      body: JSON.stringify(handWrittenNoteDetails),
    },
    props
  ).then((data) => {
    let balance = data.data.balanceAfterOrder;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));

    return Promise.resolve(data);
  });
}

export async function placeHandWrittenNoteOrderBulk(
  handWrittenNoteDetails,
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/hand-written-note-order/new-bulk`,
    {
      method: "Post",
      body: JSON.stringify(handWrittenNoteDetails),
    },
    props
  ).then((data) => {
    let balance = data.data.balanceAfterOrder;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));

    return Promise.resolve(data);
  });
}

export async function editTemplate(t, props) {
  let {
    templateId,
    name,
    startDelimiter,
    endDelimiter,
    cardSize,
    fontType,
    templateUpper,
    template,
    parameters,
    orderTimeoutDays,
    preventDuplicateOrderTimeoutDays,
    preventDuplicateReceiverNameOrderTimeoutDays,
    preventDuplicateOrders,
    preventDuplicateReceiverNameOrders,
    excludeSenderAddressInfo,
    parentTemplateTextId,
    requireParentPreviousOrders,
    teaserText,
    uppercaseAllAddressCharacters,
    redirectUrl,
    industryId,
    categoryId,
    isPublic,
  } = t;

  let rUrl = null;
  if (redirectUrl) {
    rUrl = redirectUrl;
    if (redirectUrl.startsWith("http://")) {
      // do nothing, but this is bad
    } else if (redirectUrl.startsWith("https://")) {
      // do nothing, this is good
    } else {
      rUrl = `https://${redirectUrl}`;
    }
  }

  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        templateId,
        name,
        startDelimiter,
        endDelimiter,
        cardSize,
        fontType,
        templateUpper,
        template,
        parameters,
        orderTimeoutDays,
        preventDuplicateOrderTimeoutDays,
        preventDuplicateReceiverNameOrderTimeoutDays,
        preventDuplicateOrders,
        preventDuplicateReceiverNameOrders,
        excludeSenderAddressInfo,
        parentTemplateTextId,
        requireParentPreviousOrders,
        teaserText,
        uppercaseAllAddressCharacters,
        redirectUrl: rUrl ? rUrl : null,
        industryId: industryId ? parseInt(industryId) : null,
        categoryId: categoryId ? parseInt(categoryId) : null,
        isPublic: Boolean(isPublic),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewTemplate(t, props) {
  let {
    name,
    startDelimiter,
    endDelimiter,
    cardSize,
    fontType,
    templateUpper,
    template,
    parameters,
    orderTimeoutDays,
    preventDuplicateOrderTimeoutDays,
    preventDuplicateReceiverNameOrderTimeoutDays,
    preventDuplicateOrders,
    preventDuplicateReceiverNameOrders,
    excludeSenderAddressInfo,
    parentTemplateTextId,
    requireParentPreviousOrders,
    teaserText,
    uppercaseAllAddressCharacters,
    redirectUrl,
    industryId,
    categoryId,
    isPublic,
  } = t;

  let rUrl = null;
  if (redirectUrl) {
    rUrl = redirectUrl;
    if (redirectUrl.startsWith("http://")) {
      // do nothing, but this is bad
    } else if (redirectUrl.startsWith("https://")) {
      // do nothing, this is good
    } else {
      rUrl = `https://${redirectUrl}`;
    }
  }

  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template/new`,
    {
      method: "Post",
      body: JSON.stringify({
        name,
        startDelimiter,
        endDelimiter,
        cardSize,
        fontType,
        templateUpper,
        template,
        parameters,
        orderTimeoutDays,
        preventDuplicateOrderTimeoutDays,
        preventDuplicateReceiverNameOrderTimeoutDays,
        preventDuplicateOrders,
        preventDuplicateReceiverNameOrders,
        excludeSenderAddressInfo,
        parentTemplateTextId,
        requireParentPreviousOrders,
        teaserText,
        uppercaseAllAddressCharacters,
        redirectUrl: rUrl ? rUrl : null,
        industryId: industryId ? parseInt(industryId) : null,
        categoryId: categoryId ? parseInt(categoryId) : null,
        isPublic: Boolean(isPublic),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function verifyTemplate(t, props) {
  let {
    name,
    startDelimiter,
    endDelimiter,
    cardSize,
    fontType,
    templateUpper,
    template,
    parameters,
    orderTimeoutDays,
    preventDuplicateOrderTimeoutDays,
    preventDuplicateReceiverNameOrderTimeoutDays,
    preventDuplicateOrders,
    preventDuplicateReceiverNameOrders,
    excludeSenderAddressInfo,
    parentTemplateTextId,
    requireParentPreviousOrders,
    teaserText,
    uppercaseAllAddressCharacters,
    redirectUrl,
    industryId,
    categoryId,
    isPublic,
  } = t;

  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template/verify`,
    {
      method: "Post",
      body: JSON.stringify({
        name,
        startDelimiter,
        endDelimiter,
        cardSize,
        fontType,
        templateUpper,
        template,
        parameters,
        orderTimeoutDays,
        preventDuplicateOrderTimeoutDays,
        preventDuplicateReceiverNameOrderTimeoutDays,
        preventDuplicateOrders,
        preventDuplicateReceiverNameOrders,
        excludeSenderAddressInfo,
        parentTemplateTextId,
        requireParentPreviousOrders,
        teaserText,
        uppercaseAllAddressCharacters,
        redirectUrl,
        industryId: industryId ? parseInt(industryId) : null,
        categoryId: categoryId ? parseInt(categoryId) : null,
        isPublic: Boolean(isPublic),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllStatistics(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/statistics`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllChildrenStatistics(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/children-statistics`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllChildren(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/all-children`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllExtras(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/extras`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    let balance = data.data.balance;
    let extras = _.cloneDeep(data.data);
    delete extras.balance;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(
      setUsersData(userKey, { accountDetails: { balance }, extras })
    );

    return Promise.resolve(data);
  });
}

export async function getAllTemplates(
  {
    limit,
    offset,
    order,
    query,
    userId,
    isPublic,
    returnPreviewPaths,
    industryId,
    categoryId,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        userId: userId ? parseInt(userId) : null,
        isPublic: typeof isPublic === "boolean" ? isPublic : null,
        returnPreviewPaths:
          typeof returnPreviewPaths === "boolean" ? returnPreviewPaths : null,
        industryId: industryId ? parseInt(industryId) : null,
        categoryId: categoryId ? parseInt(categoryId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getBalance(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/balance`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    let balance = data.data.balance;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));
    return Promise.resolve(data);
  });
}

export async function getBalanceStatistics(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/balance-statistics`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    let balance = data.data.balance;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));
    return Promise.resolve(data);
  });
}

export async function redeemPromotionalCode({ code }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/redeem-promotional-code`,
    {
      method: "Post",
      body: JSON.stringify({
        code,
      }),
    },
    props
  ).then((data) => {
    let balance = data.data.balance;
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { accountDetails: { balance } }));

    return Promise.resolve(data);
  });
}

export async function generatePromotionalCode({ amount, message }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/generate-promotional-code`,
    {
      method: "Post",
      body: JSON.stringify({
        amount,
        message,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function deleteTemplateById(templateId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template/delete`,
    {
      method: "Post",
      body: JSON.stringify({
        templateId: parseInt(templateId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function duplicateTemplateById({ templateId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template/duplicate`,
    {
      method: "Post",
      body: JSON.stringify({
        templateId: parseInt(templateId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getTemplateById(templateId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/template`,
    {
      method: "Post",
      body: JSON.stringify({
        templateId: parseInt(templateId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getUser(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/profile`,
    {},
    props
  ).then((data) => {
    let currentState = store.getState();
    let userKey = getCurrentUserKey(currentState);
    store.dispatch(setUsersData(userKey, { user: data.data.user }));

    return Promise.resolve(data);
  });
}

export async function getAllApiTokens({ limit, offset, order }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/get-all-api-tokens`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
      }),
    },
    props
  )
    .then((data) => {
      // let tokens = data.data.tokens

      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function generateApiToken(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/generate-api-token`,
    {
      method: "post",
      body: JSON.stringify({
        description: props.description,
      }),
    },
    props
  )
    .then((data) => {
      // let user = data.data.user
      // let apiToken = data.data.apiToken.value

      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function revokeLoginToken(tokenId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/revoke-login-token`,
    {
      method: "post",
      body: JSON.stringify({
        tokenId,
      }),
    },
    props
  )
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}
export async function revokeRefreshToken(tokenId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/revoke-refresh-token`,
    {
      method: "post",
      body: JSON.stringify({
        tokenId,
      }),
    },
    props
  )
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}
export async function revokeApiToken(tokenId, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/revoke-api-token`,
    {
      method: "post",
      body: JSON.stringify({
        tokenId,
      }),
    },
    props
  )
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function getAccessToken(props) {
  if (!props) {
    props = {};
  }

  let opts = getOptions(null, null, {
    ...props,
    forcePrimaryUser: true,
  });

  return fetch(`${host}/api/${apiVersion}/user/auth/get-access-token`, {
    ...opts,
    method: "get",
  })
    .then(responseHandler)
    .then((data) => {
      let user = data.data.user;
      let accessToken = data.data.accessToken.value;
      let accessTokenExpirationMinutes =
        data.data.accessToken.expirationMinutes;

      store.dispatch(
        setUsersData("primary", {
          user,
          accessToken: {
            value: accessToken,
          },
          accessTokenDetails: {
            expirationMinutes: accessTokenExpirationMinutes,
          },
        })
      );

      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function getUserShareEmptyPermissionObject(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share/empty-permission-object`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllActableUsers(
  { limit, offset, order, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share/search-actable`,
    {
      method: "Post",
      body: JSON.stringify({
        limit: parseInt(limit),
        offset: parseInt(offset),
        order,
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllUserShares(
  { limit, offset, order, query, mode },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share/all`,
    {
      method: "Post",
      body: JSON.stringify({
        limit: parseInt(limit),
        offset: parseInt(offset),
        order,
        query: query ? `${query}` : null,
        mode: parseInt(mode),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllAvailableFundAccounts(
  { limit, offset, order, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/finance/fund-account/all`,
    {
      method: "Post",
      body: JSON.stringify({
        limit: parseInt(limit),
        offset: parseInt(offset),
        order,
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewUserShare(
  {
    userId,
    email,
    defaultFirstName,
    defaultLastName,
    permissionObject,
    share_credits,
    share_credits_rolling_amount,
    share_credits_rolling_window_days,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share/new`,
    {
      method: "Post",
      body: JSON.stringify({
        userId: userId ? parseInt(userId) : null,
        email: email ? `${email}` : null,
        defaultFirstName: defaultFirstName ? `${defaultFirstName}` : null,
        defaultLastName: defaultLastName ? `${defaultLastName}` : null,
        permissionObject,
        share_credits: share_credits ? Boolean(share_credits) : null,
        share_credits_rolling_amount: share_credits_rolling_amount
          ? parseFloat(share_credits_rolling_amount)
          : null,
        share_credits_rolling_window_days: share_credits_rolling_window_days
          ? parseInt(share_credits_rolling_window_days)
          : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function editUserShareById(
  {
    userShareId,
    permissionObject,
    share_credits,
    share_credits_rolling_amount,
    share_credits_rolling_window_days,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        userShareId: parseInt(userShareId),
        permissionObject,
        share_credits: share_credits ? Boolean(share_credits) : null,
        share_credits_rolling_amount: share_credits_rolling_amount
          ? parseFloat(share_credits_rolling_amount)
          : null,
        share_credits_rolling_window_days: share_credits_rolling_window_days
          ? parseInt(share_credits_rolling_window_days)
          : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getUserShareById({ userShareId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share`,
    {
      method: "Post",
      body: JSON.stringify({
        userShareId: parseInt(userShareId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function deleteUserShareById({ userShareId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/share/delete`,
    {
      method: "Post",
      body: JSON.stringify({
        userShareId,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function setUserParent({ childUserId, parentUserId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/set-user-parent`,
    {
      method: "Post",
      body: JSON.stringify({
        childUserId: parseInt(childUserId),
        parentUserId: parentUserId ? parseInt(parentUserId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function setUserRole({ userId, role }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/set-user-role`,
    {
      method: "Post",
      body: JSON.stringify({
        userId: parseInt(userId),
        role: role ? `${role}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function setUserDiscount({ userId, discount }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/set-user-discount`,
    {
      method: "Post",
      body: JSON.stringify({
        userId: parseInt(userId),
        discount: parseInt(discount),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function giveCreditsToUser({ userId, amount }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/give-credits-to-user`,
    {
      method: "Post",
      body: JSON.stringify({
        userId: parseInt(userId),
        amount: `${amount}`,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function setUserWaitlistStatus({ userId, isOnWaitlist }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/set-waitlist-status`,
    {
      method: "Post",
      body: JSON.stringify({
        userId: parseInt(userId),
        isOnWaitlist: Boolean(isOnWaitlist),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getDemoUser(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/get-demo-user`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    // demoUser = data.data.user
    return Promise.resolve(data);
  });
}

export async function getActorAccessToken_plain({ userId }, props) {
  if (!props) {
    props = {};
  }

  let opts = getOptions(null, null, {
    ...props,
    forcePrimaryUser: false,
  });

  return fetch(`${host}/api/${apiVersion}/user/auth/get-actor-access-token`, {
    ...opts,
    method: "post",
    body: JSON.stringify({
      userId,
    }),
  })
    .then(responseHandler)
    .then((data) => {
      let user = data.data.user;
      let accessToken = data.data.accessToken.value;
      let accessTokenExpirationMinutes =
        data.data.accessToken.expirationMinutes;

      store.dispatch(
        setUsersData(`${user.id}`, {
          user,
          accessToken: {
            value: accessToken,
          },
          accessTokenDetails: {
            expirationMinutes: accessTokenExpirationMinutes,
          },
        })
      );

      return Promise.resolve(data);
    })
    .catch(catchHandler);
}
export async function getActorAccessToken({ userId }, props) {
  let currentState = store.getState();
  let isPrimaryUser = getIsPrimaryUser(currentState);
  let activeUser = getActiveUser(currentState);
  if (!userId && !isPrimaryUser) {
    userId = activeUser.id;
  }
  if (!userId) {
    throw new Error("No user id was provided");
  }
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/get-actor-access-token`,
    {
      method: "post",
      body: JSON.stringify({
        userId,
      }),
    },
    props
  )
    .then((data) => {
      // user data and actor access token for a different user (not ourself). Used for accessing another account / impersonating
      let user = data.data.user;
      let accessToken = data.data.accessToken.value;
      let accessTokenExpirationMinutes =
        data.data.accessToken.expirationMinutes;

      // set the data in the store
      store.dispatch(
        setUsersData(`${user.id}`, {
          user,
          accessToken: {
            value: accessToken,
          },
          accessTokenDetails: {
            expirationMinutes: accessTokenExpirationMinutes,
          },
        })
      );

      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function ping(props) {
  if (!props) {
    props = {};
  }
  let opts = getOptions(null, null, props);

  return fetch(`${host}/api/${apiVersion}/ping`, {
    method: "get",
    ...opts,
  })
    .then(responseHandler)
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function signUp(
  {
    firstName,
    lastName,
    businessName,
    email,
    primaryAddress,
    businessPhoneNumber,
    primaryCRM,
    averageAnnualRevenue,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/sign-up`,
    {
      method: "Post",
      body: JSON.stringify({
        firstName,
        lastName,
        businessName: businessName ? `${businessName}` : null,
        email,
        primaryAddress: primaryAddress ? `${primaryAddress}` : null,
        businessPhoneNumber: businessPhoneNumber
          ? `${businessPhoneNumber}`
          : null,
        primaryCRM: primaryCRM ? `${primaryCRM}` : null,
        averageAnnualRevenue: averageAnnualRevenue
          ? `${averageAnnualRevenue}`
          : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function requestLoginToken({ email }, props) {
  if (!props) {
    props = {};
  }
  let opts = getOptions(null, null, props);

  return fetch(`${host}/api/${apiVersion}/user/auth/request-login-token`, {
    method: "post",
    ...opts,
    body: JSON.stringify({
      email,
    }),
  })
    .then(responseHandler)
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function loginWithToken(
  { token, otp, returnRefreshToken },
  props
) {
  if (!props) {
    props = {};
  }
  let opts = getOptions(null, null, props);
  let combinedOptions = _.merge(opts, {
    method: "post",
    body: JSON.stringify({
      token,
      otp,
      returnRefreshToken: Boolean(returnRefreshToken),
    }),
  });

  return fetch(
    `${host}/api/${apiVersion}/user/auth/login-with-token`,
    combinedOptions
  )
    .then(responseHandler)
    .then((data) => {
      let user = data.data.user;
      let accessToken = data.data.accessToken.value;
      let accessTokenExpirationMinutes =
        data.data.accessToken.expirationMinutes;
      let refreshTokenExpirationMinutes =
        data.data.refreshToken.expirationMinutes;

      let payload = {
        user,
        accessToken: {
          value: accessToken,
        },
        accessTokenDetails: {
          expirationMinutes: accessTokenExpirationMinutes,
        },
        refreshTokenDetails: {
          expirationMinutes: refreshTokenExpirationMinutes,
        },
      };

      store.dispatch(setUsersData("primary", payload));

      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function logout(props) {
  if (!props) {
    props = {};
  }
  let opts = getOptions(null, null, props);

  let currentState = store.getState();
  let isPrimaryUser = getIsPrimaryUser(currentState);
  let currentUserKey = getCurrentUserKey(currentState);
  let userKeyList = getAllUserKeys(currentState);
  if (props.forcePrimaryUser || isPrimaryUser) {
    socket.disconnect();
    // logout everything
    store.dispatch(resetCurrentUserKey());
    userKeyList.forEach((userKey) => {
      store.dispatch(resetUsersData(userKey));
    });

    return fetch(`${host}/api/${apiVersion}/user/auth/logout`, {
      method: "get",
      ...opts,
    })
      .then(responseHandler)
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch(catchHandler);
  } else {
    // only logout the current user
    socket.disconnect();
    store.dispatch(resetCurrentUserKey());
    store.dispatch(resetUsersData(currentUserKey));
    return Promise.resolve();
  }
}

export async function revokeAllLoginTokens(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/revoke-all-login-tokens`,
    {
      method: "get",
    },
    props
  )
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function revokeAllRefreshTokens(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/revoke-all-refresh-tokens`,
    {
      method: "get",
    },
    props
  )
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function revokeAllApiTokens(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/auth/revoke-all-api-tokens`,
    {
      method: "get",
    },
    props
  )
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch(catchHandler);
}

export async function getMapCollectionById({ collectionId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection`,
    {
      method: "Post",
      body: JSON.stringify({
        collectionId: parseInt(collectionId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function duplicateMapCollectionById({ collectionId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/duplicate`,
    {
      method: "Post",
      body: JSON.stringify({
        collectionId: parseInt(collectionId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function deleteMapCollectionById({ collectionId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/delete`,
    {
      method: "Post",
      body: JSON.stringify({
        collectionId: parseInt(collectionId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewMapCollection({ name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/new`,
    {
      method: "Post",
      body: JSON.stringify({
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function editMapCollectionById({ collectionId, name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        collectionId: parseInt(collectionId),
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMapCollections(
  { limit, offset, order, query, isDisabled },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        isDisabled: typeof isDisabled === "boolean" ? isDisabled : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getUserNotificationById({ notificationId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/notification`,
    {
      method: "Post",
      body: JSON.stringify({
        notificationId: parseInt(notificationId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function editUserNotificationById(
  { notificationId, isRead },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/notification/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        notificationId: parseInt(notificationId),
        isRead: Boolean(isRead),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllUserNotifications(
  { limit, offset, order, query, isRead },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/user/notification/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        isRead:
          isRead === null || isRead === undefined ? null : Boolean(isRead),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getMapCollectionMarkerById(
  { markerId, markerIdentifier },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker`,
    {
      method: "Post",
      body: JSON.stringify({
        markerId: parseInt(markerId),
        markerIdentifier: markerIdentifier ? `${markerIdentifier}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getServerRequestById({ requestId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/server-request`,
    {
      method: "Post",
      body: JSON.stringify({
        requestId: parseInt(requestId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllServerRequests(
  { limit, offset, order, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/server-request/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getQrCodeById({ qrCodeId, qrCodeUUID }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/qr_code`,
    {
      method: "Post",
      body: JSON.stringify({
        qrCodeId: qrCodeId ? parseInt(qrCodeId) : null,
        qrCodeUUID: qrCodeUUID ? `${qrCodeUUID}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function getQrCodeScanById({ scanId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/qr_code_scan`,
    {
      method: "Post",
      body: JSON.stringify({
        scanId: parseInt(scanId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function getQrCodeById_internal({ qrCodeId, qrCodeUUID }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/qr-code`,
    {
      method: "Post",
      body: JSON.stringify({
        qrCodeId: qrCodeId ? parseInt(qrCodeId) : null,
        qrCodeUUID: qrCodeUUID ? `${qrCodeUUID}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllQrCodes_internal(
  { limit, offset, order, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/qr-code/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function submitHandWrittenNoteOrderAction_internal(
  { orderId, actionType, actionMessage },
  props
) {
  const actionTypes = {
    replace: "REPLACE",
    cancel: "CANCEL",
  };

  let actionTypeValue = null;

  if (
    actionType === "replace" ||
    actionType === 0 ||
    actionType === "0" ||
    actionType === actionTypes.replace
  ) {
    actionTypeValue = actionTypes.replace;
  } else if (
    actionType === "cancel" ||
    actionType === 1 ||
    actionType === "1" ||
    actionType === actionTypes.cancel
  ) {
    actionTypeValue = actionTypes.cancel;
  }

  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/internal/hand-written-note-order/action`,
    {
      method: "Post",
      body: JSON.stringify({
        orderId: parseInt(orderId),
        actionType: `${actionTypeValue}`,
        actionMessage: actionMessage ? `${actionMessage}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function deleteMapCollectionMarkerById(
  { markerId, markerIdentifier },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker/delete`,
    {
      method: "Post",
      body: JSON.stringify({
        markerId: markerId ? parseInt(markerId) : null,
        markerIdentifier: markerIdentifier ? `${markerIdentifier}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewMapCollectionMarker(
  { collectionId, markerIdentifier, coordinates },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker/new`,
    {
      method: "Post",
      body: JSON.stringify({
        collectionId: parseInt(collectionId),
        markerIdentifier: markerIdentifier ? `${markerIdentifier}` : null,
        coordinates: coordinates.map((coord) => {
          let isArray = Array.isArray(coord);
          let lat = isArray ? coord[0] : coord.lat || coord.latitude;
          let lng = isArray
            ? coord[1]
            : coord.lng || coord.long || coord.longitude;

          return [lat, lng];
        }),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function editMapCollectionMarkerById(
  { markerId, markerIdentifier, coordinates },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        markerId: parseInt(markerId) || null,
        markerIdentifier: markerIdentifier ? `${markerIdentifier}` : null,
        coordinates: coordinates.map((coord) => {
          let isArray = Array.isArray(coord);
          let lat = isArray ? coord[0] : coord.lat || coord.latitude;
          let lng = isArray
            ? coord[1]
            : coord.lng || coord.long || coord.longitude;

          return [lat, lng];
        }),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMapCollectionMarkers(
  { limit, collectionId, offset, order, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        collectionId: parseInt(collectionId),
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function responseError_bad_route() {
  let opts = getOptions();

  return fetch(
    `${host}/api/${apiVersion}/response_error/a_fake_route_that_doesnt_exist`,
    {
      method: "get",
      ...opts,
    }
  )
    .then(responseHandler)
    .then((data) => {
      return Promise.resolve(data);
    });
}

export async function responseError() {
  let opts = getOptions();

  return fetch(`${host}/api/${apiVersion}/response_error`, {
    method: "get",
    ...opts,
  })
    .then(responseHandler)
    .then((data) => {
      return Promise.resolve(data);
    });
}

export async function responseSuccess() {
  let opts = getOptions();

  return fetch(`${host}/api/${apiVersion}/response_success`, {
    method: "get",
    ...opts,
  })
    .then(responseHandler)
    .then((data) => {
      return Promise.resolve(data);
    });
}

export async function getAllQrCodes(
  { order, limit, offset, query, orderId, templateId, mapCollectionId },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/qr_code/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        orderId: orderId ? parseInt(orderId) : null,
        templateId: templateId ? parseInt(templateId) : null,
        mapCollectionId: mapCollectionId ? parseInt(mapCollectionId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllQrCodeScans(
  {
    order,
    limit,
    offset,
    query,
    orderId,
    templateId,
    qrCodeId,
    mapCollectionId,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/qr_code_scan/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        orderId: orderId ? parseInt(orderId) : null,
        templateId: templateId ? parseInt(templateId) : null,
        qrCodeId: qrCodeId ? parseInt(qrCodeId) : null,
        mapCollectionId: mapCollectionId ? parseInt(mapCollectionId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

// INDUSTRY ENDPOINTS ------------------------------------------------------------
export async function getIndustryById({ industryId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/industry`,
    {
      method: "Post",
      body: JSON.stringify({
        industryId: parseInt(industryId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function deleteIndustryById({ industryId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/industry/delete`,
    {
      method: "Post",
      body: JSON.stringify({
        industryId: parseInt(industryId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewIndustry({ name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/industry/new`,
    {
      method: "Post",
      body: JSON.stringify({
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function editIndustryById({ industryId, name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/industry/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        industryId: parseInt(industryId),
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllIndustries(
  { limit, offset, order, query, hasTemplates },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/industry/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        hasTemplates: Boolean(hasTemplates),
        hasTemplates_isPublic: Boolean(hasTemplates),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

// CATEGORY ENDPOINTS ------------------------------------------------------------
export async function getCategoryById({ categoryId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/category`,
    {
      method: "Post",
      body: JSON.stringify({
        categoryId: parseInt(categoryId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function deleteCategoryById({ categoryId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/category/delete`,
    {
      method: "Post",
      body: JSON.stringify({
        categoryId: parseInt(categoryId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewCategory({ name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/category/new`,
    {
      method: "Post",
      body: JSON.stringify({
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function editCategoryById({ categoryId, name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/category/edit`,
    {
      method: "Post",
      body: JSON.stringify({
        categoryId: parseInt(categoryId),
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllCategories(
  { limit, offset, order, query, hasTemplates },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/category/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        hasTemplates: Boolean(hasTemplates),
        hasTemplates_isPublic: Boolean(hasTemplates),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMessages(
  { messageGroupId, order, limit, offset, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        messageGroupId: messageGroupId ? parseInt(messageGroupId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewMessage(
  { messageGroupId, messageBody, fileId },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message/new`,
    {
      method: "Post",
      body: JSON.stringify({
        messageGroupId: parseInt(messageGroupId),
        messageBody,
        fileId: fileId ? parseInt(fileId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleMessage({ messageId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message`,
    {
      method: "Post",
      body: JSON.stringify({
        messageId: parseInt(messageId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMessageGroups(
  { order, limit, offset, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleMessageGroup({ messageGroupId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group`,
    {
      method: "Post",
      body: JSON.stringify({
        messageGroupId: parseInt(messageGroupId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMessageGroupParticipants(
  { messageGroupId, order, limit, offset, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group-participant/all`,
    {
      method: "Post",
      body: JSON.stringify({
        messageGroupId: parseInt(messageGroupId),
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function findOrCreatePrivateMessageGroup({ withUserId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group/find-or-create-private-message-group`,
    {
      method: "Post",
      body: JSON.stringify({
        withUserId: parseInt(withUserId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMessageGroupSeenBys(
  { messageGroupId, order, limit, offset, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group-seen-by/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
        messageGroupId: messageGroupId ? parseInt(messageGroupId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function updateMessageGroupSeenBy({ messageGroupId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group-seen-by/update`,
    {
      method: "Post",
      body: JSON.stringify({
        messageGroupId: parseInt(messageGroupId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleMessageGroupSeenBy(
  { messageGroupSeenById },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/message-group-seen-by`,
    {
      method: "Post",
      body: JSON.stringify({
        messageGroupSeenById: parseInt(messageGroupSeenById),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export function getSharableFileUrl({ fileUUID }) {
  return `${host}/api/${apiVersion}/file/${fileUUID}`;
}

export function getUserAvatarUrl({
  userId,
  useColorBackground,
  size,
  backgroundColorLevel,
  refreshCount,
}) {
  // get querystring for useColorBackground size backgroundColorLevel

  let queryString = "";
  if (useColorBackground) {
    queryString += `&useColorBackground=${useColorBackground}`;
  }
  if (size) {
    queryString += `&size=${size}`;
  }
  if (backgroundColorLevel) {
    queryString += `&backgroundColorLevel=${backgroundColorLevel}`;
  }
  if (refreshCount) {
    queryString += `&refreshCount=${refreshCount}`;
  }

  return `${host}/api/${apiVersion}/user/${userId}/avatar${
    queryString ? `?${queryString}` : ""
  }`;
}

export async function getUserAvatar({ userId }, props) {
  return fetch_retryForAccessToken(
    getUserAvatarUrl({ userId }),
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllFiles({ order, limit, offset, query }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/file/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function downloadSingleSharableFile({ uuid }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/file/${uuid}`,
    {
      method: "GET",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewFile({ files, isSharable }, props) {
  const formData = new FormData();
  formData.append("files", files[0]);
  formData.append("isSharable", isSharable ? "true" : "false");

  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/file/new`,
    {
      method: "Post",
      body: formData,
    },
    {
      ...props,
      includeContentType: false,
    }
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleFile({ fileId, includeSignedUrl }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/file`,
    {
      method: "Post",
      body: JSON.stringify({
        fileId: parseInt(fileId),
        includeSignedUrl: includeSignedUrl ? true : false,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllDataExports(
  { order, limit, offset, query },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/data-export/all`,
    {
      method: "Post",
      body: JSON.stringify({
        order,
        limit: parseInt(limit),
        offset: parseInt(offset),
        query: query ? `${query}` : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
export async function getSingleDataExport({ dataExportId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/data-export`,
    {
      method: "Post",
      body: JSON.stringify({
        dataExportId: parseInt(dataExportId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getDataExportTypes(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/data-export/types`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getDataExportRequestReturnTypes(props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/data-export/request-return-types`,
    {
      method: "Get",
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewDataExport(
  { targetId, exportType, returnType },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/data-export/new`,
    {
      method: "Post",
      body: JSON.stringify({
        targetId: parseInt(targetId),
        exportType: `${exportType}`,
        returnType: `${returnType}`,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllMapCollectionMarkerComments(
  {
    query,
    markerId,
    markerIdentifier,
    markerIdIn,
    markerIdNotIn,
    collectionId,
    collectionIdIn,
    collectionIdNotIn,
    limit,
    offset,
    order,
  },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker-comment/all`,
    {
      method: "Post",
      body: JSON.stringify({
        query: query ? `${query}` : null,
        markerId: markerId ? parseInt(markerId) : null,
        markerIdentifier: markerIdentifier ? `${markerIdentifier}` : null,
        markerIdIn: markerIdIn
          ? markerIdIn.map((item) => parseInt(item))
          : null,
        markerIdNotIn: markerIdNotIn
          ? markerIdNotIn.map((item) => parseInt(item))
          : null,
        collectionId: collectionId ? parseInt(collectionId) : null,
        collectionIdIn: collectionIdIn
          ? collectionIdIn.map((item) => parseInt(item))
          : null,
        collectionIdNotIn: collectionIdNotIn
          ? collectionIdNotIn.map((item) => parseInt(item))
          : null,
        limit: limit ? parseInt(limit) : null,
        offset: offset ? parseInt(offset) : null,
        order,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleMapCollectionMarkerComment(
  { commentId },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker-comment`,
    {
      method: "Post",
      body: JSON.stringify({
        commentId: parseInt(commentId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewMapCollectionMarkerComment(
  { markerId, markerIdentifier, commentBody, fileId },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/map-collection/marker-comment/new`,
    {
      method: "Post",
      body: JSON.stringify({
        markerId: markerId ? parseInt(markerId) : null,
        markerIdentifier: markerIdentifier ? markerIdentifier : null,
        commentBody,
        fileId: fileId ? parseInt(fileId) : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllContacts(
  { query, contactGroupId, limit, offset, order },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact/all`,
    {
      method: "Post",
      body: JSON.stringify({
        query: query ? `${query}` : null,
        contactGroupId: contactGroupId ? parseInt(contactGroupId) : null,
        limit: limit ? parseInt(limit) : null,
        offset: offset ? parseInt(offset) : null,
        order,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleContact({ contactId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact`,
    {
      method: "Post",
      body: JSON.stringify({
        contactId: parseInt(contactId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewContact(
  { contactGroupId, firstName, lastName, mobileNumber, email, address },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact/new`,
    {
      method: "Post",
      body: JSON.stringify({
        contactGroupId: contactGroupId ? parseInt(contactGroupId) : null,
        firstName,
        lastName,
        mobileNumber: mobileNumber ? `${mobileNumber}` : null,
        email: email ? `${email}` : null,
        address: address ? address : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function updateContact(
  { contactId, firstName, lastName, mobileNumber, email, address },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact/update`,
    {
      method: "Post",
      body: JSON.stringify({
        contactId: parseInt(contactId),
        firstName,
        lastName,
        mobileNumber: mobileNumber ? `${mobileNumber}` : null,
        email: email ? `${email}` : null,
        address: address ? address : null,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getAllContactGroups(
  { query, limit, offset, order },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact-group/all`,
    {
      method: "Post",
      body: JSON.stringify({
        query: query ? `${query}` : null,
        limit: limit ? parseInt(limit) : null,
        offset: offset ? parseInt(offset) : null,
        order,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function getSingleContactGroup({ contactGroupId }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact-group`,
    {
      method: "Post",
      body: JSON.stringify({
        contactGroupId: parseInt(contactGroupId),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function createNewContactGroup({ name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact-group/new`,
    {
      method: "Post",
      body: JSON.stringify({
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function updateContactGroup({ contactGroupId, name }, props) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact-group/update`,
    {
      method: "Post",
      body: JSON.stringify({
        contactGroupId: parseInt(contactGroupId),
        name,
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function addContactsToGroup(
  { contactGroupId, contactIds },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact-group/add-contacts`,
    {
      method: "Post",
      body: JSON.stringify({
        contactGroupId: parseInt(contactGroupId),
        contactIds: contactIds.map((contactId) => parseInt(contactId)),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}

export async function removeContactsFromGroup(
  { contactGroupId, contactIds },
  props
) {
  return fetch_retryForAccessToken(
    `${host}/api/${apiVersion}/contact-group/remove-contacts`,
    {
      method: "Post",
      body: JSON.stringify({
        contactGroupId: parseInt(contactGroupId),
        contactIds: contactIds.map((contactId) => parseInt(contactId)),
      }),
    },
    props
  ).then((data) => {
    return Promise.resolve(data);
  });
}
