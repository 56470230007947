import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import queryString from "query-string";
import { globalHistory } from "../../history";
import { NavLink } from "react-router-dom";
import { requestLoginToken, loginWithToken, getAllExtras } from "../../backend";

import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  TextField as MuiTextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { OnWaitlist } from "../../errors";
import { getRequestErrorMessage } from "../../helpers";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Spacer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

const Wrapper = styled(Card)`
  margin: ${(props) => props.theme.spacing(3)}px;
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const BigAvatar = styled(Avatar)`
  width: 55px;
  height: 55px;
  text-align: center;
`;

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    let { login_token, login_otp } = queryString.parse(
      globalHistory.location.search
    );

    this.state = {
      email: "",
      otp: "",
      emailSent: false,
      isFetching: false,
      isOnWaitlist: false,
      loginToken: login_token || null,
      loginOTP: login_otp || null,
      errorMessage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitOTP = this.handleSubmitOTP.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      errorMessage: null,
      isFetching: true,
    });

    requestLoginToken({ email: this.state.email })
      .then((data) => {
        this.setState({
          loginToken: data.data.loginToken.value,
          isFetching: false,
          emailSent: true,
        });
      })
      .catch((error) => {
        if (error.isServerResponse) {
          let isOnWaitlist = false;

          if (error.data.error instanceof OnWaitlist) {
            isOnWaitlist = true;
          }

          this.setState({
            isFetching: false,
            isOnWaitlist: isOnWaitlist,
            errorMessage: error.data.message,
          });
        } else {
          this.setState({
            isFetching: false,
            isOnWaitlist: false,
            errorMessage:
              error.message ??
              "Something went wrong while attempting to log you in with a token",
          });
        }
      });
  }

  handleSubmitOTP(e) {
    e.preventDefault();
    this.setState(
      {
        isFetching: true,
        errorMessage: null,
        loginOTP: this.state.otp,
      },
      () => {
        this.handleLogin();
      }
    );
  }

  handleLogin() {
    globalHistory.replace({
      pathname: globalHistory.location.pathname,
      search: "",
    });

    let { loginToken, loginOTP } = this.state;
    loginWithToken({ token: loginToken, otp: loginOTP })
      .then(async (data) => {
        await getAllExtras();
        globalHistory.push("/");
      })
      .catch((error) => {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage:
            "Something went wrong while attempting to log you in",
        });
        this.setState({
          loginToken: null,
          loginOTP: null,
          otp: "",
          isFetching: false,
          errorMessage: message,
        });
      });
  }

  componentDidMount() {
    if (this.state.loginToken && this.state.loginOTP) {
      this.handleLogin();
    }
  }

  render() {
    return (
      <>
        <Helmet title="Sign In" />

        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Wrapper>
              <CardHeader
                title={
                  <React.Fragment>
                    <Typography variant="h2" gutterBottom>
                      Welcome Back!
                      <br />
                      <Typography variant="subtitle1">
                        Sign in below to continue
                      </Typography>
                    </Typography>
                  </React.Fragment>
                }
                subheader={
                  this.state.loginToken ? (
                    <Typography variant="body2" color="textSecondary">
                      Enter the code we sent over
                    </Typography>
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Don't have an account?{" "}
                      <Link component={NavLink} to="/auth/sign-up">
                        Sign up
                      </Link>
                    </Typography>
                  )
                }
              />
              <CardContent>
                {this.state.loginToken ? (
                  <>
                    <form onSubmit={this.handleSubmitOTP}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={4}
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <Alert severity="success">
                            <strong>One Time Password</strong> - We just sent a
                            code to you, please check your email
                          </Alert>
                        </Grid>
                      </Grid>
                      <Spacer />
                      {this.state.errorMessage && (
                        <React.Fragment>
                          <Alert mt={2} mb={1} severity="warning">
                            {this.state.errorMessage}
                          </Alert>
                          <Spacer />
                        </React.Fragment>
                      )}
                      <TextField
                        variant="outlined"
                        disabled={this.state.isFetching}
                        required={true}
                        type="text"
                        name="otp"
                        label="OTP Code"
                        value={this.state.otp}
                        // error={Boolean(touched.email && errors.email)}
                        // helperText={touched.email && errors.email}
                        // onBlur={handleBlur}
                        fullWidth
                        onChange={(e) => {
                          this.setState({ otp: e.target.value });
                        }}
                        my={2}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={this.state.isFetching || !this.state.otp}
                      >
                        {this.state.isFetching ? (
                          <React.Fragment>
                            <CircularProgress size={20} />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>Continue</React.Fragment>
                        )}
                      </Button>
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={this.handleSubmit}>
                      {this.state.isOnWaitlist ? (
                        <React.Fragment>
                          <Alert severity="info">
                            <strong>You're on the waitlist</strong> - We're glad
                            to know your excited to get in - we'll let you know
                            when you're off the waitlist!
                          </Alert>
                          <Spacer />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {this.state.errorMessage && (
                            <React.Fragment>
                              <Alert mt={2} mb={1} severity="warning">
                                {this.state.errorMessage}
                              </Alert>
                              <Spacer />
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      <TextField
                        variant="outlined"
                        required={true}
                        type="email"
                        name="email"
                        label="Email Address"
                        value={this.state.email}
                        // error={Boolean(touched.email && errors.email)}
                        // helperText={touched.email && errors.email}
                        // onBlur={handleBlur}
                        fullWidth
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        my={2}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={this.state.isFetching}
                      >
                        {this.state.isFetching ? (
                          <React.Fragment>
                            <CircularProgress size={20} />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>Continue with email</React.Fragment>
                        )}
                      </Button>
                    </form>
                  </>
                )}
              </CardContent>
            </Wrapper>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default SignIn;
