import {
  RESET_USER_FALLBACK_RETURN_ADDRESS_INFO,
  SET_USER_FALLBACK_RETURN_ADDRESS_INFO,
} from "../../actions/types";
import { users_userFallbackReturnAddressInfo as initialState } from "../../initialState";

export default function userFallbackReturnAddressInfoReducer(
  state = initialState,
  action
) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_USER_FALLBACK_RETURN_ADDRESS_INFO:
      return initialState;
    case SET_USER_FALLBACK_RETURN_ADDRESS_INFO:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
