import { Chip, Avatar as MuiAvatar, Box, IconButton } from "@material-ui/core";
import styled from "styled-components";
import MoneyChip from "../pages/components/MoneyChip";
import TransactionTypeChip from "../pages/components/TransactionTypeChip";
import { globalHistory } from "../history";
import ClickableTableCell from "../components/ClickableTableCell";
import { RemoveRedEye } from "@material-ui/icons";

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const handleTableCellClick = (row) => {
  globalHistory.push({
    pathname: `/account/transaction/${row.id}`,
    state: {
      transactionId: row.id,
    },
  });
};

const transactionRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Transaction #",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "name",
    alignment: "left",
    label: "Name",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return (
        <Customer>
          <Avatar>{`${rowItem}`.slice(0, 1).toUpperCase()}</Avatar>
          <Box ml={3}>{rowItem}</Box>
        </Customer>
      );
    },
  },
  {
    id: "transaction_type",
    alignment: "left",
    label: "Transaction Type",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return <TransactionTypeChip transactionType={rowItem} />;
    },
  },
  {
    id: "amount",
    alignment: "left",
    label: "Amount",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return (
        <MoneyChip amount={rowItem} transactionType={row.transaction_type} />
      );
    },
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        aria-label="details"
        onClick={(event) => handleTableCellClick(row)}
      >
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default transactionRowColumns;
