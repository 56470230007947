import { RESET_USER_DATA, SET_USER_DATA } from "../../actions/types";
import { users_user as initialState } from "../../initialState";

export default function userReducer(state = initialState, action) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_USER_DATA:
      return initialState;
    case SET_USER_DATA:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
