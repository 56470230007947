import React from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
  ButtonBase,
  Card,
  CardHeader,
  CardContent,
  TextField,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  createNewContact,
  createNewContactGroup,
  getAllContacts,
  getAllDataExports,
  getAllHandWrittenNoteOrders,
  getAllPurchaseOrders,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";

import { Alert as MuiAlert } from "@material-ui/lab";
import OrderStatusChip from "../components/OrderStatusChip";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import { globalHistory } from "../../history";
import ClickableTableCell from "../../components/ClickableTableCell";
import AdvancedTable from "../tables/AdvancedTable";
import handWrittenNoteOrderRowColumns from "../../advancedTableConfigs/handWrittenNoteOrderRowColumns";
import dataExportRowColumns from "../../advancedTableConfigs/dataExportRowColumns";
import contactRowColumns from "../../advancedTableConfigs/contactRowColumns";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const blankAddressInfo = {
  name: {
    key: "name",
    value: "",
    label: "Name",
    required: true,
  },
  businessName: {
    key: "businessName",
    value: "",
    label: "Business Name",
    required: false,
  },
  addressLineOne: {
    key: "addressLineOne",
    value: "",
    label: "Address Line One",
    required: true,
  },
  addressLineTwo: {
    key: "addressLineTwo",
    value: "",
    label: "Address Line Two",
    required: false,
  },
  city: {
    key: "city",
    value: "",
    label: "City",
    required: true,
  },
  state: {
    key: "state",
    value: "",
    label: "State",
    required: true,
  },
  zipCode: {
    key: "zipCode",
    value: "",
    label: "Zip Code",
    required: true,
  },
  country: {
    key: "country",
    value: "US",
    label: "Country",
    required: false,
  },
};

function NewContactGroup() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [groupName, setGroupName] = React.useState("");

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    let details = {
      name: groupName,
    };

    let handleFetch = async () => {
      setIsLoading(true);
      setErrorMessage(null);

      try {
        let response = await createNewContactGroup(details);
        globalHistory.push({
          pathname: `/contact/group/${response.data.contactGroup.id}`,
          state: {
            contactGroupId: response.data.contactGroup.id,
          },
        });
      } catch (e) {
        setIsLoading(false);
        setErrorMessage(
          getRequestErrorMessage({
            error: e,
            fallbackMessage: "There was an error creating the contact group.",
          })
        );
      }
    };

    handleFetch();
  };

  return (
    <React.Fragment>
      <Helmet title="New Contact Group" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            New Contact Group
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>New Contact</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="New Contact Group"
              subheader="Create a descriptive name for your contact group."
            />
            <form onSubmit={handleSubmit}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Grid container spacing={6}>
                      {/* <Grid item xs={12}>
                        <Typography variant="h6">Basics</Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label={"Group Name"}
                              disabled={isLoading}
                              required={true}
                              value={groupName}
                              onChange={(event) => {
                                setGroupName(event.target.value);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default NewContactGroup;
