import React, { useState, useRef, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dateFormat from "dateformat";
import {
  Grid as MuiGrid,
  Typography,
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardActionArea,
  Divider,
} from "@material-ui/core";
import { getRequestErrorMessage, getUserNamePlus } from "../../helpers";
import { createNewFile, getAllActableUsers, getAllFiles } from "../../backend";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { File } from "react-feather";
import { blue, grey } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";

const Grid = styled(MuiGrid)(spacing);

const blankFilesResults = {
  files: [],
  fileCount: 0,
};

const FileDialog = (props) => {
  const { forcePrimaryUser } = props;

  const currentUserKey = useSelector(getCurrentUserKey);
  const textFieldProps = props.textFieldProps || {};
  let { selectedFile, setSelectedFile, open, setOpen } = props;
  if (!setSelectedFile) {
    [selectedFile, setSelectedFile] = useState(null);
  }

  if (!setOpen) {
    [open, setOpen] = useState(false);
  }

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(blankFilesResults);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, or, q, fpm }) => {
        setIsLoading(true);

        try {
          const response = await getAllFiles(
            {
              limit: l,
              offset: o,
              order: or,
              query: q,
            },
            {
              forcePrimaryUser: fpm,
            }
          );
          setSearchResults({
            files: response.data.files,
            fileCount: response.data.fileCount,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Something went wrong finding files.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (open) {
      setIsLoading(true);
      setSearchResults(blankFilesResults);
      setErrorMessage("");
      setSuccessMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
        fpm: forcePrimaryUser,
      });
    }
  }, [searchQuery, limit, offset, order, orderBy, open]);

  useEffect(() => {
    if (open && currentUserKey) {
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
        fpm: forcePrimaryUser,
      });
    } else {
      setSelectedFile(null);
    }
  }, [currentUserKey, open]);

  const handleClickFile = (file) => {
    setSelectedFile(file);
  };

  const handleOpenFile = () => {
    if (!selectedFile) {
      return;
    }

    if (props.onOpenFile) {
      props.onOpenFile(selectedFile);
    }

    setOpen(false);
    setSelectedFile(null);
  };

  const handleUploadFile = (e) => {
    e.preventDefault();

    let file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.size > 5 * 1000 * 1000) {
      return setErrorMessage("Files cannot be larger than 5MB.");
    }

    const uploadFile = async () => {
      setIsUploadingFile(true);
      setErrorMessage("");
      setSuccessMessage("");

      try {
        let uploadedFile = await createNewFile(
          {
            files: e.target.files,
          },
          {
            forcePrimaryUser,
          }
        );

        setSelectedFile(uploadedFile.data.file);
        setSearchResults((prev) => {
          return {
            files: [uploadedFile.data.file, ...prev.files],
            fileCount: prev.fileCount + 1,
          };
        });
        setSuccessMessage("File uploaded successfully.");
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "Something went wrong uploading the file.",
          })
        );
      }

      setIsUploadingFile(false);
    };

    uploadFile();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="lg"
        fullWidth
      >
        <Grid container>
          <Grid item>
            <DialogTitle>
              Files{" "}
              <Typography display="inline">
                - Viewing {searchResults.files.length} of{" "}
                {searchResults.fileCount}
              </Typography>
            </DialogTitle>
          </Grid>
        </Grid>
        <div
          style={{
            padding: "8px 24px",
          }}
        >
          <Grid container spacing={2}>
            {errorMessage && (
              <Grid item xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}
            {successMessage && (
              <Grid item xs={12}>
                <Alert severity="success">{successMessage}</Alert>
              </Grid>
            )}
          </Grid>
        </div>
        <Divider />
        <DialogContent
          style={{
            minHeight: "50vh",
          }}
        >
          <Grid container spacing={4} mb={4}>
            {isLoading ? (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item>
                      <CircularProgress size={24} />
                    </Grid>
                    <Grid item>
                      <Typography>Loading Files... </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <input
                  style={{ display: "none" }}
                  id="file-upload-card"
                  type="file"
                  disabled={isLoading || isUploadingFile}
                  onChange={handleUploadFile}
                />

                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <label
                    htmlFor="file-upload-card"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Card
                      fullWidth
                      variant="outlined"
                      style={{
                        borderStyle: "dashed",
                        height: "100%",
                        minHeight: 125,
                        cursor: "pointer",
                      }}
                    >
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {isUploadingFile ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              color: grey[600],
                            }}
                            align="center"
                          >
                            Upload new file
                          </Typography>
                        )}
                      </Grid>
                    </Card>
                  </label>
                </Grid>
                {searchResults.files.length < 1 ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: "100%",
                      }}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item>
                          <Typography>No files were found. </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {searchResults.files.map((file) => {
                      let maxFileTypeLength = 8;
                      let fileType = file.file_key.split(".").pop();
                      if (fileType.length > maxFileTypeLength) {
                        fileType = `${fileType.slice(
                          0,
                          maxFileTypeLength - 3
                        )}...`;
                      }
                      const isSelected =
                        selectedFile && selectedFile.id === file.id;

                      const activeColor = blue[600];

                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                          key={file.id}
                        >
                          <Card
                            variant="outlined"
                            style={{
                              borderColor: isSelected ? activeColor : undefined,
                            }}
                          >
                            <CardActionArea
                              onClick={() => {
                                handleClickFile(file);
                              }}
                              disabled={isUploadingFile}
                            >
                              <CardContent>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      height: 125,
                                      position: "relative",
                                    }}
                                  >
                                    <Grid
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                      }}
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <div
                                        style={{
                                          color: isSelected
                                            ? activeColor
                                            : grey[300],
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="100"
                                          height="100"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth="0.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          class="feather feather-file"
                                        >
                                          <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                          <polyline points="13 2 13 9 20 9"></polyline>
                                        </svg>
                                      </div>
                                    </Grid>

                                    <Grid
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                      }}
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Typography
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "1rem",
                                          color: isSelected
                                            ? activeColor
                                            : grey[300],
                                        }}
                                      >
                                        {fileType}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Divider
                                    style={{
                                      backgroundColor: isSelected
                                        ? activeColor
                                        : undefined,
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              <CardContent>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      noWrap
                                      style={{
                                        color: isSelected
                                          ? activeColor
                                          : undefined,
                                      }}
                                    >
                                      {file.file_name}.{fileType}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography
                                      style={{
                                        color: isSelected
                                          ? activeColor
                                          : undefined,
                                      }}
                                    >
                                      {dateFormat(
                                        new Date(file.createdAt),
                                        "dddd, yyyy h:MM TT"
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginLeft: -8,
                  marginRight: -8,
                  marginBottom: 4,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    disabled={isLoading}
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenFile}
                    disabled={!selectedFile || isLoading}
                  >
                    Open
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileDialog;
