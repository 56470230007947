import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import AutocompleteMui from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { getRequestErrorMessage } from "../helpers";
import { getAllTemplates } from "../backend";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../redux/selectors";

const blankResults = {
  templates: [],
  template_count: 0,
};

const Autocomplete = styled(AutocompleteMui)(spacing);

export default function TemplateTextAutoComplete(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let [selectedTemplate, setSelectedTemplate] = useState(null);

  if (
    props.selectedTemplate !== undefined &&
    props.setSelectedTemplate !== undefined
  ) {
    selectedTemplate = props.selectedTemplate;
    setSelectedTemplate = props.setSelectedTemplate;
  }

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(blankResults);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  useEffect(() => {
    setSelectedTemplate(null);
    setSearchResults(blankResults);
  }, [currentUserKey]);

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, or, q }) => {
        setIsLoading(true);

        try {
          const response = await getAllTemplates({
            limit: l,
            offset: o,
            order: or,
            query: q,
            isPublic: false,
            userId: currentUserKey,
          });

          setSearchResults({
            templates: response.data.templates,
            template_count: response.data.template_count,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Unable to search.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      setSearchResults(blankResults);
      setErrorMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
      });
    } else {
      setSearchResults(blankResults);
    }
  }, [searchQuery, limit, offset, order, orderBy]);

  React.useEffect(() => {
    fetchData.current({
      l: limit,
      o: offset,
      or: [orderBy, order],
      q: searchQuery,
    });
  }, [currentUserKey]);

  const parsedOptions = searchResults.templates.map((template) => template);

  if (
    selectedTemplate &&
    !parsedOptions.some((o) => o.id === selectedTemplate.id)
  ) {
    parsedOptions.push(selectedTemplate);
  }

  return (
    <Autocomplete
      {...(props.autoCompleteProps || {})}
      id="template-search"
      loading={isLoading}
      loadingText="Loading..."
      noOptionsText={errorMessage || "No results."}
      showCloseButton={false}
      fullWidth
      options={parsedOptions}
      getOptionSelected={(option, value) =>
        option && selectedTemplate ? option.id === selectedTemplate.id : false
      }
      getOptionLabel={(option) => `#${option.id} - ${option.name}`}
      onChange={(e, newValue) => {
        if (newValue) {
          setSelectedTemplate(newValue);
        } else {
          setSelectedTemplate(null);
        }
      }}
      value={selectedTemplate}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            label={"Template Name"}
            required
            error={errorMessage ? true : false}
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        );
      }}
    />
  );
}
