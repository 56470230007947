import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Introduction() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Templates
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        Templates are at the core of Radial Link and are the building blocks of
        your marketing plan. In short, they allow you to create specific
        messages that you can send directly to your customers. Templates are
        optimized to be used for multiple sales representatives without having
        to create a new one for each rep.
      </Typography>
    </Box>
  );
}

function TableOfContents() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Table of Contents
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        <ul>
          <li>
            <Link component={NavLink} exact to="/documentation/getting-started">
              Getting Started
            </Link>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
function SomethingMissing() {
  return (
    <Box mb={10}>
      <Typography variant="h3" gutterBottom>
        Something missing?
      </Typography>
      <Typography variant="subtitle1" gutterBottom my={4}>
        If something is missing in the documentation or if you found some part
        confusing, please send us an email (
        <Link href="mailto:support@radial-link.net">
          support@radial-link.net
        </Link>
        ) with your suggestions for improvement. We love hearing from you!
      </Typography>
    </Box>
  );
}

function Templates() {
  return (
    <React.Fragment>
      <Helmet title="Templates" />

      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12} lg={9} xl={7}>
          <Typography variant="h2" gutterBottom display="inline">
            Documentation
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Typography>Documentation</Typography>
          </Breadcrumbs>

          <Divider my={6} />

          <Introduction />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Templates;
