import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";

import { CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

import { BigNumber } from "../../money";

import { StarBorder as StarIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getCheckoutSession,
  cancelSubscription,
  getPlans,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { stripe, load } from "../../stripe";
import { useSelector } from "react-redux";
import useCardSizes from "../../hooks/useCardSizes";
import { getCurrentUserKey } from "../../redux/selectors";

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

const PlanItem = (props) => {
  const {
    title,
    subheader,
    description,
    action,
    price,
    interval,
    interval_unit,
    mostExpensiveOrderPrice,
    leastExpensiveOrderPrice,
  } = props;

  let newPrice = new BigNumber(price);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card p={5}>
        <CardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{ align: "center" }}
          pb={0}
          pt={2}
        />
        <CardContent>
          <Price>
            <Typography
              component="h2"
              variant="h1"
              color="textPrimary"
              align="center"
              display="inline"
            >
              {newPrice.toFormat({
                prefix: "$",
                decimalSeparator: ".",
                groupSeparator: ",",
                groupSize: 3,
                secondaryGroupSize: 0,
                fractionGroupSeparator: " ",
                fractionGroupSize: 0,
                suffix: "",
              })}
            </Typography>
            <br />

            {interval > 1 ? (
              <Typography
                variant="h6"
                color="textSecondary"
                align="center"
                display="inline"
              >
                / Every {interval} {interval_unit}s
              </Typography>
            ) : (
              <Typography
                variant="h5"
                color="textSecondary"
                align="center"
                display="inline"
              >
                Every {interval_unit}
              </Typography>
            )}
          </Price>
          <Typography variant="subtitle1" align="center">
            {description || interval > 1 ? (
              <React.Fragment>
                Complete Service Access
                <br /> From{" "}
                {newPrice
                  .dividedBy(new BigNumber(mostExpensiveOrderPrice))
                  .toFixed(0, 1)}{" "}
                to{" "}
                {newPrice
                  .dividedBy(new BigNumber(leastExpensiveOrderPrice))
                  .toFixed(0, 1)}{" "}
                orders every {interval} {interval_unit}s
              </React.Fragment>
            ) : (
              <React.Fragment>
                Complete Service Access
                <br /> From{" "}
                {newPrice
                  .dividedBy(new BigNumber(mostExpensiveOrderPrice))
                  .toFixed(0, 1)}{" "}
                to{" "}
                {newPrice
                  .dividedBy(new BigNumber(leastExpensiveOrderPrice))
                  .toFixed(0, 1)}{" "}
                order every {interval_unit}
              </React.Fragment>
            )}
          </Typography>
        </CardContent>
        <CardActions>{action}</CardActions>
      </Card>
    </Grid>
  );
};

function Plans() {
  const currentUserKey = useSelector(getCurrentUserKey);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [plans, setPlans] = React.useState([]);
  const [product, setProduct] = React.useState({});

  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [isRedirectLoading, setIsRedirectLoading] = React.useState(false);
  const [redirectErrorMessage, setRedirectErrorMessage] = React.useState("");
  const [
    isCancelSubscriptionLoading,
    setIsCancelSubscriptionLoading,
  ] = React.useState(false);
  const [
    cancelSubscriptionErrorMessage,
    setCancelSubscriptionErrorMessage,
  ] = React.useState("");

  const cardSizes = useCardSizes();

  let mostExpensiveCard = _.maxBy(
    cardSizes,
    (o) => new BigNumber(o.highestPrice).toNumber
  );

  let mostExpensiveOrderPrice = new BigNumber(
    mostExpensiveCard ? mostExpensiveCard.highestPrice || 8 : 8
  );

  let leastExpensiveCard = _.maxBy(
    cardSizes,
    (o) => new BigNumber(o.lowestPrice).toNumber
  );

  let leastExpensiveOrderPrice = new BigNumber(
    leastExpensiveCard ? leastExpensiveCard.lowestPrice || 4.5 : 4.5
  );

  React.useEffect(() => {
    let fetchData = async () => {
      setIsLoading(true);

      try {
        let results = await getPlans({});
        setPlans(results.data.plans);
        setProduct(results.data.product);
      } catch (e) {
        setErrorMessage(
          getRequestErrorMessage({
            error: e,
            fallbackMessage: "Something went wrong getting the available plans",
          })
        );
      }

      setIsLoading(false);
    };

    fetchData();
  }, [currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Plans" />
      <Typography variant="h3" gutterBottom display="inline">
        Plans
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Plans</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {errorMessage || !product ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage ??
              "Something went wrong getting the available plans."}
          </Alert>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header>
            <Typography variant="h3" gutterBottom align="center">
              We have a marketing budget for everyone
            </Typography>

            <Typography variant="subtitle1" gutterBottom align="center">
              Whether you're a big or small business, we have something that
              will work for you.
            </Typography>
          </Header>

          {redirectErrorMessage && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="warning">
                {redirectErrorMessage ||
                  "Something went wrong redirecting you to the checkout page."}
              </Alert>
            </React.Fragment>
          )}

          {cancelSubscriptionErrorMessage && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="warning">
                {cancelSubscriptionErrorMessage ||
                  "Something went wrong canceling your subscription."}
              </Alert>
            </React.Fragment>
          )}

          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={6} alignItems="flex-end">
                {plans.map((plan, index) => {
                  return (
                    <React.Fragment key={index}>
                      <PlanItem
                        title={product.name}
                        // subheader={"Most popular"}
                        price={new BigNumber(plan.unit_amount).dividedBy(100)}
                        interval={plan.recurring.interval_count}
                        interval_unit={plan.recurring.interval}
                        mostExpensiveOrderPrice={mostExpensiveOrderPrice}
                        leastExpensiveOrderPrice={leastExpensiveOrderPrice}
                        action={
                          plan.isUserSubscribed ? (
                            <React.Fragment>
                              <Button
                                fullWidth
                                variant="outlined"
                                color="primary"
                                onClick={async () => {
                                  setSelectedPlan(plan);
                                  setIsCancelSubscriptionLoading(true);
                                  setRedirectErrorMessage("");
                                  setCancelSubscriptionErrorMessage("");

                                  try {
                                    await cancelSubscription({
                                      stripe_price_id: plan.id,
                                    });

                                    setPlans(
                                      plans.map((o) => {
                                        if (o.id === plan.id) {
                                          o.isUserSubscribed = false;
                                        }

                                        return o;
                                      })
                                    );
                                  } catch (e) {
                                    setCancelSubscriptionErrorMessage(
                                      getRequestErrorMessage({
                                        error: e,
                                        fallbackMessage:
                                          "Something went wrong canceling your subscription",
                                      })
                                    );
                                  }

                                  setSelectedPlan(null);
                                  setIsCancelSubscriptionLoading(false);
                                }}
                                disabled={
                                  isCancelSubscriptionLoading ||
                                  isRedirectLoading
                                }
                              >
                                {isCancelSubscriptionLoading &&
                                selectedPlan &&
                                plan.id === selectedPlan.id ? (
                                  <CircularProgress size={25} />
                                ) : (
                                  "Cancel"
                                )}
                              </Button>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={async () => {
                                  setSelectedPlan(plan);
                                  setIsRedirectLoading(true);
                                  setRedirectErrorMessage("");
                                  setCancelSubscriptionErrorMessage("");

                                  try {
                                    let sessionResults = await getCheckoutSession(
                                      {
                                        stripe_price_id: plan.id,
                                      }
                                    );
                                    let session = sessionResults.data.session;
                                    if (!stripe) await load();
                                    let results = await stripe.redirectToCheckout(
                                      {
                                        sessionId: session.id,
                                      }
                                    );
                                  } catch (e) {
                                    setSelectedPlan(null);
                                    setIsRedirectLoading(false);
                                    setRedirectErrorMessage(
                                      getRequestErrorMessage({
                                        error: e,
                                        fallbackMessage:
                                          "Something went wrong redirecting you to the checkout page",
                                      })
                                    );
                                  }
                                }}
                                disabled={
                                  isCancelSubscriptionLoading ||
                                  isRedirectLoading
                                }
                              >
                                {isRedirectLoading &&
                                selectedPlan &&
                                plan.id === selectedPlan.id ? (
                                  <CircularProgress size={25} />
                                ) : (
                                  "Select"
                                )}
                              </Button>
                            </React.Fragment>
                          )
                        }
                      />
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Plans;
