import React from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getAllContacts,
  getAllDataExports,
  getAllHandWrittenNoteOrders,
  getAllPurchaseOrders,
  getSingleContact,
  getSingleContactGroup,
  updateContact,
  updateContactGroup,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";

import { Alert as MuiAlert } from "@material-ui/lab";
import OrderStatusChip from "../components/OrderStatusChip";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import { globalHistory } from "../../history";
import ClickableTableCell from "../../components/ClickableTableCell";
import AdvancedTable from "../tables/AdvancedTable";
import handWrittenNoteOrderRowColumns from "../../advancedTableConfigs/handWrittenNoteOrderRowColumns";
import dataExportRowColumns from "../../advancedTableConfigs/dataExportRowColumns";
import contactRowColumns from "../../advancedTableConfigs/contactRowColumns";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const blankAddressInfo = {
  name: {
    key: "name",
    endpointKey: "name",
    value: "",
    label: "Name",
    required: true,
  },
  businessName: {
    key: "businessName",
    endpointKey: "business_name",
    value: "",
    label: "Business Name",
    required: false,
  },
  addressLineOne: {
    key: "addressLineOne",
    endpointKey: "address_line_one",
    value: "",
    label: "Address Line One",
    required: true,
  },
  addressLineTwo: {
    key: "addressLineTwo",
    endpointKey: "address_line_two",
    value: "",
    label: "Address Line Two",
    required: false,
  },
  city: {
    key: "city",
    endpointKey: "city",
    value: "",
    label: "City",
    required: true,
  },
  state: {
    key: "state",
    endpointKey: "state",
    value: "",
    label: "State",
    required: true,
  },
  zipCode: {
    key: "zipCode",
    endpointKey: "zip_code",
    value: "",
    label: "Zip Code",
    required: true,
  },
  country: {
    key: "country",
    endpointKey: "country",
    value: "US",
    label: "Country",
    required: false,
  },
};

function ContactGroupDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let contactGroupId =
    _.get(props, "location.state.contactGroupId", null) ||
    _.get(props, "match.params.contactGroupId", null);
  if (contactGroupId) {
    contactGroupId = parseInt(contactGroupId);
  }

  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [contactGroupData, setContactGroupData] = React.useState(null);

  const [groupName, setGroupName] = React.useState("");

  const handleSetAddressInfo = (n) => {
    let prev = _.cloneDeep(addressInfo);
    let merged = _.merge(prev, n);
    setAddressInfo(merged);
  };

  const [isLoading_save, setIsLoading_save] = React.useState(false);
  const [errorMessage_save, setErrorMessage_save] = React.useState(null);
  const [successMessage_save, setSuccessMessage_save] = React.useState(null);

  const handleSave = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading_save(true);
    setErrorMessage_save(null);
    setSuccessMessage_save(null);

    const details = {
      contactGroupId,
      name: groupName,
    };

    try {
      const response = await updateContactGroup(details);

      setSuccessMessage_save("Group updated successfully");
    } catch (error) {
      setErrorMessage_save(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Failed to update contact",
        })
      );
    }

    setIsLoading_save(false);
  };

  const loadData = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    setSuccessMessage_save(null);
    setErrorMessage_save(null);

    try {
      const response = await getSingleContactGroup({
        contactGroupId,
      });

      setContactGroupData(response.data.contactGroup);
      setGroupName(response.data.contactGroup.name);
    } catch (error) {
      setErrorMessage(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Failed to load contact group details",
        })
      );
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, [contactGroupId, currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Contact Group Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Contact Group #{contactGroupId}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>Contact Group Details</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              Contact Details
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      {errorMessage ? (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      ) : !contactGroupId ? (
        <Alert mt={2} mb={1} severity="warning">
          The contactGroupId is invalid
        </Alert>
      ) : (
        <React.Fragment>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Contact Group Details"
                  subheader="View and edit details about your contact group below"
                />

                <form onSubmit={handleSave}>
                  <CardContent>
                    <Grid container spacing={6}>
                      {successMessage_save && (
                        <Grid item xs={12}>
                          <Alert severity="success">
                            {successMessage_save}
                          </Alert>
                        </Grid>
                      )}

                      {errorMessage_save && (
                        <Grid item xs={12}>
                          <Alert severity="error">{errorMessage_save}</Alert>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={6}>
                          <Grid item xs={12}>
                            <Grid container spacing={6}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  label={"Group Name"}
                                  disabled={isLoading}
                                  required={true}
                                  value={groupName}
                                  onChange={(event) => {
                                    setGroupName(event.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ContactGroupDetails;
