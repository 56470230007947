import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../vendor/roundedBarCharts";
import _ from "lodash";
import jsQR from "jsqr";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader as MuiCardHeader,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  TextField as MuiTextField,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
} from "react-feather";
import {
  getAllStatistics,
  getCheckoutSessionOneTimePayment,
  redeemPromotionalCode,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { stripe, load } from "../../stripe";
import { globalHistory } from "../../history";

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

function QrCodeScanner() {
  const webcamRef = React.useRef(null);
  const [deviceId, setDeviceId] = React.useState("");
  const [devices, setDevices] = React.useState([]);
  const [qrCodeLocation, setQrCodeLocation] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      let validDevices = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      setDevices(validDevices);
      setDeviceId(validDevices[0]?.deviceId);
    },
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.getUserMedia;
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const handleQrCode = (qrCode) => {
    // calculate qr code location

    let topLeft = qrCode.location.topLeftCorner;
    let topRight = qrCode.location.topRightCorner;
    let bottomLeft = qrCode.location.bottomLeftCorner;
    let bottomRight = qrCode.location.bottomRightCorner;

    let rotation =
      (Math.atan2(topRight.x - topLeft.x, topRight.y - topLeft.y) * 180) /
      Math.PI;

    if (rotation < 0.0) rotation += 360.0;

    let midX = (topLeft.x + bottomRight.x) / 2;
    let midY = (topLeft.y + bottomRight.y) / 2;

    let center = {
      x: midX,
      y: midY,
    };

    // largest side of the qr code
    let diagLength = Math.hypot(
      bottomRight.x - topLeft.x,
      bottomRight.y - topLeft.y
    );

    let totalHeight = diagLength * Math.sin(45);
    let totalWidth = diagLength * Math.sin(45);

    let offsetTop = webcamRef?.current?.video?.offsetTop || 0;
    let offsetLeft = webcamRef?.current?.video?.offsetLeft || 0;

    const qrCodeLocationDetails = {
      rotation,
      center,
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      pageCenter: {
        x: center.x + offsetLeft,
        y: center.y + offsetTop,
      },
      pageTopLeft: {
        x: topLeft.x + offsetLeft,
        y: topLeft.y + offsetTop,
      },
      pageTopRight: {
        x: topRight.x + offsetLeft,
        y: topRight.y + offsetTop,
      },
      pageBottomLeft: {
        x: bottomLeft.x + offsetLeft,
        y: bottomLeft.y + offsetTop,
      },
      pageBottomRight: {
        x: bottomRight.x + offsetLeft,
        y: bottomRight.y + offsetTop,
      },
      totalHeight,
      totalWidth,
    };

    setQrCodeLocation(qrCodeLocationDetails);

    // extract the last segment from the url
    const uuid = qrCode.data.split("/").pop();

    if (!uuid) {
      setErrorMessage("No identifier was found in the QR code");
      return;
    }

    if (uuid.length !== 32) {
      setErrorMessage("Found an invalid identifier in the QR code");
      return;
    }

    setErrorMessage(null);
    setSuccessMessage(`Found identifier: ${uuid}`);

    globalHistory.push({
      pathname: `/internal/qr-code/${uuid}`,
      state: {
        uuid: uuid,
      },
    });
  };

  const decodeQrCode = React.useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (!imageSrc) {
      // console.log("No image was found");
      return;
    }

    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      const imageData = context.getImageData(0, 0, image.width, image.height);

      const code = jsQR(imageData.data, image.width, image.height);

      if (code) {
        handleQrCode(code);
        // redirect to the page
      } else {
        setQrCodeLocation(null);
        // do nothing
      }
    };
  }, [webcamRef]);

  React.useEffect(() => {
    const intervalTiming = 50; // 20 scans per second
    const interval = setInterval(() => {
      // console.time("decodeQrCode");
      decodeQrCode();
      // console.timeEnd("decodeQrCode");
    }, intervalTiming);
    return () => clearInterval(interval);
  }, [decodeQrCode]);

  return (
    <React.Fragment>
      <Helmet title="Qr Code Scanner" />

      <Typography variant="h3" gutterBottom display="inline">
        Qr Code Scanner
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Qr Code Scanner</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Header>
        <Typography variant="h3" gutterBottom align="center">
          Qr Code Scanner
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          Scan a radial link qr code and be redirected to access special
          features.
        </Typography>
      </Header>

      {qrCodeLocation && (
        <>
          <div
            id="qr-code-position-outline"
            style={{
              position: "absolute",
              top: qrCodeLocation.pageCenter.y - qrCodeLocation.totalHeight / 2,
              left: qrCodeLocation.pageCenter.x - qrCodeLocation.totalWidth / 2,
              width: qrCodeLocation.totalWidth,
              height: qrCodeLocation.totalHeight,
              transform: `rotate(-${qrCodeLocation.rotation}deg)`,
              transformOrigin: "center",
              border: "3px solid #A2E4C9",
              color: "#A2E4C9",
              borderRadius: 5,
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </>
      )}

      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Select
            value={deviceId}
            variant="outlined"
            onChange={(e) => setDeviceId(e.target.value)}
            label="Select Camera"
            fullWidth
            inputProps={{
              style: {
                width: "100%",
                maxWidth: 700,
              },
            }}
            style={{
              maxWidth: 700,
              minWidth: 300,
              marginBottom: 16,
            }}
          >
            {devices.map(({ deviceId, label }) => (
              <MenuItem key={deviceId} value={deviceId}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {errorMessage && (
          <Grid item>
            <Alert mb={2} severity="warning">
              {errorMessage}
            </Alert>
          </Grid>
        )}
        {successMessage && (
          <Grid item>
            <Alert mb={2} severity="success">
              {successMessage}
            </Alert>
          </Grid>
        )}
        <Grid item>
          <Webcam
            ref={webcamRef}
            videoConstraints={{ deviceId, facingMode: "environment" }}
            style={{
              borderRadius: 10,
              // height: 500,
              width: "100%",
              minHeight: 300,
              maxWidth: 700,
              backgroundColor: "#0001",
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default QrCodeScanner;
