import { Badge, Fab, Grid, Paper, Typography } from "@material-ui/core";
import { Edit, Close, Chat } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { globalHistory } from "../../history";
import { useDeviceBreakpoint } from "../../hooks/useDeviceBreakpoint";
import ChatWindow from "../../pages/pages/ChatViews";
import { getIsLoggedIn } from "../../redux/selectors";

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export default function ChatPopup(props) {
  const { isExtraSmall } = useDeviceBreakpoint("lg");

  const isLoggedIn = useSelector(getIsLoggedIn);

  const [location, setLocation] = useState(globalHistory.location);

  const [unseenGroups, setUnseenGroups] = useState([]);

  React.useEffect(() => {
    return globalHistory.listen(setLocation);
  }, []);

  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);

  React.useEffect(() => {
    setIsChatVisible(shouldShowPopup);
  }, [shouldShowPopup]);

  // if the user is not logged in, return null
  if (!isLoggedIn) {
    return null;
  }

  // if the user is already at /chat, return null
  if (location?.pathname?.includes("/chat")) {
    if (shouldShowPopup) {
      setShouldShowPopup(false);
    }
    return null;
  }

  return (
    <>
      <div
        style={{
          position: "fixed",

          ...(isExtraSmall
            ? {
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
              }
            : {
                top: 16 + 64,
                bottom: 16,
                right: 16,
                left: 16,
              }),
          ...(shouldShowPopup && isExtraSmall
            ? {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                pointerEvents: "auto",
              }
            : {
                backgroundColor: "transparent",
                pointerEvents: "none",
              }),
          zIndex: 50,
        }}
      >
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
          style={{
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            ...(isExtraSmall
              ? {
                  margin: 0,

                  paddingTop: 56 + 1,
                }
              : {}),
          }}
        >
          <Grid
            item
            style={{
              ...(shouldShowPopup ? {} : { display: "none" }),
              ...(isExtraSmall
                ? {
                    height: "100%",
                    width: "100%",
                    // padding: 0,
                  }
                : { height: 500, width: 350 }),

              maxWidth: "100%",
              maxHeight: "100%",
              pointerEvents: "auto",
            }}
          >
            <Paper
              style={{
                height: "100%",
                width: "100%",
                padding: 0,
                border: "1px solid #e0e0e0",
              }}
            >
              <ChatWindow
                useSplitView={false}
                setUnseenGroups={setUnseenGroups}
                unseenGroups={unseenGroups}
                isChatVisible={isChatVisible}
                setIsChatVisible={setIsChatVisible}
              />
            </Paper>
          </Grid>

          <Grid
            item
            style={{
              pointerEvents: "auto",

              ...(isExtraSmall ? { margin: 6 } : {}),
            }}
          >
            <Indicator>
              <Fab
                onClick={() => {
                  setShouldShowPopup(!shouldShowPopup);
                }}
                color="primary"
                variant="extended"
                style={{
                  boxShadow: `rgb(44 44 44 / 10%) 0px 1px 16px 12px`,
                }}
              >
                <Typography
                  style={{
                    marginRight: 8,
                  }}
                >
                  {unseenGroups.length > 0
                    ? `${unseenGroups.length} Unseen Group${
                        unseenGroups.length > 1 ? "s" : ""
                      }`
                    : "Chat"}
                </Typography>
                {shouldShowPopup ? <Close /> : <Chat />}
              </Fab>
            </Indicator>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
