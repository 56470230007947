import React from "react";
import styled from "styled-components/macro";

const getStrokeColor = (props) => props.theme.palette.primary.main;

const StyledPath = styled.path`
  stroke: ${getStrokeColor};
`;

const StyledCircle = styled.circle`
  stroke: ${getStrokeColor};
`;

export default ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  connectionLineType,
  connectionLineStyle,
}) => {
  let isTargetLeftOfSource = targetX < sourceX;
  let isTargetAboveSource = targetY < sourceY;
  let isTargetRightOfSource = targetX > sourceX;
  let isTargetBelowSource = targetY > sourceY;

  let spaceBetweenSourceAndTarget = Math.abs(targetX - sourceX);

  let spaceBetweenSourceAndTargetY = Math.abs(targetY - sourceY);

  let handleValue = spaceBetweenSourceAndTarget / 2;

  let firstValue = sourceX + handleValue;
  if (sourcePosition === "left") {
    firstValue = sourceX - handleValue;
  }
  if (isTargetLeftOfSource) {
    firstValue = sourceX - handleValue;
  }
  if (isTargetRightOfSource) {
    firstValue = sourceX + handleValue;
  }

  let secondValue = targetX - handleValue;
  if (sourcePosition === "left") {
    secondValue = targetX + handleValue;
  }
  if (isTargetLeftOfSource) {
    secondValue = targetX + handleValue;
  }
  if (isTargetRightOfSource) {
    secondValue = targetX - handleValue;
  }

  let handlePaths = `C${firstValue},${sourceY} ${secondValue},${targetY}`;

  return (
    <g>
      <StyledPath
        fill="none"
        strokeWidth={3}
        className="animated"
        d={`M ${sourceX},${sourceY}
        
              ${handlePaths}
        
            ${targetX},${targetY}`}
        //   MsourceX, sourceY CsourceX, targetY sourceX, targetY targetX, targetY
        //   M1657.5371161409248,1572.5377073622674 C1739.5205073814873,1572.5377073622674 1739.5205073814873,1359.5376983983374 1821.50389862205,1359.5376983983374
        //   M1236.7985235868305,1572.536952001316 C1293.6507304311822,1572.536952001316 1293.6507304311822,1359.5369586659738 1350.502937275534,1359.5369586659738
        //   M 282.037 1253.04 C 338.77 1253.04 338.77 614.038 395.504 614.038
      />
      <StyledCircle
        cx={targetX}
        cy={targetY}
        fill="#fff"
        r={4}
        strokeWidth={3}
      />
    </g>
  );
};
