import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  Link,
  TextField as MuiTextField,
  IconButton,
  Checkbox,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Box,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardHeader,
  Paper,
} from "@material-ui/core";

import { CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  CloudUpload as MuiCloudUpload,
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import { getRequestErrorMessage } from "../../helpers";
import {
  generateApiToken,
  getAllApiTokens,
  getUserFallbackReturnAddress,
  logout,
  revokeAllApiTokens,
  revokeAllLoginTokens,
  revokeAllRefreshTokens,
  userUpdateFallbackReturnAddress,
  userUpdateGeneral,
  socket,
  getUserAvatarUrl,
  createNewFile,
} from "../../backend";
import {
  getActiveUser,
  getCurrentUserKey,
  getIsPrimaryUser,
} from "../../redux/selectors";
import { currentUserKey } from "../../redux/initialState";
import { isDevelopment, isStaging, isProduction } from "../../config";
import AdvancedTable from "../tables/AdvancedTable";
import apiTokenRowColumns from "../../advancedTableConfigs/apiTokenRowColumns";
import { grey, red } from "@material-ui/core/colors";
import ConfirmDialog from "../components/ConfirmDialog";

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  ${spacing}
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

const apiTokenHeadCells = [
  {
    id: "id",
    alignment: "left",
    label: "Token #",
    allowSort: true,
  },
  {
    id: "description",
    alignment: "left",
    label: "Short Description",
    allowSort: true,
  },
  {
    id: "token",
    alignment: "left",
    label: "Token",
    allowSort: true,
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
  },
  { id: "actions", alignment: "right", label: "Actions", allowSort: false },
];

function EnhancedApiTokenTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {apiTokenHeadCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.allowSort ? (
              <>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </>
            ) : (
              <>
                <TableSortLabel active={orderBy === headCell.id} hideSortIcon>
                  {headCell.label}
                </TableSortLabel>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let EnhancedApiTokenTableToolbar = (props) => {
  const {
    isLoading,
    setIsLoading,
    setErrorMessage,
    rows,
    setRows,
    totalRows,
    setTotalRows,
  } = props;

  const { numSelected } = props;
  const [newApiTokenDescription, setNewApiTokenDescription] = useState("");
  const handleNewToken = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let newTokenResults = await generateApiToken({
        description: newApiTokenDescription,
      });
      setNewApiTokenDescription("");
      setRows([newTokenResults.data.apiToken, ...rows]);
      setTotalRows(totalRows + 1);
    } catch (error) {
      setErrorMessage(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong with the request",
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            API Tokens
          </Typography>
        )}
      </ToolbarTitle>
      {(isStaging || isDevelopment) && (
        <Grid container justifyContent="flex-end">
          <form onSubmit={handleNewToken}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <TextField
                  variant="outlined"
                  margin="dense"
                  label="Description"
                  value={newApiTokenDescription}
                  onChange={(e) => setNewApiTokenDescription(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
      {/* <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
    </Toolbar>
  );
};

function DangerZone(props) {
  const [dialogsOpen, setDialogsOpen] = useState({
    one: false,
    two: false,
  });

  const handleDialog = (dialogName, isOpen) => {
    setDialogsOpen({ ...dialogsOpen, [dialogName]: isOpen });
  };

  const isPrimaryUser = useSelector(getIsPrimaryUser);

  const logoutEverywhere_onConfirm = async (props) => {
    const submit = async () => {
      props.setIsLoading(true);
      try {
        socket.off("refresh-token-revoked");
        await revokeAllLoginTokens();
        let results = await revokeAllRefreshTokens();

        if (!isPrimaryUser) {
          socket.disconnect();
        }

        const revokeCount = results.data.revokeCount;

        props.setSuccessMessage(
          `Completely logged out of ${revokeCount} device(s)`
        );
      } catch (error) {
        props.setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "Something went wrong with the request",
          })
        );
      }
      props.setIsLoading(false);
    };

    if (props.successMessage) {
      props.setIsLoading(true);
      await logout();

      props.setIsDialogOpen(false);
      props.setIsLoading(false);
      props.setErrorMessage("");
      props.setSuccessMessage("");
    } else {
      await submit();
    }
  };

  const revokeAllApiTokens_onConfirm = async (props) => {
    const submit = async () => {
      props.setIsLoading(true);
      try {
        let results = await revokeAllApiTokens();
        let revokeCount = results.data.revokeCount;

        props.setSuccessMessage(
          `Revoked ${revokeCount} API token(s). Refresh API tables to see the changes`
        );
      } catch (error) {
        props.setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "Something went wrong with the request",
          })
        );
      }
      props.setIsLoading(false);
    };

    if (props.successMessage) {
      props.setIsDialogOpen(false);
      props.setIsLoading(false);
      props.setErrorMessage("");
      props.setSuccessMessage("");
    } else {
      await submit();
    }
  };

  return (
    <Card
      my={6}
      variant="outlined"
      style={{
        borderColor: red[500],
      }}
    >
      <CardHeader title="Danger Zone" />

      <CardContent>
        <Alert severity="warning">
          <Typography>
            <strong> These settings are not reversible</strong>
          </Typography>
        </Alert>
        <Spacer my={2} />
        <Alert severity="info">
          <Typography>
            <strong>
              Some actions can take up to 1 hour to affect certain devices and
              services
            </strong>
          </Typography>
        </Alert>
      </CardContent>
      <CardContent>
        <ConfirmDialog
          isDialogOpen={dialogsOpen.one}
          setIsDialogOpen={(isOpen) => handleDialog("one", isOpen)}
          onConfirm={logoutEverywhere_onConfirm}
          onClose={(props) => {
            if (props.successMessage) {
              return null;
            }

            props.setIsDialogOpen(false);
          }}
          getConfirmButtonText={(props) => {
            if (!isPrimaryUser && props.successMessage) {
              return "Finish";
            }

            if (props.successMessage) {
              return "Logout";
            }

            if (props.isLoading) {
              return (
                <CircularProgress
                  size={25}
                  style={{
                    ...(props.successMessage ? {} : { color: "white" }),
                  }}
                />
              );
            }

            return null;
          }}
          getCancelButtonProps={(props) => {
            if (props.successMessage) {
              return {
                disabled: true,
              };
            }

            return null;
          }}
          getConfirmButtonProps={(props) => {
            if (props.successMessage) {
              return {
                color: "primary",
              };
            }

            return {
              style: {
                backgroundColor: red[500],
                color: "white",
              },
            };
          }}
          title="Sign Out Everywhere"
        >
          <Typography>
            Are you sure you want to sign out everywhere? This will also force
            you to sign out of your current session.
          </Typography>
        </ConfirmDialog>
        <Grid container spacing={8}>
          <Grid item xs={12} container spacing={2} justifyContent="center">
            <Grid item xs>
              <Typography variant="subtitle1">
                <strong>Sign out everywhere</strong>
              </Typography>
              <Typography variant="body1">
                Sign out from all devices without affecting 3rd party services.{" "}
              </Typography>
              <Typography variant="caption">
                (Devices logged in from a User Share may be temporarily logged
                out, but will still have access to your account)
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  color: red[500],
                  borderColor: red[500],
                }}
                type="submit"
                onClick={() => handleDialog("one", true)}
              >
                Sign Out
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <ConfirmDialog
          isDialogOpen={dialogsOpen.two}
          setIsDialogOpen={(isOpen) => handleDialog("two", isOpen)}
          onConfirm={revokeAllApiTokens_onConfirm}
          onClose={(props) => {
            if (props.successMessage) {
              return null;
            }

            props.setIsDialogOpen(false);
          }}
          getCancelButtonProps={(props) => {
            if (props.successMessage) {
              return {
                disabled: true,
              };
            }

            return null;
          }}
          getConfirmButtonProps={(props) => {
            if (props.successMessage) {
              return {
                color: "primary",
              };
            }

            return {
              style: {
                backgroundColor: red[500],
                color: "white",
              },
            };
          }}
          getConfirmButtonText={(props) => {
            if (props.successMessage) {
              return "Finish";
            }

            if (props.isLoading) {
              return (
                <CircularProgress
                  size={25}
                  style={{
                    ...(props.successMessage ? {} : { color: "white" }),
                  }}
                />
              );
            }

            return null;
          }}
          title="Revoke All API Tokens"
        >
          <Typography>
            Are you sure you want to revoke access for all 3rd party services?
            This will render any services using your API tokens unusable and new
            ones will need to be generated.
          </Typography>
        </ConfirmDialog>
        <Grid container spacing={8}>
          <Grid item xs={12} container spacing={2} justifyContent="center">
            <Grid item xs>
              <Typography variant="subtitle1">
                <strong>Revoke All API Tokens</strong>
              </Typography>
              <Typography variant="body1">
                Revoke API Tokens directly affecting all 3rd party services.
              </Typography>
              <Typography variant="caption">
                (Any integrations, services, or integrations using an API token
                will immediately lose access)
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  color: red[500],
                  borderColor: red[500],
                }}
                type="submit"
                onClick={() => handleDialog("two", true)}
              >
                Revoke
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function ApiTokens() {
  const [newApiTokenDescription, setNewApiTokenDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [reloadCount, setReloadCount] = useState(0);

  const [newApiToken, setNewApiToken] = useState(null);
  const [hasCopied, setHasCopied] = useState(false);

  React.useEffect(() => {
    let timeout = null;
    if (hasCopied) {
      timeout = setTimeout(() => {
        setHasCopied(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopied]);

  const handleNewToken = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let results = await generateApiToken({
        description: newApiTokenDescription,
      });

      setNewApiToken(results.data.apiToken);

      setNewApiTokenDescription("");
      setReloadCount(reloadCount + 1);
    } catch (error) {
      setErrorMessage(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong with the request",
        })
      );
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Card mb={2}>
        <CardHeader title="Create API Token" />
        <CardContent>
          <Dialog
            open={!!newApiToken}
            onClose={() => setNewApiToken(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">API Token Created</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      style={{
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Your new API Token has been created. Make sure to remember
                      it, you'll only be able to view it once.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          Token
                        </Typography>
                      </Grid>
                      <Grid item>
                        <CopyToClipboard
                          text={newApiToken?.token}
                          onCopy={() => {
                            setHasCopied(true);
                          }}
                        >
                          <Tooltip
                            title={hasCopied ? "Copied" : "Copy to clipboard"}
                            placement="top"
                          >
                            <Chip label={"copy"} size="small" />
                          </Tooltip>
                        </CopyToClipboard>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      style={{
                        backgroundColor: grey[200],
                      }}
                    >
                      <CardContent>
                        <Typography
                          variant="body1"
                          style={{
                            wordWrap: "break-word",
                          }}
                        >
                          {newApiToken?.token}
                        </Typography>
                      </CardContent>
                    </Paper>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setNewApiToken(null)}
                color="primary"
                variant="outlined"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {errorMessage && (
            <Alert severity="error" mb={4}>
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleNewToken}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Description"
                  value={newApiTokenDescription}
                  onChange={(e) => setNewApiTokenDescription(e.target.value)}
                  required
                  fullWidth
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Generate Token"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <AdvancedTable
        title="API Tokens"
        rowColumns={apiTokenRowColumns}
        identifier={reloadCount}
        getRowData={async ({ limit, offset, order }) => {
          const results = await getAllApiTokens({ limit, offset, order });

          let parsedRowData = {
            rows: results.data.tokens,
            totalRowCount: results.data.token_count,
          };

          return parsedRowData;
        }}
      />
    </div>
  );
}

const allowedProfileFileTypes = ["jpg", "jpeg", "png", "gif"];

function ProfilePhoto() {
  const user = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const [refreshCount, setRefreshCount] = React.useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    const updateProfile = async () => {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      try {
        // update profile
        let uploadedFile = await createNewFile({
          files: e.target.files,
          isSharable: true,
        });

        let updatedSettings = await userUpdateGeneral({
          profilePhotoFileId: uploadedFile.data.file.id,
        });

        setRefreshCount(refreshCount + 1);
        setSuccessMessage("Profile photo updated successfully");
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong saving your profile picture",
          })
        );
      }
      setIsLoading(false);
    };

    updateProfile();
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile Photo
        </Typography>

        {errorMessage && (
          <Alert mt={2} mb={4} severity="warning">
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert mt={2} mb={4} severity="success">
            {successMessage}
          </Alert>
        )}

        <Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            direction="column"
          >
            <Grid item>
              <Avatar
                style={{
                  width: 200,
                  height: 200,
                  maxWidth: "100%",
                }}
                src={getUserAvatarUrl({
                  userId: user.id,
                  size: 200,
                  refreshCount,
                })}
              />
            </Grid>
            <Grid item>
              <input
                accept={allowedProfileFileTypes.map((ft) => `.${ft}`).join(",")}
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                disabled={isLoading}
                onChange={handleSubmit}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  mt={3}
                  disabled={isLoading}
                  component="span"
                  // fullWidth
                >
                  {isLoading ? <CircularProgress size={25} /> : "Upload"}
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
function GeneralInfo() {
  const user = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);
  const [firstName, setFirstName] = React.useState(user.firstName || "");
  const [lastName, setLastName] = React.useState(user.lastName || "");
  const [email, setEmail] = React.useState(user.email || "");
  const [businessName, setBusinessName] = React.useState(
    user.businessName || ""
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  useEffect(() => {
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setEmail(user.email || "");
    setBusinessName(user.businessName || "");
  }, [currentUserKey]);

  const getChanges = () => {
    const changes = {
      firstName,
      lastName,
      businessName,
    };
    return changes;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const changes = getChanges();

    const saveChanges = async () => {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      try {
        await userUpdateGeneral(changes);
        setSuccessMessage("Changes saved successfully");
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong saving your settings",
          })
        );
      }
      setIsLoading(false);
    };

    saveChanges();
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          General Info
        </Typography>

        {errorMessage && (
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert mt={2} mb={1} severity="success">
            {successMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="first_name"
                label="First name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                my={2}
                disabled={isLoading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="last-name"
                label="Last name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                my={2}
                disabled={isLoading}
                required={true}
              />
            </Grid>
          </Grid>

          <TextField
            id="email"
            label="Email"
            disabled={true || isLoading}
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            my={2}
          />

          <TextField
            type="text"
            id="business_name"
            label="Business Name"
            variant="outlined"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            fullWidth
            my={2}
            disabled={isLoading}
          />

          <Button
            variant="contained"
            color="primary"
            mt={3}
            type="submit"
            disabled={isLoading}
            // fullWidth
          >
            {isLoading ? <CircularProgress size={25} /> : "Save changes"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function FallbackSenderAddress() {
  const user = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);

  // TODO: we need to fetch the current account fallback sender address

  const [senderName, setSenderName] = React.useState("");
  const [senderBusinessName, setSenderBusinessName] = React.useState("");
  const [senderAddressLineOne, setSenderAddressLineOne] = React.useState("");
  const [senderAddressLineTwo, setSenderAddressLineTwo] = React.useState("");
  const [senderCity, setSenderCity] = React.useState("");
  const [senderState, setSenderState] = React.useState("");
  const [senderZipCode, setSenderZipCode] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [refetch, setRefetch] = React.useState(true);

  React.useEffect(() => {
    setRefetch(true);
  }, [currentUserKey]);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setErrorMessage("");
      try {
        const response = await getUserFallbackReturnAddress();
        if (response.data.fallbackReturnAddress) {
          setSenderName(response.data.fallbackReturnAddress.name || "");
          setSenderBusinessName(
            response.data.fallbackReturnAddress.business_name || ""
          );
          setSenderAddressLineOne(
            response.data.fallbackReturnAddress.address_line_one || ""
          );
          setSenderAddressLineTwo(
            response.data.fallbackReturnAddress.address_line_two || ""
          );
          setSenderCity(response.data.fallbackReturnAddress.city || "");
          setSenderState(response.data.fallbackReturnAddress.state || "");
          setSenderZipCode(response.data.fallbackReturnAddress.zip_code || "");
        } else {
          setSenderName("");
          setSenderBusinessName("");
          setSenderAddressLineOne("");
          setSenderAddressLineTwo("");
          setSenderCity("");
          setSenderState("");
          setSenderZipCode("");
        }
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage:
              "Something went wrong fetching your fallback return address info",
          })
        );
      }
      setIsLoading(false);
      setRefetch(false);
    };

    fetchData();
  }, [refetch]);

  const getChanges = () => {
    const changes = {
      senderName: `${senderName}`,
      senderBusinessName: `${senderBusinessName}`,
      senderAddressLineOne: `${senderAddressLineOne}`,
      senderAddressLineTwo: `${senderAddressLineTwo}`,
      senderCity: `${senderCity}`,
      senderState: `${senderState}`,
      senderZipCode: `${senderZipCode}`,
    };
    return changes;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const changes = getChanges();

    const saveChanges = async () => {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      try {
        // await userUpdateGeneral(changes);
        await userUpdateFallbackReturnAddress(changes);
        await setRefetch(true);
        setSuccessMessage("Changes saved successfully");
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Something went wrong saving your settings",
          })
        );
      }
      setIsLoading(false);
    };

    saveChanges();
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Fallback Address
        </Typography>

        {errorMessage && (
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert mt={2} mb={1} severity="success">
            {successMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="sender_name"
                label="Sender Name"
                variant="outlined"
                value={senderName}
                onChange={(e) => setSenderName(e.target.value)}
                fullWidth
                my={2}
                disabled={isLoading}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                id="sender_business_name"
                label="Business Name"
                variant="outlined"
                value={senderBusinessName}
                onChange={(e) => setSenderBusinessName(e.target.value)}
                fullWidth
                my={2}
                disabled={isLoading}
                required={false}
              />
            </Grid>
          </Grid>

          <TextField
            type="text"
            id="sender_address_line_one"
            label="Address Line One"
            disabled={isLoading}
            variant="outlined"
            value={senderAddressLineOne}
            onChange={(e) => setSenderAddressLineOne(e.target.value)}
            fullWidth
            my={2}
            required={true}
          />

          <TextField
            type="text"
            id="sender_address_line_two"
            label="Address Line Two"
            disabled={isLoading}
            variant="outlined"
            value={senderAddressLineTwo}
            onChange={(e) => setSenderAddressLineTwo(e.target.value)}
            fullWidth
            my={2}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                id="sender_city"
                label="City"
                disabled={isLoading}
                variant="outlined"
                value={senderCity}
                onChange={(e) => setSenderCity(e.target.value)}
                fullWidth
                my={2}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                id="sender_state"
                label="State"
                disabled={isLoading}
                variant="outlined"
                value={senderState}
                onChange={(e) => setSenderState(e.target.value)}
                fullWidth
                my={2}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                type="text"
                id="sender_zip_code"
                label="Zip Code"
                disabled={isLoading}
                variant="outlined"
                value={senderZipCode}
                onChange={(e) => setSenderZipCode(e.target.value)}
                fullWidth
                my={2}
                required={true}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            mt={3}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={25} /> : "Save changes"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function AccountSettings() {
  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Settings
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Settings</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
              <Grid
                style={{
                  height: "100%",
                }}
                container
                alignItems="stretch"
              >
                <ProfilePhoto />
              </Grid>
            </Grid>
            <Grid item xs={12} sm>
              <Grid
                style={{
                  height: "100%",
                }}
                container
                alignItems="stretch"
              >
                <GeneralInfo />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FallbackSenderAddress />
          <ApiTokens />
          <DangerZone />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AccountSettings;
