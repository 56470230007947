import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "../../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ReactFlow, {
  Background,
  isNode,
  Controls,
  removeElements,
  addEdge,
  useZoomPanHelper,
  ReactFlowProvider,
  useStoreState,
  ControlButton,
} from "react-flow-renderer";
import dagre from "dagre";

import { CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  green,
  orange,
  grey,
  red,
  yellow,
  blue,
} from "@material-ui/core/colors";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Snackbar,
  Paper,
  Portal,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
  Maximize2,
  Minimize2,
} from "react-feather";
import {
  getActorAccessToken,
  getAllChildren,
  getAllChildrenStatistics,
  getAllStatistics,
  getBalance,
  setUserDiscount,
  setUserParent,
  setUserRole,
  setUserWaitlistStatus,
} from "../../../backend";
import { getRequestErrorMessage, getUserNamePlus } from "../../../helpers";
import { BigNumber } from "../../../money";
import {
  getActiveAccountDetails,
  getActiveUser,
  getAllUserKeys,
  getCurrentUserKey,
} from "../../../redux/selectors";
import { store } from "../../../redux/store";
import { setCurrentUserKey } from "../../../redux/actions/currentUserKeyActions";
import { resetUsersData } from "../../../redux/actions/usersActions";
import constants from "../../../constants";
import ButtonEdge from "./ButtonEdge";
import { compareRoles, getRoleNumber } from "../../../permissions";
import { nanoid } from "nanoid";
import UserNode from "./UserNode";
import ConnectionLine from "./ConnectionLine";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

const getLayoutedElements = (elements, nodeSizes) => {
  const DEFAULT_NODE_WIDTH = 550;
  const DEFAULT_NODE_HEIGHT = 150;
  const horizontalPadding = 75;
  const verticalPadding = 0;
  const DIR = "LR";
  const isHorizontal = DIR === "LR" || DIR === "RL";
  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: DIR });

  const getSize = (el) => {
    let existingNodeSize = _.find(nodeSizes, { id: el.id });
    return {
      width:
        (existingNodeSize ? existingNodeSize.width : DEFAULT_NODE_WIDTH) +
        horizontalPadding,
      height:
        (existingNodeSize ? existingNodeSize.height : DEFAULT_NODE_HEIGHT) +
        verticalPadding,
    };
  };

  elements.forEach((el) => {
    if (isNode(el)) {
      let size = getSize(el);
      dagreGraph.setNode(el.id, {
        width: size.width,
        height: size.height,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      let size = getSize(el);

      el.position = {
        x: nodeWithPosition.x - size.width / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - size.height / 2 + Math.random() / 1000,
      };
    }

    return el;
  });
};

const getLabel = (u) => {
  let currentState = store.getState();
  let activeUser = getActiveUser(currentState).user;
  return getUserNamePlus({
    compareUser: activeUser,
    overrideUser: u,
    replaceForEmailConditional: true,
    includeIsYou: true,
  });
  return `${_.words(u.role).map(_.capitalize).join(" ")} - #${u.id}, ${
    u.firstName || "Unnamed"
  } ${u.lastName || "User"}, ${u.businessName || "Unnamed Business"}`;
};

const buildElements = (children, topLevelParent) => {
  let tempChildNodes = [];
  if (topLevelParent) {
    tempChildNodes.push({
      id: `${topLevelParent.id}`,
      type: "userNode",
      data: {
        label: getLabel(topLevelParent),
        child: topLevelParent,
      },
      position: { x: 0, y: 0 },
    });
  }

  let tempChildEdges = [];
  children.forEach((child, index) => {
    let parentChildId = `${
      child.parent_user_id
        ? child.parent_user_id
        : topLevelParent
        ? topLevelParent.id
        : ""
    }`;
    let childId = `${child.id}`;

    let childData = {
      id: childId,
      data: {
        label: getLabel(child),
        child,
      },
      position: { x: 0, y: 0 },
    };

    if (child.role === constants.roles.user.typeName) {
      childData.type = "userNode";
    } else {
      childData.type = "userNode";
    }

    tempChildNodes.push(childData);
    const connectToTopLevelParent = false;

    if (
      connectToTopLevelParent ||
      child.role === constants.roles.superAdmin.typeName ||
      child.parent_user_id
    ) {
      let edgeData = {
        id: `e${parentChildId}-${childId}`,
        data: {
          child,
        },
        source: parentChildId,
        target: childId,
        animated: false,
        type: "buttonedge",
      };
      tempChildEdges.push(edgeData);
    }
  });

  let tempElements = [...tempChildNodes, ...tempChildEdges];

  return tempElements;
};

const edgeTypes = {
  buttonedge: ButtonEdge,
};

const nodeTypes = {
  userNode: UserNode,
};

const ChildrenDisplay = withTheme(
  ({
    theme,
    childrenResponseData,
    elements,
    setElements,
    setSnackbarMessage,
    attachedFullScreenContainer,
    fullScreenHandle,
    nodeSizesRef,
    isFullScreen,
    setIsFullScreen,
    reportFullScreenChange,
  }) => {
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const containerRef = React.useRef(null);

    const activeUser = useSelector(getActiveUser).user;
    const userKeyList = useSelector(getAllUserKeys);
    const userKeyList_excludePrimary = userKeyList.filter(
      (key) => key !== "primary"
    );

    const isCeo = activeUser.role === constants.roles.ceo.typeName;

    const hasLayedOut = React.useRef(false);
    const layoutCount = React.useRef(0);
    let adjustedElements = elements;

    if (
      adjustedElements.length > 0 &&
      !hasLayedOut.current &&
      nodeSizesRef.current.length > 0
    ) {
      if (layoutCount.current >= 2) {
        hasLayedOut.current = true;
      } else {
        layoutCount.current++;
        adjustedElements = getLayoutedElements(
          adjustedElements,
          nodeSizesRef.current
        );
      }
    }

    const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
    };

    useEffect(() => {
      if (reactFlowInstance) {
        reactFlowInstance.fitView({ padding: 0.25, includeHiddenNodes: false });
      }
    }, [reactFlowInstance]);

    // const onElementsRemove = (elementsToRemove) =>
    //   setElements((els) => removeElements(elementsToRemove, els));

    // const onConnect = (params) =>
    //   setElements((els) => addEdge({ ...params, type: "buttonedge" }, els));

    const onSetUserRole = (userNode, event) => {
      const role = event.target.value;

      // clone elements
      let clonedElements = _.cloneDeep(elements);
      let backupElements = _.cloneDeep(elements);

      // find child, set child element value
      let indexOfChildElement = _.findIndex(clonedElements, (user) => {
        return user.data.child.id === userNode.data.child.id;
      });

      // set role
      clonedElements[indexOfChildElement].data.child.role = role;

      // remove upflow edge if ceo
      if (role === constants.roles.ceo.typeName) {
        let upflowEdge = _.find(clonedElements, (edge) => {
          if (isNode(edge)) {
            return false;
          }
          return edge.data.child.id === userNode.data.child.id;
        });
        if (upflowEdge) {
          clonedElements = removeElements([upflowEdge], clonedElements);
        }
      }

      // if setting role to user, remove downflow edges
      if (role === constants.roles.user.typeName) {
        let downflowEdges = _.filter(clonedElements, (edge) => {
          if (isNode(edge)) {
            return false;
          }
          return edge.data.child.parent_user_id === userNode.data.child.id;
        });

        clonedElements = removeElements(downflowEdges, clonedElements);
      }

      // update elements
      setElements(clonedElements);

      // try saving and undo if error

      setUserRole({
        userId: userNode.data.child.id,
        role: role,
      })
        .then((data) => {
          // all set, no need to do anything
        })
        .catch((error) => {
          setSnackbarMessage(error);

          // TODO: user could have made another change and we need to make sure that if
          // only this request failed that we arent reseting the entire state back
          // to this point.
          setElements(backupElements);
        });
    };
    const submitUserDiscountDebounce = React.useRef(
      _.debounce((userId, discount, backup) => {
        setUserDiscount({
          userId: userId,
          discount,
        })
          .then((data) => {
            // all set, no need to do anything
          })
          .catch((error) => {
            setSnackbarMessage(error);

            // TODO: user could have made another change and we need to make sure that if
            // only this request failed that we aren't resetting the entire state back
            // to this point.
            setElements(backup);
          });
      }, 500)
    );

    const onSetUserDiscount = (userNode, event) => {
      const discount = event.target.value;

      // clone elements
      let clonedElements = _.cloneDeep(elements);
      let backupElements = _.cloneDeep(elements);

      // find child, set child element value
      let indexOfChildElement = _.findIndex(clonedElements, (user) => {
        return user.data.child.id === userNode.data.child.id;
      });

      // set discount
      clonedElements[
        indexOfChildElement
      ].data.child.discount_percentage = discount;

      // update elements
      setElements(clonedElements);

      // try saving and undo if error

      submitUserDiscountDebounce.current(
        userNode.data.child.id,
        discount,
        backupElements
      );
    };

    const onSetUserWaitlistStatus = (userNode, event) => {
      const isOnWaitlist = event.target.checked;

      // clone elements
      let clonedElements = _.cloneDeep(elements);
      let backupElements = _.cloneDeep(elements);

      // find child, set child element value
      let indexOfChildElement = _.findIndex(clonedElements, (user) => {
        return user.data.child.id === userNode.data.child.id;
      });

      // set role
      clonedElements[
        indexOfChildElement
      ].data.child.is_on_waitlist = isOnWaitlist;

      // update elements
      setElements(clonedElements);

      // try saving and undo if error

      setUserWaitlistStatus({
        userId: userNode.data.child.id,
        isOnWaitlist: isOnWaitlist,
      })
        .then((data) => {
          // all set, no need to do anything
        })
        .catch((error) => {
          setSnackbarMessage(error);

          // TODO: user could have made another change and we need to make sure that if
          // only this request failed that we arent reseting the entire state back
          // to this point.
          setElements(backupElements);
        });
    };

    const onEdgeRemove = (edgeNode, event) => {
      // clone elements
      let clonedElements = _.cloneDeep(elements);
      let backupElements = _.cloneDeep(elements);

      // find child, set child element value
      let indexOfChildElement = _.findIndex(
        clonedElements,
        (user) => `${user.id}` === edgeNode.target
      );
      // remove parent id
      clonedElements[indexOfChildElement].data.child.parent_user_id = null;

      // set child element value
      let childElement = clonedElements[indexOfChildElement];

      // find parent, set parent element value
      let indexOfParentElement = _.findIndex(
        clonedElements,
        (user) => `${user.id}` === edgeNode.source
      );
      let parentElement = clonedElements[indexOfParentElement];

      // remove edge
      clonedElements = removeElements([edgeNode], clonedElements);

      // remove all downflow elements
      if (!isCeo) {
        // recursively find all downflow nodes and edges
        // and remove them

        let downflowNodes = [];

        let maxDepth = 10;

        let findChildren = (element, _depth = 0) => {
          downflowNodes.push(element);
          let foundChildren = clonedElements.filter((el) => {
            if (el.data.child.parent_user_id === element.data.child.id) {
              return true;
            }

            return false;
          });

          if (foundChildren.length > 0 && _depth < maxDepth)
            foundChildren.forEach((child) => {
              findChildren(child, _depth + 1);
            });
        };

        findChildren(childElement);

        clonedElements = removeElements(downflowNodes, clonedElements);
      }

      setElements(clonedElements);

      // try saving and undo if error
      setUserParent({
        childUserId: edgeNode.data.child.id,
        parentUserId: null,
      })
        .then((data) => {
          // all set, no need to do anything
        })
        .catch((error) => {
          // we ran into an error and need to remove the edge

          setSnackbarMessage(error);

          // TODO: user could have made another change and we need to make sure that if
          // only this request failed that we arent reseting the entire state back
          // to this point.
          setElements(backupElements);
        });
    };

    const onConnect = (params) => {
      // clone elements
      let clonedElements = _.cloneDeep(elements);
      let backupElements = _.cloneDeep(elements);

      // find parent
      let indexOfParentElement = _.findIndex(
        clonedElements,
        (user) => `${user.id}` === params.source
      );

      // set parent element value
      let parentElement = clonedElements[indexOfParentElement];

      // find child
      let indexOfChildElement = _.findIndex(
        clonedElements,
        (user) => `${user.id}` === params.target
      );
      let childElement = clonedElements[indexOfChildElement];

      // try switching
      // note: we can only attach to someone who is of a heigher role.
      let parentRoleNumber = getRoleNumber(parentElement.data.child.role);
      let childRoleNumber = getRoleNumber(childElement.data.child.role);

      if (parentRoleNumber <= childRoleNumber) {
        setSnackbarMessage(
          new Error("Parent must have a higher role level than child")
        );
        return;
      }

      if (parentRoleNumber - childRoleNumber > 1) {
        setSnackbarMessage(
          new Error("Parent must have a role level of child + 1")
        );
        return;
      }

      let existingEdge = _.find(
        elements,
        (edge) =>
          edge.id ===
          `e${childElement.data.child.parent_user_id}-${childElement.data.child.id}`
      );

      if (existingEdge) {
        clonedElements = removeElements([existingEdge], clonedElements);
      } else if (
        childElement.data.child.role === constants.roles.superAdmin.typeName
      ) {
        setSnackbarMessage(
          new Error(
            "Super admins don't have parents. They automatically attach to the CEO."
          )
        );
        return;
      }
      childElement.data.child.parent_user_id = parseInt(params.source);
      clonedElements[indexOfChildElement] = childElement;

      // set updated child element value
      childElement = clonedElements[indexOfChildElement];

      // add edge
      let edgeData = {
        id: `e${params.source}-${params.target}`,
        data: {
          child: childElement.data.child,
        },
        source: params.source,
        target: params.target,
        animated: false,
        type: "buttonedge",
      };
      clonedElements = addEdge(edgeData, clonedElements);

      setElements(clonedElements);

      // try saving and undo if error

      setUserParent({
        childUserId: params.target,
        parentUserId: params.source,
      })
        .then((data) => {
          // all set, no need to do anything
        })
        .catch((error) => {
          // we ran into an error and need to remove the edge

          setSnackbarMessage(error);

          // TODO: user could have made another change and we need to make sure that if
          // only this request failed that we arent reseting the entire state back
          // to this point.
          setElements(backupElements);
        });
    };

    const handleDoubleClick = async (event, node) => {
      if (activeUser.id === node.data.child.id) {
        // return when double clicking self
        return;
      }
      setIsLoading(true);
      setErrorMessage("");
      try {
        const response = await getActorAccessToken(
          {
            userId: node.id,
          },
          {
            forcePrimaryUser: true,
          }
        );

        store.dispatch(setCurrentUserKey(response.data.user.id));
        userKeyList_excludePrimary
          .filter((userKey) => userKey !== `${response.data.user.id}`)
          .forEach((userKey) => {
            store.dispatch(resetUsersData(userKey));
          });
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage: "Unable to switch workspace.",
          })
        );
      }

      setIsLoading(false);
    };

    adjustedElements = adjustedElements.map((elementInstance) => {
      if (isNode(elementInstance)) {
        elementInstance.data.onSetUserRole = (event) => {
          onSetUserRole(elementInstance, event);
        };
        elementInstance.data.onSetUserWaitlistStatus = (event) => {
          onSetUserWaitlistStatus(elementInstance, event);
        };
        elementInstance.data.onSetUserDiscount = (event) => {
          onSetUserDiscount(elementInstance, event);
        };
      } else {
        elementInstance.data.onEdgeRemove = (event) => {
          onEdgeRemove(elementInstance, event);
        };
      }

      elementInstance.data.fullScreenHandle = fullScreenHandle;
      elementInstance.data.attachedFullScreenContainer = attachedFullScreenContainer;

      return elementInstance;
    });

    return (
      <div>
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Managed Users
            </Typography>

            <Spacer mb={6} />

            <FullScreen
              handle={fullScreenHandle}
              onChange={reportFullScreenChange}
            >
              <Paper>
                <div
                  style={
                    isFullScreen
                      ? { height: "100vh" }
                      : { height: 750, maxHeight: "calc(100vh - 200px" }
                  }
                  ref={containerRef}
                >
                  <ReactFlow
                    // style={{ background: grey[50] }}
                    onLoad={setReactFlowInstance}
                    onNodeDoubleClick={handleDoubleClick}
                    // onElementsRemove={onElementsRemove}
                    onConnect={onConnect}
                    elements={adjustedElements}
                    elementsSelectable={true}
                    nodesConnectable={true}
                    nodesDraggable={true}
                    edgeTypes={edgeTypes}
                    nodeTypes={nodeTypes}
                    minZoom={0.1}
                    maxZoom={3}
                    connectionLineComponent={ConnectionLine}
                  >
                    <Controls>
                      <ControlButton
                        onClick={() => {
                          setElements(
                            getLayoutedElements(
                              adjustedElements,
                              nodeSizesRef.current
                            )
                          );
                        }}
                      >
                        <AccountTreeIcon />
                      </ControlButton>
                      <ControlButton
                        onClick={() => {
                          toggleFullScreen();
                        }}
                      >
                        {isFullScreen ? <Minimize2 /> : <Maximize2 />}
                      </ControlButton>
                    </Controls>
                    <Background color="#aaa" gap={16 * 1.25} size={0.75} />
                  </ReactFlow>
                </div>
              </Paper>
            </FullScreen>
          </CardContent>
        </Card>
      </div>
    );
  }
);

const HandleNodeSizes = React.memo((props) => {
  const nodeStates = useStoreState((state) => state.nodes);
  const nodeSizes = nodeStates.map((node) => ({
    id: node.id,
    width: node.__rf.width,
    height: node.__rf.height,
  }));
  props.nodeSizesRef.current = nodeSizes;

  return null;
});

function ChildrenGraph() {
  const user = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);

  const fullScreenHandle = useFullScreenHandle();

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    if (!fullScreenHandle || !fullScreenHandle.node.current) {
      return;
    }
    if (isFullScreen) {
      fullScreenHandle.enter();
    } else {
      fullScreenHandle.exit();
    }
  }, [isFullScreen]);

  const reportFullScreenChange = React.useCallback(
    (state, handle) => {
      if (handle === fullScreenHandle) {
        setIsFullScreen(state);
      }
    },
    [fullScreenHandle]
  );

  const attachedFullScreenContainer = () => fullScreenHandle.node.current;

  const nodeSizesRef = React.useRef([]);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [childrenResponseData, setChildrenResponseData] = useState(null);
  const [elements, setElements] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarKey, setSnackbarKey] = useState(nanoid(21));
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState(null);

  const setSnackbarMessage = (err) => {
    setSnackbarOpen(false);
    setSnackbarKey(nanoid(21));
    setSnackbarErrorMessage(
      getRequestErrorMessage({
        error: err,
        fallbackMessage: "An unknown error occured.",
      })
    );
    setSnackbarOpen(true);
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await getAllChildren();
        setChildrenResponseData(data.data);
        setElements(
          getLayoutedElements(
            buildElements(data.data.flatChildrenList, data.data.childrenTree)
          )
        );
      } catch (err) {
        let errorText = getRequestErrorMessage({
          error: err,
          message: "Something went wrong loading your children.",
        });
        setErrorMessage(errorText);
      }
      setIsLoading(false);
    }

    fetchData();
  }, [currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Typography variant="h3" gutterBottom display="inline">
        Dashboard
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Dashboard</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {/* move this into the fullscreen? */}

      <Portal container={attachedFullScreenContainer}>
        <Snackbar
          key={snackbarKey}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          autoHideDuration={6000}
          disableWindowBlurListener={true}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarErrorMessage}
        >
          <React.Fragment>
            <Alert
              mt={2}
              mb={1}
              severity="warning"
              onClose={() => setSnackbarOpen(false)}
            >
              {snackbarErrorMessage}
            </Alert>
          </React.Fragment>
        </Snackbar>
      </Portal>

      {errorMessage || !childrenResponseData ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage ?? "Something went wrong loading children"}
          </Alert>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <ReactFlowProvider>
                <HandleNodeSizes nodeSizesRef={nodeSizesRef} />
                <ChildrenDisplay
                  nodeSizesRef={nodeSizesRef}
                  elements={elements}
                  setElements={setElements}
                  childrenResponseData={childrenResponseData}
                  setSnackbarMessage={setSnackbarMessage}
                  attachedFullScreenContainer={attachedFullScreenContainer}
                  fullScreenHandle={fullScreenHandle}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  reportFullScreenChange={reportFullScreenChange}
                />
              </ReactFlowProvider>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ChildrenGraph;
