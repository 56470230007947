import {
  RESET_REFRESH_TOKEN_DETAILS_DATA,
  SET_REFRESH_TOKEN_DETAILS_DATA,
} from "../../actions/types";
import { users_refreshTokenDetails as initialState } from "../../initialState";
import { addMinutesToDate } from "../../../helpers";

export default function refreshTokenDetailsReducer(
  state = initialState,
  action
) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_REFRESH_TOKEN_DETAILS_DATA:
      return initialState;
    case SET_REFRESH_TOKEN_DETAILS_DATA:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        expirationMinutes: action.payload.expirationMinutes,
        expirationDate: addMinutesToDate(
          new Date(Date.now()),
          action.payload.expirationMinutes
        ).getTime(),
      };
    default:
      return state;
  }
}
