import React from "react";
import styled from "styled-components/macro";
import { TextField as MuiTextField, InputAdornment } from "@material-ui/core";
import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)(spacing);

export default function HttpsTextField(props) {
  if (!props) {
    props = {};
  }

  const { onChange, setValue } = props;

  const otherProps = {
    ...props,
  };
  delete otherProps.onChange;
  delete otherProps.setValue;

  const handleValue = (value, event) => {
    setValue
      ? setValue(value, event)
      : onChange(
          event
            ? event
            : {
                target: {
                  value,
                },
              },
          value
        );
  };

  if (props.value) {
    if (props.value.startsWith("http://")) {
      handleValue(props.value.substring(7));
    } else if (props.value.startsWith("https://")) {
      handleValue(props.value.substring(8));
    }
  }

  return (
    <TextField
      variant="outlined"
      fullWidth
      type="text"
      {...otherProps}
      InputProps={{
        ...(otherProps.InputProps || {}),
        startAdornment: (
          <InputAdornment position="start">https://</InputAdornment>
        ),
      }}
      onChange={(e) => {
        let rUrl = e.target.value;

        if (rUrl.startsWith("http://")) {
          rUrl = rUrl.substring(7);
        } else if (rUrl.startsWith("https://")) {
          rUrl = rUrl.substring(8);
        }

        handleValue(rUrl, e);
      }}
    />
  );
}
