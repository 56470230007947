import React from "react";
import styled from "styled-components/macro";
import config from "../config";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";
import { globalHistory } from "../history";
import { isDevelopment, isLocal, isProduction, isStaging } from "../config";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem
                button={true}
                key="Email"
                component="a"
                href="mailto:support@radial-link.net"
              >
                <ListItemText primary="Support" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  globalHistory.push("/documentation/getting-started");
                }}
              >
                <ListItemText primary="Help Center" />
              </ListItem>
              {/* <ListItem button={true}>
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem button={true}>
                <ListItemText primary="Terms of Service" />
              </ListItem> */}
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <ListItem button={true} disabled>
              <ListItemText primary={`${config.REVISION_HASH_SHORT}`} />
            </ListItem>
            <ListItem button={true} disabled>
              <ListItemText
                primary={`ENV.${
                  isProduction
                    ? "Production"
                    : isStaging
                    ? "Staging"
                    : isLocal
                    ? "Local"
                    : isDevelopment
                    ? "Development"
                    : `Unknown || ${process.env.NODE_ENV}`
                }`}
              />
            </ListItem>
            <ListItem button={true} disabled>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Radial Link`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
