import breakpoints from "../theme/breakpoints";

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  ACTING: "ACTING",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

const roles = {
  ceo: {
    typeName: "CEO",
    name: "CEO",
  },
  superAdmin: {
    typeName: "SUPER_ADMIN",
    name: "Super Admin",
  },
  repManager: {
    typeName: "REP_MANAGER",
    name: "Rep Manager",
  },
  rep: {
    typeName: "REP",
    name: "Rep",
  },
  user: {
    typeName: "USER",
    name: "User",
  },
};

const constants = {
  breakpoints: breakpoints.values,
  roles: roles,
  presetRoles: {
    usersUp: [
      roles.ceo.typeName,
      roles.superAdmin.typeName,
      roles.repManager.typeName,
      roles.rep.typeName,
      roles.user.typeName,
    ],
    repsUp: [
      roles.ceo.typeName,
      roles.superAdmin.typeName,
      roles.repManager.typeName,
      roles.rep.typeName,
    ],
    repManagersUp: [
      roles.ceo.typeName,
      roles.superAdmin.typeName,
      roles.repManager.typeName,
    ],
    superAdminsUp: [roles.ceo.typeName, roles.superAdmin.typeName],
    ceoUp: [roles.ceo.typeName],
  },
  themeModes: {
    light: "light",
    dark: "dark",
  },
  transaction_types: {
    withdrawal: {
      typeName: "WITHDRAWAL",
    },
    deposit: {
      typeName: "DEPOSIT",
    },
  },
  serverResponseIdentifier: ["BPRMGNRZZXKQCSHQ", "JCCJGDJPKVFVASQU"],
  hand_written_note_order_status_types: {
    pending: "PENDING",
    donePending: "DONE:PENDING",
    creating: "CREATING",
    doneCreating: "DONE:CREATING",
    packaging: "PACKAGING",
    donePackaging: "DONE:PACKAGING",
    shipping: "SHIPPING",
    doneShipping: "DONE:SHIPPING",
    canceling: "CANCELING",
    doneCanceling: "DONE:CANCELING",
    errored: "ERRORED",
  },
  hand_written_note_order_fulfillment_types: {
    user: "USER",
    company: "COMPANY",
  },
  hand_written_note_order_types: {
    bulk: { typeName: "BULK" },
    single: { typeName: "SINGLE" },
    singleBulk: { typeName: "SINGLE_BULK" },
    radial: { typeName: "RADIAL" },
    mapCollection: { typeName: "MAP_COLLECTION" },
  },
  selectable_font_types: {
    premium_ultra_17: {
      key: "premium_ultra_17",
      name: "Premium Ulta 17",
    },
    premium_ultra_17_plain: {
      key: "premium_ultra_17_plain",
      name: "Premium Ulta 17 Plain",
    },
    jons_hand_written_single_line: {
      key: "jons_hand_written_single_line",
      name: "Jon Moore SL",
    },
    jons_hand_written_single_line_plain: {
      key: "jons_hand_written_single_line_plain",
      name: "Jon Moore SL Plain",
    },
    josh_sidwell_preset: {
      key: "josh_sidwell_preset",
      name: "Josh Sidwell Mimick (PU17)",
    },
  },
  hand_written_note_order_card_sizes: {
    default: {
      hideFromClient: false,
      supportsUpperLower: true,
      price: 8.0,
      highestPrice: "8.00",
      lowestPrice: "4.50",
      priceByOrderType: {
        single: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
        bulk: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
        radial: {
          price: "4.50",
          overThresholdPrice: "4.50",
          threshold: 1,
        },
        mapCollection: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
      },
      key: "default",
      name: "Suggested",
      cardInchDimensions_upper: {
        width: 5.5,
        height: 4.25,
        padding: 0.2,
        position: {
          width: 0,
          height: 0,
        },
      },
      cardInchDimensions: {
        width: 5.5,
        height: 4.25,
        padding: 0.2,
        position: {
          width: 0,
          height: 4.25,
        },
      },
      envelopeInchDimensions: {
        width: 5.75,
        height: 4.375,
        padding: 0.2,
        position: {
          width: 5.5,
          height: 0,
        },
        senderLines: {
          position: {
            width: 0,
            height: 0,
          },
        },
        receiverLines: {
          position: {
            width: 1.25,
            height: 1.75,
          },
        },
        teaserText: {
          position: {
            width: 0.25,
            height: 3.25,
          },
        },
      },
    },
    a1_a2: {
      hideFromClient: true,
      supportsUpperLower: true,
      price: 8.0,
      highestPrice: "8.00",
      lowestPrice: "4.50",
      priceByOrderType: {
        single: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
        bulk: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
        radial: {
          price: "4.50",
          overThresholdPrice: "4.50",
          threshold: 1,
        },
        mapCollection: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
      },
      key: "a1_a2",
      name: "A1 + A2",
      cardInchDimensions_upper: {
        width: 5.25,
        height: 3.75,
        padding: 0.2,
        position: {
          width: 0,
          height: 0,
        },
      },
      cardInchDimensions: {
        width: 5.25,
        height: 3.75,
        padding: 0.2,
        position: {
          width: 0,
          height: 3.75,
        },
      },
      envelopeInchDimensions: {
        width: 6.25,
        height: 4.5,
        padding: 0.2,
        position: {
          width: 5.25,
          height: 0,
        },
        senderLines: {
          position: {
            width: 0,
            height: 0,
          },
        },
        receiverLines: {
          position: {
            width: 1.6,
            height: 1.6,
          },
        },
        teaserText: {
          position: {
            width: 0.25,
            height: 3.25,
          },
        },
      },
    },
    a2: {
      hideFromClient: false,
      supportsUpperLower: true,
      price: 8.0,
      highestPrice: "8.00",
      lowestPrice: "4.50",
      priceByOrderType: {
        single: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
        bulk: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
        radial: {
          price: "4.50",
          overThresholdPrice: "4.50",
          threshold: 1,
        },
        mapCollection: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
      },
      key: "a2",
      name: "A2",
      cardInchDimensions_upper: {
        width: 5.5,
        height: 4.25,
        padding: 0.2,
        position: {
          width: 0,
          height: 0,
        },
      },
      cardInchDimensions: {
        width: 5.5,
        height: 4.25,
        padding: 0.2,
        position: {
          width: 0,
          height: 4.25,
        },
      },
      envelopeInchDimensions: {
        width: 5.75,
        height: 4.375,
        padding: 0.2,
        position: {
          width: 5.5,
          height: 0,
        },
        senderLines: {
          position: {
            width: 0,
            height: 0,
          },
        },
        receiverLines: {
          position: {
            width: 1.25,
            height: 1.75,
          },
        },
        teaserText: {
          position: {
            width: 0.25,
            height: 3.25,
          },
        },
      },
    },
    door_note: {
      hideFromClient: true,
      supportsUpperLower: false,
      price: 5.0,
      highestPrice: "5.00",
      lowestPrice: "5.00",
      priceByOrderType: {
        single: {
          price: "5.00",
          overThresholdPrice: "4.00",
          threshold: 50,
        },
        bulk: {
          price: "5.00",
          overThresholdPrice: "4.00",
          threshold: 50,
        },
        radial: {
          price: "4.50",
          overThresholdPrice: "4.50",
          threshold: 1,
        },
        mapCollection: {
          price: "8.00",
          overThresholdPrice: "5.00",
          threshold: 50,
        },
      },
      key: "door_note",
      name: "Door Note",
      cardInchDimensions_upper: null,
      cardInchDimensions: {
        width: 4.25,
        height: 5.5,
        padding: 0.2,
        position: {
          width: 0,
          height: 0,
        },
      },
      envelopeInchDimensions: null,
    },
  },
};

export default constants;
