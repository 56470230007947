import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import { useSelector } from "react-redux";
import { spacing } from "@material-ui/system";
import constants from "../constants";

import {
  Badge,
  Box as MuiBox,
  Chip,
  Grid,
  Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Button,
  CardContent,
  Card as MuiCard,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert as MuiAlert } from "@material-ui/lab";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import { sidebarRoutes as routes } from "../routes/index";

import { ReactComponent as Logo } from "../vendor/logo.svg";
import _ from "lodash";
import {
  getActiveUser,
  getAllUserKeys,
  getCurrentUserKey,
  getIsPrimaryUser,
  getPrimaryUser,
  getSecondaryUser,
  getUserModeString,
} from "../redux/selectors";
import { store } from "../redux/store";
import { resetUsersData } from "../redux/actions/usersActions";
import {
  resetCurrentUserKey,
  setCurrentUserKey,
} from "../redux/actions/currentUserKeyActions";
import {
  getActorAccessToken,
  getAllActableUsers,
  getAllUserShares,
  getDemoUser,
  getUserAvatarUrl,
} from "../backend";
import { getRequestErrorMessage, getUserNamePlus } from "../helpers";
import AutocompleteActableUsers from "./AutocompleteActableUsers";

const Alert = styled(MuiAlert)(spacing);

const Box = styled(MuiBox)(spacing);

const Card = styled(MuiCard)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const LargeAvatar = styled(Avatar)`
  width: ${(props) => (props.size ? props.size : props.theme.spacing(20))}px;
  height: ${(props) => (props.size ? props.size : props.theme.spacing(20))}px;
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled(Button)`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.offline
        ? props.theme.sidebar.footer.offline.background
        : props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: ${(props) => props.size || 12}px;
    width: ${(props) => props.size || 12}px;
    border-radius: 50%;
  }
`;

const blankActableUsersResults = {
  userShareUsers: [],
  userShareUserCount: 0,
  childrenUsers: [],
  childrenUserCount: 0,
  actableUsers: [],
};

const SelectUser = (props) => {
  const { selectedUser, setSelectedUser } = props;
  const parentIsLoading = props.isLoading;

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [mode, setMode] = React.useState(2);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(
    blankActableUsersResults
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, or, q, m }) => {
        setIsLoading(true);

        try {
          const response = await getAllActableUsers(
            {
              limit: l,
              offset: o,
              order: or,
              query: q,
            },
            {
              forcePrimaryUser: true,
            }
          );
          setSearchResults({
            userShareUsers: response.data.userShareUsers,
            userShareUserCount: response.data.userShareUserCount,
            childrenUsers: response.data.childrenUsers,
            childrenUserCount: response.data.childrenUserCount,
            actableUsers: response.data.actableUsers,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Unable to search.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      setSearchResults(blankActableUsersResults);
      setErrorMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
      });
    } else {
      setSearchResults(blankActableUsersResults);
    }
  }, [searchQuery, limit, offset, order, orderBy]);

  const parsedOptions = searchResults.actableUsers;

  if (selectedUser && !parsedOptions.some((o) => o.id === selectedUser.id)) {
    parsedOptions.push(selectedUser);
  }

  return (
    <>
      <Autocomplete
        id="user-share-search"
        disableClearable={true}
        loading={isLoading}
        loadingText="Loading..."
        noOptionsText={errorMessage || "No results."}
        fullWidth
        options={parsedOptions}
        getOptionSelected={(option, value) =>
          option && selectedUser ? option.id === selectedUser.id : false
        }
        getOptionLabel={(option) => {
          return `#${option.id} - ${option.businessName}, ${getUserNamePlus({
            overrideUser: option,
          })}, ${option.email}`;
        }}
        onChange={(e, newValue) => {
          if (newValue) {
            setSelectedUser(newValue);
          }
        }}
        value={selectedUser}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              label={"Search Workspaces"}
              required
              error={errorMessage ? true : false}
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={parentIsLoading}
              autoFocus
            />
          );
        }}
      />
    </>
  );
};

const UserMenu = (props) => {
  const { onClose, open } = props;
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const user = useSelector(getActiveUser).user;
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;
  const secondaryUser = useSelector(getSecondaryUser).user;
  const isPrimaryUser = useSelector(getIsPrimaryUser);
  const userKeyList = useSelector(getAllUserKeys);
  const userKeyList_excludePrimary = userKeyList.filter(
    (key) => key !== "primary"
  );
  const currentUserKey = useSelector(getCurrentUserKey);

  const hasSecondaryUser = !!secondaryUser;

  const closeDialog = () => {
    setSelectedUser(null);
    onClose();
  };

  const handleSwitchWorkspace = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await getActorAccessToken(
        {
          userId: selectedUser.id,
        },
        {
          forcePrimaryUser: true,
        }
      );
      store.dispatch(setCurrentUserKey(response.data.user.id));
      userKeyList_excludePrimary
        .filter((userKey) => userKey !== `${response.data.user.id}`)
        .forEach((userKey) => {
          store.dispatch(resetUsersData(userKey));
        });
      closeDialog();
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage: "Unable to switch workspace.",
        })
      );
    }

    setIsLoading(false);
  };

  const handleSwitchWorkspace_demo = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const demoUserResponse = await getDemoUser();

      const response = await getActorAccessToken(
        {
          userId: demoUserResponse.data.user.id,
        },
        {
          forcePrimaryUser: true,
        }
      );
      store.dispatch(setCurrentUserKey(response.data.user.id));
      userKeyList_excludePrimary
        .filter((userKey) => userKey !== `${response.data.user.id}`)
        .forEach((userKey) => {
          store.dispatch(resetUsersData(userKey));
        });
      closeDialog();
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage: "Unable to switch to demo workspace.",
        })
      );
    }

    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="user-menu-title"
      aria-describedby="user-menu-description"
      maxWidth="sm"
      fullWidth
      style={{
        zIndex: 1200,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <DialogTitle>Switch Workspace</DialogTitle>
          {/* <Typography variant="h6">Switch Workspaces</Typography> */}
        </Grid>
        <Grid item>
          {activeUser.role !== constants.roles.user.typeName && (
            <DialogTitle>
              <Button
                onClick={handleSwitchWorkspace_demo}
                variant="outlined"
                color="primary"
                disabled={selectedUser || isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Demo Mode"}
              </Button>
            </DialogTitle>
          )}
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container justifyContent="space-around" spacing={2}>
          <Grid item>
            <Tooltip title="Return to primary workspace">
              <Button
                onClick={() => {
                  store.dispatch(resetCurrentUserKey());
                  // userKeyList_excludePrimary.forEach((userKey) => {
                  //   store.dispatch(resetUsersData(userKey));
                  // });
                  closeDialog();
                }}
                disabled={isLoading}
              >
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Typography variant="h6" align="center">
                      Primary Account
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="center">
                    <SidebarFooterBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      variant="dot"
                      size={18}
                      offline={!isPrimaryUser}
                    >
                      <LargeAvatar
                        alt={"Lucy Lavender"}
                        src={getUserAvatarUrl({
                          userId: primaryUser.id,
                          size: 80,
                        })}
                      />
                    </SidebarFooterBadge>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" align="center">
                      {getUserNamePlus({
                        forcePrimaryUser: true,
                      })}
                    </Typography>

                    <Typography variant="subtitle1" align="center">
                      {primaryUser.email}
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                      #{primaryUser.id}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                isPrimaryUser
                  ? hasSecondaryUser
                    ? "Return to previous workspace"
                    : "Select a workspace below"
                  : "Return to active workspace"
              }
            >
              <div>
                <Button
                  onClick={() => {
                    if (hasSecondaryUser) {
                      store.dispatch(setCurrentUserKey(secondaryUser.id));
                    }
                    closeDialog();
                  }}
                  disabled={!hasSecondaryUser || isLoading}
                >
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Typography variant="h6" align="center">
                        {isPrimaryUser
                          ? hasSecondaryUser
                            ? "Previous Workspace"
                            : "Select Workspace"
                          : "Active Workspace"}
                      </Typography>
                    </Grid>
                    <Grid item container justifyContent="center">
                      <SidebarFooterBadge
                        offline={isPrimaryUser}
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                        size={18}
                      >
                        <LargeAvatar
                          alt={"Lucy Lavender"}
                          src={getUserAvatarUrl({
                            userId: secondaryUser ? secondaryUser.id : -2,
                            size: 80,
                          })}
                        />
                      </SidebarFooterBadge>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" align="center">
                        {!hasSecondaryUser ? (
                          <React.Fragment>John Doe</React.Fragment>
                        ) : (
                          <React.Fragment>
                            {getUserNamePlus({
                              overrideUser: secondaryUser,
                            })}
                          </React.Fragment>
                        )}
                      </Typography>

                      <Typography variant="subtitle1" align="center">
                        {!hasSecondaryUser
                          ? "johndoe@example.com"
                          : secondaryUser.email}
                      </Typography>

                      <Typography variant="subtitle2" align="center">
                        #{!hasSecondaryUser ? "1" : secondaryUser.id}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText align="center">
              Workspace switching allows you to access other accounts and manage
              everything they own within a set of defined permissions.
            </DialogContentText>
          </Grid>
          {errorMessage && (
            <React.Fragment>
              <Grid item xs={12}>
                <Alert mt={2} mb={1} severity="warning">
                  {errorMessage}
                </Alert>
              </Grid>
              <br />
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <AutocompleteActableUsers
              isLoading={isLoading}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={closeDialog} color="primary" disabled={isLoading}>
              close
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleSwitchWorkspace}
              variant="contained"
              color="primary"
              disabled={!selectedUser || isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Switch"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarLink = ({ name, to, badge, icon }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
  const user = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;
  const isPrimaryUser = useSelector(getIsPrimaryUser);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <Drawer
      variant="permanent"
      {...rest}
      style={{
        zIndex: 1150,
      }}
    >
      <Brand component={NavLink} to="/" button>
        <Box ml={1}>Radial Link</Box>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category, index) => {
              let shouldAlwaysShow = false;
              if (category.alwaysShowForPrimaryUserRole) {
                if (
                  primaryUser &&
                  category.alwaysShowForPrimaryUserRole &&
                  (Array.isArray(category.alwaysShowForPrimaryUserRole)
                    ? category.alwaysShowForPrimaryUserRole.includes(
                        primaryUser.role
                      )
                    : primaryUser.role ===
                      category.alwaysShowForPrimaryUserRole)
                ) {
                  shouldAlwaysShow = true;
                }
              }

              if (!shouldAlwaysShow) {
                if (category.useOnlyWhenActing && isPrimaryUser) {
                  return null;
                }

                if (
                  user &&
                  category.useWithRole &&
                  (Array.isArray(category.useWithRole)
                    ? !category.useWithRole.includes(user.role)
                    : user.role !== category.useWithRole)
                ) {
                  return null;
                }

                if (
                  primaryUser &&
                  category.useWithPrimaryUserRole &&
                  (Array.isArray(category.useWithPrimaryUserRole)
                    ? !category.useWithPrimaryUserRole.includes(
                        primaryUser.role
                      )
                    : primaryUser.role !== category.useWithPrimaryUserRole)
                ) {
                  return null;
                }
              }

              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <SidebarSection>{category.header}</SidebarSection>
                  ) : null}

                  {category.children && category.icon ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                      />

                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map((route, index) => {
                          const pathName = location.pathname;
                          const isActive = pathName.indexOf(route.path) === 0;

                          let shouldAlwaysShow = false;
                          if (route.alwaysShowForPrimaryUserRole) {
                            if (
                              primaryUser &&
                              route.alwaysShowForPrimaryUserRole &&
                              (Array.isArray(route.alwaysShowForPrimaryUserRole)
                                ? route.alwaysShowForPrimaryUserRole.includes(
                                    primaryUser.role
                                  )
                                : primaryUser.role ===
                                  route.alwaysShowForPrimaryUserRole)
                            ) {
                              shouldAlwaysShow = true;
                            }
                          }

                          if (!shouldAlwaysShow) {
                            if (route.useOnlyWhenActing && isPrimaryUser) {
                              return null;
                            }

                            if (
                              user &&
                              route.useWithRole &&
                              (Array.isArray(route.useWithRole)
                                ? !route.useWithRole.includes(user.role)
                                : user.role !== route.useWithRole)
                            ) {
                              return null;
                            }

                            if (
                              primaryUser &&
                              route.useWithPrimaryUserRole &&
                              (Array.isArray(route.useWithPrimaryUserRole)
                                ? !route.useWithPrimaryUserRole.includes(
                                    primaryUser.role
                                  )
                                : primaryUser.role !==
                                  route.useWithPrimaryUserRole)
                            ) {
                              return null;
                            }
                          }

                          if (
                            route.includeInSidebar === false &&
                            !route.includeInSidebarWhenActive
                          )
                            return null;

                          if (
                            route.includeInSidebarWhenActive === true &&
                            !isActive
                          ) {
                            return null;
                          }

                          return (
                            <SidebarLink
                              key={index}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                            />
                          );
                        })}
                      </Collapse>
                    </React.Fragment>
                  ) : category.icon ? (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </Items>
        </List>
      </Scrollbar>
      <UserMenu
        open={isUserMenuOpen}
        onClose={() => {
          setIsUserMenuOpen(false);
        }}
      />
      <SidebarFooter
        onClick={() => {
          setIsUserMenuOpen(!isUserMenuOpen);
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SidebarFooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar src={getUserAvatarUrl({ userId: user.id })} />
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">
              {getUserNamePlus()}
            </SidebarFooterText>

            <SidebarFooterSubText variant="caption" align="left">
              {_.words(user.role).map(_.capitalize).join(" ")}
            </SidebarFooterSubText>
          </Grid>
          <Grid item xs container justifyContent="flex-end">
            <SidebarFooterText>
              <SupervisorAccountIcon color="inherit" fontSize="large" />
            </SidebarFooterText>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
