import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import _, { set } from "lodash";
import { BigNumber } from "../../money";

import { red, green, blue } from "@material-ui/core/colors";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader as MuiCardHeader,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  TextField as MuiTextField,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { Alert as MuiAlert } from "@material-ui/lab";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
} from "react-feather";
import {
  getAllStatistics,
  getCheckoutSessionOneTimePayment,
  giveCreditsToUser,
  redeemPromotionalCode,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { stripe, load } from "../../stripe";
import { getActiveUser, getIsPrimaryUser } from "../../redux/selectors";
import { globalHistory } from "../../history";
import constants from "../../constants";

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

function AddCredits() {
  const activeUser = useSelector(getActiveUser).user;
  const isPrimaryUser = useSelector(getIsPrimaryUser);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [totalAmount, setTotalAmount] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await giveCreditsToUser(
        {
          userId: activeUser.id,
          amount: totalAmount,
        },
        {
          forcePrimaryUser: true,
        }
      );

      globalHistory.push("/account/transaction");
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage: "Something went wrong claiming the promotional code",
        })
      );
    }

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <Helmet title="One Time Payment" />

      <Typography variant="h3" gutterBottom display="inline">
        Add Credits (Internal)
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Add Credits (Internal)</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Header>
        <Typography variant="h3" gutterBottom align="center">
          Add credits to this user account
        </Typography>

        <Typography variant="subtitle1" gutterBottom align="center">
          Enter the total amount (up to $1000), and the credits will be added.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Card p={5}>
            {isLoading ? (
              <React.Fragment>
                <Loading>
                  <CircularProgress />
                </Loading>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  <CardHeader
                    title="Enter Total Amount"
                    subheader="Value must be less than $1000"
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{ align: "center" }}
                    pb={0}
                    pt={2}
                  />
                  <CardContent>
                    {errorMessage && (
                      <React.Fragment>
                        <Alert mt={2} mb={1} severity="warning">
                          {errorMessage}
                        </Alert>
                        <br />
                      </React.Fragment>
                    )}
                    <TextField
                      label="Payment Amount"
                      variant="outlined"
                      required
                      fullWidth
                      my={2}
                      type="number"
                      value={totalAmount}
                      disabled={isLoading}
                      onChange={(e) => {
                        setTotalAmount(e.target.value);
                      }}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        isLoading ||
                        (isPrimaryUser &&
                          activeUser.role !== constants.roles.ceo.typeName)
                      }
                    >
                      Add Credits
                    </Button>
                  </CardActions>
                </form>
              </React.Fragment>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AddCredits;
