import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Button,
  CardActions,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";

import { CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";

import { BigNumber } from "../../money";

import { StarBorder as StarIcon } from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getAllIndustries,
  getAllCategories,
  getAllTemplates,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { stripe, load } from "../../stripe";
import { useSelector } from "react-redux";
import useCardSizes from "../../hooks/useCardSizes";
import { getCurrentUserKey } from "../../redux/selectors";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PathPreview from "../../components/PathPreview";
import _ from "lodash";

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
`;

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px 0;
`;

const IndustryItem = (props) => {
  const { industry, selectedIndustry, setSelectedIndustry } = props;

  const isSelected = selectedIndustry === industry.id;

  return (
    <FormControlLabel
      label={industry.name}
      control={
        <Checkbox
          checked={isSelected}
          onChange={() =>
            isSelected
              ? setSelectedIndustry(null)
              : setSelectedIndustry(industry.id)
          }
        />
      }
    />
  );
};

const CategoryItem = (props) => {
  const { category, selectedCategory, setSelectedCategory } = props;

  const isSelected = selectedCategory === category.id;

  return (
    <FormControlLabel
      label={category.name}
      control={
        <Checkbox
          checked={isSelected}
          onChange={() =>
            isSelected
              ? setSelectedCategory(null)
              : setSelectedCategory(category.id)
          }
        />
      }
    />
  );
};

const TemplateItem = (props) => {
  const { template } = props;

  return (
    <Grid item lg={12} xl={6}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" noWrap>
              {template.name || "Unnamed Template"}
            </Typography>
          }
          subheader={template.description}
          action={
            <Button
              to={{
                pathname: `/template/${template.id}`,
                state: {
                  templateId: template.id,
                },
              }}
              component={NavLink}
            >
              View
            </Button>
          }
        />
        <CardContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Content</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                {/* upper text */}
                {template.text_upper && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Card Top Half
                    </Typography>
                    <Paper
                      variant="outlined"
                      style={{
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1">
                        {template.text_upper.split("\n").map((line, index) => {
                          if (!line) {
                            return (
                              <React.Fragment key={index}>
                                <br />
                              </React.Fragment>
                            );
                          }
                          return (
                            <React.Fragment key={index}>
                              <br />
                              {line}
                            </React.Fragment>
                          );
                        })}
                      </Typography>
                    </Paper>
                  </Grid>
                )}

                {/* lower text */}
                {template.text && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Card Bottom Half
                    </Typography>
                    <Paper
                      variant="outlined"
                      style={{
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1">
                        {template.text.split("\n").map((line, index) => {
                          if (!line) {
                            return (
                              <React.Fragment key={index}>
                                <br />
                              </React.Fragment>
                            );
                          }
                          return (
                            <React.Fragment key={index}>
                              <br />
                              {line}
                            </React.Fragment>
                          );
                        })}
                      </Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Preview</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {template.generated_preview_paths ? (
                    <Grid container spacing={3}>
                      {template.generated_preview_paths_upper && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                              Card Top Half
                            </Typography>
                            <Paper
                              variant="outlined"
                              style={{
                                padding: "16px",
                              }}
                            >
                              <PathPreview
                                paths={template.generated_preview_paths_upper}
                              />
                            </Paper>
                          </Grid>
                        </React.Fragment>
                      )}
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" gutterBottom>
                          Card Bottom Half
                        </Typography>
                        <Paper
                          variant="outlined"
                          style={{
                            padding: "16px",
                          }}
                        >
                          <PathPreview
                            paths={template.generated_preview_paths}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  ) : (
                    <React.Fragment>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        No preview available.
                      </Typography>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Grid>
  );
};

function PremiumTemplates() {
  const currentUserKey = useSelector(getCurrentUserKey);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [loadMoreErrorMessage, setLoadMoreErrorMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingTemplates, setIsLoadingTemplates] = React.useState(true);
  const [isLoadingMoreTemplates, setIsLoadingMoreTemplates] = React.useState(
    false
  );

  const [searchQuery, setSearchQuery] = React.useState("");
  const [
    searchQuery_refreshCount,
    setSearchQuery_refreshCount,
  ] = React.useState(0);

  // use a useCallback to avoid recreating the debounced function every time
  const updateSearchQueryRefreshCount = React.useCallback(
    _.throttle(
      () => {
        setSearchQuery_refreshCount((prev) => prev + 1);
      },
      750,
      {
        leading: false,
      }
    ),
    [null]
  );

  const [industries, setIndustries] = React.useState([]);
  const [industriesCount, setIndustriesCount] = React.useState(0);
  const [industriesExpanded, setIndustriesExpanded] = React.useState(true);
  const [selectedIndustry, setSelectedIndustry] = React.useState(null);

  const [categories, setCategories] = React.useState([]);
  const [categoriesCount, setCategoriesCount] = React.useState(0);
  const [categoriesExpanded, setCategoriesExpanded] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const [templates, setTemplates] = React.useState([]);
  const [templateCount, setTemplateCount] = React.useState(0);
  const [templateLimit, setTemplateLimit] = React.useState(5);
  const [templatePage, setTemplatePage] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");

  const estimatedCurrentlyViewing =
    templateLimit + templateLimit * templatePage;
  const templateOffset = templatePage * templateLimit;
  const canLoadMore =
    templates.length < templateCount &&
    estimatedCurrentlyViewing < templateCount;

  React.useEffect(
    () => {
      let fetchData = async () => {
        setIsLoading(true);

        try {
          let results = await Promise.all([
            getAllIndustries({
              hasTemplates: true,
              hasTemplates_isPublic: true,
            }),
            getAllCategories({
              hasTemplates: true,
              hasTemplates_isPublic: true,
            }),
          ]);

          setIndustries(results[0].data.industries);
          setIndustriesCount(results[0].data.industryCount);
          setCategories(results[1].data.categories);
          setCategoriesCount(results[1].data.categoryCount);
        } catch (e) {
          setErrorMessage(
            getRequestErrorMessage({
              error: e,
              fallbackMessage:
                "Something went wrong getting the available industries.",
            })
          );
        }

        setIsLoading(false);
      };

      fetchData();
    },
    [
      /*currentUserKey*/
    ]
  );

  // using this special effect to ONLY debounce the search query
  React.useEffect(() => {
    setIsLoadingTemplates(true);
    updateSearchQueryRefreshCount.cancel();
    updateSearchQueryRefreshCount();
  }, [searchQuery]);

  React.useEffect(() => {
    let fetchData = async () => {
      setIsLoadingTemplates(true);
      updateSearchQueryRefreshCount.cancel();
      // reset values

      setTemplateCount(0);
      setTemplates([]);
      setTemplatePage(0);

      try {
        let results = await getAllTemplates({
          query: searchQuery,
          industryId: selectedIndustry,
          categoryId: selectedCategory,
          limit: templateLimit,
          offset: 0,
          order: [orderBy, order],
          isPublic: true,
          returnPreviewPaths: true,
        });

        setTemplates(results.data.templates);
        setTemplateCount(results.data.template_count);
      } catch (e) {
        setErrorMessage(
          getRequestErrorMessage({
            error: e,
            fallbackMessage:
              "Something went wrong getting the available templates.",
          })
        );
      }

      setIsLoadingTemplates(false);
    };

    fetchData();
  }, [
    searchQuery_refreshCount,
    selectedIndustry,
    selectedCategory,
    currentUserKey,
  ]);

  const handleLoadMore = () => {
    let fetchData = async () => {
      setIsLoadingMoreTemplates(true);
      setLoadMoreErrorMessage("");
      const newPage = templatePage + 1;
      const newOffset = newPage * templateLimit;
      setTemplatePage(newPage);

      try {
        let results = await getAllTemplates({
          query: searchQuery,
          industryId: selectedIndustry,
          categoryId: selectedCategory,
          limit: templateLimit,
          offset: newOffset,
          order: [orderBy, order],
          isPublic: true,
          returnPreviewPaths: true,
        });

        setTemplates((prev) => [...prev, ...results.data.templates]);
      } catch (e) {
        setLoadMoreErrorMessage(
          getRequestErrorMessage({
            error: e,
            fallbackMessage: "Something went wrong getting more templates.",
          })
        );
      }

      setIsLoadingMoreTemplates(false);
    };

    fetchData();
  };

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Premium Templates" />
      <Typography variant="h3" gutterBottom display="inline">
        Premium Templates
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Premium Templates</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {errorMessage ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage ??
              "Something went wrong loading the templates. Please try again later."}
          </Alert>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* contain the results */}
          <Grid container spacing={4}>
            {/* filters card */}
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <Card>
                <CardHeader
                  title="Filters"
                  subheader="Select the filters to see the available templates."
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {/* search */}

                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        placeholder="Search"
                        margin="dense"
                        helperText="Search by template name or content."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion
                        expanded={industriesExpanded}
                        onChange={(e, expanded) => {
                          setIndustriesExpanded(expanded);
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="subtitle1" gutterBottom>
                            Industry
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {industries.length > 0 ? (
                            <React.Fragment>
                              <FormGroup column>
                                {industries.map((industry) => (
                                  <IndustryItem
                                    key={industry.id}
                                    industry={industry}
                                    selectedIndustry={selectedIndustry}
                                    setSelectedIndustry={setSelectedIndustry}
                                  />
                                ))}
                              </FormGroup>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                              >
                                No industries found.
                              </Typography>
                            </React.Fragment>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion
                        expanded={categoriesExpanded}
                        onChange={(e, expanded) => {
                          setCategoriesExpanded(expanded);
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="subtitle1" gutterBottom>
                            Category
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {categories.length > 0 ? (
                            <React.Fragment>
                              <FormGroup column>
                                {categories.map((category) => (
                                  <CategoryItem
                                    key={category.id}
                                    category={category}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                  />
                                ))}
                              </FormGroup>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                gutterBottom
                              >
                                No categories found.
                              </Typography>
                            </React.Fragment>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* result cards */}
            <Grid item xs={12} md>
              {isLoadingTemplates ? (
                <React.Fragment>
                  <Card>
                    <CardHeader
                      title="Results"
                      subheader={
                        <React.Fragment>
                          <Typography variant="inherit" display="inline">
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>Searching for templates...</Grid>
                            </Grid>
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </Card>
                  <Divider my={2} />
                  <Grid container spacing={2}>
                    <React.Fragment>
                      <Grid item xs={12} container justifyContent="center">
                        <CircularProgress size={50} />
                      </Grid>
                    </React.Fragment>
                  </Grid>
                </React.Fragment>
              ) : templateCount === 0 ? (
                <React.Fragment>
                  <Card>
                    <CardHeader
                      title="Results"
                      subheader="No templates found."
                    />
                  </Card>
                  <Divider my={2} />
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        gutterBottom
                        color="textSecondary"
                      >
                        Hmmm... Doesn't look like we could find anything, please
                        adjust the filters and try again
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Card>
                    <CardHeader
                      title="Results"
                      subheader={`${templateCount} template${
                        templateCount === 1 ? "" : "s"
                      } found, displaying ${templates.length}.`}
                    />
                  </Card>
                  <Divider my={2} />
                  <Grid container spacing={2}>
                    {templates.map((template) => (
                      <TemplateItem key={template.id} template={template} />
                    ))}
                    <Grid
                      item
                      xs
                      container
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* display load more button */}

                      <Button onClick={handleLoadMore} disabled={!canLoadMore}>
                        {isLoadingMoreTemplates ? (
                          <CircularProgress size={20} />
                        ) : loadMoreErrorMessage ? (
                          loadMoreErrorMessage
                        ) : canLoadMore ? (
                          "Load More"
                        ) : (
                          "No More Templates"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default PremiumTemplates;
