import React from "react";
import { Chip as MuiChip } from "@material-ui/core";
import constants from "../../constants";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import {
  green,
  orange,
  grey,
  red,
  yellow,
  blue,
} from "@material-ui/core/colors";
import { BigNumber } from "../../money";
const orderStati = constants.hand_written_note_order_status_types;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => {
    if (props.isZero) {
      return grey[props.colorLevel * 100];
    }
    if (props.isNegative) {
      return red[props.colorLevel * 100];
    } else {
      return blue[props.colorLevel * 100];
    }
  }};
`;

export default function PercentageChip(props) {
  let propsToPass = props.propsToPass ?? {};
  let amount = new BigNumber(props.amount || 0);
  let isZero = amount.isEqualTo(0);
  let isNegative = amount.isNegative();
  let string = new BigNumber(amount.absoluteValue()).toFormat(0);
  let label = `${string}%`;

  return (
    <Chip
      size="small"
      mr={1}
      mb={1}
      label={label}
      colorLevel={props.colorLevel || 2}
      isZero={isZero}
      isNegative={isNegative}
      {...propsToPass}
    />
  );
}
