import "emoji-mart/css/emoji-mart.css";
import React, { useState, useEffect } from "react";
import { Picker as MissivePicker } from "emoji-mart";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import dateFormat from "dateformat";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkExternalLinks from "remark-external-links";
import { usePageVisibility } from "react-page-visibility";
import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  IconButton,
  Popover as MuiPopover,
  Paper,
  Button,
  Tooltip,
  CircularProgress,
  CardContent,
} from "@material-ui/core";
import { useDeviceBreakpoint } from "../../hooks/useDeviceBreakpoint";
import { spacing } from "@material-ui/system";
import ms from "ms";
import SendIcon from "@material-ui/icons/Send";
import {
  ArrowBack,
  AttachFile,
  ChatBubble,
  Close,
  Create,
  Delete,
  Done,
  DoneAll,
  InsertEmoticon,
  MoreVert,
  Notifications,
  SupervisorAccount,
} from "@material-ui/icons";
import {
  getRequestErrorMessage,
  getUserNamePlus,
  stringToColor_alpha,
} from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveUser,
  getIsPrimaryUser,
  getPrimaryUser,
} from "../../redux/selectors";
import {
  createNewMessage,
  findOrCreatePrivateMessageGroup,
  getAllMessageGroupParticipants,
  getAllMessageGroups,
  getAllMessageGroupSeenBys,
  getAllMessages,
  getSharableFileUrl,
  getSingleMessageGroup,
  getUser,
  getUserAvatarUrl,
  socket,
  updateMessageGroupSeenBy,
} from "../../backend";
import { Alert as MuiAlert, AvatarGroup } from "@material-ui/lab";
import { nanoid } from "nanoid";
import useLocalStorage from "../../hooks/useLocalStorage";
import { blue, green, grey, red } from "@material-ui/core/colors";
import _ from "lodash";
import AutocompleteActableUsers from "../../components/AutocompleteActableUsers";
import { File, Mail, MessageCircle } from "react-feather";
import { setCurrentUserKey } from "../../redux/actions/currentUserKeyActions";
import constants from "../../constants";
import FileDialog from "../../components/FileDialog";
import Linkify from "../../components/Linkify";

const Picker = styled(MissivePicker)``;

const Alert = styled(MuiAlert)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const PopoverHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatContainer = styled(Grid)`
  width: 100%;
  height: calc(100% - 1px);
`;

const ChatSidebar = styled(Grid)`
  border-right: ${(props) =>
    props.displayDivider ? `1px solid ${props.theme.palette.divider}` : "none"};
`;

const ChatMain = styled(Grid)``;

const ChatMessages = styled(Grid)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
`;

const ChatMessage = styled.div`
  margin: 12px 24px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
  max-width: 100%;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-flex;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  max-width: 100%;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(2)}px;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
  ${(props) => props.theme.shadows[1]};
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  padding: ${(props) => props.theme.spacing(3)}px
    ${(props) => props.theme.spacing(2)}px;
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Online = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

function HandleBrowserNotifications(props) {
  const { notificationToNotify, setNotificationToNotify } = props;
  const primaryUser = useSelector(getPrimaryUser).user;
  const [hasPermission, setHasPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isPageVisible = usePageVisibility();
  const notificationsRef = React.useRef([]);

  const supportsNotification = Boolean("Notification" in window);

  useEffect(() => {
    if (isPageVisible) {
      notificationsRef.current.forEach(({ close }) => {
        close();
      });
      notificationsRef.current = [];
    }
  }, [isPageVisible]);
  const sendNotification = React.useCallback(
    (title, body) => {
      if (
        !hasPermission ||
        isLoading ||
        isPageVisible ||
        !supportsNotification
      ) {
        return;
      }

      const notification = new Notification(title, {
        body,
        icon: "/icons/android-chrome-192x192.png",
      });

      notification.onclick = function () {
        window.focus();
        notification.close();
      };

      let notificationProps = {
        notification,
        close: () => {
          notification.close();
        },
      };

      notificationsRef.current.push(notificationProps);
    },
    [hasPermission, isPageVisible, isLoading, supportsNotification]
  );
  const checkPermission = () => {
    if (!supportsNotification) {
      return false;
    }

    let returnValue = false;
    try {
      Notification.requestPermission().then;
      returnValue = true;
    } catch (e) {
      returnValue = false;
    }

    return returnValue;
  };
  const handlePermission = (permission) => {
    // set the button to shown or hidden, depending on what the user answers
    if (
      (supportsNotification && permission === "denied") ||
      permission === "default"
    ) {
      setHasPermission(false);
    } else {
      setHasPermission(true);
    }

    setIsLoading(false);
  };
  const requestPermission = () => {
    setIsLoading(true);

    if (!supportsNotification) {
      console.log("This browser does not support notifications.");
    } else {
      if (checkPermission()) {
        Notification.requestPermission().then((permission) => {
          handlePermission(permission);
        });
      } else {
        Notification.requestPermission((permission) => {
          handlePermission(permission);
        });
      }
    }
  };

  const prevNotificationRef = React.useRef(null);
  useEffect(() => {
    if (
      notificationToNotify &&
      !_.isEqual(prevNotificationRef.current, notificationToNotify)
    ) {
      let username = getUserNamePlus({
        overrideUser: notificationToNotify.user,
        compareUser: primaryUser,
        includeIsYou: true,
      });

      sendNotification(username, notificationToNotify.message_body);
      setNotificationToNotify(null);
      prevNotificationRef.current = notificationToNotify;
    }
  }, [notificationToNotify, sendNotification]);

  useEffect(() => {
    requestPermission();
  }, [null]);

  if (isLoading) {
    return null;
  }

  if (!supportsNotification) {
    return null;
  }

  if (hasPermission) {
    return null;
  }

  return (
    <ListItem dense button onClick={requestPermission}>
      <ListItemText
        primary="Enable Notifications"
        secondary="Click here to enable browser notifications"
      />
    </ListItem>
  );
}

function ChatParticipantRenderIsTyping({ user, typingDetail }) {
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;

  const isTypingThreshold = 15 * 1000;

  const getTypingState = React.useCallback(() => {
    let newIsTyping = false;

    if (user && typingDetail) {
      const isPrimaryUsersTypingDetail =
        typingDetail.user_id === primaryUser.id;

      // we only want to show the typing indicator if the user
      // is typing within the last x amount of time
      newIsTyping =
        typingDetail.is_typing &&
        new Date(typingDetail.createdAt) >= new Date() - isTypingThreshold;

      if (isPrimaryUsersTypingDetail) {
        newIsTyping = false;
      }
    }

    return newIsTyping;
  }, [typingDetail, user, primaryUser]);

  const [isTyping, setIsTyping] = useState(false);

  React.useEffect(() => {
    setIsTyping(getTypingState());

    const interval = setInterval(() => {
      setIsTyping(getTypingState());
    }, isTypingThreshold);

    return () => {
      clearInterval(interval);
    };
  }, [getTypingState]);

  if (!isTyping) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: 10 + 8,
          width: 40 + 8,
          backgroundColor: grey[200],
          borderRadius: 10000,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          "--dot-flashing-color-one": blue[400],
          "--dot-flashing-color-two": grey[300],
        }}
      >
        <div className="dot-flashing" />
      </div>
    </div>
  );
}

function ChatParticipantActivities(props) {
  const {
    messageGroupParticipants,
    messageGroupSeenBys,
    messageGroupMessages,
    selectedMessageGroupId,
    messageGroupTypingDetails,
  } = props;

  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;

  const renderSeenBy = (user, seenBy) => {
    let hasUserSeenMessage = false;

    if (user && seenBy) {
      const isPrimaryUser = user.id === primaryUser.id;
      const isPrimaryUsersSeenBy = seenBy.user_id === primaryUser.id;

      hasUserSeenMessage =
        new Date(seenBy.updatedAt) >=
        new Date(messageGroupMessages[0].createdAt);

      if (isPrimaryUsersSeenBy) {
        hasUserSeenMessage = true;
      }

      if (messageGroupMessages[0].user_id === seenBy.user_id) {
        hasUserSeenMessage = true;
      }
    }

    return (
      <div
        style={{
          fontSize: 12,
          backgroundColor: "white",
          borderRadius: 10000,
          height: 18,
          width: 18,
          zIndex: 1,
          position: "absolute",
          right: -18 / 3,
          bottom: -18 / 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: `1px solid ${hasUserSeenMessage ? blue[500] : grey[500]}`,
        }}
      >
        {hasUserSeenMessage ? (
          <DoneAll
            color="secondary"
            style={{
              fontSize: 14,
              color: blue[500],
            }}
          />
        ) : (
          <Done
            color="secondary"
            style={{
              fontSize: 14,
              color: grey[500],
            }}
          />
        )}
      </div>
    );
  };

  const renderAvatar = (user, seenBy) => {
    let tooltipText = "Unnamed User";

    const isPrimaryUser = user.id === primaryUser.id;

    if (user) {
      let username = getUserNamePlus({
        overrideUser: user,
        compareUser: primaryUser,
        includeIsYou: true,
        // replaceForOnlyYouConditional: true,
      });

      tooltipText = isPrimaryUser ? "You" : user.email;
    }

    return (
      <Grid item>
        <Grid
          container
          style={{
            position: "relative",
          }}
        >
          <Grid item container direction="row" alignItems="flex-end">
            <Tooltip title={tooltipText}>
              <Avatar
                style={{
                  height: 28,
                  width: 28,
                }}
                src={getUserAvatarUrl({ userId: user?.id || -1, size: 28 })}
              />
            </Tooltip>
            {renderSeenBy(user, seenBy)}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderParticipant = (participant) => {
    const user = participant.user;
    const usersSeenBy = _.find(messageGroupSeenBys, { user_id: user.id });
    const typingDetail =
      messageGroupTypingDetails[`${selectedMessageGroupId}-${user.id}`];
    return (
      <Grid item>
        <Grid container flexDirection="row" spacing={2} alignItems="center">
          <Grid item>{renderAvatar(user, usersSeenBy)}</Grid>
          <Grid item>
            <ChatParticipantRenderIsTyping
              user={user}
              typingDetail={typingDetail}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <ChatMessage position="left">
      <Grid container spacing={4} flexDirection="row">
        {messageGroupParticipants
          .sort((a, b) => {
            return a.user_id - b.user_id;
          })
          .map(renderParticipant)}
      </Grid>
    </ChatMessage>
  );
}

function ChatMessageFile(props) {
  const { file } = props;

  const fileEnding = file.file_key.split(".").pop();
  const resourceUrl = getSharableFileUrl({
    fileUUID: file.uuid,
  });

  const isImage = ["jpg", "jpeg", "png", "gif"].includes(fileEnding);
  const showPreview = true;

  return (
    <Grid container>
      <Grid
        item
        style={{
          maxWidth: "100%",
        }}
      >
        {/* <Card
          style={{
            marginTop: 8,
          }}
        >
          <CardContent> */}
        <Grid container spacing={3} justifyContent="start">
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h6" align="left">
                  <File />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" align="left">
                  Attachment
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {isImage && showPreview && (
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <img
                  src={resourceUrl}
                  style={{
                    maxWidth: "100%",
                    borderRadius: 8,
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{
              marginBottom: 12,
            }}
            zeroMinWidth
          >
            <Typography
              variant="body2"
              align="left"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
              }}
            >
              {`${file.file_name}.${fileEnding}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              link
              target={`_blank`}
              href={resourceUrl}
              color="inherit"
              variant="outlined"
            >
              Download
            </Button>
          </Grid>
        </Grid>
        {/* </CardContent>
        </Card> */}
      </Grid>
    </Grid>
  );
}
function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function ChatMessageComponent(props) {
  const { errorDetails, setErrorDetails, message, file } = props;

  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;
  const messageUser = message.user;

  const isActiveUsersMessage = activeUser.id === message.user_id;
  const isPrimaryUsersMessage = primaryUser.id === message.user_id;
  const position = isPrimaryUsersMessage ? "right" : "left";

  const primaryUsername = getUserNamePlus({
    overrideUser: primaryUser,
  });
  const usernameText = isPrimaryUsersMessage
    ? "You"
    : messageUser
    ? getUserNamePlus({
        overrideUser: messageUser,
        compareUser: primaryUser,
        includeIsYou: true,
        // replaceForOnlyYouConditional: true,
      })
    : "Unnamed User";

  const avatarText = _.words(
    isPrimaryUsersMessage ? primaryUsername : usernameText
  )
    .map((w) => w[0])
    .slice(0, 1)
    .join("");

  const getTimeText = () => {
    let createdAtDate = new Date(message.createdAt);
    let timeDifference = new Date() - createdAtDate;
    if (timeDifference < 1000) {
      timeDifference = 1000;
    }
    if (timeDifference < ms("1hr")) {
      return `${ms(timeDifference, { long: true })} ago`;
    } else if (
      dateFormat(createdAtDate, "dddd") === dateFormat(new Date(), "dddd")
    ) {
      // return today and time
      return `Today, ${dateFormat(createdAtDate, "h:MM TT")}`;
    } else if (
      dateFormat(
        new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate() + 1
        ),
        "dddd"
      ) === dateFormat(new Date(), "dddd")
    ) {
      // return yesterday and time
      return `Yesterday, ${dateFormat(createdAtDate, "h:MM TT")}`;
    } else {
      // return date and time
      return `${dateFormat(createdAtDate, "dddd, yyyy h:MM TT")}`;
    }
  };

  const [timeText, setTimeText] = useState(getTimeText());

  // Used to ensure that the chat messages time stamps are always up to date
  // this is a bit of a hack, but it works
  // we can also listen for if the tab is active or not
  // if it is not active, we can stop the interval
  // otherwise we can start it again

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimeText(getTimeText());
    }, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar
          style={{
            backgroundColor: stringToColor_alpha(messageUser?.email || ""),
          }}
          src={getUserAvatarUrl({
            userId: messageUser?.id || -1,
            size: 34,
          })}
        />
        <ChatMessageBubble
          highlighted={position === "right"}
          style={
            errorDetails && errorDetails.errorMessage
              ? {
                  background: red[500],
                }
              : {}
          }
        >
          <Box>
            <ChatMessageBubbleName variant="body1">
              {usernameText}
            </ChatMessageBubbleName>
          </Box>
          {/* <Box>
            <ReactMarkdown
              children={message.message_body}
              // remarkPlugins={[remarkGfm, remarkExternalLinks]}
              skipHtml={true}
            />
          </Box> */}
          {file ? (
            <ChatMessageFile file={file} />
          ) : (
            <Typography
              variant="body2"
              align="left"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
              }}
            >
              {`${message.message_body}`.split("\n").map((text, index) => {
                return (
                  <>
                    <Linkify
                      text={text}
                      renderText={(text) => text}
                      renderLink={(url) => {
                        const u = new URL(url);
                        const sameSite =
                          new URL(url).host === window.location.host;

                        const props = {
                          key: url,
                          href: !sameSite ? url : undefined,
                          to: sameSite ? u.pathname : undefined,
                          target: !sameSite ? "_blank" : undefined,
                          component: sameSite ? NavLink : undefined,
                          underline: "always",
                          variant: "body2",
                          style: {
                            color: "white",
                          },
                        };

                        return <Link {...props}>{url}</Link>;
                      }}
                    />
                    <br />
                  </>
                );
              })}
            </Typography>
          )}
        </ChatMessageBubble>
        {errorDetails && errorDetails.errorMessage && (
          <Typography style={{ color: red[500] }}>
            {errorDetails && errorDetails.errorMessage}
          </Typography>
        )}
        <ChatMessageTime variant="body2">{timeText}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function ChatSidebarComponent(props) {
  const containerStyle = props.style || {};

  const { isExtraSmall } = useDeviceBreakpoint("lg");
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;
  const isPrimaryUser = useSelector(getIsPrimaryUser);

  const [selectedActableUser, setSelectedActableUser] = useState(null);

  const [isLoading, setIsLoading] = React.useState(true);

  const [query, setQuery] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);

  const {
    isConnected,

    refreshCount,
    setRefreshCount,

    errorMessage,
    setErrorMessage,

    messageGroups,
    setMessageGroups,
    messageGroupCount,
    setMessageGroupCount,

    messagesRefreshCount,
    setMessagesRefreshCount,
    groupsRefreshCount,
    setGroupsRefreshCount,

    messageGroupSeenBys,
    setMessageGroupSeenBys,
    messageGroupSeenByCount,
    setMessageGroupSeenByCount,

    unseenGroups,

    notificationToNotify,
    setNotificationToNotify,
  } = props;

  React.useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        let response = await getAllMessageGroups(
          {
            order: [orderBy, order],
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            query,
          },
          {
            forcePrimaryUser: true,
          }
        );

        setMessageGroups(response.data.messageGroups);
        setMessageGroupCount(response.data.messageGroupCount);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage:
              "Something went wrong while loading the chat groups.",
          })
        );
      }
      setIsLoading(false);
    };

    load();
  }, [refreshCount, query]);

  const findOrCreatePrivate = ({ withUserId }) => {
    let load = async () => {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        let response = await findOrCreatePrivateMessageGroup(
          {
            withUserId,
          },
          {
            forcePrimaryUser: true,
          }
        );
        setRefreshCount(refreshCount + 1);
        props.setSelectedMessageGroupId(response.data.messageGroup.id);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage:
              "Something went wrong while finding or creating a private chat group.",
          })
        );
      }
      setIsLoading(false);
    };

    load();
  };

  const findOrCreatePrivateMessageWithParent = () => {
    let load = async () => {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        let userResponse = await getUser({
          forcePrimaryUser: true,
        });

        if (!userResponse.data.user.parent_user_id) {
          throw new Error(
            "Oops! Looks like you don't have an account manager assigned to you yet."
          );
        }

        let response = await findOrCreatePrivateMessageGroup(
          {
            withUserId: userResponse.data.user.parent_user_id,
          },
          {
            forcePrimaryUser: true,
          }
        );
        setRefreshCount(refreshCount + 1);
        props.setSelectedMessageGroupId(response.data.messageGroup.id);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage:
              "Something went wrong while finding or creating a private chat group.",
          })
        );
      }
      setIsLoading(false);
    };

    load();
  };

  React.useEffect(() => {
    if (selectedActableUser) {
      findOrCreatePrivate({ withUserId: selectedActableUser.id });
      setSelectedActableUser(null);
    }
  }, [selectedActableUser]);

  return (
    <ChatSidebar
      item
      {...(props.useSplitView
        ? {
            xs: 12,
            sm: 4,
            lg: 3,
          }
        : {
            xs: true,
          })}
      displayDivider={props.useSplitView ? !isExtraSmall : false}
      direction="column"
      container
      style={containerStyle}
    >
      <Grid item container alignItems="center">
        <Box
          p={2}
          style={{
            minHeight: 75,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <AutocompleteActableUsers
            selectedUser={selectedActableUser}
            setSelectedUser={setSelectedActableUser}
            textfieldProps={{
              required: false,
              autoFocus: false,
              label: "Search Available Contacts",
            }}
          />
        </Box>
      </Grid>
      <Divider />
      <Grid
        item
        xs
        style={{
          overflowY: "auto",
          display: "flex",
        }}
      >
        <Grid
          container
          style={{
            height: "100%",
            maxWidth: "100%",
          }}
        >
          <Grid item xs>
            <List>
              {!isPrimaryUser && (
                <ListItem>
                  <Alert
                    severity="info"
                    style={{
                      width: "100%",
                    }}
                  >
                    You're in a different workspace, but your messages will
                    still be coming directly from you ({primaryUser.email}).
                    Currently, you can only see your own messages.
                  </Alert>
                </ListItem>
              )}

              {errorMessage && (
                <ListItem>
                  <Alert
                    severity="error"
                    style={{
                      width: "100%",
                    }}
                  >
                    {errorMessage}
                  </Alert>
                </ListItem>
              )}

              {!isConnected && (
                <ListItem>
                  <Alert
                    severity="warning"
                    style={{
                      width: "100%",
                    }}
                  >
                    You're not connected to the server.
                    <br />
                    <br />
                    The server may be receiving a quick update and will be back
                    shortly.
                    <br />
                    <br />
                    If the issue persists, please try refreshing or checking
                    your internet connection.
                  </Alert>
                </ListItem>
              )}

              <HandleBrowserNotifications
                notificationToNotify={notificationToNotify}
                setNotificationToNotify={setNotificationToNotify}
              />

              <ListItem
                dense
                button
                onClick={findOrCreatePrivateMessageWithParent}
              >
                <ListItemIcon>
                  <Avatar
                    style={{
                      backgroundColor: blue[600],
                    }}
                  >
                    <SupervisorAccount />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary="Account Manager"
                  secondary="Message your account manager"
                />
              </ListItem>

              <ListItem
                dense
                button
                onClick={() => {
                  findOrCreatePrivate({
                    withUserId: primaryUser.id,
                  });
                }}
              >
                <ListItemIcon>
                  <Avatar
                    style={{
                      backgroundColor: blue[300],
                    }}
                  >
                    <Create />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary="Create Note"
                  secondary="A private message group with only yourself"
                />
              </ListItem>
              <Divider />
              {isLoading ? (
                <>
                  <ListItem>
                    <ListItemText primary="Loading..." />
                  </ListItem>
                </>
              ) : messageGroups.length < 1 ? (
                <>
                  <ListItem>
                    <ListItemText
                      primary="No messages found."
                      secondary="Please try again later."
                    />
                  </ListItem>
                </>
              ) : (
                <>
                  {_.sortBy(messageGroups, (a) => {
                    return -new Date(
                      _.get(a, "first_messages[0].updatedAt", 0)
                    );
                  }).map((row) => {
                    const firstRelevantParticipant =
                      row.message_group_participants.length > 1
                        ? _.find(
                            row.message_group_participants,
                            (participant) =>
                              participant.user.id !== primaryUser.id
                          )
                        : row.message_group_participants[0];

                    const firstRelevantParticipantName = firstRelevantParticipant
                      ? getUserNamePlus({
                          overrideUser: firstRelevantParticipant.user,
                          compareUser: primaryUser,
                          includeIsYou: true,
                          replaceForOnlyYouConditional: true,
                          overrideIsYouText: "Note To Self",
                        })
                      : "Unnamed User";

                    const hasUnseenMessages = _.find(
                      unseenGroups,
                      (id) => id === row.id
                    );

                    return (
                      <ListItem
                        key={row.id}
                        button
                        onClick={() => {
                          if (row.id === props.selectedMessageGroupId) {
                            setMessagesRefreshCount((p) => p + 1);
                          } else {
                            props.setSelectedMessageGroupId(row.id);
                          }
                        }}
                        selected={props.selectedMessageGroupId === row.id}
                      >
                        <ListItemIcon>
                          <Indicator
                            invisible={!hasUnseenMessages}
                            badgeContent={"New"}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <Avatar
                              src={getUserAvatarUrl({
                                userId:
                                  firstRelevantParticipant?.user?.id || -1,
                              })}
                            />
                          </Indicator>
                        </ListItemIcon>
                        <ListItemText
                          primary={firstRelevantParticipantName}
                          secondary={_.get(
                            row,
                            "first_messages[0].message_body"
                          )}
                          secondaryTypographyProps={{
                            noWrap: true,
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </>
              )}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </ChatSidebar>
  );
}

function ChatMainComponent(props) {
  const containerStyle = props.style || {};

  const dispatch = useDispatch();

  const { isExtraSmall } = useDeviceBreakpoint("lg");
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;
  const selectedMessageGroupId = props.selectedMessageGroupId;

  const inputFormRef = React.useRef(null);

  const [showMoreAnchorEl, setShowMoreAnchorEl] = React.useState(null);
  const isShowMoreOpen = Boolean(showMoreAnchorEl);
  const showMoreId = isShowMoreOpen ? "show-more-popover" : undefined;

  const {
    refreshCount,
    setRefreshCount,

    errorMessage,
    setErrorMessage,

    messagesContainerRef,
    scrollToBottom,

    messageGroupParticipants,
    setMessageGroupParticipants,

    messageGroupParticipantCount,
    setMessageGroupParticipantCount,

    messageGroupMessageCount,
    setMessageGroupMessageCount,

    messageGroupMessages,
    setMessageGroupMessages,

    messagesRefreshCount,
    setMessagesRefreshCount,
    groupsRefreshCount,
    setGroupsRefreshCount,

    messageGroupSeenBys,
    setMessageGroupSeenBys,
    messageGroupSeenByCount,
    setMessageGroupSeenByCount,

    unseenGroups,

    messageGroupTypingDetails,
    setMessageGroupTypingDetails,
  } = props;

  const [message, setMessage] = useLocalStorage(
    `${primaryUser.id}-message-cache`,
    "",
    {
      syncTab: true,
      syncPage: true,
    }
  );
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = React.useState(false);
  const handleInsertEmoji = (props) => {
    setMessage(message + props.native);
    setIsEmojiPickerOpen(false);
  };

  React.useEffect(() => {
    socket.emit("message_group:typing", {
      message_group_id: selectedMessageGroupId,
      is_typing: message && message.length > 0 ? true : false,
      clientExtras: {
        hello: "world",
        message: message,
      },
    });
  }, [message]);

  const [chatMessageErrorDetails, setChatMessageErrorDetails] = React.useState(
    {}
  );

  const setErrorDetails = (id, props) => {
    setChatMessageErrorDetails((prev) => {
      let newErrors = _.cloneDeep(prev);

      if (props === null || props === undefined) {
        delete newErrors[id];
      } else {
        let entry = { id, errorMessage: props.errorMessage };
        newErrors[id] = entry;
      }

      return newErrors;
    });
  };

  const handleSend = React.useCallback(
    (props) => {
      if (!props) {
        props = {};
      }

      const { file } = props;

      if (!message && !file) {
        return;
      }

      const entry = {
        id: nanoid(),
        user_id: primaryUser.id,
        message_group_id: selectedMessageGroupId,
        message_body: file ? file.file_name : `${message}`,
        file_id: file ? file.id : null,
        file: file ? file : null,
        user: primaryUser,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      };

      // send message
      setMessageGroupMessages((prevMessages) => {
        return [entry, ...prevMessages];
      });
      setMessage("");
      setTimeout(scrollToBottom, 0);

      // update to the database

      let load = async () => {
        try {
          await createNewMessage(
            {
              messageGroupId: selectedMessageGroupId,
              messageBody: entry.message_body,
              fileId: entry.file_id,
            },
            {
              forcePrimaryUser: true,
            }
          );
        } catch (error) {
          let errorDetails = {
            errorMessage: getRequestErrorMessage({
              error,
              fallbackMessage:
                "Something went wrong while sending your message.",
            }),
          };

          setErrorDetails(entry.id, errorDetails);
        }
      };

      load();
    },
    [message, selectedFile, selectedMessageGroupId]
  );

  React.useEffect(() => {
    let callback = (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        handleSend();
      }
    };

    if (inputFormRef.current) {
      inputFormRef.current.addEventListener("keydown", callback);
    }

    return () => {
      if (inputFormRef.current) {
        inputFormRef.current.removeEventListener("keydown", callback);
      }
    };
  }, [handleSend]);

  React.useEffect(() => {
    // initial load
    scrollToBottom();
  }, []);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);

  const [query, setQuery] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    const load = async () => {
      setIsLoadingMore(true);

      try {
        const response = await getAllMessages(
          {
            messageGroupId: selectedMessageGroupId,
            order: ["updatedAt", "desc"],
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            query,
          },
          {
            forcePrimaryUser: true,
          }
        );

        setMessageGroupMessages((prev) => {
          return _.uniqBy([...prev, ...response.data.messages], "id");
        });
        setMessageGroupMessageCount(response.data.messageCount);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "Error loading chat messages.",
          })
        );
      }

      setIsLoadingMore(false);
    };

    if (page > 0) {
      load();
    }
  }, [page]);

  React.useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      setErrorMessage(null);

      try {
        const response = await getAllMessages(
          {
            messageGroupId: selectedMessageGroupId,
            order: ["updatedAt", "desc"],
            offset: 0,
            limit: rowsPerPage,
            query,
          },
          {
            forcePrimaryUser: true,
          }
        );
        const response_participants = await getAllMessageGroupParticipants(
          {
            messageGroupId: selectedMessageGroupId,
            order: ["updatedAt", "desc"],
            offset: 0,
            limit: 100,
            query: null,
          },
          {
            forcePrimaryUser: true,
          }
        );
        const response_seenBys = await getAllMessageGroupSeenBys(
          {
            messageGroupId: selectedMessageGroupId,
            order: ["updatedAt", "desc"],
            offset: 0,
            limit: 100,
            query: null,
          },
          {
            forcePrimaryUser: true,
          }
        );
        const response_updateSeenBy = await updateMessageGroupSeenBy(
          {
            messageGroupId: selectedMessageGroupId,
          },
          {
            forcePrimaryUser: true,
          }
        );

        setMessageGroupParticipants(
          response_participants.data.messageGroupParticipants
        );
        setMessageGroupParticipantCount(
          response_participants.data.messageGroupParticipantCount
        );

        setMessageGroupMessages(response.data.messages);
        setMessageGroupMessageCount(response.data.messageCount);

        setMessageGroupSeenBys(response_seenBys.data.messageGroupSeenBys);
        setMessageGroupSeenByCount(
          response_seenBys.data.messageGroupSeenByCount
        );

        setTimeout(() => {
          scrollToBottom();
        }, 0);
      } catch (error) {
        setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "Error loading chat messages.",
          })
        );
      }

      setIsLoading(false);
    };

    if (selectedMessageGroupId) {
      load();
    } else {
      setMessageGroupParticipants([]);
      setMessageGroupParticipantCount(0);
      setMessageGroupMessages([]);
      setMessageGroupMessageCount(0);
      setMessageGroupSeenBys([]);
      setMessageGroupSeenByCount(0);
    }

    setPage(0);
  }, [refreshCount, selectedMessageGroupId, query]);

  const [isFilePickerOpen, setIsFilePickerOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleOnOpenFile = React.useCallback((file) => {
    handleSend({ file });
  });

  const firstRelevantParticipant =
    messageGroupParticipants.length > 1
      ? messageGroupParticipants.find(
          (participant) => participant.user.id !== primaryUser.id
        )
      : messageGroupParticipants[0];

  const firstRelevantParticipantName = firstRelevantParticipant
    ? getUserNamePlus({
        overrideUser: firstRelevantParticipant.user,
        compareUser: primaryUser,
        includeIsYou: true,
        replaceForOnlyYouConditional: true,
        overrideIsYouText: "Note To Self",
      })
    : "Unnamed User";

  const possibleSwitchableParticipants = messageGroupParticipants.filter(
    (participant) => {
      if (participant.user_id === primaryUser.id) return false;

      if (primaryUser.role !== constants.roles.user.typeName) {
        return true;
      }

      return false;
    }
  );

  return (
    <ChatMain
      item
      {...(props.useSplitView
        ? {
            xs: 12,
            sm: 8,
            lg: 9,
          }
        : {
            xs: true,
          })}
      id="chat-main"
      direction="column"
      container
      style={containerStyle}
    >
      <Grid item container alignItems="center">
        <Box
          p={2}
          style={{
            minHeight: 75,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>
              <IconButton
                onClick={() => {
                  props.setSelectedMessageGroupId(null);
                }}
              >
                <Badge
                  invisible={unseenGroups.length === 0}
                  color="primary"
                  variant="dot"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <ArrowBack />
                </Badge>
              </IconButton>
            </Grid>
            <Grid item xs container alignItems="center">
              {selectedMessageGroupId && !isLoading && (
                <Grid item>
                  <ChatMessageAvatar
                    style={{
                      backgroundColor: stringToColor_alpha(
                        firstRelevantParticipant?.user?.email || ""
                      ),
                    }}
                    src={getUserAvatarUrl({
                      userId: firstRelevantParticipant?.user?.id || -1,
                      size: 34,
                    })}
                  />
                </Grid>
              )}
              <Grid
                item
                xs
                style={{
                  overflow: "hidden",
                }}
              >
                <Typography noWrap>
                  {isLoading ? (
                    "Loading..."
                  ) : selectedMessageGroupId ? (
                    <>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {firstRelevantParticipantName}
                      </span>
                      <br />
                      <span>
                        {firstRelevantParticipant
                          ? firstRelevantParticipant.user.email
                          : null}
                      </span>
                    </>
                  ) : (
                    "Select a chat"
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={"More Options"} placement="left">
                  <IconButton
                    onClick={(e) => {
                      setShowMoreAnchorEl(
                        e.currentTarget
                        // e.currentTarget ? null : e.currentTarget
                      );
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <Popover
                  id={showMoreId}
                  open={isShowMoreOpen}
                  anchorEl={showMoreAnchorEl}
                  onClose={() => {
                    setShowMoreAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <PopoverHeader
                    p={2}
                    variant="outlined"
                    style={{
                      width: 300,
                      maxWidth: "100%",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item>
                        <Typography variant="subtitle1" color="textPrimary">
                          More Options
                        </Typography>
                      </Grid>
                    </Grid>
                  </PopoverHeader>
                  <List disablePadding>
                    {possibleSwitchableParticipants.length > 0 && false ? (
                      possibleSwitchableParticipants.map((participant) => {
                        return (
                          <ListItem
                            key={participant.id}
                            divider
                            button
                            onClick={() => {
                              console.log(
                                "Trying to switch to user: ",
                                participant.user_id
                              );
                            }}
                          >
                            <ListItemText
                              primary="Try Switching"
                              secondary={<>{participant.user.email}</>}
                            />
                          </ListItem>
                        );
                      })
                    ) : (
                      <>
                        <ListItem divider>
                          <ListItemText
                            primary="Options Unavailable"
                            secondary={
                              "Please try checking back later, selecting the chat again, or refreshing your browser."
                            }
                          />
                        </ListItem>
                      </>
                    )}
                    <Box p={1} display="flex" justifyContent="center">
                      <Button
                        size="small"
                        link
                        onClick={() => {
                          setShowMoreAnchorEl(null);
                        }}
                        fullWidth
                        to="#"
                      >
                        Close
                      </Button>
                    </Box>
                  </List>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Divider />
      <ChatMessages item xs ref={messagesContainerRef}>
        {!selectedMessageGroupId ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
              height: "100%",
              padding: 16,
            }}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                align="center"
                style={{
                  fontSize: 18,
                  opacity: 0.5,
                  fontWeight: "bold",
                }}
              >
                No chat selected
              </Typography>
            </Grid>
          </Grid>
        ) : isLoading ? (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height: "100%",
                padding: 16,
              }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  align="center"
                  style={{
                    fontSize: 18,
                    opacity: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Loading...
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : errorMessage ? (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height: "100%",
                padding: 16,
              }}
            >
              <Grid item>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            </Grid>
          </>
        ) : messageGroupMessages.length < 1 ? (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height: "100%",
                padding: 16,
              }}
            >
              <Grid item>
                <Typography color="textSecondary" align="center">
                  Nothing to see here.
                  <br />
                  Be the first one to start the conversation!
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <ChatParticipantActivities
              messageGroupParticipants={messageGroupParticipants}
              messageGroupSeenBys={messageGroupSeenBys}
              messageGroupMessages={messageGroupMessages}
              selectedMessageGroupId={selectedMessageGroupId}
              messageGroupTypingDetails={messageGroupTypingDetails}
            />
            {messageGroupMessages.map((message) => {
              return (
                <ChatMessageComponent
                  key={`message-component-${message.id}`}
                  setErrorDetails={(props) => {
                    setErrorDetails(message.id, props);
                  }}
                  errorDetails={chatMessageErrorDetails[message.id]}
                  message={message}
                  file={message.file}
                />
              );
            })}
            {/* load more */}
            {messageGroupMessages.length > 0 && (
              <>
                <Grid container justifyContent="center">
                  <Button
                    // variant="outlined"
                    color="primary"
                    // link
                    disabled={
                      isLoading ||
                      isLoadingMore ||
                      messageGroupMessages.length >= messageGroupMessageCount
                    }
                    onClick={() => {
                      setPage((prev) => prev + 1);
                    }}
                  >
                    {isLoadingMore ? (
                      <>
                        <CircularProgress size={24} />
                      </>
                    ) : (
                      `${
                        messageGroupMessages.length >= messageGroupMessageCount
                          ? "You've reached the end of the chat"
                          : "Load More"
                      } - Viewing ${messageGroupMessages.length} of 
                    ${messageGroupMessageCount}`
                    )}
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </ChatMessages>

      <Divider />
      <ChatInput
        container
        component="form"
        ref={inputFormRef}
        onSubmit={(e) => {
          e.preventDefault();
          handleSend();
        }}
        wrap="nowrap"
      >
        <MuiPopover
          open={isEmojiPickerOpen}
          anchorEl={messagesContainerRef.current}
          onClose={() => {
            setIsEmojiPickerOpen(false);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <Grid
            container
            style={{
              padding: 4,
            }}
          >
            <Picker
              onSelect={handleInsertEmoji}
              emoji="grinning"
              title="Pick your emoji…"
              perLine={8}
            />
          </Grid>
        </MuiPopover>
        <Grid item>
          <Grid
            style={{
              height: "100%",
            }}
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <IconButton
                size="small"
                onClick={() => {
                  setIsEmojiPickerOpen((prev) => !prev);
                }}
                disabled={!selectedMessageGroupId}
              >
                <InsertEmoticon />
              </IconButton>
            </Grid>
            <FileDialog
              open={isFilePickerOpen}
              setOpen={setIsFilePickerOpen}
              onOpenFile={(file) => {
                handleOnOpenFile(file);
              }}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              forcePrimaryUser={true}
            />
            <Grid item>
              <IconButton
                size="small"
                onClick={() => {
                  setIsFilePickerOpen((prev) => !prev);
                }}
                disabled={!selectedMessageGroupId}
              >
                <AttachFile />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <TextField
            variant="outlined"
            label="Type your message"
            multiline
            minRows={1}
            maxRows={15}
            fullWidth
            value={message}
            disabled={!selectedMessageGroupId}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid
            style={{ height: "100%" }}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Box ml={2}>
              <Fab
                color="primary"
                aria-label="add"
                size="small"
                type="submit"
                disabled={
                  !message || message.length < 1 || !selectedMessageGroupId
                }
              >
                <SendIcon />
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </ChatInput>
    </ChatMain>
  );
}

function ChatWindow(props) {
  const activeUser = useSelector(getActiveUser).user;
  const primaryUser = useSelector(getPrimaryUser).user;

  const containerStyle = props.style || {};

  const useSplitView =
    typeof props.useSplitView === "boolean" ? props.useSplitView : true;

  const { isExtraSmall } = useDeviceBreakpoint("lg");

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [messagesRefreshCount, setMessagesRefreshCount] = React.useState(0);
  const [groupsRefreshCount, setGroupsRefreshCount] = React.useState(0);

  const [messagesErrorMessage, setMessagesErrorMessage] = React.useState(null);
  const [groupsErrorMessage, setGroupsErrorMessage] = React.useState(null);

  const [selectedMessageGroupId, setSelectedMessageGroupId] = useLocalStorage(
    `${primaryUser.id}-selectedMessageGroupId-cache`,
    null,
    {
      syncTab: true,
      syncPage: true,
      parser: (state) => {
        if (state === null) {
          return null;
        }
        return parseInt(state);
      },
    }
  );

  const [messageGroups, setMessageGroups] = useState([]);
  const [messageGroupCount, setMessageGroupCount] = React.useState(0);

  const [messageGroupTypingDetails, setMessageGroupTypingDetails] = useState(
    {}
  );

  const [
    messageGroupParticipants,
    setMessageGroupParticipants,
  ] = React.useState([]);
  const [
    messageGroupParticipantCount,
    setMessageGroupParticipantCount,
  ] = React.useState(0);

  const [messageGroupSeenBys, setMessageGroupSeenBys] = React.useState([]);
  const [messageGroupSeenByCount, setMessageGroupSeenByCount] = React.useState(
    0
  );

  const [messageGroupMessages, setMessageGroupMessages] = React.useState([]);
  const [
    messageGroupMessageCount,
    setMessageGroupMessageCount,
  ] = React.useState(0);

  const messagesContainerRef = React.useRef(null);
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const [notificationToNotify, setNotificationToNotify] = React.useState(null);

  React.useEffect(() => {
    if (messageGroupMessages && messageGroupMessages.length > 0) {
      setMessageGroups((prev) => {
        // we should update the message group "first_messages"

        const replaceWithMessage = messageGroupMessages[0];

        const newMessageGroups = [...prev];
        const messageGroupIndex = newMessageGroups.findIndex(
          (messageGroup) =>
            messageGroup.id === replaceWithMessage.message_group_id
        );

        if (messageGroupIndex > -1) {
          newMessageGroups[messageGroupIndex].first_messages = [
            replaceWithMessage,
          ];
        }

        return newMessageGroups;
      });
    }
  }, [messageGroupMessages]);

  const selectedMessageGroupIdRef = React.useRef(selectedMessageGroupId);
  useEffect(() => {
    selectedMessageGroupIdRef.current = selectedMessageGroupId;
  }, [selectedMessageGroupId]);

  const isPageVisible = usePageVisibility();
  const isPageVisibleRef = React.useRef(isPageVisible);

  let [isChatVisible, setIsChatVisible] = React.useState(
    props.isChatVisible !== undefined ? props.isChatVisible : true
  );
  const isChatVisibleRef = React.useRef(isChatVisible);

  if (
    props.isChatVisible !== undefined &&
    props.setIsChatVisible !== undefined
  ) {
    isChatVisible = props.isChatVisible;
    setIsChatVisible = props.setIsChatVisible;
  }

  useEffect(() => {
    isChatVisibleRef.current = isChatVisible;
    isPageVisibleRef.current = isPageVisible;
    if (
      selectedMessageGroupIdRef.current &&
      isChatVisibleRef.current &&
      isPageVisibleRef.current
    ) {
      updateMessageGroupSeenBy({
        messageGroupId: selectedMessageGroupIdRef.current,
      }).catch((error) => {
        // console.log(error);
      });
    }
  }, [isChatVisible, isPageVisible]);

  // --------------------------------------------------
  // ---------------  LISTEN FOR EVENTS  -----------------

  useEffect(() => {
    async function messageCreated(socketProps) {
      const message = socketProps.message;

      const isPrimaryUsersMessage = primaryUser.id === message.user_id;

      if (
        typeof selectedMessageGroupIdRef.current === "number" &&
        selectedMessageGroupIdRef.current === message.message_group_id
      ) {
        if (isChatVisibleRef.current && isPageVisibleRef.current) {
          // we need to let the server know we are up to date on the message group

          updateMessageGroupSeenBy(
            {
              messageGroupId: selectedMessageGroupIdRef.current,
            },
            {
              forcePrimaryUser: true,
            }
          ).catch((error) => {
            // console.log(error);
          });
        }

        // we need to update the message.
        // this will also automatically update the preview text

        let pushedNewMessage = false;

        // if we are already scrolled all the way down, we should scroll to the bottom after pushing the new message
        let shouldScrollToBottom = messagesContainerRef.current
          ? messagesContainerRef.current.scrollHeight -
              messagesContainerRef.current.scrollTop ===
            messagesContainerRef.current.clientHeight
          : false;

        setMessageGroupMessages((prev) => {
          let next = _.cloneDeep(prev);

          if (isPrimaryUsersMessage) {
            let potentialLocallyAddedMessage = null;
            let potentialLocallyAddedMessageIndex = _.findIndex(
              next,
              (prevMessage) =>
                typeof prevMessage.id === "string" &&
                prevMessage.user_id === primaryUser.id
            );

            if (potentialLocallyAddedMessageIndex > -1) {
              potentialLocallyAddedMessage =
                next[potentialLocallyAddedMessageIndex];
            }

            if (
              potentialLocallyAddedMessage &&
              potentialLocallyAddedMessage.message_body ===
                message.message_body &&
              potentialLocallyAddedMessageIndex < 3 // must be one of the latest 3 messages
            ) {
              next[potentialLocallyAddedMessageIndex] = message;
            } else {
              pushedNewMessage = true;
              next = [message, ...next];
            }
          } else {
            pushedNewMessage = true;
            next = [message, ...next];
          }

          return next;
        });

        if (pushedNewMessage) {
          setNotificationToNotify(message);
          setMessageGroupMessageCount((prev) => prev + 1);
        }
        if (shouldScrollToBottom) {
          setTimeout(scrollToBottom, 0);
        }
      } else {
        // this is for a different group than we are viewing or we have no selected group
        setNotificationToNotify(message);

        let shouldAddNewGroup = false;
        setMessageGroups((prev) => {
          let next = _.cloneDeep(prev);
          let messageGroupIndex = _.findIndex(
            next,
            (messageGroup) => messageGroup.id === message.message_group_id
          );
          if (messageGroupIndex > -1) {
            // we need to update the preview text
            // we have a message group, update its first messages
            next[messageGroupIndex].first_messages = [message];
          } else {
            shouldAddNewGroup = true;
          }
          return next;
        });
        if (shouldAddNewGroup) {
          try {
            const messageGroupResponse = await getSingleMessageGroup({
              messageGroupId: message.message_group_id,
            });

            setMessageGroupCount((prev) => prev + 1);
            setMessageGroups((prev) => {
              return [messageGroupResponse.data.messageGroup, ...prev];
            });
          } catch (error) {
            setGroupsErrorMessage(
              getRequestErrorMessage({
                error,
                fallbackMessage:
                  "There was an error getting message group you've been added to",
              })
            );
          }
        }
      }
    }
    async function messageGroupDeleted(socketProps) {
      setSelectedMessageGroupId(null);
      setMessageGroupCount((prev) => prev - 1);
      setMessageGroups((prev) => {
        return prev.filter(
          (messageGroup) => messageGroup.id !== socketProps.message_group.id
        );
      });
    }
    async function messageGroupParticipantCreated(socketProps) {
      if (
        selectedMessageGroupIdRef.current &&
        socketProps.message_group_participant.message_group_id ===
          selectedMessageGroupIdRef.current
      ) {
        let shouldIncreaseParticipantCount = false;
        setMessageGroupParticipants((prev) => {
          let next = _.cloneDeep(prev);
          let prevIndex = _.findIndex(
            next,
            (messageGroupParticipant) =>
              messageGroupParticipant.user_id ===
              socketProps.message_group_participant.user_id
          );
          if (prevIndex === -1) {
            shouldIncreaseParticipantCount = true;
            next = [...next, socketProps.message_group_participant];
          } else {
            next[prevIndex] = socketProps.message_group_participant;
          }

          return next;
        });

        if (shouldIncreaseParticipantCount) {
          setMessageGroupParticipantCount((prev) => prev + 1);
        }
      }
    }
    async function messageGroupParticipantDeleted(socketProps) {
      if (
        selectedMessageGroupIdRef.current &&
        socketProps.message_group_participant.message_group_id ===
          selectedMessageGroupIdRef.current
      ) {
        let shouldDecreaseParticipantCount = false;
        setMessageGroupParticipants((prev) => {
          let next = prev.filter(
            (messageGroupParticipant) =>
              messageGroupParticipant.user_id !==
              socketProps.message_group_participant.user_id
          );
          if (next.length < prev.length) {
            shouldDecreaseParticipantCount = true;
          }
          return next;
        });

        let shouldDecreaseSeenByCount = false;
        setMessageGroupSeenBys((prev) => {
          let next = prev.filter(
            (messageGroupSeenBy) =>
              messageGroupSeenBy.user_id !==
              socketProps.message_group_participant.user_id
          );
          if (next.length < prev.length) {
            shouldDecreaseSeenByCount = true;
          }
          return next;
        });

        if (shouldDecreaseParticipantCount) {
          setMessageGroupParticipantCount((prev) => prev - 1);
        }

        if (shouldDecreaseSeenByCount) {
          setMessageGroupSeenByCount((prev) => prev - 1);
        }
      }

      if (socketProps.message_group_participant.user_id === primaryUser.id) {
        // We just got removed from a group
        if (
          selectedMessageGroupIdRef.current &&
          socketProps.message_group_participant.message_group_id ===
            selectedMessageGroupIdRef.current
        ) {
          setSelectedMessageGroupId(null);
        }
        setMessageGroupCount((prev) => prev - 1);
        setMessageGroups((prev) => {
          return prev.filter(
            (messageGroup) =>
              messageGroup.id !==
              socketProps.message_group_participant.message_group_id
          );
        });
      }
    }
    async function messageGroupSeenByUpdated(socketProps) {
      const messageGroupSeenBy = socketProps.message_group_seen_by;

      const isPrimaryUsersSeenBy =
        messageGroupSeenBy.user_id === primaryUser.id;

      // update the message group seen bys

      if (
        typeof selectedMessageGroupIdRef.current === "number" &&
        selectedMessageGroupIdRef.current ===
          messageGroupSeenBy.message_group_id
      ) {
        let shouldIncreaseSeenByCount = false;
        setMessageGroupSeenBys((prev) => {
          let next = _.cloneDeep(prev);
          let prevIndex = _.findIndex(
            next,
            (mgsb) => mgsb.user_id === messageGroupSeenBy.user_id
          );

          if (prevIndex === -1) {
            shouldIncreaseSeenByCount = true;
            next = [...next, messageGroupSeenBy];
          } else {
            next[prevIndex] = messageGroupSeenBy;
          }

          return next;
        });

        if (shouldIncreaseSeenByCount) {
          setMessageGroupSeenByCount((prev) => prev + 1);
        }
      }

      // update message groups
      setMessageGroups((prev) => {
        const next = _.cloneDeep(prev);

        const messageGroupIndex = next.findIndex(
          (messageGroup) =>
            messageGroup.id === messageGroupSeenBy.message_group_id
        );

        if (messageGroupIndex === -1) {
          return next;
        }

        let prevIndex = _.findIndex(
          next[messageGroupIndex].message_group_seen_bys,
          (mgsb) => mgsb.user_id === messageGroupSeenBy.user_id
        );

        if (prevIndex === -1) {
          next[messageGroupIndex].message_group_seen_bys = [
            ...next[messageGroupIndex].message_group_seen_bys,
            messageGroupSeenBy,
          ];
        } else {
          next[messageGroupIndex].message_group_seen_bys[
            prevIndex
          ] = messageGroupSeenBy;
        }

        return next;
      });
    }
    async function messageGroupTyping(socketProps) {
      setMessageGroupTypingDetails((prev) => {
        let next = _.cloneDeep(prev);

        next[`${socketProps.message_group_id}-${socketProps.user_id}`] = {
          message_group_id: socketProps.message_group_id,
          user_id: socketProps.user_id,
          is_typing: socketProps.is_typing,
          createdAt: new Date(), // ignore the servers date for now
        };

        return next;
      });
    }
    async function connect(socketProps) {
      setIsConnected(true);

      // setMessagesRefreshCount((p) => p + 1);
      setGroupsRefreshCount((p) => p + 1);
    }
    async function disconnect(socketProps) {
      setIsConnected(false);
    }

    let handlers = [
      { handler: messageCreated, event: "message:create" },
      { handler: messageGroupDeleted, event: "message_group:destroy" },
      {
        handler: messageGroupParticipantCreated,
        event: "message_group_participant:create",
      },
      {
        handler: messageGroupParticipantDeleted,
        event: "message_group_participant:destroy",
      },
      {
        handler: messageGroupSeenByUpdated,
        event: "message_group_seen_by:update",
      },
      { handler: messageGroupTyping, event: "message_group:typing" },
      { handler: connect, event: "connect" },
      { handler: disconnect, event: "disconnect" },
    ];

    const listen = () => {
      console.log("Listening for chat events");
      handlers.forEach((handlerProps) => {
        socket.on(handlerProps.event, handlerProps.handler);
      });
    };

    const remove = () => {
      console.log("Stopped listening for chat events");
      handlers.forEach((handlerProps) => {
        socket.off(handlerProps.event, handlerProps.handler);
      });
    };

    listen();
    return remove;
  }, []);

  // --------------------------------------------------

  let [unseenGroups, setUnseenGroups] = useState(
    props.unseenGroups !== undefined ? props.unseenGroups : []
  );
  if (props.unseenGroups !== undefined && props.setUnseenGroups !== undefined) {
    unseenGroups = props.unseenGroups;
    setUnseenGroups = props.setUnseenGroups;
  }

  React.useEffect(() => {
    let newUnseenGroups = messageGroups
      .filter((messageGroup) => {
        // filter out if we are viewing the group

        if (
          messageGroup.id === selectedMessageGroupIdRef.current &&
          isChatVisibleRef.current & isPageVisibleRef.current
        ) {
          return false;
        }

        return true;
      })
      .filter((messageGroup) => {
        const firstMessage = _.get(messageGroup, "first_messages[0]", null);
        const primaryUsersSeenBy = _.find(messageGroup.message_group_seen_bys, {
          user_id: primaryUser.id,
        });

        if (!firstMessage) {
          return false;
        }

        if (primaryUsersSeenBy) {
          let latestMessageDate = new Date(firstMessage.createdAt);
          let primaryUsersLastSeenDate = new Date(primaryUsersSeenBy.updatedAt);

          if (latestMessageDate <= primaryUsersLastSeenDate) {
            return false;
          }
        }

        return true;
      });

    setUnseenGroups(newUnseenGroups.map((messageGroup) => messageGroup.id));
  }, [messageGroups]);

  const passProps = {
    isConnected,
    useSplitView,
    messagesRefreshCount,
    setMessagesRefreshCount,
    groupsRefreshCount,
    setGroupsRefreshCount,
    selectedMessageGroupId,
    setSelectedMessageGroupId,
    messagesContainerRef,
    scrollToBottom,
    messageGroups,
    setMessageGroups,
    messageGroupCount,
    setMessageGroupCount,
    messageGroupParticipants,
    setMessageGroupParticipants,
    messageGroupParticipantCount,
    setMessageGroupParticipantCount,
    messageGroupMessages,
    setMessageGroupMessages,
    messageGroupMessageCount,
    setMessageGroupMessageCount,
    messageGroupSeenBys,
    setMessageGroupSeenBys,
    messageGroupSeenByCount,
    setMessageGroupSeenByCount,
    unseenGroups,
    messageGroupTypingDetails,
    setMessageGroupTypingDetails,
    notificationToNotify,
    setNotificationToNotify,
  };

  return (
    <ChatContainer container component={Card} style={containerStyle}>
      <Grid container>
        <ChatSidebarComponent
          {...passProps}
          refreshCount={groupsRefreshCount}
          setRefreshCount={setGroupsRefreshCount}
          errorMessage={groupsErrorMessage}
          setErrorMessage={setGroupsErrorMessage}
          style={
            !useSplitView
              ? selectedMessageGroupId
                ? { display: "none" }
                : {}
              : isExtraSmall && selectedMessageGroupId
              ? { display: "none" }
              : {}
          }
        />
        <ChatMainComponent
          {...passProps}
          refreshCount={messagesRefreshCount}
          setRefreshCount={setMessagesRefreshCount}
          errorMessage={messagesErrorMessage}
          setErrorMessage={setMessagesErrorMessage}
          style={
            !useSplitView
              ? !selectedMessageGroupId
                ? { display: "none" }
                : {}
              : isExtraSmall && !selectedMessageGroupId
              ? { display: "none" }
              : {}
          }
        />
      </Grid>
    </ChatContainer>
  );
}

export default ChatWindow;
