import React from "react";
import { Chip as MuiChip } from "@material-ui/core";
import constants from "../../constants";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { green, orange, grey, red, yellow } from "@material-ui/core/colors";
import { getOrderStatusColor } from "../../helpers";
const orderStati = constants.hand_written_note_order_status_types;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  background: ${(props) => {
    return getOrderStatusColor(props.order_status);
  }};
  color: ${(props) => {
    switch (props.order_status) {
      case orderStati.pending:
        return "";
      case orderStati.donePending:
        return "";
      case orderStati.creating:
        return "";
      case orderStati.doneCreating:
        return "";
      case orderStati.packaging:
        return "";
      case orderStati.donePackaging:
        return "";
      case orderStati.shipping:
        return props.theme.palette.common.white;
      case orderStati.doneShipping:
        return props.theme.palette.common.white;
      case orderStati.canceling:
        return "";
      case orderStati.doneCanceling:
        return "";
      case orderStati.errored:
        return props.theme.palette.common.white;
    }
  }};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

export default function OrderStatusChip(props) {
  let propsToPass = props.propsToPass ?? {};
  return (
    <Chip
      size="small"
      mr={1}
      mb={1}
      label={props.label ?? props.order_status}
      order_status={props.order_status}
      {...propsToPass}
    />
  );
}
