import React from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { getAllPurchaseOrders } from "../../backend";
import { getRequestErrorMessage } from "../../helpers";

import { Alert as MuiAlert } from "@material-ui/lab";
import OrderStatusChip from "../components/OrderStatusChip";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import { globalHistory } from "../../history";
import ClickableTableCell from "../../components/ClickableTableCell";
import purchaseOrderGroupRowColumns from "../../advancedTableConfigs/purchaseOrderGroupRowColumns";
import AdvancedTable from "../tables/AdvancedTable";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function PurchaseOrderList() {
  const [errorMessage, setErrorMessage] = React.useState(null);

  return (
    <React.Fragment>
      <Helmet title="Purchase Orders" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Purchase Orders
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>Purchase Orders</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <AdvancedTable
            title="Purchase Orders"
            rowColumns={purchaseOrderGroupRowColumns}
            identifier={null}
            getRowData={async ({ limit, offset, order }) => {
              const results = await getAllPurchaseOrders({
                limit,
                offset,
                order,
              });

              let parsedRowData = {
                rows: results.data.purchase_order_groups,
                totalRowCount: results.data.purchase_order_groups_count,
              };

              return parsedRowData;
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PurchaseOrderList;
