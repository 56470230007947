import { globalHistory } from "../history";
import styled from "styled-components/macro";
import { Avatar as MuiAvatar, Box, Chip, IconButton } from "@material-ui/core";
import OrderStatusChip from "../pages/components/OrderStatusChip";
import ClickableTableCell from "../components/ClickableTableCell";
import { RemoveRedEye as RemoveRedEyeIcon } from "@material-ui/icons";
import DisplayUserActivitiesResponsibleUsers from "./DisplayUserActivitiesResponsibleUsers";

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const handleOrderTableCellClick = (row) => {
  globalHistory.push({
    pathname: `/order/${row.id}`,
    state: {
      orderId: row.id,
    },
  });
};

const handWrittenNoteOrderRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Order #",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "user_activities",
    alignment: "left",
    label: "User Activity",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <DisplayUserActivitiesResponsibleUsers userActivities={rowItem} />
    ),
  },
  {
    id: "purchase_order",
    alignment: "left",
    label: "Purchase Order",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return (
        <Customer>
          <Avatar>{`${rowItem}`.slice(0, 1).toUpperCase()}</Avatar>
          <Box ml={3}>{rowItem}</Box>
        </Customer>
      );
    },
  },
  {
    id: "order_status",
    alignment: "left",
    label: "Order Status",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => {
      return <OrderStatusChip order_status={rowItem} />;
    },
  },
  {
    id: "order_qr_code_scan_count",
    alignment: "left",
    label: "Scan Count",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip size="small" mr={1} mb={1} label={rowItem} />
    ),
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    cellComponent: ClickableTableCell,
    getCellProps: ({ row, index, rowItem }) => {
      return {
        onClick: (event) => handleOrderTableCellClick(row),
      };
    },
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        aria-label="details"
        onClick={(event) => handleOrderTableCellClick(row)}
      >
        <RemoveRedEyeIcon />
      </IconButton>
    ),
  },
];

export default handWrittenNoteOrderRowColumns;
