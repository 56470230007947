import { combineReducers } from "redux";

import networkStatusReducer from "./networkStatusReducer";
import usersReducer from "./usersReducer";
import currentUserKeyReducer from "./currentUserKeyReducer";

export default combineReducers({
  networkStatus: networkStatusReducer,
  currentUserKey: currentUserKeyReducer,
  users: usersReducer,
});
