import {
  Chip,
  Avatar as MuiAvatar,
  Box,
  IconButton,
  Badge,
  Tooltip,
} from "@material-ui/core";
import { globalHistory } from "../history";
import ClickableTableCell from "../components/ClickableTableCell";
import { RemoveRedEye } from "@material-ui/icons";
import styled from "styled-components";
import { getUserNamePlus } from "../helpers";
import { useSelector } from "react-redux";
import { getActiveUser } from "../redux/selectors";
import { getUserAvatarUrl } from "../backend";

const StyledBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  border-radius: 50%;
  span {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: ${(props) => props.size || 12}px;
    width: ${(props) => props.size || 12}px;
    border-radius: 50%;
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const RenderPlainResponsibility = (props) => {
  const activeUser = useSelector(getActiveUser).user;

  let { responsibility } = props;
  let responsibleUser = responsibility.responsibleUser;

  // if company
  if (responsibility.isCompanyResponsible) {
    return (
      <Tooltip title={"Radial Link - We made changes to your item 🙂"}>
        <Avatar>RL</Avatar>
      </Tooltip>
    );
  }

  // if api key
  if (responsibility.isApiKeyResponsible) {
    return (
      <Tooltip title={`API Key - ${responsibility.apiKeyDescription}`}>
        <Avatar>API</Avatar>
      </Tooltip>
    );
  }

  // get formatted username
  let username = `${getUserNamePlus({
    overrideUser: responsibleUser,
    compareUser: activeUser,
    includeIsYou: true,
  })} - ${responsibleUser.email}`;

  // for wrapping in a badge if is the creator
  return (
    <Tooltip title={username}>
      <Avatar src={getUserAvatarUrl({ userId: responsibleUser.id })} />
    </Tooltip>
  );
};

const userActivityRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Activity #",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "responsibility",
    alignment: "left",
    label: "User",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => {
      return <RenderPlainResponsibility responsibility={rowItem} />;
    },
  },
  {
    id: "type",
    alignment: "left",
    label: "Type",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => _.capitalize(rowItem),
  },
  {
    id: "description",
    alignment: "left",
    label: "Description",
    allowSort: true,
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "actions",
    alignment: "right",
    label: "Actions",
    allowSort: false,
    renderRowItem: ({ row, index, rowItem }) => (
      <IconButton
        disabled={true}
        aria-label="details"
        onClick={(event) => handleTableCellClick(row)}
      >
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default userActivityRowColumns;
