// adapted from https://stackoverflow.com/a/8943487

const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export default function Linkify(props) {
  const { text, renderText, renderLink } = props;
  return text.split(urlRegex).map((part, index, originalArray) => {
    if (
      index > 0 &&
      isValidHttpUrl(originalArray[index - 1]) &&
      new URL(originalArray[index - 1]).protocol.replace(":", "") === part
    ) {
      return null;
    }

    if (isValidHttpUrl(part)) {
      return renderLink(part, index);
    }

    return renderText(part, index);
  });
}
