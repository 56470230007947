// network

export function getNetworkStatusIsOnline(state) {
  return state.networkStatus.isOnline ? true : false;
}

// auth state

export function getIsLoggedIn(state) {
  let refreshTokenDetails = getPrimaryRefreshTokenDetails(state);
  let accessToken = getActiveAccessToken(state);
  if (!accessToken || !accessToken.value) {
    accessToken = null;
  }

  if (!accessToken || !refreshTokenDetails.expirationDate) {
    return false;
  } else {
    return true;
  }
}

// user
export function getActiveUser(state) {
  return state.users[state.currentUserKey] || {};
}
export function getSecondaryUser(state) {
  let userKeys = getAllUserKeys(state);
  userKeys = userKeys.filter((k) => `${k}` !== "primary");
  if (userKeys.length > 0) {
    return state.users[userKeys[0]] || {};
  }
  return {};
}
export function getPrimaryUser(state) {
  return state.users.primary || {};
}
export function getIsPrimaryUser(state) {
  return state.currentUserKey === "primary";
}
export function getCurrentUserKey(state) {
  return state.currentUserKey;
}
export function getActiveUserId(state) {
  let user = getActiveUser(state).user;
  return user ? user.id : null;
}
export function getPrimaryUserId(state) {
  let user = getPrimaryUser(state).user;
  return user ? user.id : null;
}

// userFallbackReturnAddressInfo
export function getActiveUserFallbackReturnAddressInfo(state) {
  let userFallbackReturnAddressInfo = getActiveUser(state)
    .userFallbackReturnAddressInfo;
  return userFallbackReturnAddressInfo &&
    userFallbackReturnAddressInfo.id !== null
    ? userFallbackReturnAddressInfo
    : null;
}

export function getPrimaryUserFallbackReturnAddressInfo(state) {
  let userFallbackReturnAddressInfo = getPrimaryUser(state)
    .userFallbackReturnAddressInfo;
  return userFallbackReturnAddressInfo &&
    userFallbackReturnAddressInfo.id !== null
    ? userFallbackReturnAddressInfo
    : null;
}

export function getAllUserKeys(state) {
  return Object.keys(state.users);
}

export function getUserModeString(state) {
  let isPrimaryUser = getIsPrimaryUser(state);
  if (isPrimaryUser) {
    return "Primary";
  } else {
    return "Actor";
  }
}

// accountDetails
export function getActiveAccountDetails(state) {
  return getActiveUser(state).accountDetails;
}
export function getPrimaryAccountDetails(state) {
  return getPrimaryUser(state).accountDetails;
}

// accessToken
export function getActiveAccessToken(state) {
  return getActiveUser(state).accessToken;
}
export function getPrimaryAccessToken(state) {
  return getPrimaryUser(state).accessToken;
}

// accessTokenDetails
export function getActiveAccessTokenDetails(state) {
  return getActiveUser(state).accessTokenDetails;
}
export function getPrimaryAccessTokenDetails(state) {
  return getPrimaryUser(state).accessTokenDetails;
}

// refreshTokenDetails
export function getActiveRefreshTokenDetails(state) {
  return getActiveUser(state).refreshTokenDetails;
}
export function getPrimaryRefreshTokenDetails(state) {
  return getPrimaryUser(state).refreshTokenDetails;
}

// extras
export function getActiveExtras(state) {
  return getActiveUser(state).extras;
}
export function getPrimaryExtras(state) {
  return getPrimaryUser(state).extras;
}

// extras card sizes
export function getActiveExtrasCardSizes(state) {
  let extras = getActiveExtras(state);
  return extras.card_sizes || [];
}
export function getPrimaryExtrasCardSizes(state) {
  let extras = getPrimaryUser(state);
  return extras.card_sizes || [];
}

// extras font types
export function getActiveExtrasFontTypes(state) {
  let extras = getActiveExtras(state);
  return extras.font_types || [];
}
export function getPrimaryExtrasFontTypes(state) {
  let extras = getPrimaryUser(state);
  return extras.font_types || [];
}

// extras last_known_location
export function getActiveExtrasLastKnownLocation(state) {
  let extras = getActiveExtras(state);
  return extras.last_known_location || [];
}
export function getPrimaryExtrasLastKnownLocation(state) {
  let extras = getPrimaryUser(state);
  return extras.last_known_location || [];
}
