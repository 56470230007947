import { RESET_USERS_DATA, SET_USERS_DATA } from "./types";

export const resetUsersData = (userKey) => {
  return {
    type: RESET_USERS_DATA,
    payload: {
      userKey,
    },
  };
};

export const setUsersData = (userKey, data) => {
  return {
    type: SET_USERS_DATA,
    payload: {
      userKey,
      data,
    },
  };
};
