import _ from "lodash";
import {
  getActiveExtrasCardSizes,
  getActiveExtrasFontTypes,
  getActiveUser,
  getPrimaryUser,
} from "../redux/selectors";
import { store } from "../redux/store";
import constants from "../constants";
import { green, orange, grey, red, yellow } from "@material-ui/core/colors";

export const isInstanceOfAll = (input, arr) => {
  return arr.every((value) => {
    return input instanceof value;
  });
};

export const isInstanceOfSome = (input, arr) => {
  return arr.some((value) => {
    return input instanceof value;
  });
};

export const addMinutesToDate = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const getDelimiterRegex = ({ startDelimiter, endDelimiter }) => {
  let r = `\\${startDelimiter}(.*?)\\${endDelimiter}`;
  let regex = new RegExp(r, "gm");
  return regex;
};

export const getVariablesFromDelimitedText = ({
  text,
  startDelimiter,
  endDelimiter,
}) => {
  if (!startDelimiter) startDelimiter = "${";
  if (!endDelimiter) endDelimiter = "}";

  let regex = getDelimiterRegex({ startDelimiter, endDelimiter });
  let m = Array.from(text.matchAll(regex))
    .map((match) => match[1])
    // .map(match=>_.trimStart(match, start))
    // .map(match=>_.trimEnd(match, end))
    .map(_.deburr)
    .map((match) => _.trim(match, " "))
    .filter((m) => m);
  m = _.uniq(m);

  return m ? (m.length > 0 ? m : []) : [];
};

export const parseKeyValuePair = (text) => {
  return text
    .split(",")
    .map((p) => {
      let [key, value] = p.split(":");

      key = _.trim(key);
      key = _.trim(key, '"');
      value = _.trim(value);
      value = _.trim(value, '"');
      if (key && value) {
        return [key, value];
      } else {
        return null;
      }
    })
    .filter((kv) => kv);
};

export function scale(lines, options) {
  return lines.map((line) => {
    return line.map(([x, y]) => [
      x * (options.scaleX || options.scale),
      y * (options.scaleY || options.scale),
    ]);
  });
}

export function findBounds(lines, props = {}) {
  let points = lines.flat();
  let n = points.length;
  if (n === 0) {
    return [];
  }
  let d = points[0].length;
  let lo = points[0].slice();
  let hi = points[0].slice();
  for (let i = 1; i < n; ++i) {
    let p = points[i];
    for (let j = 0; j < d; ++j) {
      let x = p[j];
      lo[j] = Math.min(lo[j], x);
      hi[j] = Math.max(hi[j], x);
    }
  }

  let returnData = [lo, hi];

  if (props.named) {
    let [[minX, minY], [maxX, maxY]] = returnData;
    returnData = {
      minX,
      minY,
      maxX,
      maxY,
    };
  }
  return returnData;
}

export function resetPosition(lines) {
  let bounds = findBounds(lines);
  let [[minX, minY] /*, [ maxX, maxY ]*/] = bounds;
  let xOffset = 0,
    yOffset = 0;

  if (minX < 0) {
    xOffset = Math.abs(minX);
  } else if (minX > 0) {
    xOffset = -minX;
  }

  if (minY < 0) {
    yOffset = Math.abs(minY);
  } else if (minY > 0) {
    yOffset = -minY;
  }

  return lines.map((line) => {
    return line.map(([x, y]) => [x + xOffset, y + yOffset]);
  });
}

export function move(lines, options) {
  let l = lines;
  if (options.positionAsAbsolute) {
    l = resetPosition(lines);
  }
  return l.map((line) => {
    return line.map(([x, y]) => [x + options.x, y + options.y]);
  });
}

export function scaleToFit(lines, props) {
  let sizeToBoundsMinX = props.minX || 0;
  let sizeToBoundsMinY = props.minY || 0;

  let sizeToBoundsMaxX = props.maxX;
  let sizeToBoundsMaxY = props.maxY;

  let sizeToBoundsX = sizeToBoundsMaxX - sizeToBoundsMinX;
  let sizeToBoundsY = sizeToBoundsMaxY - sizeToBoundsMinY;

  let newLines = lines;

  let initalBounds = findBounds(newLines, { named: true });

  let scaleX = sizeToBoundsX / (initalBounds.maxX - initalBounds.minX);
  let scaleY = sizeToBoundsY / (initalBounds.maxY - initalBounds.minY);
  let smallestScale = scaleX > scaleY ? scaleY : scaleX;

  newLines = scale(newLines, {
    scale: smallestScale,
  });

  newLines = resetPosition(newLines);

  let sizedBounds = findBounds(newLines, { named: true });

  let sizeX = sizedBounds.maxX - sizedBounds.minX;
  let difference = sizeToBoundsX - sizeX;

  let centerFactorX = difference / 2;

  newLines = move(newLines, {
    x: centerFactorX,
    y: 0,
    positionAsAbsolute: true,
  });

  return newLines;
}

export function dist(pointOne, pointTwo) {
  let x1 = pointOne.x;
  let y1 = pointOne.y;
  let x2 = pointTwo.x;
  let y2 = pointTwo.y;
  if (!x2) x2 = 0;
  if (!y2) y2 = 0;
  return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
}

export function calculateLengthOfPaths(paths, resolution) {
  if (!paths || paths.length < 1) {
    return 0;
  }

  let totalLength = paths.reduce(
    (prevLength, currPath, pathIndex, pathsArray) => {
      return (
        prevLength +
        currPath.reduce(
          (prev, curr, coordIndex, coordsArray) => {
            let prevX = prev.coord[0];
            let prevY = prev.coord[1];
            let currX = curr[0];
            let currY = curr[1];

            let distanceToNext = dist(
              {
                x: prevX,
                y: prevY,
              },
              {
                x: currX,
                y: currY,
              }
            );

            return {
              length: distanceToNext + prev.length,
              coord: curr,
            };
          },
          { length: 0, coord: currPath[0] }
        ).length
      );
    },
    0
  );

  if (resolution) {
    totalLength = totalLength / resolution;
  }

  totalLength = parseFloat(totalLength.toFixed(4));

  return totalLength;
}

export const getNamedCoordinateCenters = (a) => {
  const arr = a.flat();
  const x = arr.map((xy) => xy.latitude || xy.lat);
  const y = arr.map((xy) => xy.longitude || xy.lng);
  const cx = (Math.min(...x) + Math.max(...x)) / 2;
  const cy = (Math.min(...y) + Math.max(...y)) / 2;
  return {
    latitude: cx,
    longitude: cy,
  };
};

export function getRequestErrorMessage({
  error,
  fallbackMessage,
  onlyUseServerResponse = false,
}) {
  if (typeof error === "string") return error;

  let errorMessage = `${fallbackMessage}`;

  if (!error) {
    return fallbackMessage;
  }

  if (error.isServerResponse) {
    errorMessage = error.data.message ?? errorMessage;
  } else {
    if (onlyUseServerResponse) {
    } else {
      errorMessage = error.message ?? errorMessage;
    }
  }

  return errorMessage;
}

export function getUserName({ user, onlyFirstName = false }) {
  if (user.firstName && user.lastName) {
    // if onlyFirstName is false return the first and last name combined as one string, otherwise return only the first name
    return onlyFirstName
      ? `${user.firstName}`
      : `${user.firstName} ${user.lastName}`;
  } else {
    // else return the email address
    return `${user.email}`;
  }
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function getHandWrittenNoteOrderCardSizeKey(keySize) {
  let currentState = store.getState();
  const cardSizes = getActiveExtrasCardSizes(currentState);
  const cardSize = _.find(cardSizes, { key: keySize });
  if (cardSize) {
    return keySize;
  }
  return "default";
}

export function getHandWrittenNoteOrderCardSize(keySize) {
  let currentState = store.getState();
  const cardSizes = getActiveExtrasCardSizes(currentState);
  const cardSize = _.find(cardSizes, {
    key: getHandWrittenNoteOrderCardSizeKey(keySize),
  });
  if (cardSize) {
    return cardSize;
  }
  return null;
}

export function getFontTypeConfigKey(keyType) {
  let currentState = store.getState();
  const fontTypes = getActiveExtrasFontTypes(currentState);
  const fontType = _.find(fontTypes, { key: keyType });
  if (fontType) {
    return keyType;
  }
  return "default";
}

export function getFontTypeConfig(keyType) {
  let currentState = store.getState();
  const fontTypes = getActiveExtrasFontTypes(currentState);
  const fontType = _.find(fontTypes, {
    key: getFontTypeConfigKey(keyType),
  });
  if (fontType) {
    return fontType;
  }
  return null;
}

export function getUserNamePlus({
  forcePrimaryUser = false,
  onlyFirstName = false,
  overrideUser = null,
  compareUser = null,
  includeIsYou = false,
  replaceForOnlyYouConditional = false,
  overrideIsYouText = null,
  returnOnlyFirstCharacter = false,
  replaceForEmail = false,
  replaceForEmailConditional = false,
} = {}) {
  let currentState = store.getState();
  let activeUser = getActiveUser(currentState).user;
  let primaryUser = getPrimaryUser(currentState).user;

  let useUser = forcePrimaryUser ? primaryUser : activeUser;
  if (overrideUser) {
    useUser = overrideUser;
  }

  let compareUserIsUseUser = compareUser && compareUser.id === useUser.id;

  let returnText = `${
    useUser.firstName
      ? _.capitalize(useUser.firstName)
      : onlyFirstName
      ? "Unnamed User"
      : "Unnamed"
  }`;

  if (!onlyFirstName) {
    returnText = `${returnText} ${
      useUser.lastName ? _.capitalize(useUser.lastName) : "User"
    }`;
  }

  if (compareUserIsUseUser && includeIsYou) {
    let isYouText = "You";
    if (overrideIsYouText) {
      isYouText = `${overrideIsYouText}`;
    }

    if (replaceForOnlyYouConditional) {
      returnText = isYouText;
    } else {
      returnText = `${returnText} (${isYouText})`;
    }
  }

  if (replaceForEmail) {
    returnText = `${useUser.email}`;
  }

  if (replaceForEmailConditional) {
    if (onlyFirstName && !useUser.firstName) {
      returnText = `${useUser.email}`;
    }

    if (!onlyFirstName && !useUser.firstName && !useUser.lastName) {
      returnText = `${useUser.email}`;
    }
  }

  if (returnOnlyFirstCharacter) {
    returnText = returnText.charAt(0);
  }

  return returnText;
}

export function getOrderStatusColor(status) {
  switch (status) {
    case constants.hand_written_note_order_status_types.pending:
      return orange[100];
    case constants.hand_written_note_order_status_types.donePending:
      return green[100];
    case constants.hand_written_note_order_status_types.creating:
      return green[200];
    case constants.hand_written_note_order_status_types.doneCreating:
      return green[300];
    case constants.hand_written_note_order_status_types.packaging:
      return green[400];
    case constants.hand_written_note_order_status_types.donePackaging:
      return green[500];
    case constants.hand_written_note_order_status_types.shipping:
      return green[600];
    case constants.hand_written_note_order_status_types.doneShipping:
      return green[700];
    case constants.hand_written_note_order_status_types.canceling:
      return yellow[100];
    case constants.hand_written_note_order_status_types.doneCanceling:
      return yellow[300];
    case constants.hand_written_note_order_status_types.errored:
      return red[600];
    default:
      return grey[500];
  }
}

export function getOrderStatusColor_text(
  status,
  light = "#fff",
  dark = "#000"
) {
  switch (status) {
    case constants.hand_written_note_order_status_types.pending:
      return dark;
    case constants.hand_written_note_order_status_types.donePending:
      return dark;
    case constants.hand_written_note_order_status_types.creating:
      return dark;
    case constants.hand_written_note_order_status_types.doneCreating:
      return dark;
    case constants.hand_written_note_order_status_types.packaging:
      return dark;
    case constants.hand_written_note_order_status_types.donePackaging:
      return dark;
    case constants.hand_written_note_order_status_types.shipping:
      return light;
    case constants.hand_written_note_order_status_types.doneShipping:
      return light;
    case constants.hand_written_note_order_status_types.canceling:
      return dark;
    case constants.hand_written_note_order_status_types.doneCanceling:
      return dark;
    case constants.hand_written_note_order_status_types.errored:
      return light;
  }
}

export function stringToColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
}

export function stringToColor_alpha(str, alpha = "60") {
  let base = stringToColor(str);

  return `${base}${alpha}`;
}
