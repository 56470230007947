export const SET_ALL_EXTRAS = "SET_ALL_EXTRAS";
export const RESET_ALL_EXTRAS = "RESET_ALL_EXTRAS";

export const RESET_USER_DATA = "RESET_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";

export const RESET_USERS_DATA = "RESET_USERS_DATA";
export const SET_USERS_DATA = "SET_USERS_DATA";

export const RESET_ACCOUNT_DETAILS = "RESET_ACCOUNT_DETAILS";
export const SET_ACCOUNT_DETAILS_BALANCE = "SET_ACCOUNT_DETAILS_BALANCE";

export const SET_ACCESS_TOKEN_DATA = "SET_ACCESS_TOKEN_DATA";
export const RESET_ACCESS_TOKEN_DATA = "RESET_ACCESS_TOKEN_DATA";

export const RESET_REFRESH_TOKEN_DETAILS_DATA =
  "RESET_REFRESH_TOKEN_DETAILS_DATA";
export const SET_REFRESH_TOKEN_DETAILS_DATA = "SET_REFRESH_TOKEN_DETAILS_DATA";

export const RESET_ACCESS_TOKEN_DETAILS_DATA =
  "RESET_ACCESS_TOKEN_DETAILS_DATA";
export const SET_ACCESS_TOKEN_DETAILS_DATA = "SET_ACCESS_TOKEN_DETAILS_DATA";

export const RESET_NETWORK_STATUS = "RESET_NETWORK_STATUS";
export const SET_NETWORK_STATUS = "SET_NETWORK_STATUS";

export const RESET_CURRENT_USER_KEY = "RESET_CURRENT_USER_KEY";
export const SET_CURRENT_USER_KEY = "SET_CURRENT_USER_KEY";

export const RESET_USER_FALLBACK_RETURN_ADDRESS_INFO =
  "RESET_USER_FALLBACK_RETURN_ADDRESS_INFO";
export const SET_USER_FALLBACK_RETURN_ADDRESS_INFO =
  "SET_USER_FALLBACK_RETURN_ADDRESS_INFO";
