import {
  Avatar as MuiAvatar,
  Badge,
  Typography,
  Tooltip,
} from "@material-ui/core";
import styled from "styled-components";
import { AvatarGroup } from "@material-ui/lab";
import _ from "lodash";
import { getUserNamePlus } from "../helpers";
import { useSelector } from "react-redux";
import { getActiveUser } from "../redux/selectors";
import { getUserAvatarUrl } from "../backend";

const StyledBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  border-radius: 50%;
  span {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: ${(props) => props.size || 12}px;
    width: ${(props) => props.size || 12}px;
    border-radius: 50%;
  }
`;
const Avatar = styled(MuiAvatar)`
  background: ${(props) =>
    props.background || props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

export default function DisplayUserActivitiesResponsibleUsers({
  userActivities,
}) {
  if (!userActivities || userActivities.length === 0) {
    return <Typography color="textSecondary">N/A</Typography>;
  }

  const activeUser = useSelector(getActiveUser).user;

  let uniqueActivitiesByUser = _.uniqBy(userActivities, (userActivity) => {
    // compare by prefixed strings
    // if isCompanyResponsible, return "company_responsible"
    // if isActorResponsible, return "actor_responsible" + userActivity.responsibility.responsibleUser.id
    // if isUserResponsible, return "user_responsible" + userActivity.responsibility.responsibleUser.id
    // if isApiKeyResponsible, return "api_key_responsible" + userActivity.responsibility.responsibleApiKeyId

    if (userActivity.responsibility.isCompanyResponsible) {
      return "company_responsible";
    }
    if (userActivity.responsibility.isActorResponsible) {
      return `actor_responsible${userActivity.responsibility.responsibleUser.id}`;
    }
    if (userActivity.responsibility.isUserResponsible) {
      return `user_responsible${userActivity.responsibility.responsibleUser.id}`;
    }
    if (userActivity.responsibility.isApiKeyResponsible) {
      return `api_key_responsible${userActivity.responsibility.responsibleApiKeyId}`;
    }

    return "";
  });

  // sort by oldest first
  uniqueActivitiesByUser = _.sortBy(
    uniqueActivitiesByUser,
    (activity) => activity.createdAt
  );

  let oldestActivity = _.minBy(uniqueActivitiesByUser, "createdAt");

  return (
    <Customer>
      <AvatarGroup max={3}>
        {uniqueActivitiesByUser.map((activity) => {
          let responsibility = activity.responsibility;
          let responsibleUser = responsibility.responsibleUser;
          let isCreator = activity.id === oldestActivity.id;
          let componentKey = `responsibility-key-${activity.id}`;

          // if company
          if (responsibility.isCompanyResponsible) {
            return (
              <Tooltip
                key={componentKey}
                title={"Radial Link - We made changes to your item 🙂"}
              >
                <Avatar>RL</Avatar>
              </Tooltip>
            );
          }

          // if api key
          if (responsibility.isApiKeyResponsible) {
            return (
              <Tooltip
                key={componentKey}
                title={`API Key - ${responsibility.apiKeyDescription}`}
              >
                <Avatar>API</Avatar>
              </Tooltip>
            );
          }

          // get formatted username
          let username = `${getUserNamePlus({
            overrideUser: responsibleUser,
            compareUser: activeUser,
            includeIsYou: true,
          })} - ${responsibleUser.email}`;

          // for wrapping in a badge if is the creator
          let UserComponent = (
            <Tooltip key={componentKey} title={username}>
              <Avatar src={getUserAvatarUrl({ userId: responsibleUser.id })} />
            </Tooltip>
          );

          if (isCreator && uniqueActivitiesByUser.length > 1) {
            // if oldest, consider as the creator
            return (
              <StyledBadge
                overlap="circular"
                variant="dot"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                key={componentKey}
              >
                {UserComponent}
              </StyledBadge>
            );
          }

          return UserComponent;
        })}
      </AvatarGroup>
    </Customer>
  );
}
