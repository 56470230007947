import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import React from "react";
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";
import CancelIcon from "@material-ui/icons/Cancel";
import { getUserNamePlus } from "../../../helpers";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors";
import constants from "../../../constants";
import { Alert } from "@material-ui/lab";

const foreignObjectSize = 40;

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const [
    isRemoveParentDialogOpen,
    setIsRemoveParentDialogOpen,
  ] = React.useState(false);
  const [removeParentEvent, setRemoveParentEvent] = React.useState(null);

  let activeUser = useSelector(getActiveUser).user;
  let userName = getUserNamePlus({
    overrideUser: data.child,
    compareUser: activeUser,
    includeIsYou: true,
  });
  let userEmail = data.child.email;

  const isActiveUser = data.child.id === activeUser.id;
  const attachedFullScreenContainer = data.attachedFullScreenContainer;

  let canHaveButton = true;
  if (!data.child.parent_user_id || !data.onEdgeRemove || data.child.is_demo) {
    canHaveButton = false;
  }

  return (
    <>
      <Dialog
        open={isRemoveParentDialogOpen && removeParentEvent}
        onClose={() => {
          setIsRemoveParentDialogOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        container={attachedFullScreenContainer}
      >
        <DialogTitle>Remove User Parent</DialogTitle>
        <DialogContent>
          {activeUser.role !== constants.roles.ceo.typeName && (
            <DialogContentText>
              <Alert severity="warning">
                <strong>WARNING:</strong> Removing the users parent will remove
                your ability to access this user or any downflow children. It's
                always better to try assigning them to a different parent first.
              </Alert>
            </DialogContentText>
          )}
          <DialogContentText>
            This will remove the parent of <strong>{userEmail}</strong> and any
            parent or upflow parents will lose access to the users data and
            downflow children.
          </DialogContentText>
          <DialogContentText>
            Are you sure you want to remove the users parent?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsRemoveParentDialogOpen(false);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setIsRemoveParentDialogOpen(false);
              data.onEdgeRemove(removeParentEvent);
            }}
            variant="contained"
            color="primary"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <path
        id={id}
        style={{ strokeWidth: 3, ...style }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        overflow="visible"
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div
          style={{
            width: foreignObjectSize,
            height: foreignObjectSize,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255,255,255,.75)",
            borderRadius: "100%",
          }}
        >
          <IconButton
            className="edgebutton"
            onClick={(event) => {
              setIsRemoveParentDialogOpen(true);
              setRemoveParentEvent(event);
            }}
            disabled={!canHaveButton}
            color="primary"
          >
            <CancelIcon fontSize="inherit" color="inherit" />
          </IconButton>
        </div>
      </foreignObject>
    </>
  );
}
