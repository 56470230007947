import React, { useState, useRef, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Badge,
  Box as MuiBox,
  Chip,
  Grid,
  Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Button,
  CardContent,
  Card as MuiCard,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { getRequestErrorMessage, getUserNamePlus } from "../../helpers";
import { getAllActableUsers } from "../../backend";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";

const blankActableUsersResults = {
  userShareUsers: [],
  userShareUserCount: 0,
  childrenUsers: [],
  childrenUserCount: 0,
  actableUsers: [],
};

const AutocompleteActableUsers = (props) => {
  const currentUserKey = useSelector(getCurrentUserKey);
  const parentIsLoading = props.isLoading;
  const textfieldProps = props.textfieldProps || {};
  let { selectedUser, setSelectedUser } = props;
  if (!setSelectedUser) {
    [selectedUser, setSelectedUser] = useState(null);
  }

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [mode, setMode] = React.useState(2);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(
    blankActableUsersResults
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, or, q, m }) => {
        setIsLoading(true);

        try {
          const response = await getAllActableUsers(
            {
              limit: l,
              offset: o,
              order: or,
              query: q,
            },
            {
              forcePrimaryUser: true,
            }
          );
          setSearchResults({
            userShareUsers: response.data.userShareUsers,
            userShareUserCount: response.data.userShareUserCount,
            childrenUsers: response.data.childrenUsers,
            childrenUserCount: response.data.childrenUserCount,
            actableUsers: response.data.actableUsers,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Unable to search.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      setSearchResults(blankActableUsersResults);
      setErrorMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
      });
    } else {
      setSearchResults(blankActableUsersResults);
    }
  }, [searchQuery, limit, offset, order, orderBy]);

  const parsedOptions = searchResults.actableUsers;

  if (selectedUser && !parsedOptions.some((o) => o.id === selectedUser.id)) {
    parsedOptions.push(selectedUser);
  }

  useEffect(() => {
    fetchData.current({
      l: limit,
      o: offset,
      or: [orderBy, order],
      q: searchQuery,
    });
  }, [currentUserKey]);

  return (
    <>
      <Autocomplete
        id="user-share-search"
        disableClearable={true}
        loading={isLoading}
        loadingText="Loading..."
        noOptionsText={errorMessage || "No results."}
        fullWidth
        options={parsedOptions}
        getOptionSelected={(option, value) =>
          option && selectedUser ? option.id === selectedUser.id : false
        }
        getOptionLabel={(option) => {
          return `#${option.id} - ${getUserNamePlus({
            overrideUser: option,
          })} (${option.email})`;
        }}
        onChange={(e, newValue) => {
          if (newValue) {
            setSelectedUser(newValue);
          }
        }}
        value={selectedUser}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              label={"Search Workspaces"}
              required
              error={errorMessage ? true : false}
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={parentIsLoading}
              autoFocus
              {...textfieldProps}
            />
          );
        }}
      />
    </>
  );
};

export default AutocompleteActableUsers;
