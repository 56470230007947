import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Bell, Home, UserPlus, Server, ShoppingCart } from "react-feather";
import { useSelector } from "react-redux";
import { getActiveUserId, getCurrentUserKey } from "../redux/selectors";
import {
  editUserNotificationById,
  getAllUserNotifications,
  socket,
} from "../backend";
import { Drafts, Refresh } from "@material-ui/icons";
import { globalHistory } from "../history";
import { getRequestErrorMessage } from "../helpers";
import { nanoid } from "nanoid";
import useMemoryState from "../hooks/useMemoryState";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ notification, handleRead }) {
  let linkTo = null;
  let Icon = Home;

  if (notification.fallback_url) {
    linkTo = notification.fallback_url;
  }

  if (notification.hand_written_note_order_id) {
    linkTo = {
      pathname: `/order/${notification.hand_written_note_order_id}`,
      state: {
        orderId: notification.hand_written_note_order_id,
      },
    };

    Icon = ShoppingCart;
  }

  // handle other notification types

  const createdAtDate = new Date(notification.createdAt);
  return (
    <ListItem
      divider
      button
      onClick={() => {
        if (!linkTo) {
          return;
        }
        globalHistory.push(linkTo);
      }}
    >
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ListItemText
          primary={notification.title}
          primaryTypographyProps={{
            variant: "subtitle2",
            color: "textPrimary",
          }}
          secondary={notification.description}
        />
        <ListItemText
          secondary={createdAtDate.toLocaleString("en-US", {
            month: "short",
            hour: "numeric",
            minute: "numeric",
            day: "numeric",
            year: "numeric",
          })}
        />
      </Grid>
      <ListItemSecondaryAction onClick={handleRead}>
        <IconButton>
          <Drafts />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const notification_dropdown_id = nanoid();
const getMemoryStateKey = (key, currentUserKey) =>
  `v1_notifications_dropdown_${notification_dropdown_id}_${key}_${currentUserKey}`;

function NotificationsDropdown() {
  // selectors and refs
  const ref = useRef(null);
  const currentUserKey = useSelector(getCurrentUserKey);
  const activeUserId = useSelector(getActiveUserId);

  // component state
  const [isFirstMount, setIsFirstMount] = useState(true);

  // memory state - use unique keys to avoid conflicts in other components that use the same memory state hook
  const isOpen_key = getMemoryStateKey("isOpen", currentUserKey);
  const [isOpen, setOpen] = useMemoryState(isOpen_key, false);

  const isLoading_key = getMemoryStateKey("isLoading", currentUserKey);
  const [isLoading, setIsLoading] = useMemoryState(isLoading_key, true);

  const errorMessage_key = getMemoryStateKey("errorMessage", currentUserKey);
  const [errorMessage, setErrorMessage] = useMemoryState(
    errorMessage_key,
    null
  );

  const rows_key = getMemoryStateKey("rows", currentUserKey);
  const [rows, setRows] = useMemoryState(rows_key, []);

  const totalRows_key = getMemoryStateKey("totalRows", currentUserKey);
  const [totalRows, setTotalRows] = useMemoryState(totalRows_key, 0);

  const shouldRefresh_key = getMemoryStateKey("shouldRefresh", currentUserKey);
  const [shouldRefresh, setShouldRefresh] = useMemoryState(
    shouldRefresh_key,
    true
  );

  // constants
  const order = "asc"; // asc or desc
  const orderBy = "updatedAt";
  const page = 0;
  const rowsPerPage = 5;
  const hasNotifications = totalRows > 0;
  const maxDisplayTotalRows = 99;

  // functions
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRead = async (row) => {
    setErrorMessage(null);
    let clonedRow = _.cloneDeep(row);
    let clonedRows = _.cloneDeep(rows);
    let clonedTotalRows = _.cloneDeep(totalRows);

    try {
      const newRows = rows.filter((r) => r.id !== clonedRow.id);
      setRows(newRows);
      setTotalRows(totalRows - 1);
      let results = await editUserNotificationById({
        isRead: true,
        notificationId: clonedRow.id,
      });
    } catch (error) {
      setRows(clonedRows);
      setTotalRows(clonedTotalRows);
      setErrorMessage(
        getRequestErrorMessage({
          error,
          fallbackMessage: "Failed to mark notification as read",
        })
      );
    }
  };

  // Effects

  React.useEffect(() => {
    // This component is mounted for every page (using the dashboard layout, which is why we use the memory state)
    // which means the currentUserKey will be "different", even if the user is the same.
    // shouldRefresh already will ensure that this component will get data on the first page load
    // but we need to ensure that the component will get data if the user changes while on the same page.

    if (isFirstMount) {
      setIsFirstMount(false);
    } else {
      setTotalRows(0);
      setRows([]);
      setShouldRefresh(true);
    }
  }, [currentUserKey]);

  React.useEffect(() => {
    if (isOpen) {
      setShouldRefresh(true);
    }
  }, [isOpen]);

  React.useEffect(() => {
    function handleNewNotification(notification) {
      setRows((prevRows) => {
        if (!prevRows || !prevRows.length) {
          return [notification];
        }

        if (prevRows.length + 1 > rowsPerPage) {
          return prevRows;
        }

        let newRows = [];

        if (order === "asc" || order === "ASC") {
          newRows = [...prevRows, notification];
        } else if (order === "desc" || order === "DESC") {
          newRows = [notification, ...prevRows];
        }

        return newRows;
      });
      setTotalRows((totalRows) => (totalRows || 0) + 1);
    }

    socket.on("user_notification:create", handleNewNotification);

    // cleanup
    return function cleanup() {
      socket.off("user_notification:create", handleNewNotification);
    };

    // // ALTERNATIVE
    // // If the menu is open, don't refresh the notifications
    // // this may need to change. We don't want someone to try marking
    // // a notification as read exactly when a new one is added, resulting in clicking
    // // the wrong one.

    // if (isOpen) {
    //   socket.off("user_notification:create", handleNewNotification);
    // } else {
    //   socket.on("user_notification:create", handleNewNotification);
    // }
  }, [null]);

  React.useEffect(() => {
    // handle getting data whenever shouldRefresh is true
    if (shouldRefresh) {
      async function loadData() {
        setIsLoading(true);
        setErrorMessage(null);
        try {
          const response = await getAllUserNotifications({
            order: [orderBy, order],
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            isRead: false,
          });
          setRows(response.data.notifications);
          setTotalRows(response.data.notificationCount);
        } catch (error) {
          let message = getRequestErrorMessage({
            error,
            fallbackMessage: "Something went wrong!",
          });

          setErrorMessage(message);
        }
        setIsLoading(false);
      }

      loadData();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Indicator badgeContent={totalRows}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="subtitle1" color="textPrimary">
                {!hasNotifications
                  ? "New Notifications"
                  : totalRows > rowsPerPage
                  ? totalRows > maxDisplayTotalRows
                    ? `${rowsPerPage} of ${maxDisplayTotalRows}+ Notifications`
                    : `${rowsPerPage} of ${totalRows} Notifications`
                  : `${totalRows} New Notifications`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setShouldRefresh(true);
                }}
              >
                <Refresh />
              </IconButton>
            </Grid>
          </Grid>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {isLoading ? (
              <React.Fragment>
                <ListItem divider alignItems="center">
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                      <CircularProgress size={25} />
                    </Grid>
                  </Grid>
                </ListItem>
              </React.Fragment>
            ) : !hasNotifications && !errorMessage ? (
              <React.Fragment>
                <ListItem divider>
                  <ListItemText
                    primary={"All Caught Up"}
                    primaryTypographyProps={{
                      align: "center",
                      variant: "subtitle2",
                      color: "textPrimary",
                    }}
                    secondaryTypographyProps={{
                      align: "center",
                    }}
                    secondary={
                      "You've read all your notifications, try checking back later"
                    }
                  />
                </ListItem>
              </React.Fragment>
            ) : rows.length === 0 ? (
              // refresh here
              <React.Fragment>
                <ListItem divider>
                  <ListItemText
                    primary={"Refresh for Notifications"}
                    primaryTypographyProps={{
                      align: "center",
                      variant: "subtitle2",
                      color: "textPrimary",
                    }}
                    secondaryTypographyProps={{
                      align: "center",
                    }}
                    secondary={
                      "You've viewed the first page of notifications, try refreshing to load more"
                    }
                  />
                </ListItem>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {errorMessage && (
                  <ListItem divider alignItems="center">
                    <ListItemText
                      primary={"An Error Occurred"}
                      primaryTypographyProps={{
                        align: "center",
                        variant: "subtitle2",
                        color: "textPrimary",
                      }}
                      secondaryTypographyProps={{
                        align: "center",
                        color: "error",
                      }}
                      secondary={errorMessage}
                    />
                  </ListItem>
                )}
                {rows.map((row) => (
                  <Notification
                    key={row.id}
                    notification={row}
                    handleRead={() => {
                      handleRead(row);
                    }}
                  />
                ))}
              </React.Fragment>
            )}
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="#">
              Show all notifications
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NotificationsDropdown;
