import {
  MenuItem,
  Select,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Grid,
  Paper as PaperMui,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import React, { memo } from "react";
import constants from "../../../constants";
import { Handle } from "react-flow-renderer";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors";
import { getUserNamePlus } from "../../../helpers";
import _ from "lodash";
import { grey } from "@material-ui/core/colors";
import { getRoleNumber } from "../../../permissions";
import { Alert } from "@material-ui/lab";
import { setUserDiscount } from "../../../backend";

const Paper = styled(PaperMui)`
  border-color: ${(props) => {
    if (props.isSelected) {
      return props.theme.palette.primary.main;
    }
  }};
  border-width: 2px;
  box-shadow: rgb(50 50 93 / 5%) 0px 2px 7px -1px,
    rgb(0 0 0 / 7%) 0px 1px 5px -1px !important;
`;

export default memo(({ id, data, type, selected, isConnectable }) => {
  // setUserRoleDialog
  const [isSetUserRoleDialogOpen, setIsSetUserRoleDialogOpen] = React.useState(
    false
  );
  const [userRoleEvent, setUserRoleEvent] = React.useState(null);

  const activeUser = useSelector(getActiveUser).user;
  let userName = getUserNamePlus({
    overrideUser: data.child,
    compareUser: activeUser,
    includeIsYou: true,
  });
  let userEmail = data.child.email;

  const handleSize = 15;
  const baseHandleStyle = {
    width: handleSize,
    height: handleSize,
    borderRadius: 1000 * 1000,
  };

  const currentRoleObject = _.find(constants.roles, {
    typeName: data.child.role,
  });
  const userRoleEventRoleObject = _.find(constants.roles, {
    typeName: _.get(userRoleEvent, "target.value", null),
  });

  const currentRoleName = currentRoleObject
    ? currentRoleObject.name
    : "Undetermined";

  const userRoleEventRoleName = userRoleEvent
    ? userRoleEventRoleObject.name
    : "Undetermined";

  const userRoleEventRoleNumber = userRoleEvent
    ? getRoleNumber(userRoleEventRoleObject.typeName)
    : 0;

  const attachedFullScreenContainer = data.attachedFullScreenContainer;
  // () => data.fullScreenHandle.node.current;

  const isActiveUser = data.child.id === activeUser.id;
  const hasSameRoleAsActiveUser = data.child.role === activeUser.role;

  const activeUserRoleNumber = getRoleNumber(activeUser.role);
  const userRoleNumber = getRoleNumber(data.child.role);

  const activeUserHasGreaterRole = activeUserRoleNumber > userRoleNumber;
  const activeUserHasSameRole = activeUserRoleNumber === userRoleNumber;
  const activeUserHasLowerRole = activeUserRoleNumber < userRoleNumber;

  //onSetUserWaitlistStatus
  return (
    <>
      <Dialog
        open={isSetUserRoleDialogOpen && userRoleEvent}
        onClose={() => {
          setIsSetUserRoleDialogOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        container={attachedFullScreenContainer}
      >
        <DialogTitle>
          Change Role From '{currentRoleName}' To '{userRoleEventRoleName}'
        </DialogTitle>
        <DialogContent>
          {userRoleEventRoleNumber >= activeUserRoleNumber && (
            <DialogContentText>
              <Alert severity="warning">
                <strong>WARNING:</strong> Changing the users role to the same as
                your own will remove your ability to change their role in the
                future.
              </Alert>
            </DialogContentText>
          )}
          {userRoleEventRoleObject &&
            userRoleEventRoleObject.typeName ===
              constants.roles.user.typeName && (
              <DialogContentText>
                <Alert severity="info">
                  Changing the users role to '{userRoleEventRoleObject.typeName}
                  ' will remove all their children
                </Alert>
              </DialogContentText>
            )}
          <DialogContentText>
            This will change the role of <strong>{userEmail}</strong> and give
            them access to all application data, analytics, and features
            associated with the role.
          </DialogContentText>
          {/* {userRoleEventRoleNumber >= activeUserRoleNumber && (
            <DialogContentText>
              <strong>WARNING:</strong> by changing the users role to the same
              as yours, you will lose management access and user will become
              unaccessible to you.
            </DialogContentText>
          )} */}
          <DialogContentText>
            Are you sure you want to change the users role?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsSetUserRoleDialogOpen(false);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setIsSetUserRoleDialogOpen(false);
              data.onSetUserRole(userRoleEvent);
            }}
            variant="contained"
            color="primary"
          >
            Change Role
          </Button>
        </DialogActions>
      </Dialog>
      {data.child.role !== constants.roles.ceo.typeName &&
        data.child.id !== activeUser.id && (
          <Handle
            type="target"
            position="left"
            style={{
              left: -(handleSize * 0.5),
              ...baseHandleStyle,
            }}
            isConnectable={isConnectable}
          />
        )}
      <Paper variant="outlined" isPrimary={isActiveUser} isSelected={selected}>
        <Card>
          <CardContent>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  {userName}
                </Typography>
                <Typography variant="caption" gutterBottom>
                  {userEmail}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={data.child.role}
                    label="Role"
                    onChange={(event) => {
                      setUserRoleEvent(event);
                      setIsSetUserRoleDialogOpen(true);
                    }}
                    MenuProps={{
                      container: attachedFullScreenContainer,
                    }}
                    disabled={
                      isActiveUser ||
                      hasSameRoleAsActiveUser ||
                      !activeUserHasGreaterRole ||
                      activeUserHasSameRole ||
                      activeUserHasLowerRole ||
                      data.child.is_demo
                    }
                  >
                    {Object.values(constants.roles).map((role) => {
                      return (
                        <MenuItem key={role.typeName} value={role.typeName}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {/* discount_percentage */}
                <TextField
                  label="Discount Percentage"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={data.child.discount_percentage}
                  onChange={(event) => {
                    data.onSetUserDiscount(event);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={data.child.is_on_waitlist}
                        onChange={data.onSetUserWaitlistStatus}
                      />
                    }
                    label="On Waitlist"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      {data.child.role !== constants.roles.user.typeName && (
        <Handle
          type="source"
          position="right"
          style={{
            right: -(handleSize * 0.5),
            ...baseHandleStyle,
          }}
          isConnectable={isConnectable}
        />
      )}
    </>
  );
});
