import { EventTarget, Event } from "event-target-shim";
import React, { useState, useEffect, useCallback } from "react";

let customEvents = new EventTarget();

// A quick and dirty way to store state in localStorage without
// the mess of redux boilerplate.

export default function useLocalStorage(key, initialState, options) {
  if (!options) {
    options = {};
  }

  const { syncTabs = true, syncPage = true, parser } = options;

  let parserCallback = useCallback(
    (state) => {
      if (parser && typeof parser === "function") {
        return parser(state);
      }
      return state;
    },
    [parser]
  );

  // init state
  const [state, setState] = useState(() => {
    const hasMemoryValue = localStorage.getItem(key);
    if (hasMemoryValue) {
      return hasMemoryValue;
    } else {
      return typeof initialState === "function" ? initialState() : initialState;
    }
  });

  // handle updating the state if the key changes (e.g. the active user changed, we updated to key, so we should handle the new value. Essentially re initializing the state)
  useEffect(() => {
    const hasMemoryValue = localStorage.getItem(key);
    if (hasMemoryValue) {
      setState(hasMemoryValue);
    } else {
      setState(
        typeof initialState === "function" ? initialState() : initialState
      );
    }
  }, [key]);

  // handle updating the state if it changes in another browser tab. Multiple tabs only, doesn't work for the same page and is handled below.
  useEffect(() => {
    if (syncTabs) {
      let storageEvent = function (e) {
        if (e.key === key) {
          setState(e.newValue);
        }
      };

      window.addEventListener("storage", storageEvent);

      return () => {
        window.removeEventListener("storage", storageEvent);
      };
    }
  }, [key, syncTabs]);

  // handle updating the state if it changes in the same tab. Using custom events to listen and emit. Only works for the same page, multiple tabs handled above.

  useEffect(() => {
    if (syncPage) {
      let localEvent = function (e) {
        const memoryValue = localStorage.getItem(key);
        setState(memoryValue);
      };

      customEvents.addEventListener(key, localEvent);

      return () => {
        customEvents.removeEventListener(key, localEvent);
      };
    }
  }, [key, syncPage]);

  // we need to ensure everything is updated when the key changes. Fire events and updates for the correct state.
  const onChange = useCallback(
    (nextState) => {
      let next =
        typeof nextState === "function"
          ? nextState(localStorage.getItem(key))
          : nextState;
      if (next === null || next === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, next);
      }
      setState(next);
      syncPage && customEvents.dispatchEvent(new Event(key));
    },
    [key, syncPage]
  );

  return [parserCallback(state), onChange];
}
