import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { globalHistory } from "../history";
import { resetCurrentUserKey } from "../redux/actions/currentUserKeyActions";
import { resetUsersData } from "../redux/actions/usersActions";
import {
  getActiveAccessToken,
  getActiveAccessTokenDetails,
  getCurrentUserKey,
  getIsLoggedIn,
  getIsPrimaryUser,
  getPrimaryRefreshTokenDetails,
} from "../redux/selectors";
import { store } from "../redux/store";

// For routes that can only be accessed by authenticated users
function AuthGuard({
  children,
  setContentPadding,
  contentPadding,
  defaultContentPadding,
  shouldShowFooter,
  setShouldShowFooter,
}) {
  const isPrimaryUser = useSelector(getIsPrimaryUser);
  const currentUserKey = useSelector(getCurrentUserKey);
  const isLoggedIn = useSelector(getIsLoggedIn);

  if (!isLoggedIn) {
    if (!isPrimaryUser) {
      store.dispatch(resetCurrentUserKey());
      store.dispatch(resetUsersData(currentUserKey));
    } else {
      globalHistory.push("/auth/sign-in");
    }
    return null;
  } else {
    return React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          defaultContentPadding,
          contentPadding,
          setContentPadding,
          shouldShowFooter,
          setShouldShowFooter,
        });
      }
      return child;
    });
  }
}

export default AuthGuard;
