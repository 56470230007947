import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

import { CircularProgress } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  green,
  orange,
  grey,
  red,
  yellow,
  blue,
} from "@material-ui/core/colors";

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
} from "react-feather";
import {
  getAllChildrenStatistics,
  getAllStatistics,
  getBalance,
  getUserAvatarUrl,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { BigNumber } from "../../money";
import {
  getActiveAccountDetails,
  getActiveUser,
  getCurrentUserKey,
} from "../../redux/selectors";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

function Details() {
  const user = useSelector(getActiveUser).user;
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile Details
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Avatar
            alt="Lucy Lavender"
            src={getUserAvatarUrl({
              userId: user.id,
            })}
          />
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightMedium">
              {user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.email}
            </Box>
            <Box fontWeight="fontWeightRegular">
              {_.words(user.role).map(_.capitalize).join(" ")}
            </Box>
          </Typography>

          {/* <Button mr={2} variant="contained" size="small">
            Follow
          </Button>
          <Button mr={2} variant="contained" color="primary" size="small">
            Message
          </Button> */}
        </Centered>
      </CardContent>
    </Card>
  );
}

function Skills() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Skills
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Chip size="small" mr={1} mb={1} label="HTML" color="secondary" />
          <Chip size="small" mr={1} mb={1} label="JavaScript" />
          <Chip size="small" mr={1} mb={1} label="Sass" />
          <Chip size="small" mr={1} mb={1} label="React" />
          <Chip size="small" mr={1} mb={1} label="Redux" />
          <Chip size="small" mr={1} mb={1} label="Next.js" />
          <Chip size="small" mr={1} mb={1} label="Material UI" />
          <Chip size="small" mr={1} mb={1} label="UI" />
          <Chip size="small" mr={1} mb={1} label="UX" />
        </Centered>
      </CardContent>
    </Card>
  );
}

function About() {
  const user = useSelector(getActiveUser).user;

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          About
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Mail />
            </AboutIcon>
          </Grid>
          <Grid item>Email: {user.email}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <User />
            </AboutIcon>
          </Grid>
          <Grid item>First name: {user.firstName ?? "Null"}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <User />
            </AboutIcon>
          </Grid>
          <Grid item>Last name: {user.lastName ?? "Null"}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Briefcase />
            </AboutIcon>
          </Grid>
          <Grid item>
            Permission Role: {_.words(user.role).map(_.capitalize).join(" ")}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <MapPin />
            </AboutIcon>
          </Grid>
          <Grid item>Joined: {new Date(user.createdAt).toDateString()}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <MapPin />
            </AboutIcon>
          </Grid>
          <Grid item>
            Last updated: {new Date(user.updatedAt).toDateString()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Elsewhere() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Elsewhere
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <ExternalLink />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">lucylavender.io</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Twitter />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">Twitter</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Facebook />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">Facebook</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <AboutIcon>
              <Instagram />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">Instagram</Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Earnings() {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ 2.405</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total Earnings
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={75}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function Stats(props) {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">{props.count}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            {props.label ?? "Pending Orders"}
          </Typography>

          <StatsIcon>
            <ShoppingBag />
          </StatsIcon>
          {typeof props.count === "number" ? (
            <LinearProgress
              variant="determinate"
              value={props.count}
              color="secondary"
              mt={4}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              value={0}
              color="secondary"
              mt={4}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

function Revenue() {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ 1.224</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total Revenue
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={50}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function Products() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Products
        </Typography>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Tech</TableCell>
                <TableCell>License</TableCell>
                <TableCell>Sales</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  AppStack
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="HTML"
                    rgbcolor={blue[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>76</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Radial Link
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="React"
                    rgbcolor={green[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>38</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Milo
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="HTML"
                    rgbcolor={blue[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>43</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Robust UI Kit
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="Angular"
                    rgbcolor={red[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>27</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Spark
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="React"
                    rgbcolor={green[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>12</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </CardContent>
    </Card>
  );
}

const OrdersByMonth = withTheme(({ theme, orderCountByMonth }) => {
  let sortedData = orderCountByMonth;

  let labels = sortedData.map((o) => {
    if (o.label) {
      return o.label;
    }
    let date = new Date(o.date);

    // return short month text as a label
    return date.toLocaleDateString("en-US", {
      month: "short",
    });
  });

  let datasetOneData = sortedData.map((o) => {
    return o.orderDoneCreatingCount;
  });

  let datasetTwoData = sortedData.map((o) => {
    return o.orderDoneCancelingCount;
  });
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Canceled",
        backgroundColor: grey[400],
        borderColor: grey[400],
        hoverBackgroundColor: grey[400],
        hoverBorderColor: grey[400],
        data: datasetTwoData,
        barPercentage: 0.625,
        categoryPercentage: 0.5,
      },
      {
        label: "Created",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: datasetOneData,
        barPercentage: 0.625,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Orders Created by Month
        </Typography>

        <Spacer mb={6} />

        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
});

function ChildrenDashboard() {
  const user = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [statistics, setStatistics] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await getAllChildrenStatistics();
        setStatistics(data.data);
        setIsLoading(false);
      } catch (err) {
        let errorText = getRequestErrorMessage({
          error: err,
          message: "Something went wrong loading your dashboard.",
        });
        setErrorMessage(errorText);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Typography variant="h3" gutterBottom display="inline">
        Dashboard
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Dashboard</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {errorMessage || !statistics ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage ?? "Something went wrong loading your dashboard."}
          </Alert>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <OrdersByMonth orderCountByMonth={statistics.orderCountByMonth} />
              <Card mb={6}>
                <CardContent>
                  {/* <Typography variant="h6" gutterBottom>
                    Overall Order Statistics
                  </Typography> */}
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Pending"}
                        count={statistics.orderPendingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"In Queue"}
                        count={statistics.orderDonePendingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Creating"}
                        count={statistics.orderCreatingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Created"}
                        count={statistics.orderDoneCreatingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={
                          statistics.childrenCount > 1 ? "Clients" : "Client"
                        }
                        count={statistics.childrenCount}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {/* <Products /> */}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ChildrenDashboard;
