import React from "react";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  TextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import blankSenderAddressInfo from "./blankSenderAddressInfo";
import {
  placeHandWrittenNoteOrder,
  placeHandWrittenNoteOrderRadial,
} from "../../../backend";
import { globalHistory } from "../../../history";
import { getRequestErrorMessage } from "../../../helpers";
import _ from "lodash";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { CircularProgress } from "@material-ui/core";
import { BigNumber, getDiscountPercentageProps } from "../../../money";
import { useSelector } from "react-redux";
import {
  getActiveUser,
  getActiveUserFallbackReturnAddressInfo,
  getPrimaryUserFallbackReturnAddressInfo,
} from "../../../redux/selectors";
import fillBlankAddressValues from "./fillBlankAddressValues";
import HttpsTextField from "../../../components/HttpsTextField";
const Alert = styled(MuiAlert)(spacing);

const getDefaultOrderParametersObject = (parameters) => {
  return parameters
    .map((key) => {
      return {
        value: "",
        key: key,
      };
    })
    .reduce((result, item, index, array) => {
      result[item.key] = item;
      return result;
    }, {});
};

export default function RadialForm(props) {
  const { selectedTemplate, selectedFundAccount, cardSize } = props;

  const activeUser = useSelector(getActiveUser).user;

  let userFallbackReturnAddress = useSelector(
    getPrimaryUserFallbackReturnAddressInfo
  );

  if (!userFallbackReturnAddress)
    userFallbackReturnAddress = useSelector(
      getActiveUserFallbackReturnAddressInfo
    );

  const templateId = selectedTemplate.id;
  const includeEnvelopeParams =
    !cardSize || cardSize.envelopeInchDimensions !== null;
  const includeSenderAddressInfo = !selectedTemplate.exclude_sender_address_info;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [purchaseOrder, setPurchaseOrder] = React.useState("");
  const [redirectUrl, setRedirectUrl] = React.useState("");

  const [receiverName, setReceiverName] = React.useState("Current Resident");
  const [searchAddress, setSearchAddress] = React.useState("");
  const [searchSize, setSearchSize] = React.useState(500);
  const [searchReturnLimit, setSearchReturnLimit] = React.useState(10);

  const [orderParameters, setOrderParameters] = React.useState(null);
  const [senderAddressInfo, setSenderAddressInfo] = React.useState(null);

  const handleSetSenderAddressInfo = (n) => {
    let prev = _.cloneDeep(senderAddressInfo);
    let merged = _.merge(prev, n);
    setSenderAddressInfo(merged);
  };

  const handleSetOrderParameters = (n) => {
    let prev = _.cloneDeep(orderParameters);
    let merged = _.merge(prev, n);
    setOrderParameters(merged);
  };

  const getOrderDetails = () => {
    let orderDetails = {
      templateId: templateId,
      purchaseOrder: purchaseOrder,
      redirectUrl: redirectUrl
        ? redirectUrl.includes("://")
          ? redirectUrl
          : `https://${redirectUrl}`
        : null,
      receiverName: receiverName,
      searchAddress: searchAddress,
      searchSize: searchSize ? (searchSize < 1 ? 1 : searchSize) : 1,
      searchReturnLimit: searchReturnLimit
        ? searchReturnLimit < 1
          ? 1
          : searchReturnLimit
        : 1,
    };

    if (orderParameters) {
      orderDetails.parameters = {};
      Object.values(orderParameters).forEach((param) => {
        orderDetails.parameters[param.key] = param.value;
      });
    }

    if (senderAddressInfo) {
      Object.values(senderAddressInfo).forEach((param) => {
        orderDetails[param.endpointKey] = param.value;
      });
    }

    return orderDetails;
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);
    setErrorMessage(null);
    try {
      const orderDetails = getOrderDetails();
      let results = await placeHandWrittenNoteOrderRadial(orderDetails, {
        overrideUseBalanceFromUserId: selectedFundAccount?.user?.id,
      });
      if (results.data.orderCount === 0) {
        setErrorMessage(
          "No orders were placed, try adjusting your search parameters"
        );
      } else if (results.data.orderCount === 1) {
        return globalHistory.push({
          pathname: `/order/${results.data.orders[0].id}`,
          state: {
            orderId: results.data.orders[0].id,
          },
        });
      } else {
        return globalHistory.push({
          pathname: `/purchase-order/${purchaseOrder}`,
          state: {
            purchaseOrder: purchaseOrder,
          },
        });
      }
    } catch (err) {
      setErrorMessage(
        getRequestErrorMessage({
          error: err,
          fallbackMessage:
            "An unknown error occurred while submitting your order",
        })
      );
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (
      selectedTemplate.required_parameters &&
      selectedTemplate.required_parameters.length > 0
    ) {
      setOrderParameters(
        getDefaultOrderParametersObject(selectedTemplate.required_parameters)
      );
    }

    if (includeEnvelopeParams && includeSenderAddressInfo) {
      if (userFallbackReturnAddress) {
        setSenderAddressInfo(
          fillBlankAddressValues(
            blankSenderAddressInfo,
            userFallbackReturnAddress
          )
        );
      } else {
        setSenderAddressInfo(blankSenderAddressInfo);
      }
    }
  }, [templateId, includeEnvelopeParams, includeSenderAddressInfo]);

  const {
    discountPercentageDecimal,
    discountPercentage,
  } = getDiscountPercentageProps(activeUser.discount_percentage);

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <CardContent>
          {errorMessage && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="warning">
                {errorMessage ??
                  "An unknown error occurred while submitting your order"}
              </Alert>
              <br />
              <br />
            </React.Fragment>
          )}
          {searchReturnLimit > 1 && searchAddress && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="info">
                Estimated Price: $
                {new BigNumber(
                  searchReturnLimit >=
                  cardSize.priceByOrderType.radial.threshold
                    ? cardSize.priceByOrderType.radial.overThresholdPrice
                    : cardSize.priceByOrderType.radial.price
                )
                  .multipliedBy(searchReturnLimit)
                  .times(discountPercentageDecimal)
                  .toFormat(2)}{" "}
                ($
                {new BigNumber(
                  searchReturnLimit >=
                  cardSize.priceByOrderType.radial.threshold
                    ? cardSize.priceByOrderType.radial.overThresholdPrice
                    : cardSize.priceByOrderType.radial.price
                )
                  .times(discountPercentageDecimal)
                  .toFormat(2)}
                /order)
                {!discountPercentage.lte(0) &&
                  ` - ${discountPercentage}% savings applied`}
              </Alert>
              <br />
              <br />
            </React.Fragment>
          )}
          <Grid container spacing={6}>
            <Typography variant="h6">Options</Typography>
            <Grid container spacing={6} item>
              <Grid item xs={12} lg={6}>
                <TextField
                  type="text"
                  id={"purchaseOrder"}
                  label={"Purchase Order"}
                  variant="outlined"
                  value={purchaseOrder}
                  onChange={(e) => {
                    setPurchaseOrder(e.target.value);
                  }}
                  fullWidth
                  my={2}
                  disabled={isLoading}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  type="text"
                  id={"searchAddress"}
                  label={"Search Address"}
                  helperText={
                    "Enter a valid address including: city, state, and zip code"
                  }
                  variant="outlined"
                  value={searchAddress}
                  onChange={(e) => {
                    setSearchAddress(e.target.value);
                  }}
                  fullWidth
                  my={2}
                  disabled={isLoading}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  type="text"
                  id={"receiverName"}
                  label={"Receiver Name"}
                  variant="outlined"
                  value={receiverName}
                  onChange={(e) => {
                    setReceiverName(e.target.value);
                  }}
                  fullWidth
                  my={2}
                  disabled={isLoading}
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg>
                <TextField
                  type="number"
                  id={"searchSize"}
                  label={"Search Size"}
                  variant="outlined"
                  value={searchSize}
                  onChange={(e) => {
                    setSearchSize(e.target.value);
                  }}
                  fullWidth
                  my={2}
                  disabled={isLoading}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg>
                <TextField
                  type="number"
                  id={"searchReturnLimit"}
                  label={"Search Return Limit"}
                  variant="outlined"
                  value={searchReturnLimit}
                  onChange={(e) => {
                    setSearchReturnLimit(e.target.value);
                  }}
                  fullWidth
                  my={2}
                  disabled={isLoading}
                  required={true}
                />
              </Grid>

              <Grid item xs={12}>
                <HttpsTextField
                  value={redirectUrl}
                  label="QR Code (Redirect) URL"
                  helperText="A url to provide tracking for and redirect all clients to through a printed QR code included with each card (i.e. scheduling form, landing page, etc)"
                  onChange={(e) => {
                    setRedirectUrl(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <br />
          <br />
          {orderParameters && (
            <React.Fragment>
              <Grid container spacing={6}>
                <Typography variant="h6">Template Parameters</Typography>
                <Grid container spacing={6} item>
                  {Object.values(orderParameters).map((param) => {
                    return (
                      <Grid item xs={12} sm key={param.key}>
                        <TextField
                          type="text"
                          id={param.key}
                          label={_.words(param.key).map(_.capitalize).join(" ")}
                          helperText={param.key}
                          variant="outlined"
                          value={param.value}
                          onChange={(e) => {
                            handleSetOrderParameters({
                              [param.key]: {
                                value: e.target.value,
                              },
                            });
                          }}
                          fullWidth
                          my={2}
                          disabled={isLoading}
                          required={true}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <br />
              <br />
            </React.Fragment>
          )}
          {senderAddressInfo && (
            <React.Fragment>
              <Grid container spacing={6}>
                <Typography variant="h6">Sender Address</Typography>
                <Grid container spacing={6} item>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      id={senderAddressInfo.name.endpointKey}
                      label={senderAddressInfo.name.label}
                      variant="outlined"
                      value={senderAddressInfo.name.value}
                      onChange={(e) =>
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.name.key]: {
                            value: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={senderAddressInfo.name.required}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      id={senderAddressInfo.businessName.endpointKey}
                      label={senderAddressInfo.businessName.label}
                      variant="outlined"
                      value={senderAddressInfo.businessName.value}
                      onChange={(e) =>
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.businessName.key]: {
                            value: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={senderAddressInfo.businessName.required}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    id={senderAddressInfo.addressLineOne.endpointKey}
                    label={senderAddressInfo.addressLineOne.label}
                    variant="outlined"
                    value={senderAddressInfo.addressLineOne.value}
                    onChange={(e) =>
                      handleSetSenderAddressInfo({
                        [senderAddressInfo.addressLineOne.key]: {
                          value: e.target.value,
                        },
                      })
                    }
                    fullWidth
                    my={2}
                    disabled={isLoading}
                    required={senderAddressInfo.addressLineOne.required}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    type="text"
                    id={senderAddressInfo.addressLineTwo.endpointKey}
                    label={senderAddressInfo.addressLineTwo.label}
                    variant="outlined"
                    value={senderAddressInfo.addressLineTwo.value}
                    onChange={(e) =>
                      handleSetSenderAddressInfo({
                        [senderAddressInfo.addressLineTwo.key]: {
                          value: e.target.value,
                        },
                      })
                    }
                    fullWidth
                    my={2}
                    disabled={isLoading}
                    required={senderAddressInfo.addressLineTwo.required}
                  />
                </Grid>

                <Grid container spacing={6} item>
                  <Grid item xs={12} md={4}>
                    <TextField
                      type="text"
                      id={senderAddressInfo.city.endpointKey}
                      label={senderAddressInfo.city.label}
                      variant="outlined"
                      value={senderAddressInfo.city.value}
                      onChange={(e) =>
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.city.key]: {
                            value: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={senderAddressInfo.city.required}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      type="text"
                      id={senderAddressInfo.state.endpointKey}
                      label={senderAddressInfo.state.label}
                      variant="outlined"
                      value={senderAddressInfo.state.value}
                      onChange={(e) =>
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.state.key]: {
                            value: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={senderAddressInfo.state.required}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      type="text"
                      id={senderAddressInfo.zipCode.endpointKey}
                      label={senderAddressInfo.zipCode.label}
                      variant="outlined"
                      value={senderAddressInfo.zipCode.value}
                      onChange={(e) =>
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.zipCode.key]: {
                            value: e.target.value,
                          },
                        })
                      }
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={senderAddressInfo.zipCode.required}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <br />
              <br />
            </React.Fragment>
          )}
        </CardContent>
        <CardActions>
          <Button
            // fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <React.Fragment>
                <CircularProgress size={24} />
              </React.Fragment>
            ) : (
              "Submit"
            )}
          </Button>
        </CardActions>
      </form>
    </React.Fragment>
  );
}
