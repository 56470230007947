import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import AutocompleteMui from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { getRequestErrorMessage, getUserNamePlus } from "../helpers";
import {
  getAllAvailableFundAccounts,
  getAllTemplates,
  getBalance,
} from "../backend";
import { useSelector } from "react-redux";
import { getActiveUser, getCurrentUserKey } from "../redux/selectors";

const blankResults = {
  fundAccounts: [],
  fundAccountCount: 0,
};

const Autocomplete = styled(AutocompleteMui)(spacing);

export default function AvailableFundAccountAutoComplete(props) {
  const currentUserKey = useSelector(getCurrentUserKey);
  const activeUser = useSelector(getActiveUser).user;

  const [activeUserBalance, setActiveUserBalance] = useState("0.00");

  const selfFundAccount = {
    id: activeUser.id,
    balance: activeUserBalance,
    user: activeUser,
  };

  let [selectedFundAccount, setSelectedFundAccount] = useState(null);

  if (
    props.selectedFundAccount !== undefined &&
    props.setSelectedFundAccount !== undefined
  ) {
    selectedFundAccount = props.selectedFundAccount;
    setSelectedFundAccount = props.setSelectedFundAccount;
  }

  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(blankResults);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  useEffect(() => {
    setSelectedFundAccount(selfFundAccount);
  }, [currentUserKey]);

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, or, q }) => {
        setIsLoading(true);

        try {
          const activeUserBalanceResults = await getBalance();
          const response = await getAllAvailableFundAccounts({
            limit: l,
            offset: o,
            order: or,
            query: q,
          });

          setActiveUserBalance(activeUserBalanceResults.data.balance);

          setSearchResults({
            fundAccounts: response.data.fundAccounts,
            fundAccountCount: response.data.fundAccountCount,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Unable to search.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      setSearchResults(blankResults);
      setErrorMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        or: [orderBy, order],
        q: searchQuery,
      });
    } else {
      setSearchResults(blankResults);
    }
  }, [searchQuery, limit, offset, order, orderBy]);

  React.useEffect(() => {
    setSearchResults(blankResults);
    fetchData.current({
      l: limit,
      o: offset,
      or: [orderBy, order],
      q: searchQuery,
    });
  }, [currentUserKey]);

  const parsedOptions = searchResults.fundAccounts.map(
    (fundAccount) => fundAccount
  );

  // create "fund account" for active user
  if (!isLoading) {
    parsedOptions.unshift(selfFundAccount);
  }

  if (
    selectedFundAccount &&
    !parsedOptions.some((o) => o.id === selectedFundAccount.id)
  ) {
    parsedOptions.push(selectedFundAccount);
  }

  return (
    <Autocomplete
      {...(props.autoCompleteProps || {})}
      id="fund-account-search"
      disableClearable={true}
      loading={isLoading}
      loadingText="Loading..."
      noOptionsText={errorMessage || "No results."}
      fullWidth
      options={parsedOptions}
      getOptionSelected={(option, value) =>
        option && selectedFundAccount
          ? option.id === selectedFundAccount.id
          : false
      }
      getOptionLabel={(option) => {
        // if (option.id === activeUser.id) {
        //   return `You - $${activeUserBalance}`;
        // }

        return `#${option.id} - ${getUserNamePlus({
          compareUser: activeUser,
          overrideUser: option.user,
          includeIsYou: true,
        })}, ${option.user.email} - $${option.balance}`;
      }}
      onChange={(e, newValue) => {
        if (!newValue) {
          return;
        }

        setSelectedFundAccount(newValue);
      }}
      value={selectedFundAccount}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
            }}
            label={"Available Fund Accounts"}
            required
            error={errorMessage ? true : false}
            variant="outlined"
            value={searchQuery}
            // helperText="Choose which account to pull funds from"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        );
      }}
    />
  );
}
