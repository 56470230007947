import { RESET_CURRENT_USER_KEY, SET_CURRENT_USER_KEY } from "./types";

export const resetCurrentUserKey = () => {
  return {
    type: RESET_CURRENT_USER_KEY,
  };
};

export const setCurrentUserKey = (userKey) => {
  return {
    type: SET_CURRENT_USER_KEY,
    payload: {
      userKey,
    },
  };
};
