import _ from "lodash";

export default function fillBlankAddressValues(addressObject, props) {
  const newAddressDetails = _.cloneDeep(addressObject);

  _.forEach(newAddressDetails, (value, key) => {
    let fillValue = _.get(props, key, "");

    if (!fillValue) fillValue = _.get(props, value.key, "");

    if (!fillValue) fillValue = _.get(props, value.endpointKey, "");

    if (!fillValue) fillValue = _.get(props, value.databaseKey, "");

    if (fillValue) newAddressDetails[key].value = `${fillValue}`;
  });

  return newAddressDetails;
}
