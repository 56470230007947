import _ from "lodash";
import {
  RESET_ACCESS_TOKEN_DATA,
  RESET_ACCESS_TOKEN_DETAILS_DATA,
  RESET_ACCOUNT_DETAILS,
  RESET_ALL_EXTRAS,
  RESET_REFRESH_TOKEN_DETAILS_DATA,
  RESET_USERS_DATA,
  RESET_USER_DATA,
  SET_ACCESS_TOKEN_DATA,
  SET_ACCESS_TOKEN_DETAILS_DATA,
  SET_ACCOUNT_DETAILS_BALANCE,
  SET_ALL_EXTRAS,
  SET_REFRESH_TOKEN_DETAILS_DATA,
  SET_USERS_DATA,
  SET_USER_DATA,
  RESET_USER_FALLBACK_RETURN_ADDRESS_INFO,
  SET_USER_FALLBACK_RETURN_ADDRESS_INFO,
} from "../actions/types";
import { users as initialState } from "../initialState";
import accessTokenDetailsReducer from "./users/accessTokenDetailsReducer";
import accessTokenReducer from "./users/accessTokenReducer";
import accountDetailsReducer from "./users/accountDetailsReducer";
import extrasReducer from "./users/extrasReducer";
import refreshTokenDetailsReducer from "./users/refreshTokenDetailsReducer";
import userReducer from "./users/userReducer";
import userFallbackReturnAddressInfoReducer from "./users/userFallbackReturnAddressInfoReducer";

export default function usersReducer(state = initialState, action) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_USERS_DATA:
      let resettingPrimary = action.payload.userKey === "primary";
      if (!resettingPrimary) {
        // we can just delete the entry
        let state_cloned = _.cloneDeep(state);
        delete state_cloned[action.payload.userKey];
        return state_cloned;
      }
      return _.assign({}, state, {
        [action.payload.userKey]: {
          user: userReducer(state[action.payload.userKey].user, {
            type: RESET_USER_DATA,
          }),
          userFallbackReturnAddressInfo: userFallbackReturnAddressInfoReducer(
            state[action.payload.userKey].userFallbackReturnAddressInfo,
            {
              type: RESET_USER_FALLBACK_RETURN_ADDRESS_INFO,
            }
          ),
          accountDetails: accountDetailsReducer(
            state[action.payload.userKey].accountDetails,
            {
              type: RESET_ACCOUNT_DETAILS,
            }
          ),
          accessToken: accessTokenReducer(
            state[action.payload.userKey].accessToken,
            { type: RESET_ACCESS_TOKEN_DATA }
          ),
          accessTokenDetails: accessTokenDetailsReducer(
            state[action.payload.userKey].accessTokenDetails,
            {
              type: RESET_ACCESS_TOKEN_DETAILS_DATA,
            }
          ),
          refreshTokenDetails: refreshTokenDetailsReducer(
            state[action.payload.userKey].refreshTokenDetails,
            {
              type: RESET_REFRESH_TOKEN_DETAILS_DATA,
            }
          ),
          extras: extrasReducer(state[action.payload.userKey].extras, {
            type: RESET_ALL_EXTRAS,
          }),
        },
      });
    case SET_USERS_DATA:
      if (!action.payload) {
        return state;
      }
      let statePath = state[action.payload.userKey] || {};
      return _.assign({}, state, {
        [action.payload.userKey]: {
          user: userReducer(statePath.user, {
            type: SET_USER_DATA,
            payload: action.payload.data.user,
          }),
          userFallbackReturnAddressInfo: userFallbackReturnAddressInfoReducer(
            statePath.userFallbackReturnAddressInfo,
            {
              type: SET_USER_FALLBACK_RETURN_ADDRESS_INFO,
              payload: action.payload.data.userFallbackReturnAddressInfo,
            }
          ),
          accountDetails: accountDetailsReducer(statePath.accountDetails, {
            type: SET_ACCOUNT_DETAILS_BALANCE,
            payload: action.payload.data.accountDetails,
          }),
          accessToken: accessTokenReducer(statePath.accessToken, {
            type: SET_ACCESS_TOKEN_DATA,
            payload: action.payload.data.accessToken,
          }),
          accessTokenDetails: accessTokenDetailsReducer(
            statePath.accessTokenDetails,
            {
              type: SET_ACCESS_TOKEN_DETAILS_DATA,
              payload: action.payload.data.accessTokenDetails,
            }
          ),
          refreshTokenDetails: refreshTokenDetailsReducer(
            statePath.refreshTokenDetails,
            {
              type: SET_REFRESH_TOKEN_DETAILS_DATA,
              payload: action.payload.data.refreshTokenDetails,
            }
          ),
          extras: extrasReducer(statePath.extras, {
            type: SET_ALL_EXTRAS,
            payload: action.payload.data.extras,
          }),
        },
      });
    default:
      return state;
  }
}
