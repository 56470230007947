import React from "react";
import { Chip as MuiChip } from "@material-ui/core";
import constants from "../../constants";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import { green, orange, grey, red, yellow } from "@material-ui/core/colors";
import { BigNumber } from "../../money";
const orderStati = constants.hand_written_note_order_status_types;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => {
    switch (props.transactionType) {
      case constants.transaction_types.deposit.typeName:
        return green[props.colorLevel * 100];
      case constants.transaction_types.withdrawal.typeName:
        return red[props.colorLevel * 100];
      default:
        return grey[props.colorLevel * 100];
    }
  }};
  color: ${(props) => {
    switch (props.transactionType) {
      case constants.transaction_types.deposit.typeName:
        return "";
      case constants.transaction_types.withdrawal.typeName:
        return "";
      default:
        return "";
    }
  }};
`;

export default function TransactionTypeChip(props) {
  const propsToPass = props.propsToPass ?? {};
  const transactionType = `${props.transactionType}`;
  const label =
    transactionType.charAt(0).toUpperCase() + transactionType.slice(1);

  return (
    <Chip
      size="small"
      mr={1}
      mb={1}
      label={label}
      colorLevel={props.colorLevel || 2}
      transactionType={transactionType}
      {...propsToPass}
    />
  );
}
