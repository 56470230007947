module.exports.NO_TOKEN_PROVIDED = "NO_TOKEN_PROVIDED";
module.exports.USER_NOT_ADMIN = "USER_NOT_ADMIN";
module.exports.USER_ACCESS_TOKEN_INVALID = "USER_ACCESS_TOKEN_INVALID";
module.exports.ACTOR_ACCESS_TOKEN_INVALID = "ACTOR_ACCESS_TOKEN_INVALID";
module.exports.USER_REFRESH_TOKEN_INVALID = "USER_REFRESH_TOKEN_INVALID";
module.exports.USER_API_TOKEN_INVALID = "USER_API_TOKEN_INVALID";
module.exports.USER_LOGIN_TOKEN_INVALID = "USER_LOGIN_TOKEN_INVALID";
module.exports.NON_EXISTENT_ROUTE = "NON_EXISTENT_ROUTE";
module.exports.TEMPLATE_VERIFICATION_ERROR = "TEMPLATE_VERIFICATION_ERROR";
module.exports.INVALID_REQUEST_PARAMETERS = "INVALID_REQUEST_PARAMETERS";
module.exports.NON_EXISTENT_OR_UNAUTHORIZED = "NON_EXISTENT_OR_UNAUTHORIZED";
module.exports.ASSOCIATION_CONSTRAINT = "ASSOCIATION_CONSTRAINT";
module.exports.DATABASE_ERROR = "DATABASE_ERROR";
module.exports.STATUS_CONSTRAINT = "STATUS_CONSTRAINT";
module.exports.INSUFFICENT_FUNDS = "INSUFFICENT_FUNDS";
module.exports.INSUFFICENT_PERMISSIONS = "INSUFFICENT_PERMISSIONS";
module.exports.INSUFFICENT_ROLE = "INSUFFICENT_ROLE";
module.exports.NOT_STRIPE = "NOT_STRIPE";
module.exports.ON_WAITLIST = "ON_WAITLIST";
