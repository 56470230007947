import React, { useEffect } from "react";
import _ from "lodash";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
  ButtonBase,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import { getActiveUser } from "../../../redux/selectors";
import constants from "../../../constants";

export default function RecursivePermissionList(props) {
  let {
    node,
    depth = 1,
    path,
    updatePathValue,
    topLevelParent,
    topLevelParentProps,
    parent,
    parentProps,
    disabled,
    compareUser,
  } = props;

  if (!node) {
    return null;
  }

  let tempNode = _.cloneDeep(node);

  if (
    compareUser &&
    compareUser.role === constants.roles.user.typeName &&
    depth === 1
  ) {
    delete tempNode.ceos;
    delete tempNode.superAdmins;
    delete tempNode.repManagers;
    delete tempNode.reps;
  }

  return (
    <List>
      {Object.values(tempNode).map((n, i) => {
        // setup main list values
        const [collapseIsOpen, setCollapseIsOpen] = React.useState(false);
        const labelId = `checkbox-list-label-${
          n.readWriteShortKeyPath || n.shortKey
        }`;
        const name = n.name + ` (${depth})`;

        // setup update helper values
        const newPath = path ? `${path}.${n.key}` : n.key;
        const readPath = `${newPath}.read`;
        const writePath = `${newPath}.write`;

        // setup readValue
        let readValue = n.read;

        // setup writeValue
        let writeValue = n.write;

        // setup isDisabled
        let isDisabled = n.disabled;
        if (disabled) {
          isDisabled = true;
        }
        if (parent && parent.disabled) {
          isDisabled = true;
        }
        if (parentProps && parentProps.disabled) {
          isDisabled = true;
        }
        if (topLevelParent && topLevelParent.disabled) {
          isDisabled = true;
        }
        if (topLevelParentProps && topLevelParentProps.disabled) {
          isDisabled = true;
        }

        // setup children helper values
        let childrenAllChecked_read = true;
        let childrenSomeChecked_read = false;

        let childrenAllChecked_write = true;
        let childrenSomeChecked_write = false;

        const checkNodeChildren = (children) => {
          if (!children) {
            return;
          }
          Object.values(children).forEach((child) => {
            if (child.read) {
              childrenSomeChecked_read = true;
            } else {
              childrenAllChecked_read = false;
            }
            if (child.write) {
              childrenSomeChecked_write = true;
            } else {
              childrenAllChecked_write = false;
            }

            if (child.children) {
              checkNodeChildren(child.children);
            }
          });
        };

        checkNodeChildren(n.children);

        useEffect(() => {
          if (!n.children) {
            return;
          }

          let updatePaths = [];
          let updateValue = true;

          if (childrenAllChecked_read) {
            updatePaths.push(readPath);
          }

          if (childrenAllChecked_write) {
            updatePaths.push(writePath);
          }

          if (updatePaths.length > 0) {
            updatePathValue(updatePaths, updateValue);
          }
        }, [childrenAllChecked_read, childrenAllChecked_write]);

        // handle toggling values, and update parent if we are unchecking a box
        const handleToggle = (mode) => {
          let shouldToggleRead = mode === "read";
          let updatePath = mode === "read" ? readPath : writePath;
          let currentValue = shouldToggleRead ? readValue : writeValue;
          let newValue = !currentValue;
          let pathsToUpdate = [updatePath];

          if (n.children) {
            // recursively uncheck all children
            const recursivelySetChildren = (children, path = newPath) => {
              Object.values(children).forEach((child) => {
                const childPath = `${path}.children.${child.key}`;
                const childUpdatePath = shouldToggleRead
                  ? `${childPath}.read`
                  : `${childPath}.write`;

                pathsToUpdate.push(childUpdatePath);

                if (child.children) {
                  recursivelySetChildren(child.children, childPath);
                }
              });
            };
            recursivelySetChildren(n.children);
          }

          if (!newValue && parent) {
            // update parent
            const parentUpdatePath = shouldToggleRead
              ? parentProps.readPath
              : parentProps.writePath;
            pathsToUpdate.push(parentUpdatePath);
          }

          if (!newValue && topLevelParent) {
            // update top level parent
            const topLevelParentUpdatePath = shouldToggleRead
              ? topLevelParentProps.readPath
              : topLevelParentProps.writePath;
            pathsToUpdate.push(topLevelParentUpdatePath);
          }

          updatePathValue(pathsToUpdate, newValue);
        };

        return (
          <React.Fragment key={i}>
            <ListItem key={labelId} role={undefined} dense button>
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <ListItemIcon>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs
                        component={FormControlLabel}
                        control={
                          <Checkbox
                            disabled={isDisabled}
                            checked={readValue}
                            indeterminate={
                              n.children &&
                              childrenSomeChecked_read &&
                              !childrenAllChecked_read
                            }
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId + "-read",
                            }}
                            onChange={() => {
                              handleToggle("read");
                            }}
                            size="small"
                          />
                        }
                        label={"Read"}
                        labelPlacement="bottom"
                      />
                      <Grid
                        item
                        xs
                        component={FormControlLabel}
                        control={
                          <Checkbox
                            disabled={isDisabled}
                            checked={writeValue}
                            indeterminate={
                              n.children &&
                              childrenSomeChecked_write &&
                              !childrenAllChecked_write
                            }
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId + "-write",
                            }}
                            onChange={() => {
                              handleToggle("write");
                            }}
                            size="small"
                          />
                        }
                        label={"Write"}
                        labelPlacement="bottom"
                      />
                    </Grid>
                  </ListItemIcon>
                </Grid>
                <Grid item xs>
                  <Grid
                    container
                    alignItems="center"
                    component={ButtonBase}
                    onClick={() => {
                      setCollapseIsOpen(!collapseIsOpen);
                    }}
                  >
                    <Grid item>
                      <ListItemText
                        id={labelId}
                        primary={name}
                        secondary={n.description}
                        align="left"
                      />
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                      {n.children &&
                        (collapseIsOpen ? <ExpandLess /> : <ExpandMore />)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
            {n.children && (
              <Collapse in={collapseIsOpen} timeout="auto" unmountOnExit>
                <ListItem>
                  <List style={{ width: "100%" }}>
                    <RecursivePermissionList
                      compareUser={compareUser}
                      node={n.children}
                      depth={depth + 1}
                      path={`${newPath}.children`}
                      updatePathValue={updatePathValue}
                      disabled={disabled}
                      topLevelParent={topLevelParent || n}
                      topLevelParentProps={
                        topLevelParentProps || {
                          depth,
                          path,
                          labelId,
                          name,
                          newPath,
                          readPath,
                          isDisabled,
                          writePath,
                          readValue,
                          writeValue,
                        }
                      }
                      parent={n}
                      parentProps={{
                        depth,
                        path,
                        labelId,
                        name,
                        newPath,
                        readPath,
                        isDisabled,
                        writePath,
                        readValue,
                        writeValue,
                      }}
                    />
                  </List>
                </ListItem>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
}
