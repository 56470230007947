import React from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {
  getAllMapCollections,
  getAllPurchaseOrders,
  getAllTemplates,
  getAllUserShares,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";

import { Alert as MuiAlert } from "@material-ui/lab";
import OrderStatusChip from "../components/OrderStatusChip";
import { useSelector } from "react-redux";
import { getCurrentUserKey } from "../../redux/selectors";
import ClickableTableCell from "../../components/ClickableTableCell";
import { globalHistory } from "../../history";
import AdvancedTable from "../tables/AdvancedTable";
import userShareRowColumns from "../../advancedTableConfigs/userShareRowColumns";
import QuestionMarkPopover from "../components/QuestionMarkPopover";

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.success && green[500]};
  background: ${(props) => props.pending && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

function UserShareList() {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searchMode, setSearchMode] = React.useState(2);

  return (
    <React.Fragment>
      <Helmet title="User Shares" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            User Shares
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Typography>User Shares</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              component={ReactRouterLink}
              to={{
                pathname: "/account/share/new",
                state: {
                  intentToCreate: true,
                },
              }}
            >
              <AddIcon />
              New Share
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Alert
            severity="info"
            component={ReactRouterLink}
            to={{
              pathname: "/account/share/new",
              state: {
                intentToCreate: true,
              },
            }}
            style={{
              textDecoration: "none",
            }}
          >
            <Grid container alignItems="center">
              <Typography variant="body1" style={{ marginRight: 4 }}>
                <strong>Introducing credit sharing!</strong> Click here to
                create a new share or select an existing one below to set an
                allowance for your best sales reps!
              </Typography>
              <QuestionMarkPopover>
                <Typography variant="body1">
                  <strong>What is credit sharing?</strong>
                  <br />
                  <br />
                  Credit sharing allows you to set a rolling maximum for any
                  user to create orders directly from their account, but use
                  your funds.
                  <br />
                  <br />
                  Useful for handing out credits to your best sales reps or
                  other employees under you.
                </Typography>
              </QuestionMarkPopover>
            </Grid>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Paper square>
            <Tabs
              value={searchMode - 1}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => setSearchMode(value + 1)}
            >
              <Tab
                label={
                  <Grid container alignItems="center">
                    <Typography
                      variant="body1"
                      style={{
                        marginRight: 4,
                      }}
                    >
                      Shared to others
                    </Typography>
                    <QuestionMarkPopover>
                      <Typography variant="body1">
                        These are shares that have been created (by you or
                        someone else) that allow other users to access your
                        account or credits.
                      </Typography>
                    </QuestionMarkPopover>
                  </Grid>
                }
              />
              <Tab
                label={
                  <Grid container alignItems="center">
                    <Typography
                      variant="body1"
                      style={{
                        marginRight: 4,
                      }}
                    >
                      Shared with me
                    </Typography>
                    <QuestionMarkPopover>
                      <Typography variant="body1">
                        These are shares that you have been added to and grant
                        you permission to access other user accounts and/or
                        funds.
                      </Typography>
                    </QuestionMarkPopover>
                  </Grid>
                }
              />
              <Tab
                label={
                  <Grid container alignItems="center">
                    <Typography
                      variant="body1"
                      style={{
                        marginRight: 4,
                      }}
                    >
                      Managed by me
                    </Typography>
                    <QuestionMarkPopover>
                      <Typography variant="body1">
                        These are shares that you manage, including your own and
                        other users.
                      </Typography>
                    </QuestionMarkPopover>
                  </Grid>
                }
              />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <AdvancedTable
            title="User Shares"
            rowColumns={userShareRowColumns}
            identifier={searchMode}
            getCustomRowRenderProps={({
              row,
              index,
              rowItem,
              rowConfigItem,
            }) => {
              let extractedRowUser = {};

              if (searchMode === 1) {
                // returns the shares that we are a user of (someone can act as us)
                // return the person that can act as us
                extractedRowUser = row.user_share_actor;
              } else if (searchMode === 2) {
                // returns the shares that we are the actor of (we can act as someone else)
                // return the users we can impersonate
                extractedRowUser = row.user_share_user;
              } else if (searchMode === 3) {
                // returns the shares that we manage (we grant access between actors and user with this share)
                // return the user we managed this for (this will end up being our own
                extractedRowUser = row.user_share_user;
              }

              return {
                extractedRowUser,
              };
            }}
            getRowData={async ({ limit, offset, order }) => {
              const results = await getAllUserShares({
                limit,
                offset,
                order,
                mode: searchMode,
              });

              let parsedRowData = {
                rows: results.data.userShares,
                totalRowCount: results.data.userShareCount,
              };

              return parsedRowData;
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserShareList;
