import { RESET_CURRENT_USER_KEY, SET_CURRENT_USER_KEY } from "../actions/types";
import { currentUserKey as initialState } from "../initialState";

export default function currentUserKeyReducer(state = initialState, action) {
  if (state === null || state === undefined) {
    return initialState;
  }
  switch (action.type) {
    case RESET_CURRENT_USER_KEY:
      return initialState;
    case SET_CURRENT_USER_KEY:
      if (!action.payload) {
        return state;
      }
      return action.payload.userKey;
    default:
      return state;
  }
}
