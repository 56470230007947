import React from "react";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import styled, { createGlobalStyle } from "styled-components/macro";
import blankReceiverAddressInfo from "./blankReceiverAddressInfo";
import blankSenderAddressInfo from "./blankSenderAddressInfo";
import {
  placeHandWrittenNoteOrder,
  placeHandWrittenNoteOrderBulk,
} from "../../../backend";
import { globalHistory } from "../../../history";
import { getRequestErrorMessage } from "../../../helpers";
import _ from "lodash";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { CircularProgress } from "@material-ui/core";
import XLSX from "xlsx";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { BigNumber, getDiscountPercentageProps } from "../../../money";
import { useSelector } from "react-redux";
import {
  getActiveUser,
  getActiveUserFallbackReturnAddressInfo,
  getPrimaryUserFallbackReturnAddressInfo,
} from "../../../redux/selectors";
import fillBlankAddressValues from "./fillBlankAddressValues";
import { AddCircle, ExpandMore, RemoveCircle } from "@material-ui/icons";
import { nanoid } from "nanoid";
import HttpsTextField from "../../../components/HttpsTextField";
import { NavLink } from "react-router-dom";

const GlobalStyleDropzone = createGlobalStyle`
  [class^="DropzoneArea-dropZone"] {
    min-height: 160px;
  }
`;

const Alert = styled(MuiAlert)(spacing);

const allowedFileTypes = [".xlsx", ".xlsb", ".xlsm", ".xls", ".csv", ".ods"];
const SheetJSFT = allowedFileTypes.join(",");

const getDefaultOrderParametersObject = (parameters) => {
  return parameters
    .map((key) => {
      return {
        value: "",
        extractValue: "",
        key: key,
      };
    })
    .reduce((result, item, index, array) => {
      result[item.key] = item;
      return result;
    }, {});
};

const AdvancedTextField = (props) => {
  const {
    keyName,
    value,
    extractValue,
    onChange,
    options = [],
    label = "",
    required = false,
    disabled = false,
    autocompleteProps = {},
    inputProps = {},
    InputProps = {},
    inputComponent = null,
  } = props;

  const InputComponent = inputComponent || TextField;

  return (
    <Autocomplete
      type="text"
      id={keyName}
      disableClearable={true}
      fullWidth
      disabled={disabled}
      my={2}
      {...autocompleteProps}
      options={_.uniq([...options]).filter((option) => {
        return _.toLower(option).includes(
          _.toLower(value) || _.toLower(extractValue)
        );
      })}
      value={value || extractValue}
      freeSolo={true}
      onChange={(e, newValue) => {
        if (newValue) {
          if (options.includes(newValue)) {
            onChange({
              extractValue: newValue,
              value: "",
            });
          } else {
            onChange({
              extractValue: "",
              value: newValue,
            });
          }
        }
      }}
      renderInput={(params) => {
        return (
          <InputComponent
            disabled={disabled}
            variant="outlined"
            required={required}
            helperText={keyName}
            {...inputProps}
            {...params}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
            }}
            label={label ? label : _.words(keyName).map(_.capitalize).join(" ")}
            value={value || extractValue}
            onChange={(e) => {
              let value = e.target.value;

              if (value && options.includes(value)) {
                onChange({
                  extractValue: value,
                  value: "",
                });
              } else {
                onChange({
                  extractValue: "",
                  value: value,
                });
              }
            }}
          />
        );
      }}
    />
  );
};

export default function BulkForm(props) {
  const { selectedTemplate, selectedFundAccount, cardSize } = props;

  const activeUser = useSelector(getActiveUser).user;

  let userFallbackReturnAddress = useSelector(
    getPrimaryUserFallbackReturnAddressInfo
  );

  if (!userFallbackReturnAddress)
    userFallbackReturnAddress = useSelector(
      getActiveUserFallbackReturnAddressInfo
    );

  const templateId = selectedTemplate.id;
  const includeEnvelopeParams =
    !cardSize || cardSize.envelopeInchDimensions !== null;
  const includeSenderAddressInfo = !selectedTemplate.exclude_sender_address_info;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [purchaseOrder, setPurchaseOrder] = React.useState({
    value: "",
    extractValue: "",
    key: "purchaseOrder",
  });
  const [copies, setCopies] = React.useState(1);

  const [orderParameters, setOrderParameters] = React.useState(null);
  const [senderAddressInfo, setSenderAddressInfo] = React.useState(null);

  const [receiverAddressInfoList, setReceiverAddressInfoList] = React.useState(
    []
  );

  const [file, setFile] = React.useState(null);
  const [fileColumnHeaders, setFileColumnHeaders] = React.useState([]);
  const [fileData, setFileData] = React.useState([]);
  const [fileHasParsed, setFileHasParsed] = React.useState(false);

  const [resetDefaults, setResetDefaults] = React.useState(false);

  let fileHasData =
    fileData &&
    fileData.length > 0 &&
    fileColumnHeaders &&
    fileColumnHeaders.length > 0;
  let hasSelectedFile = file && file.name;
  let isFileReady = fileHasParsed && hasSelectedFile && fileHasData;

  let allowCopies = !includeEnvelopeParams && !isFileReady;
  let allowsMultipleReceivers = includeEnvelopeParams && !isFileReady;

  const handleSetFile = (files) => {
    if (files && files[0]) {
      const file = files[0];
      setFile(file);
    }
  };

  const handleDeleteFile = () => {
    setResetDefaults(true);
  };

  const handleSetPurchaseOrder = (n) => {
    let prev = _.cloneDeep(purchaseOrder);
    let merged = _.merge(prev, n);
    setPurchaseOrder(merged);
  };

  const handleSetSenderAddressInfo = (n) => {
    let prev = _.cloneDeep(senderAddressInfo);
    let merged = _.merge(prev, n);
    setSenderAddressInfo(merged);
  };

  const handleSetReceiverAddressListItemInfo = (n, i) => {
    let prev = _.cloneDeep(receiverAddressInfoList);
    prev[i] = _.merge(prev[i], n);
    setReceiverAddressInfoList(prev);
  };

  const handleSetOrderParameters = (n) => {
    let prev = _.cloneDeep(orderParameters);
    let merged = _.merge(prev, n);
    setOrderParameters(merged);
  };

  const getOrderDetails = () => {
    let orderDetails = {
      templateId: templateId,
    };

    if (isFileReady) {
      // return for bulk order
      orderDetails.orders = fileData.map((row) => {
        let order = {
          purchaseOrder: `${
            row[purchaseOrder.extractValue] ||
            row[purchaseOrder.value] ||
            purchaseOrder.value ||
            ""
          }`,
          parameters: {},
        };

        if (orderParameters) {
          _.forEach(orderParameters, (parameter, key) => {
            order.parameters[parameter.key] = `${
              row[parameter.extractValue] ||
              row[parameter.value] ||
              parameter.value ||
              ""
            }`;
          });
        }
        if (senderAddressInfo) {
          _.forEach(senderAddressInfo, (parameter, key) => {
            order[parameter.endpointKey] = `${
              row[parameter.extractValue] ||
              row[parameter.value] ||
              parameter.value ||
              ""
            }`;
          });
        }

        if (receiverAddressInfoList && receiverAddressInfoList.length > 0) {
          _.forEach(receiverAddressInfoList[0], (parameter, key) => {
            let isUrl = false;
            if (parameter.key === "redirectUrl") {
              isUrl = true;
            }

            if (key === "refId") {
              return;
            }

            let useValue =
              row[parameter.extractValue] ||
              row[parameter.value] ||
              parameter.value;

            if (isUrl && useValue) {
              useValue = useValue.includes("://")
                ? useValue
                : `https://${useValue}`;
            }

            order[parameter.endpointKey] = `${useValue || ""}`;
          });
        }

        return order;
      });
    } else if (allowsMultipleReceivers) {
      orderDetails = {
        ...orderDetails,
      };

      if (receiverAddressInfoList && receiverAddressInfoList.length > 0) {
        orderDetails.orders = receiverAddressInfoList.map((row) => {
          let order = {
            purchaseOrder: purchaseOrder.value,
            parameters: {},
          };

          if (orderParameters) {
            Object.values(orderParameters).forEach((param) => {
              order.parameters[param.key] = param.value;
            });
          }

          if (senderAddressInfo) {
            Object.values(senderAddressInfo).forEach((param) => {
              order[param.endpointKey] = param.value;
            });
          }

          if (row) {
            Object.values(row).forEach((param) => {
              let isUrl = false;
              if (param.key === "redirectUrl") {
                isUrl = true;
              }

              if (param.value) {
                order[param.endpointKey] = !isUrl
                  ? param.value
                  : param.value.includes("://")
                  ? param.value
                  : param.value
                  ? `https://${param.value}`
                  : "";
              }
            });
          }

          return order;
        });
      }
    } else {
      // return for "single order"
      orderDetails = {
        ...orderDetails,
        purchaseOrder: purchaseOrder.value,
        copies: copies ? (copies < 1 ? 1 : copies) : 1,
      };

      if (orderParameters) {
        orderDetails.parameters = {};
        Object.values(orderParameters).forEach((param) => {
          orderDetails.parameters[param.key] = param.value;
        });
      }

      if (senderAddressInfo) {
        Object.values(senderAddressInfo).forEach((param) => {
          orderDetails[param.endpointKey] = param.value;
        });
      }

      if (receiverAddressInfoList && receiverAddressInfoList.length > 0) {
        Object.values(receiverAddressInfo[0]).forEach((param) => {
          let isUrl = false;
          if (param.key === "redirectUrl") {
            isUrl = true;
          }

          if (param.value) {
            orderDetails[param.endpointKey] = !isUrl
              ? param.value
              : param.value.includes("://")
              ? param.value
              : param.value
              ? `https://${param.value}`
              : "";
          }
        });
      }
    }

    return orderDetails;
  };

  const [bulkOrderResults, setBulkOrderResults] = React.useState(null);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);
    setErrorMessage(null);

    const orderDetails = getOrderDetails();

    if (isFileReady || allowsMultipleReceivers) {
      // this should be a bulk order
      try {
        let results = await placeHandWrittenNoteOrderBulk(orderDetails, {
          overrideUseBalanceFromUserId: selectedFundAccount?.user?.id,
        });
        setBulkOrderResults(results.data);
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage:
              "An unknown error occurred while submitting your order",
          })
        );
      }
    } else {
      // this should be a single order
      try {
        let results = await placeHandWrittenNoteOrder(orderDetails, {
          overrideUseBalanceFromUserId: selectedFundAccount?.user?.id,
        });
        if (orderDetails.copies > 1) {
          return globalHistory.push("/order");
        } else {
          return globalHistory.push({
            pathname: `/order/${results.data.order.id}`,
            state: {
              orderId: results.data.order.id,
            },
          });
        }
      } catch (err) {
        setErrorMessage(
          getRequestErrorMessage({
            error: err,
            fallbackMessage:
              "An unknown error occurred while submitting your order",
          })
        );
      }
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    if (resetDefaults) {
      setResetDefaults(false);
      handleSetPurchaseOrder({
        value: "",
        extractValue: "",
      });
      setErrorMessage(null);
      setFile(null);
      setFileColumnHeaders([]);
      setFileData([]);
      setFileHasParsed(false);

      if (
        selectedTemplate.required_parameters &&
        selectedTemplate.required_parameters.length > 0
      ) {
        setOrderParameters(
          getDefaultOrderParametersObject(selectedTemplate.required_parameters)
        );
      }

      if (includeEnvelopeParams) {
        setReceiverAddressInfoList([
          { ...blankReceiverAddressInfo, refId: nanoid() },
        ]);

        if (includeSenderAddressInfo) {
          if (userFallbackReturnAddress) {
            setSenderAddressInfo(
              fillBlankAddressValues(
                blankSenderAddressInfo,
                userFallbackReturnAddress
              )
            );
          } else {
            setSenderAddressInfo(blankSenderAddressInfo);
          }
        }
      }
    }
  }, [resetDefaults]);

  React.useEffect(() => {
    if (
      selectedTemplate.required_parameters &&
      selectedTemplate.required_parameters.length > 0
    ) {
      setOrderParameters(
        getDefaultOrderParametersObject(selectedTemplate.required_parameters)
      );
    }

    if (includeEnvelopeParams) {
      handleSetReceiverAddressListItemInfo(
        { ...blankReceiverAddressInfo, refId: nanoid() },
        0
      );

      if (includeSenderAddressInfo) {
        if (userFallbackReturnAddress) {
          setSenderAddressInfo(
            fillBlankAddressValues(
              blankSenderAddressInfo,
              userFallbackReturnAddress
            )
          );
        } else {
          setSenderAddressInfo(blankSenderAddressInfo);
        }
      }
    }
  }, [templateId, includeEnvelopeParams, includeSenderAddressInfo]);

  React.useEffect(() => {
    if (!file) return;

    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      /* Update state */

      let headers = [];
      if (data && data[0]) {
        headers = Object.keys(data[0]);
      }

      let autoSelectAddressInfo = {};
      let autoSelectOrderDetails = {};
      let autoSelectParameters = {};

      let autoOrderParameters = {};
      let autoSenderAddressInfo = {};
      let autoReceiverAddressInfo = {};

      if (orderParameters)
        Object.values(orderParameters).forEach((item) => {
          if (headers.includes(item.key)) {
            autoOrderParameters[item.key] = _.cloneDeep(item);
            autoOrderParameters[item.key].extractValue = item.key;
            autoOrderParameters[item.key].value = "";
          }
        });

      if (senderAddressInfo)
        Object.values(senderAddressInfo).forEach((item) => {
          if (headers.includes(item.endpointKey)) {
            autoSenderAddressInfo[item.key] = _.cloneDeep(item);
            autoSenderAddressInfo[item.key].extractValue = item.endpointKey;
            autoSenderAddressInfo[item.key].value = "";
          }
        });

      if (receiverAddressInfoList && receiverAddressInfoList.length > 0) {
        Object.values(receiverAddressInfoList[0]).forEach((item) => {
          if (headers.includes(item.endpointKey)) {
            autoReceiverAddressInfo[item.key] = _.cloneDeep(item);
            autoReceiverAddressInfo[item.key].extractValue = item.endpointKey;
            autoReceiverAddressInfo[item.key].value = "";
          }
        });
      }

      if (!_.isEmpty(autoOrderParameters))
        handleSetOrderParameters(autoOrderParameters);

      if (!_.isEmpty(autoSenderAddressInfo))
        handleSetSenderAddressInfo(autoSenderAddressInfo);

      if (!_.isEmpty(autoReceiverAddressInfo))
        handleSetReceiverAddressListItemInfo(autoReceiverAddressInfo, 0);

      setFileColumnHeaders(headers);

      setFileData(data);

      setFileHasParsed(true);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  const {
    discountPercentageDecimal,
    discountPercentage,
  } = getDiscountPercentageProps(activeUser.discount_percentage);

  return (
    <React.Fragment>
      <Dialog
        open={!!bulkOrderResults}
        onClose={() => {
          /* do nothing, we don't want it to close */
        }}
      >
        <DialogTitle>Orders Placed</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {bulkOrderResults?.rejectedOrderCount > 0 && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  <Typography variant="body1">
                    {bulkOrderResults?.rejectedOrderCount}{" "}
                    {bulkOrderResults?.rejectedOrderCount > 1
                      ? "orders were"
                      : "order was"}{" "}
                    rejected, follow the link below to view cause of rejection
                    for each order.
                  </Typography>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Alert mb={4} severity="success">
                <Typography variant="body1">
                  {bulkOrderResults?.orderCount}{" "}
                  {bulkOrderResults?.orderCount === 1
                    ? "order has"
                    : "orders have"}{" "}
                  been placed.
                </Typography>
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                We received the order details! You should receive an email and
                notification shortly confirming which orders were placed.
                Meanwhile, we'll get started cranking them out!
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* {bulkOrderResults?.orderCount < 1 && (
            // only show this button if there are no orders and let them try again essentially
            <Button onClick={() => setBulkOrderResults(null)}>Close</Button>
          )} */}
          {bulkOrderResults?.rejectedOrderCount > 0 &&
            bulkOrderResults?.dataExport && (
              <Button
                link
                component={NavLink}
                variant="outlined"
                to={{
                  pathname: `/export/${bulkOrderResults?.dataExport.id}`,
                  state: {
                    dataExportId: bulkOrderResults?.dataExport.id,
                  },
                }}
              >
                View Rejected Orders
              </Button>
            )}
          <Button
            link
            component={NavLink}
            variant="outlined"
            color="primary"
            to="/order"
          >
            Orders
          </Button>
        </DialogActions>
      </Dialog>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <CardContent>
          {errorMessage && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="warning">
                {errorMessage ??
                  "An unknown error occurred while submitting your order"}
              </Alert>
              <br />
              <br />
            </React.Fragment>
          )}

          {fileHasParsed && hasSelectedFile && !fileHasData && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="warning">
                The file you selected does not contain any data. Please select
                another and try again.
              </Alert>
              <br />
              <br />
            </React.Fragment>
          )}

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DropzoneArea
                clearOnUnmount={true}
                acceptedFiles={allowedFileTypes}
                multiple={false}
                filesLimit={1}
                showFileNamesInPreview={true}
                showFileNames={true}
                onChange={handleSetFile}
                onDelete={handleDeleteFile}
              />
            </Grid>
          </Grid>

          <br />
          <br />
          {fileHasData && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="warning">
                When extracting data from a file, the inputs below must exactly
                match the column headers in order to successfully extract the
                content from each row. Otherwise, insert any default content to
                use for all orders instead.
                <br />
                <br />
                <strong>Pro-tip:</strong> Clicking the inputs below will display
                available column headers, or type your own content.
              </Alert>
              <br />
            </React.Fragment>
          )}
          {fileHasData && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="info">
                Estimated Price: $
                {new BigNumber(
                  fileData.length >= cardSize.priceByOrderType.bulk.threshold
                    ? cardSize.priceByOrderType.bulk.overThresholdPrice
                    : cardSize.priceByOrderType.bulk.price
                )
                  .multipliedBy(fileData.length)
                  .times(discountPercentageDecimal)
                  .toFormat(2)}{" "}
                ($
                {new BigNumber(
                  fileData.length >= cardSize.priceByOrderType.bulk.threshold
                    ? cardSize.priceByOrderType.bulk.overThresholdPrice
                    : cardSize.priceByOrderType.bulk.price
                )
                  .times(discountPercentageDecimal)
                  .toFormat(2)}
                /order)
                {!discountPercentage.lte(0) &&
                  ` - ${discountPercentage}% savings applied`}
              </Alert>
              <br />
              <br />
            </React.Fragment>
          )}
          {copies > 1 && (
            <React.Fragment>
              <Alert mt={2} mb={1} severity="info">
                Estimated Price: $
                {new BigNumber(
                  copies >= cardSize.priceByOrderType.single.threshold
                    ? cardSize.priceByOrderType.single.overThresholdPrice
                    : cardSize.priceByOrderType.single.price
                )
                  .multipliedBy(copies)
                  .times(discountPercentageDecimal)
                  .toFormat(2)}{" "}
                ($
                {new BigNumber(
                  copies >= cardSize.priceByOrderType.single.threshold
                    ? cardSize.priceByOrderType.single.overThresholdPrice
                    : cardSize.priceByOrderType.single.price
                )
                  .times(discountPercentageDecimal)
                  .toFormat(2)}
                /order)
                {!discountPercentage.lte(0) &&
                  ` - ${discountPercentage}% savings applied`}
              </Alert>
              <br />
              <br />
            </React.Fragment>
          )}
          <Grid container spacing={6}>
            <Typography variant="h6">Options</Typography>
            <Grid container spacing={6} item>
              <Grid item xs={12} sm>
                <AdvancedTextField
                  keyName="purchaseOrder"
                  label="Purchase Order"
                  value={purchaseOrder.value}
                  extractValue={purchaseOrder.extractValue}
                  options={fileColumnHeaders}
                  required={true}
                  disabled={isLoading}
                  onChange={(values) => {
                    handleSetPurchaseOrder(values);
                  }}
                />
              </Grid>
              {allowCopies && (
                <React.Fragment>
                  <Grid item xs={12} sm>
                    <TextField
                      type="number"
                      id={"copies"}
                      label={"Copies"}
                      variant="outlined"
                      value={copies}
                      onChange={(e) => {
                        setCopies(e.target.value);
                      }}
                      fullWidth
                      my={2}
                      disabled={isLoading}
                      required={true}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <br />
          <br />
          {orderParameters && (
            <React.Fragment>
              <Grid container spacing={6}>
                <Typography variant="h6">Template Parameters</Typography>
                <Grid container spacing={6} item>
                  {Object.values(orderParameters).map((param) => {
                    return (
                      <Grid item xs={12} sm key={param.key}>
                        <AdvancedTextField
                          key={param.key}
                          keyName={param.key}
                          value={param.value}
                          disabled={isLoading}
                          required={true}
                          extractValue={param.extractValue}
                          options={fileColumnHeaders}
                          onChange={(values) => {
                            handleSetOrderParameters({
                              [param.key]: values,
                            });
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <br />
              <br />
            </React.Fragment>
          )}
          {senderAddressInfo && (
            <React.Fragment>
              <Grid container spacing={6}>
                <Typography variant="h6">Sender Address</Typography>
                <Grid container spacing={6} item>
                  <Grid item xs={12} md={6}>
                    <AdvancedTextField
                      keyName={senderAddressInfo.name.endpointKey}
                      label={senderAddressInfo.name.label}
                      disabled={isLoading}
                      required={senderAddressInfo.name.required}
                      value={senderAddressInfo.name.value}
                      extractValue={senderAddressInfo.name.extractValue}
                      options={fileColumnHeaders}
                      onChange={(values) => {
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.name.key]: values,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <AdvancedTextField
                      keyName={senderAddressInfo.businessName.endpointKey}
                      label={senderAddressInfo.businessName.label}
                      disabled={isLoading}
                      required={senderAddressInfo.businessName.required}
                      value={senderAddressInfo.businessName.value}
                      extractValue={senderAddressInfo.businessName.extractValue}
                      options={fileColumnHeaders}
                      onChange={(values) => {
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.businessName.key]: values,
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <AdvancedTextField
                    keyName={senderAddressInfo.addressLineOne.endpointKey}
                    label={senderAddressInfo.addressLineOne.label}
                    disabled={isLoading}
                    required={senderAddressInfo.addressLineOne.required}
                    value={senderAddressInfo.addressLineOne.value}
                    extractValue={senderAddressInfo.addressLineOne.extractValue}
                    options={fileColumnHeaders}
                    onChange={(values) => {
                      handleSetSenderAddressInfo({
                        [senderAddressInfo.addressLineOne.key]: values,
                      });
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <AdvancedTextField
                    keyName={senderAddressInfo.addressLineTwo.endpointKey}
                    label={senderAddressInfo.addressLineTwo.label}
                    disabled={isLoading}
                    required={senderAddressInfo.addressLineTwo.required}
                    value={senderAddressInfo.addressLineTwo.value}
                    extractValue={senderAddressInfo.addressLineTwo.extractValue}
                    options={fileColumnHeaders}
                    onChange={(values) => {
                      handleSetSenderAddressInfo({
                        [senderAddressInfo.addressLineTwo.key]: values,
                      });
                    }}
                  />
                </Grid>

                <Grid container spacing={6} item>
                  <Grid item xs={12} md={4}>
                    <AdvancedTextField
                      keyName={senderAddressInfo.city.endpointKey}
                      label={senderAddressInfo.city.label}
                      disabled={isLoading}
                      required={senderAddressInfo.city.required}
                      value={senderAddressInfo.city.value}
                      extractValue={senderAddressInfo.city.extractValue}
                      options={fileColumnHeaders}
                      onChange={(values) => {
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.city.key]: values,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <AdvancedTextField
                      keyName={senderAddressInfo.state.endpointKey}
                      label={senderAddressInfo.state.label}
                      disabled={isLoading}
                      required={senderAddressInfo.state.required}
                      value={senderAddressInfo.state.value}
                      extractValue={senderAddressInfo.state.extractValue}
                      options={fileColumnHeaders}
                      onChange={(values) => {
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.state.key]: values,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <AdvancedTextField
                      keyName={senderAddressInfo.zipCode.endpointKey}
                      label={senderAddressInfo.zipCode.label}
                      disabled={isLoading}
                      required={senderAddressInfo.zipCode.required}
                      value={senderAddressInfo.zipCode.value}
                      extractValue={senderAddressInfo.zipCode.extractValue}
                      options={fileColumnHeaders}
                      onChange={(values) => {
                        handleSetSenderAddressInfo({
                          [senderAddressInfo.zipCode.key]: values,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <br />
              <br />
            </React.Fragment>
          )}
          {receiverAddressInfoList && receiverAddressInfoList.length > 0 && (
            <React.Fragment>
              <Grid container spacing={6} alignItems="center">
                <Grid item>
                  <Typography variant="h6">Addresses</Typography>
                </Grid>
                {allowsMultipleReceivers && (
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        // add new blank
                        setReceiverAddressInfoList([
                          ...receiverAddressInfoList,
                          { ...blankReceiverAddressInfo, refId: nanoid() },
                        ]);
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              {receiverAddressInfoList
                .filter((r, i) => {
                  if (allowsMultipleReceivers) {
                    return true;
                  } else {
                    return i === 0;
                  }
                })
                .map((receiverAddressInfo, index) => {
                  return (
                    <Accordion
                      key={receiverAddressInfo.refId}
                      defaultExpanded={index === 0}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Grid container spacing={6} alignItems="center">
                          <Grid item>
                            <Typography>
                              Address {index + 1} -{" "}
                              {receiverAddressInfo.name.value ||
                                receiverAddressInfo.name.extractValue}
                            </Typography>
                          </Grid>
                          {index !== 0 && (
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  // remove this item
                                  setReceiverAddressInfoList((prev) => {
                                    return prev.filter((r, i) => i !== index);
                                  });
                                }}
                              >
                                <RemoveCircle />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={6}>
                          <Grid container spacing={6} item>
                            <Grid item xs={12} md={6}>
                              <AdvancedTextField
                                keyName={receiverAddressInfo.name.endpointKey}
                                label={receiverAddressInfo.name.label}
                                disabled={isLoading}
                                required={receiverAddressInfo.name.required}
                                value={receiverAddressInfo.name.value}
                                extractValue={
                                  receiverAddressInfo.name.extractValue
                                }
                                options={fileColumnHeaders}
                                onChange={(values) => {
                                  handleSetReceiverAddressListItemInfo(
                                    {
                                      [receiverAddressInfo.name.key]: values,
                                    },
                                    index
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <AdvancedTextField
                                keyName={
                                  receiverAddressInfo.businessName.endpointKey
                                }
                                label={receiverAddressInfo.businessName.label}
                                disabled={isLoading}
                                required={
                                  receiverAddressInfo.businessName.required
                                }
                                value={receiverAddressInfo.businessName.value}
                                extractValue={
                                  receiverAddressInfo.businessName.extractValue
                                }
                                options={fileColumnHeaders}
                                onChange={(values) => {
                                  handleSetReceiverAddressListItemInfo(
                                    {
                                      [receiverAddressInfo.businessName
                                        .key]: values,
                                    },
                                    index
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <AdvancedTextField
                              keyName={
                                receiverAddressInfo.addressLineOne.endpointKey
                              }
                              label={receiverAddressInfo.addressLineOne.label}
                              disabled={isLoading}
                              required={
                                receiverAddressInfo.addressLineOne.required
                              }
                              value={receiverAddressInfo.addressLineOne.value}
                              extractValue={
                                receiverAddressInfo.addressLineOne.extractValue
                              }
                              options={fileColumnHeaders}
                              onChange={(values) => {
                                handleSetReceiverAddressListItemInfo(
                                  {
                                    [receiverAddressInfo.addressLineOne
                                      .key]: values,
                                  },
                                  index
                                );
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <AdvancedTextField
                              keyName={
                                receiverAddressInfo.addressLineTwo.endpointKey
                              }
                              label={receiverAddressInfo.addressLineTwo.label}
                              disabled={isLoading}
                              required={
                                receiverAddressInfo.addressLineTwo.required
                              }
                              value={receiverAddressInfo.addressLineTwo.value}
                              extractValue={
                                receiverAddressInfo.addressLineTwo.extractValue
                              }
                              options={fileColumnHeaders}
                              onChange={(values) => {
                                handleSetReceiverAddressListItemInfo(
                                  {
                                    [receiverAddressInfo.addressLineTwo
                                      .key]: values,
                                  },
                                  index
                                );
                              }}
                            />
                          </Grid>

                          <Grid container spacing={6} item>
                            <Grid item xs={12} md={4}>
                              <AdvancedTextField
                                keyName={receiverAddressInfo.city.endpointKey}
                                label={receiverAddressInfo.city.label}
                                disabled={isLoading}
                                required={receiverAddressInfo.city.required}
                                value={receiverAddressInfo.city.value}
                                extractValue={
                                  receiverAddressInfo.city.extractValue
                                }
                                options={fileColumnHeaders}
                                onChange={(values) => {
                                  handleSetReceiverAddressListItemInfo(
                                    {
                                      [receiverAddressInfo.city.key]: values,
                                    },
                                    index
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <AdvancedTextField
                                keyName={receiverAddressInfo.state.endpointKey}
                                label={receiverAddressInfo.state.label}
                                disabled={isLoading}
                                required={receiverAddressInfo.state.required}
                                value={receiverAddressInfo.state.value}
                                extractValue={
                                  receiverAddressInfo.state.extractValue
                                }
                                options={fileColumnHeaders}
                                onChange={(values) => {
                                  handleSetReceiverAddressListItemInfo(
                                    {
                                      [receiverAddressInfo.state.key]: values,
                                    },
                                    index
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <AdvancedTextField
                                keyName={
                                  receiverAddressInfo.zipCode.endpointKey
                                }
                                label={receiverAddressInfo.zipCode.label}
                                disabled={isLoading}
                                required={receiverAddressInfo.zipCode.required}
                                value={receiverAddressInfo.zipCode.value}
                                extractValue={
                                  receiverAddressInfo.zipCode.extractValue
                                }
                                options={fileColumnHeaders}
                                onChange={(values) => {
                                  handleSetReceiverAddressListItemInfo(
                                    {
                                      [receiverAddressInfo.zipCode.key]: values,
                                    },
                                    index
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {/* qr code redirectUrl */}
                              <AdvancedTextField
                                keyName={
                                  receiverAddressInfo.redirectUrl.endpointKey
                                }
                                label={receiverAddressInfo.redirectUrl.label}
                                disabled={isLoading}
                                required={
                                  receiverAddressInfo.redirectUrl.required
                                }
                                value={receiverAddressInfo.redirectUrl.value}
                                extractValue={
                                  receiverAddressInfo.redirectUrl.extractValue
                                }
                                options={fileColumnHeaders}
                                onChange={(values) => {
                                  handleSetReceiverAddressListItemInfo(
                                    {
                                      [receiverAddressInfo.redirectUrl
                                        .key]: values,
                                    },
                                    index
                                  );
                                }}
                                inputComponent={HttpsTextField}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              <br />
              <br />
            </React.Fragment>
          )}
        </CardContent>
        <CardActions>
          <Button
            // fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <React.Fragment>
                <CircularProgress size={24} />
              </React.Fragment>
            ) : (
              "Submit"
            )}
          </Button>
        </CardActions>
      </form>
    </React.Fragment>
  );
}
