import React, { useEffect, useState } from "react";
import {
  Chip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Alert as MuiAlert, AlertTitle } from "@material-ui/lab";
import { spacing } from "@material-ui/system";

const Alert = styled(MuiAlert)(spacing);

export default function ConfirmDialog(props) {
  let [isDialogOpen, setIsDialogOpen] = useState(
    typeof props.isDialogOpen === "boolean" ? props.isDialogOpen : false
  );
  let [isLoading, setIsLoading] = useState(
    typeof props.isLoading === "boolean" ? props.isLoading : false
  );
  let [errorMessage, setErrorMessage] = useState(
    typeof props.errorMessage === "string" ? props.errorMessage : null
  );
  let [successMessage, setSuccessMessage] = useState(
    typeof props.successMessage === "string" ? props.successMessage : null
  );

  if (props.isDialogOpen && props.setIsDialogOpen) {
    isDialogOpen = props.isDialogOpen;
    setIsDialogOpen = props.setIsDialogOpen;
  }

  if (props.isLoading && props.setIsLoading) {
    isLoading = props.isLoading;
    setIsLoading = props.setIsLoading;
  }

  if (props.errorMessage && props.setErrorMessage) {
    errorMessage = props.errorMessage;
    setErrorMessage = props.setErrorMessage;
  }

  if (props.successMessage && props.setSuccessMessage) {
    successMessage = props.successMessage;
    setSuccessMessage = props.setSuccessMessage;
  }

  useEffect(() => {
    setErrorMessage(null);
    setIsLoading(false);
  }, [isDialogOpen]);

  const callbackProps = {
    isDialogOpen,
    setIsDialogOpen,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => {
        if (props.onClose) {
          props.onClose(callbackProps);
        } else {
          setIsDialogOpen(false);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>

      {errorMessage && (
        <DialogContent>
          <Alert severity="error">{errorMessage}</Alert>
        </DialogContent>
      )}

      {successMessage && (
        <DialogContent>
          <Alert severity="success">{successMessage}</Alert>
        </DialogContent>
      )}

      {props.children && <DialogContent>{props.children}</DialogContent>}

      <DialogActions>
        <Button
          onClick={() => {
            if (props.onClose) {
              props.onClose(callbackProps);
            } else {
              setIsDialogOpen(false);
            }
          }}
          disabled={isLoading}
          variant="outlined"
          {...((props.getCancelButtonProps &&
            props.getCancelButtonProps(callbackProps)) ||
            {})}
        >
          Close
        </Button>
        {props.onConfirm && (
          <Button
            onClick={() => {
              props.onConfirm && props.onConfirm(callbackProps);
            }}
            disabled={isLoading}
            variant="contained"
            {...((props.getConfirmButtonProps &&
              props.getConfirmButtonProps(callbackProps)) ||
              {})}
          >
            {(props.getConfirmButtonText &&
              props.getConfirmButtonText(callbackProps)) ||
              "Confirm"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
