import { useSelector } from "react-redux";
import constants from "../constants";
import { getActiveExtrasCardSizes, getActiveUser } from "../redux/selectors";

export default function useCardSizes(selectedKey = "") {
  const card_sizes = useSelector(getActiveExtrasCardSizes);
  const user = useSelector(getActiveUser).user;

  let cardSizes = card_sizes.filter((o) => {
    if (selectedKey === o.key) {
      return true;
    }

    if (o.hideFromClient) {
      return constants.presetRoles.repsUp.includes(user.role);
    }

    return true;
  });

  return cardSizes;
}
