import * as BG from "bignumber.js";
BG.config({ ROUDNING_MODE: BG.ROUND_HALF_EVEN });

export const BigNumber = BG;

export const getDiscountPercentageProps = (dp) => {
  let discountPercentage = dp;
  if (!discountPercentage) {
    discountPercentage = 0;
  } else if (discountPercentage >= 100) {
    discountPercentage = 100;
  } else if (discountPercentage <= 0) {
    discountPercentage = 0;
  }

  discountPercentage = new BigNumber(discountPercentage);

  let discountPercentageDecimal = new BigNumber(1).minus(
    new BigNumber(discountPercentage).dividedBy(100)
  );

  if (discountPercentageDecimal.lte(0)) {
    discountPercentageDecimal = new BigNumber(1);
  }

  return {
    discountPercentage,
    discountPercentageDecimal,
  };
};
