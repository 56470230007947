import { loadStripe } from "@stripe/stripe-js";
import { isProduction } from "../config";

let stripe = null;

let load = async () => {
  if (stripe) {
    return stripe;
  }
  stripe = await loadStripe(
    isProduction
      ? "pk_live_51JHwkXCPOhEjCQKA0odzLtFcKb49URZ1KRTZfLSAVupnH8ixvzRBTKEbnnQiDxRhYrO0WBboh4rpaR68ms6kzGXK00xzjW2Lrb"
      : "pk_test_51JHwkXCPOhEjCQKAMRtLGpxo3TQqYlkV8LgOOkBb9bROMRUPjj2Nrwg3YyxX0JQvInAG7O2KJNgDqHaDYkchocej00T3HuWlqX"
  );
  return stripe;
};

export { stripe, load };
