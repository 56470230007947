import { useEffect, useState } from "react";
import { Chip, Avatar as MuiAvatar, Box } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CopyRowItem = ({ rowItem, rowIndex, row }) => {
  const [copiedText, setCopiedText] = useState("Copy");
  const maxLength = 32;

  useEffect(() => {
    // reset copied text after timeout
    const timeout = setTimeout(() => {
      setCopiedText("");
    }, 5000);
  }, [copiedText]);

  return (
    <CopyToClipboard text={rowItem} onCopy={() => setCopiedText(rowItem)}>
      <Tooltip
        title={copiedText === rowItem ? "Copied" : "Copy To Clipboard"}
        placement="top"
      >
        <Chip
          size="small"
          mr={1}
          mb={1}
          label={
            `${rowItem}`.length > maxLength
              ? `${rowItem.slice(0, maxLength)}...`
              : `${rowItem}`
          }
        />
      </Tooltip>
    </CopyToClipboard>
  );
};

const UserAgent = ({ rowItem, rowIndex, row }) => {
  const [copiedText, setCopiedText] = useState("");

  useEffect(() => {
    // reset copied text after timeout
    const timeout = setTimeout(() => {
      setCopiedText("");
    }, 5000);
  }, [copiedText]);

  return (
    <Customer>
      <Avatar>{`${rowItem}`.slice(0, 1).toUpperCase()}</Avatar>
      <Box ml={3}>
        <CopyToClipboard text={rowItem} onCopy={() => setCopiedText(rowItem)}>
          <Tooltip
            title={copiedText === rowItem ? "Copied" : "Copy To Clipboard"}
            placement="top"
          >
            <Box style={{ cursor: "pointer" }}>{rowItem}</Box>
          </Tooltip>
        </CopyToClipboard>
      </Box>
    </Customer>
  );
};

const serverRequestRowColumns = [
  {
    id: "id",
    alignment: "left",
    label: "Request #",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => `#${rowItem}`,
  },
  {
    id: "user_agent",
    alignment: "left",
    label: "User Agent",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => {
      return <UserAgent rowItem={rowItem} rowIndex={index} row={row} />;
    },
  },
  {
    id: "ip_address",
    alignment: "left",
    label: "IP Address",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => {
      return <CopyRowItem rowItem={rowItem} rowIndex={index} row={row} />;
    },
  },
  {
    id: "request_protocol",
    alignment: "left",
    label: "Protocol",
    allowSort: true,
  },
  {
    id: "request_hostname",
    alignment: "left",
    label: "Hostname",
    allowSort: true,
  },
  {
    id: "request_original_url",
    alignment: "left",
    label: "Original Url",
    allowSort: true,
  },
  {
    id: "request_method",
    alignment: "left",
    label: "Method",
    allowSort: true,
  },
  {
    id: "createdAt",
    alignment: "left",
    label: "Created",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
  {
    id: "updatedAt",
    alignment: "left",
    label: "Updated",
    allowSort: true,
    renderRowItem: ({ row, index, rowItem }) => (
      <Chip
        size="small"
        // mr={1}
        mb={1}
        ml={4}
        label={new Date(rowItem).toDateString()}
      />
    ),
  },
];

export default serverRequestRowColumns;
