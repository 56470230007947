import React from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import _, { set } from "lodash";
import { NavLink } from "react-router-dom";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import { userSearchAll } from "../backend";
import { getRequestErrorMessage } from "../helpers";
import { globalHistory } from "../history";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  padding: 0px 12px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const blankResults = {
  template_text_results: [],
  template_text_result_count: 0,
  hand_written_note_order_results: [],
  hand_written_note_order_result_count: 0,
  transaction_results: [],
  transaction_result_count: 0,
  purchase_order_group_results: [],
  purchase_order_group_result_count: 0,
  map_collection_results: [],
  map_collection_result_count: 0,
};

const AppBarComponent = ({ onDrawerToggle }) => {
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState(blankResults);

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const fetchData = React.useRef(
    _.debounce(
      async ({ l, o, q }) => {
        setIsLoading(true);

        try {
          const response = await userSearchAll({
            limit: l,
            offset: o,
            query: q,
          });

          setSearchResults({
            template_text_results: response.data.template_text_results,
            template_text_result_count:
              response.data.template_text_result_count,
            hand_written_note_order_results:
              response.data.hand_written_note_order_results,
            hand_written_note_order_result_count:
              response.data.hand_written_note_order_result_count,
            transaction_results: response.data.transaction_results,
            transaction_result_count: response.data.transaction_result_count,
            purchase_order_group_results:
              response.data.purchase_order_group_results,
            purchase_order_group_result_count:
              response.data.purchase_order_group_result_count,
            map_collection_results: response.data.map_collection_results,
            map_collection_result_count:
              response.data.map_collection_result_count,
          });
        } catch (err) {
          setErrorMessage(
            getRequestErrorMessage({
              error: err,
              fallbackMessage: "Unable to search.",
            })
          );
        }
        setIsLoading(false);
      },
      750,
      { leading: false }
    )
  );

  React.useEffect(() => {
    if (searchQuery) {
      setIsLoading(true);
      setSearchResults(blankResults);
      setErrorMessage("");
      fetchData.current({
        l: limit,
        o: offset,
        q: searchQuery,
      });
    } else {
      setSearchResults(blankResults);
    }
  }, [searchQuery, limit, offset]);

  const parsedOptions = [];

  if (!errorMessage) {
    searchResults.template_text_results.forEach((item) => {
      parsedOptions.push({
        type: "template_text",
        path: `/template/${item.id}`,
        id: item.id,
        state: {
          templateId: item.id,
        },
        name: item.name,
        title: `Template #${item.id} - ${item.name}`,
      });
    });

    searchResults.hand_written_note_order_results.forEach((item) => {
      parsedOptions.push({
        type: "hand_written_note_order",
        path: `/order/${item.id}`,
        id: item.id,
        state: {
          orderId: item.id,
        },
        purchase_order: item.purchase_order,
        title: `Order #${item.id} - ${item.purchase_order}`,
      });
    });

    searchResults.purchase_order_group_results.forEach((item) => {
      parsedOptions.push({
        type: "purchase_order_group",
        path: `/purchase-order/${item.purchase_order}`,
        id: item.purchase_order,
        state: {
          purchaseOrder: item.purchase_order,
        },
        purchase_order: item.purchase_order,
        title: `Purchase Order - ${item.purchase_order}`,
      });
    });

    searchResults.transaction_results.forEach((item) => {
      parsedOptions.push({
        type: "transaction",
        path: `/account/transaction/${item.id}`,
        id: item.id,
        state: {
          transactionId: item.id,
        },
        name: item.name,
        title: `Transaction #${item.id} - ${item.name}`,
      });
    });

    searchResults.map_collection_results.forEach((item) => {
      parsedOptions.push({
        type: "map_collection",
        path: `/map-collection/${item.id}`,
        id: item.id,
        state: {
          collectionId: item.id,
        },
        name: item.name,
        title: `Map Collection #${item.id} - ${item.name}`,
      });
    });
  }

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <Search>
                <Grid container spacing={3} direction="row" alignItems="center">
                  <Grid item>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <SearchIcon />
                    )}
                  </Grid>
                  <Grid item xs>
                    <Autocomplete
                      id="search-all"
                      disableClearable={true}
                      loading={isLoading}
                      loadingText="Loading..."
                      noOptionsText={errorMessage || "No results."}
                      fullWidth
                      options={parsedOptions}
                      getOptionLabel={(option) => option.title}
                      onChange={(e, newValue) => {
                        if (newValue) {
                          globalHistory.push({
                            pathname: newValue.path,
                            state: newValue.state,
                          });
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            error={errorMessage ? true : false}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            placeholder="Search templates, orders, purchase orders, transactions, and map collections"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                        );
                      }}
                      // freeSolo
                      // popupIcon={null}
                      // renderOption={(item) => {
                      //   return (
                      //     <NavLink
                      //       to={{
                      //         pathname: item.path,
                      //         state: item.state,
                      //       }}
                      //     >
                      //       {item.title}
                      //     </NavLink>
                      //   );

                      //   return null;
                      // }}
                    />
                  </Grid>
                </Grid>
              </Search>
            </Grid>
            <Grid item>
              {/* <MessagesDropdown /> */}
              <NotificationsDropdown />
              {/* <LanguagesDropdown /> */}
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
