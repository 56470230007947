import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import "../../vendor/roundedBarCharts";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import useLocalStorage from "../../hooks/useLocalStorage";

import { CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  green,
  orange,
  grey,
  red,
  yellow,
  blue,
} from "@material-ui/core/colors";

const Alert = styled(MuiAlert)(spacing);

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {
  Briefcase,
  DollarSign,
  ExternalLink,
  Facebook,
  Home,
  Instagram,
  MapPin,
  ShoppingBag,
  Twitter,
  User,
  Mail,
} from "react-feather";
import {
  getAllStatistics,
  getBalance,
  getBalanceStatistics,
  getAllQrCodeScans,
  getUserAvatarUrl,
} from "../../backend";
import { getRequestErrorMessage } from "../../helpers";
import { BigNumber } from "../../money";
import {
  getActiveAccountDetails,
  getActiveUser,
  getCurrentUserKey,
} from "../../redux/selectors";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const ChartWrapper = styled.div`
  height: 400px;
  position: relative;
`;

const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProductsChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
`;

function Details() {
  const user = useSelector(getActiveUser).user;
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Profile Details
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Avatar
            alt="Lucy Lavender"
            src={getUserAvatarUrl({
              userId: user.id,
            })}
          />
          <Typography variant="body2" component="div" gutterBottom>
            <Box fontWeight="fontWeightMedium">
              {user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName}`
                : user.email}
            </Box>
            <Box fontWeight="fontWeightRegular">
              {_.words(user.role).map(_.capitalize).join(" ")}
            </Box>
          </Typography>

          {/* <Button mr={2} variant="contained" size="small">
            Follow
          </Button>
          <Button mr={2} variant="contained" color="primary" size="small">
            Message
          </Button> */}
        </Centered>
      </CardContent>
    </Card>
  );
}

function Skills() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Skills
        </Typography>

        <Spacer mb={4} />

        <Centered>
          <Chip size="small" mr={1} mb={1} label="HTML" color="secondary" />
          <Chip size="small" mr={1} mb={1} label="JavaScript" />
          <Chip size="small" mr={1} mb={1} label="Sass" />
          <Chip size="small" mr={1} mb={1} label="React" />
          <Chip size="small" mr={1} mb={1} label="Redux" />
          <Chip size="small" mr={1} mb={1} label="Next.js" />
          <Chip size="small" mr={1} mb={1} label="Material UI" />
          <Chip size="small" mr={1} mb={1} label="UI" />
          <Chip size="small" mr={1} mb={1} label="UX" />
        </Centered>
      </CardContent>
    </Card>
  );
}

function About() {
  const user = useSelector(getActiveUser).user;

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          About
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Mail />
            </AboutIcon>
          </Grid>
          <Grid item>Email: {user.email}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <User />
            </AboutIcon>
          </Grid>
          <Grid item>First name: {user.firstName ?? "Null"}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <User />
            </AboutIcon>
          </Grid>
          <Grid item>Last name: {user.lastName ?? "Null"}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Briefcase />
            </AboutIcon>
          </Grid>
          <Grid item>
            Permission Role: {_.words(user.role).map(_.capitalize).join(" ")}
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <MapPin />
            </AboutIcon>
          </Grid>
          <Grid item>Joined: {new Date(user.createdAt).toDateString()}</Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <MapPin />
            </AboutIcon>
          </Grid>
          <Grid item>
            Last updated: {new Date(user.updatedAt).toDateString()}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Elsewhere() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Elsewhere
        </Typography>

        <Spacer mb={4} />

        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <ExternalLink />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">lucylavender.io</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Twitter />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">Twitter</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Facebook />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">Facebook</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <AboutIcon>
              <Instagram />
            </AboutIcon>
          </Grid>
          <Grid item>
            <Link href="https://material-app.bootlab.io/">Instagram</Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function Earnings() {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ 2.405</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total Earnings
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={75}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function Stats(props) {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">{props.count}</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            {props.label ?? "Pending Orders"}
          </Typography>

          <StatsIcon>
            <ShoppingBag />
          </StatsIcon>
          {typeof props.count === "number" ? (
            <LinearProgress
              variant="determinate"
              value={props.count}
              color="secondary"
              mt={4}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              value={0}
              color="secondary"
              mt={4}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

function Revenue() {
  return (
    <Box position="relative">
      <Card mb={6} pt={2}>
        <CardContent>
          <Typography variant="h2" gutterBottom>
            <Box fontWeight="fontWeightRegular">$ 1.224</Box>
          </Typography>
          <Typography variant="body2" gutterBottom mt={3} mb={0}>
            Total Revenue
          </Typography>

          <StatsIcon>
            <DollarSign />
          </StatsIcon>
          <LinearProgress
            variant="determinate"
            value={50}
            color="secondary"
            mt={4}
          />
        </CardContent>
      </Card>
    </Box>
  );
}

function Products() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Products
        </Typography>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Tech</TableCell>
                <TableCell>License</TableCell>
                <TableCell>Sales</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  AppStack
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="HTML"
                    rgbcolor={blue[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>76</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Radial Link
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="React"
                    rgbcolor={green[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>38</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Milo
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="HTML"
                    rgbcolor={blue[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>43</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Robust UI Kit
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="Angular"
                    rgbcolor={red[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>27</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Spark
                </TableCell>
                <TableCell>
                  <ProductsChip
                    size="small"
                    label="React"
                    rgbcolor={green[500]}
                  />
                </TableCell>
                <TableCell>Single License</TableCell>
                <TableCell>12</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </CardContent>
    </Card>
  );
}

const OrdersByMonth = withTheme(
  ({
    theme,
    orderCountByMonth,
    closeRatioPercentage,
    grossMarginPercentage,
    averageTicketRevenue,
    leadRatePercentage,
    targetRoiRate,
    rejectionRatePercentage,
  }) => {
    let sortedData = orderCountByMonth;

    let labels = sortedData.map((o) => {
      if (o.label) {
        return o.label;
      }

      let date = new Date(o.date);

      // return short month text as a label
      return date.toLocaleDateString("en-US", {
        month: "short",
      });
    });

    let datasetOneData = sortedData.map((o, index) => {
      return o.orderDoneCreatingCount;
    });

    let datasetTwoData = sortedData.map((o) => {
      return o.orderDoneCancelingCount;
    });

    let qrCodeScanDataset = sortedData.map((o) => {
      return o.qrCodeScanCount;
    });

    let datasetThreeData = sortedData.map((o) => {
      let number = new BigNumber(
        new BigNumber(o.orderSummedTransactions).toFixed(2)
      );

      return number.toNumber();
    });

    // TODO: floating ROI label

    const data = {
      labels: labels,
      datasets: [
        {
          type: "line",
          label: "Spent",
          backgroundColor: "transparent",
          borderColor: blue[400],
          hoverBackgroundColor: blue[400],
          hoverBorderColor: blue[400],
          data: datasetThreeData,
          barPercentage: 0.625,
          categoryPercentage: 0.5,
          yAxisID: "money",
        },
        {
          type: "bar",
          label: "Canceled",
          backgroundColor: grey[400],
          borderColor: grey[400],
          hoverBackgroundColor: grey[400],
          hoverBorderColor: grey[400],
          data: datasetTwoData,
          barPercentage: 0.625,
          categoryPercentage: 0.5,
          yAxisID: "count",
        },
        {
          type: "bar",
          label: "Created",
          backgroundColor: blue[600],
          borderColor: blue[600],
          hoverBackgroundColor: blue[600],
          hoverBorderColor: blue[600],
          data: datasetOneData,
          barPercentage: 0.625,
          categoryPercentage: 0.5,
          yAxisID: "count",
        },
        {
          type: "bar",
          label: "QR Scans",
          backgroundColor: yellow[600],
          borderColor: yellow[600],
          hoverBackgroundColor: yellow[600],
          hoverBorderColor: yellow[600],
          data: qrCodeScanDataset,
          barPercentage: 0.625,
          categoryPercentage: 0.5,
          yAxisID: "count",
        },
      ],
    };

    let closeRatioPercentage_number = parseFloat(closeRatioPercentage) / 100;
    let grossMarginPercentage_number = parseFloat(grossMarginPercentage) / 100;
    let averageTicketRevenue_number = parseFloat(averageTicketRevenue);
    let leadRatePercentage_number = parseFloat(leadRatePercentage) / 100;
    let rejectionRatePercentage_number =
      parseFloat(rejectionRatePercentage) / 100;

    let datasetFourData = [];
    let datasetFiveData = [];

    if (
      closeRatioPercentage_number &&
      grossMarginPercentage_number &&
      averageTicketRevenue_number &&
      leadRatePercentage_number &&
      rejectionRatePercentage_number &&
      targetRoiRate
    ) {
      sortedData.forEach((o, index) => {
        let rejectedOrderCount =
          o.orderDoneCreatingCount * rejectionRatePercentage_number;
        let monthlyOrderCount = o.orderDoneCreatingCount - rejectedOrderCount;

        let monthlySpendSum = new BigNumber(
          o.orderSummedTransactions
        ).toNumber();

        let leadRate = leadRatePercentage_number;

        // no orders, we couldn't have made anything if nothing went out
        // no leads, we couldn't have made anything if nothing turned into a lead
        if (monthlyOrderCount < 1 || !leadRate) {
          datasetFourData.push(0);
          datasetFiveData.push(0);
          return;
        }

        let newAppointments = monthlyOrderCount * leadRate;

        let newJobs = newAppointments * closeRatioPercentage_number;

        let newRevenue = newJobs * averageTicketRevenue_number;

        let returnOnInvestment = newRevenue * grossMarginPercentage_number;
        let returnOnInvestmentPercentage =
          Math.abs(returnOnInvestment / monthlySpendSum) || 0;

        datasetFourData.push(returnOnInvestment.toFixed(2));
        datasetFiveData.push(
          parseFloat(returnOnInvestmentPercentage.toFixed(2))
        );
      });

      data.datasets.unshift({
        label: "Target ROI",
        type: "line",
        backgroundColor: "transparent",
        borderColor: grey[300],
        hoverBackgroundColor: grey[300],
        hoverBorderColor: grey[300],
        data: datasetFiveData.map((o, index) => {
          let wantedROIRate = parseFloat(targetRoiRate) || 4;
          return wantedROIRate;
        }),
        barPercentage: 0.625,
        categoryPercentage: 0.5,
        yAxisID: "roi",
      });

      data.datasets.unshift({
        label: "ROI Rate",
        type: "line",
        backgroundColor: "transparent",
        borderColor: green[200],
        hoverBackgroundColor: green[200],
        hoverBorderColor: green[200],
        data: datasetFiveData,
        barPercentage: 0.625,
        categoryPercentage: 0.5,
        yAxisID: "roi",
      });

      data.datasets.unshift({
        label: "ROI Yield",
        type: "line",
        backgroundColor: "transparent",
        borderColor: green[500],
        hoverBackgroundColor: green[500],
        hoverBorderColor: green[500],
        data: datasetFourData,
        barPercentage: 0.625,
        categoryPercentage: 0.5,
        yAxisID: "money",
      });
    }

    const options = {
      maintainAspectRatio: false,
      cornerRadius: 2,
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            let value = tooltipItem.yLabel;

            if (data.datasets[tooltipItem.datasetIndex].yAxisID === "money") {
              value = new BigNumber(
                new BigNumber(tooltipItem.yLabel).toFixed(2)
              ).toFormat({
                prefix: "$",
                decimalSeparator: ".",
                groupSeparator: ",",
                groupSize: 3,
                secondaryGroupSize: 0,
                fractionGroupSeparator: " ",
                fractionGroupSize: 0,
                suffix: "",
              });

              if (
                data.datasets[tooltipItem.datasetIndex].label === "ROI Yield"
              ) {
                value = `${value} - ${new BigNumber(
                  datasetFiveData[tooltipItem.index]
                ).toFixed(2)}`;
              }
            }

            return `${data.datasets[tooltipItem.datasetIndex].label}: ${value}`;
          },
        },
      },
      scales: {
        yAxes: [
          {
            id: "count",
            position: "left",
            gridLines: {
              display: false,
            },
            stacked: false,
            ticks: {
              stepSize: 20,
              beginAtZero: true,
            },
          },
          {
            id: "money",
            position: "right",
            gridLines: {
              display: false,
            },
            stacked: false,
            ticks: {
              callback: function (value, index, values) {
                return new BigNumber(value).toFormat({
                  prefix: "$",
                  decimalSeparator: ".",
                  groupSeparator: ",",
                  groupSize: 3,
                  secondaryGroupSize: 0,
                  fractionGroupSeparator: " ",
                  fractionGroupSize: 0,
                  suffix: "",
                });
              },
              stepSize: 1000,
              beginAtZero: true,
            },
          },
          {
            id: "roi",
            position: "right",
            gridLines: {
              display: false,
            },
            stacked: false,
            ticks: {
              stepSize: 2,
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            stacked: false,
            gridLines: {
              color: "transparent",
            },
          },
        ],
      },
    };

    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Orders Statistics
          </Typography>

          <Spacer mb={6} />

          <ChartWrapper>
            <Bar data={data} options={options} />
          </ChartWrapper>
        </CardContent>
      </Card>
    );
  }
);

function getFormatedMoney(value) {
  let m = new BigNumber(value);
  let mo = new BigNumber(m.abs());
  let mon = mo.toFormat({
    prefix: "$",
    decimalSeparator: ".",
    groupSeparator: ",",
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: " ",
    fractionGroupSize: 0,
    suffix: "",
  });

  if (m.isNegative()) {
    mon = `-${mon}`;
  }

  return mon;
}

function Dashboard() {
  const user = useSelector(getActiveUser).user;
  const currentUserKey = useSelector(getCurrentUserKey);
  const balance = useSelector(getActiveAccountDetails).balance;

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [balanceStatistics, setBalanceStatistics] = useState(null);
  const [localStoragePrefix, setLocalStoragePrefix] = useState(
    `${currentUserKey}_dashboard`
  );
  const [qrScanCount, setQrScanCount] = useState(0);
  useEffect(() => {
    setLocalStoragePrefix(`${currentUserKey}_dashboard`);
  }, [currentUserKey]);

  const [syncTabs, setSyncTabs] = useState(true);
  const [syncPage, setSyncPage] = useState(true);

  const [closeRatioPercentage, setCloseRatioPercentage] = useLocalStorage(
    `${localStoragePrefix}_closeRatioPercentage`,
    "",
    {
      syncTabs: syncTabs,
      syncPage: syncPage,
    }
  );

  const [grossMarginPercentage, setGrossMarginPercentage] = useLocalStorage(
    `${localStoragePrefix}_grossMarginPercentage`,
    "",
    {
      syncTabs: syncTabs,
      syncPage: syncPage,
    }
  );
  const [averageTicketRevenue, setAverageTicketRevenue] = useLocalStorage(
    `${localStoragePrefix}_averageTicketRevenue`,
    "",
    {
      syncTabs: syncTabs,
      syncPage: syncPage,
    }
  );

  const [leadRatePercentage, setLeadRatePercentage] = useLocalStorage(
    `${localStoragePrefix}_leadRatePercentage`,
    4,
    {
      syncTabs: syncTabs,
      syncPage: syncPage,
    }
  );

  const [targetRoiRate, setTargetRoiRate] = useLocalStorage(
    `${localStoragePrefix}_targetRoiRate`,
    4,
    {
      syncTabs: syncTabs,
      syncPage: syncPage,
    }
  );

  const [rejectionRatePercentage, setRejectionRatePercentage] = useLocalStorage(
    `${localStoragePrefix}_rejectionRatePercentage`,
    4,
    {
      syncTabs: syncTabs,
      syncPage: syncPage,
    }
  );

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const baseStatisticsData = await getAllStatistics();
        const balanceStats = await getBalanceStatistics();
        const qrCodeScanData = await getAllQrCodeScans({
          limit: 0,
        });
        setStatistics(baseStatisticsData.data);
        setQrScanCount(qrCodeScanData.data.qrCodeScanCount);
        setBalanceStatistics(balanceStats.data);
        setIsLoading(false);
      } catch (err) {
        let errorText = getRequestErrorMessage({
          error: err,
          message: "Something went wrong loading your dashboard.",
        });
        setErrorMessage(errorText);
        setIsLoading(false);
      }
    }

    fetchData();
  }, [currentUserKey]);

  if (isLoading) {
    return (
      <Loading>
        <CircularProgress size={75} />
      </Loading>
    );
  }

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />

      <Typography variant="h3" gutterBottom display="inline">
        Dashboard
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Link component={NavLink} exact to="/">
          Pages
        </Link>
        <Typography>Dashboard</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {errorMessage || !statistics ? (
        <React.Fragment>
          <Alert mt={2} mb={1} severity="warning">
            {errorMessage ?? "Something went wrong loading your dashboard."}
          </Alert>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <OrdersByMonth
                orderCountByMonth={statistics.orderCountByMonth}
                closeRatioPercentage={closeRatioPercentage}
                grossMarginPercentage={grossMarginPercentage}
                averageTicketRevenue={averageTicketRevenue}
                leadRatePercentage={leadRatePercentage}
                targetRoiRate={targetRoiRate}
                rejectionRatePercentage={rejectionRatePercentage}
              />

              <Card mb={6}>
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        ROI Calculator
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="number"
                        label="AVG Close Rate"
                        variant="outlined"
                        value={closeRatioPercentage}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (value > 100) {
                            value = 100;
                          }
                          if (value < 0) {
                            value = 0;
                          }
                          setCloseRatioPercentage(value);
                        }}
                        fullWidth
                        my={2}
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="number"
                        label="AVG Gross Margin"
                        variant="outlined"
                        value={grossMarginPercentage}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (value > 100) {
                            value = 100;
                          }
                          if (value < 0) {
                            value = 0;
                          }
                          setGrossMarginPercentage(value);
                        }}
                        fullWidth
                        my={2}
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="number"
                        label="AVG Ticket Revenue"
                        variant="outlined"
                        value={averageTicketRevenue}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (value < 0) {
                            value = 0;
                          }
                          setAverageTicketRevenue(value);
                        }}
                        fullWidth
                        my={2}
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="number"
                        label="AVG Card Lead Rate"
                        variant="outlined"
                        value={leadRatePercentage}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (value > 100) {
                            value = 100;
                          }
                          if (value < 0) {
                            value = 0;
                          }
                          setLeadRatePercentage(value);
                        }}
                        fullWidth
                        my={2}
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="number"
                        label="AVG Card Rejection Rate"
                        variant="outlined"
                        value={rejectionRatePercentage}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (value > 100) {
                            value = 100;
                          }
                          if (value < 0) {
                            value = 0;
                          }
                          setRejectionRatePercentage(value);
                        }}
                        fullWidth
                        my={2}
                        required={true}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <TextField
                        type="number"
                        label="Target ROI"
                        variant="outlined"
                        value={targetRoiRate}
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          if (value < 0) {
                            value = 0;
                          }
                          setTargetRoiRate(value);
                        }}
                        fullWidth
                        my={2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card mb={6}>
                <CardContent>
                  {/* <Typography variant="h6" gutterBottom>
                    Overall Order Statistics
                  </Typography> */}
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Pending"}
                        count={statistics.orderPendingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"In Queue"}
                        count={statistics.orderDonePendingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Creating"}
                        count={statistics.orderCreatingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Created"}
                        count={statistics.orderDoneCreatingCount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Account Balance"}
                        count={getFormatedMoney(balanceStatistics.balance)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Estimated Balance (7 Day)"}
                        count={getFormatedMoney(
                          balanceStatistics.balanceInSevenDays
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Average Daily Spend (7 Day)"}
                        count={getFormatedMoney(
                          balanceStatistics.averageDailySpend
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats
                        label={"Balance Runs Out In 'X' Days"}
                        count={`${new BigNumber(
                          balanceStatistics.balanceRunsOutInXDays
                        ).toFixed(0)}`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <Stats label={"QR Scanned"} count={qrScanCount} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {/* <Products /> */}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Dashboard;
