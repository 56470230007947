import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import {
  CardContent,
  Button as MuiButton,
  Card as MuiCard,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Chip,
  Divider as MuiDivider,
  Grid,
  Link,
  CircularProgress,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardHeader,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  AddCircle as AddCircleIcon,
  ExpandMore,
} from "@material-ui/icons";
import { spacing, display } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  duplicateTemplateById,
  getHandWrittenNoteOrdersByTemplateId,
  getTemplateById,
  getAllQrCodeScans,
  getAllHandWrittenNoteOrders,
  getAllUserActivities,
  cancelHandWrittenNoteOrdersByTemplateId,
} from "../../backend";
import { getRequestErrorMessage, getUserNamePlus } from "../../helpers";
import { getActiveUser, getCurrentUserKey } from "../../redux/selectors";
import { globalHistory } from "../../history";
import AdvancedTable from "../tables/AdvancedTable";
import handWrittenNoteOrderRowColumns from "../../advancedTableConfigs/handWrittenNoteOrderRowColumns";
import qrCodeScanRowColumns from "../../advancedTableConfigs/qrCodeScanRowColumns";
import userActivityRowColumns from "../../advancedTableConfigs/userActivityRowColumns";
import PathPreview from "../../components/PathPreview";
import { Trash2 } from "react-feather";
import { red } from "@material-ui/core/colors";
import ConfirmDialog from "../components/ConfirmDialog";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(display);

function TemplateDetails(props) {
  const currentUserKey = useSelector(getCurrentUserKey);

  let templateId =
    _.get(props, "location.state.templateId", null) ??
    _.get(props, "match.params.templateId", null);
  if (templateId) {
    templateId = parseInt(templateId);
  }

  const [isCancelOrdersDialogOpen, setIsCancelOrdersDialogOpen] = useState(
    false
  );
  const cancelOrders_onConfirm = async (props) => {
    const submit = async () => {
      props.setIsLoading(true);
      try {
        let results = await cancelHandWrittenNoteOrdersByTemplateId(templateId);

        const affectedRows = results.data.affectedOrders;

        props.setSuccessMessage(
          `Canceled ${affectedRows} order(s) for template #${templateId}`
        );
      } catch (error) {
        props.setErrorMessage(
          getRequestErrorMessage({
            error,
            fallbackMessage: "Something went wrong with the request",
          })
        );
      }
      props.setIsLoading(false);
    };

    if (props.successMessage) {
      props.setIsDialogOpen(false);
      props.setIsLoading(false);
      props.setErrorMessage("");
      props.setSuccessMessage("");
    } else {
      await submit();
    }
  };

  const [isLoading, setIsLoading] = React.useState(true);
  const [isDuplicateLoading, setIsDuplicateLoading] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [
    duplicateTemplateErrorMessage,
    setDuplicateTemplateErrorMessage,
  ] = React.useState(null);
  const [templateData, setTemplateData] = React.useState(null);
  const [templateRows, setTemplateRows] = React.useState([]);
  const user = useSelector(getActiveUser).user;
  const [qrScanData, setQrScanData] = useState(null);
  const handleDuplicateMapCollection = async () => {
    setIsDuplicateLoading(true);
    setDuplicateTemplateErrorMessage(null);
    try {
      const newTemplateTextResponse = await duplicateTemplateById({
        templateId,
      });
      globalHistory.push({
        pathname: `/template/${newTemplateTextResponse.data.template.id}`,
        state: {
          templateId: newTemplateTextResponse.data.template.id,
        },
      });
    } catch (error) {
      let message = getRequestErrorMessage({
        error,
        fallbackMessage: "Something went wrong duplicating the template.",
      });
      setDuplicateTemplateErrorMessage(message);
    }

    setIsDuplicateLoading(false);
  };

  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      try {
        const response = await getTemplateById(templateId);
        setTemplateData(response.data.template);
        setErrorMessage(null);
        setDuplicateTemplateErrorMessage(null);
        const templateQrScanData = await getAllQrCodeScans({ templateId });
        setQrScanData(templateQrScanData.data);
      } catch (error) {
        let message = getRequestErrorMessage({
          error,
          fallbackMessage: "Something went wrong!",
        });

        setTemplateData(null);
        setErrorMessage(message);
      }
      setIsLoading(false);
    }

    if (templateId) {
      loadData();
    } else {
      setErrorMessage("The provided template id is not valid");
    }
  }, [templateId, currentUserKey]);

  if (!hasLoaded && !isLoading) {
    setHasLoaded(true);
  }

  const isOwner = user.id === templateData?.user_id;
  const isPublic = templateData?.is_public;

  return (
    <React.Fragment>
      <Helmet title="Template Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Template: #{templateId}
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to="/">
              Pages
            </Link>
            <Link component={NavLink} exact to="/template">
              Templates
            </Link>
            <Typography>Details</Typography>
          </Breadcrumbs>
        </Grid>
        {templateData && (
          <Grid item>
            <Grid container spacing={6}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleDuplicateMapCollection}
                >
                  <Typography variant="button" ml={2}>
                    Duplicate
                  </Typography>
                </Button>
              </Grid>
              {isOwner && (
                <React.Fragment>
                  <ConfirmDialog
                    isDialogOpen={isCancelOrdersDialogOpen}
                    setIsDialogOpen={(isOpen) =>
                      setIsCancelOrdersDialogOpen(isOpen)
                    }
                    onConfirm={cancelOrders_onConfirm}
                    onClose={(props) => {
                      if (props.successMessage) {
                        return null;
                      }

                      props.setIsDialogOpen(false);
                    }}
                    getConfirmButtonText={(props) => {
                      if (props.successMessage) {
                        return "Finish";
                      }
                      if (props.isLoading) {
                        return (
                          <CircularProgress
                            size={25}
                            style={{
                              ...(props.successMessage
                                ? {}
                                : { color: "white" }),
                            }}
                          />
                        );
                      }

                      return null;
                    }}
                    getCancelButtonProps={(props) => {
                      if (props.successMessage) {
                        return {
                          disabled: true,
                        };
                      }

                      return null;
                    }}
                    getConfirmButtonProps={(props) => {
                      if (props.successMessage) {
                        return {
                          color: "primary",
                        };
                      }

                      return {
                        style: {
                          backgroundColor: red[500],
                          color: "white",
                        },
                      };
                    }}
                    title="Cancel All Orders"
                  >
                    <Typography>
                      Are you sure you want to cancel all orders for this
                      template? This is irreversible.
                    </Typography>
                  </ConfirmDialog>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        flex: 1,
                        borderColor: red[500],
                      }}
                      onClick={() => {
                        setIsCancelOrdersDialogOpen(true);
                      }}
                    >
                      <Trash2
                        style={{
                          marginRight: 4,
                        }}
                        size={16}
                        color={red[500]}
                      />
                      <Typography
                        variant="button"
                        ml={2}
                        style={{
                          color: red[500],
                        }}
                      >
                        Cancel Orders
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={ReactRouterLink}
                      to={{
                        pathname: "/template/edit",
                        state: {
                          templateData: templateData,
                        },
                      }}
                    >
                      <EditIcon
                        style={{
                          marginRight: 4,
                        }}
                        size={16}
                      />
                      <Typography variant="button" ml={2}>
                        Edit Template
                      </Typography>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      component={ReactRouterLink}
                      to={{
                        pathname: "/order/new",
                        state: {
                          templateData: templateData,
                        },
                      }}
                    >
                      <AddCircleIcon
                        style={{
                          marginRight: 4,
                        }}
                        size={16}
                      />
                      <Typography variant="button" ml={2}>
                        Create Order
                      </Typography>
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Divider my={6} />

      {duplicateTemplateErrorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {duplicateTemplateErrorMessage}
        </Alert>
      )}

      {errorMessage && (
        <Alert mt={2} mb={1} severity="warning">
          {errorMessage}
        </Alert>
      )}

      {isLoading || isDuplicateLoading ? (
        <>
          <Loading>
            <CircularProgress />
          </Loading>
        </>
      ) : !templateData ? null : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom display="inline">
                          <Typography
                            variant="inherit"
                            color="textSecondary"
                            style={{
                              opacity: 0.4,
                            }}
                          >
                            Template{" "}
                          </Typography>
                          {templateData.name || "Unnamed Template"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="caption">Template No.</Typography>
                        <br />
                        <Chip
                          size="small"
                          mr={1}
                          mb={1}
                          label={`#${templateId}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Creation Date
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              // mr={1}
                              mb={1}
                              label={new Date(
                                templateData.createdAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                templateData.createdAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="left"
                          display="block"
                        >
                          Name
                        </Typography>
                        <Typography variant="body2" align="left">
                          {templateData.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="caption"
                          align="right"
                          display="block"
                        >
                          Last Updated
                        </Typography>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Chip
                              size="small"
                              // mr={1}
                              mb={1}
                              label={new Date(
                                templateData.updatedAt
                              ).toDateString()}
                            />
                            <br />
                            <Typography align="right">
                              {new Date(
                                templateData.updatedAt
                              ).toLocaleTimeString()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {isOwner && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="subtitle2"
                              align="left"
                              display="block"
                            >
                              Order Scan Count
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="caption"
                              align="right"
                              display="block"
                            >
                              <Chip
                                size="small"
                                // mr={1}
                                mb={1}
                                label={qrScanData.qrCodeScanCount}
                              />
                            </Typography>
                          </Grid>
                        </React.Fragment>
                      )}
                      <Grid item xs={12}></Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Shadow>
                <Card px={6} pt={6}>
                  <CardHeader
                    title={<Typography variant="h6">Preview</Typography>}
                  />
                  <CardContent>
                    <Accordion defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="subtitle1">Content</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          {/* upper text */}
                          {templateData.text_upper && (
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" gutterBottom>
                                Card Top Half
                              </Typography>
                              <Paper
                                variant="outlined"
                                style={{
                                  padding: "16px",
                                }}
                              >
                                <Typography variant="body1">
                                  {templateData.text_upper
                                    .split("\n")
                                    .map((line, index) => {
                                      if (!line) {
                                        return (
                                          <React.Fragment key={index}>
                                            <br />
                                          </React.Fragment>
                                        );
                                      }
                                      return (
                                        <React.Fragment key={index}>
                                          <br />
                                          {line}
                                        </React.Fragment>
                                      );
                                    })}
                                </Typography>
                              </Paper>
                            </Grid>
                          )}

                          {/* lower text */}
                          {templateData.text && (
                            <Grid item xs={12}>
                              <Typography variant="subtitle2" gutterBottom>
                                Card Bottom Half
                              </Typography>
                              <Paper
                                variant="outlined"
                                style={{
                                  padding: "16px",
                                }}
                              >
                                <Typography variant="body1">
                                  {templateData.text
                                    .split("\n")
                                    .map((line, index) => {
                                      if (!line) {
                                        return (
                                          <React.Fragment key={index}>
                                            <br />
                                          </React.Fragment>
                                        );
                                      }
                                      return (
                                        <React.Fragment key={index}>
                                          <br />
                                          {line}
                                        </React.Fragment>
                                      );
                                    })}
                                </Typography>
                              </Paper>
                            </Grid>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="subtitle1">Preview</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            {templateData.generated_preview_paths ? (
                              <Grid container spacing={3}>
                                {templateData.generated_preview_paths_upper && (
                                  <React.Fragment>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        Card Top Half
                                      </Typography>
                                      <Paper
                                        variant="outlined"
                                        style={{
                                          padding: "16px",
                                        }}
                                      >
                                        <PathPreview
                                          paths={
                                            templateData.generated_preview_paths_upper
                                          }
                                        />
                                      </Paper>
                                    </Grid>
                                  </React.Fragment>
                                )}
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    Card Bottom Half
                                  </Typography>
                                  <Paper
                                    variant="outlined"
                                    style={{
                                      padding: "16px",
                                    }}
                                  >
                                    <PathPreview
                                      paths={
                                        templateData.generated_preview_paths
                                      }
                                    />
                                  </Paper>
                                </Grid>
                              </Grid>
                            ) : (
                              <React.Fragment>
                                <Typography
                                  variant="subtitle2"
                                  color="textSecondary"
                                  align="center"
                                >
                                  No preview available.
                                </Typography>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                </Card>
              </Shadow>
            </Grid>
          </Grid>
          {isOwner && (
            <React.Fragment>
              <br />
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Divider my={6} />
                  <AdvancedTable
                    isCollapsed={true}
                    title="Orders"
                    rowColumns={handWrittenNoteOrderRowColumns}
                    identifier={templateId}
                    getRowData={async ({ limit, offset, order }) => {
                      let results = await getAllHandWrittenNoteOrders({
                        templateId,
                        limit,
                        offset,
                        order,
                      });

                      let parsedRowData = {
                        rows: results.data.orders,
                        totalRowCount: results.data.order_count,
                      };

                      return parsedRowData;
                    }}
                  />
                  <AdvancedTable
                    isCollapsed={true}
                    title="QR Scans"
                    rowColumns={qrCodeScanRowColumns}
                    identifier={templateId}
                    getRowData={async ({ limit, offset, order }) => {
                      let results = await getAllQrCodeScans({
                        templateId,
                        limit,
                        offset,
                        order,
                      });

                      let parsedRowData = {
                        rows: results.data.qrCodeScans,
                        totalRowCount: results.data.qrCodeScanCount,
                      };

                      return parsedRowData;
                    }}
                  />

                  <AdvancedTable
                    isCollapsed={true}
                    title="User Activity"
                    rowColumns={userActivityRowColumns}
                    identifier={templateId}
                    getRowData={async ({ limit, offset, order }) => {
                      let results = await getAllUserActivities({
                        limit,
                        offset,
                        order,
                        relations: [["template_text_id", templateId]],
                        includeResponsibility: true,
                      });

                      let parsedRowData = {
                        rows: results.data.userActivities,
                        totalRowCount: results.data.userActivityCount,
                      };

                      return parsedRowData;
                    }}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default TemplateDetails;
